/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Product from './Product';

/**
 * The ProductCode model module.
 * @module model/ProductCode
 * @version V1
 */
class ProductCode {
    /**
     * Constructs a new <code>ProductCode</code>.
     * @alias module:model/ProductCode
     */
    constructor() { 
        
        ProductCode.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProductCode</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProductCode} obj Optional instance to populate.
     * @return {module:model/ProductCode} The populated <code>ProductCode</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductCode();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('productSku')) {
                obj['productSku'] = ApiClient.convertToType(data['productSku'], 'String');
            }
            if (data.hasOwnProperty('codeString')) {
                obj['codeString'] = ApiClient.convertToType(data['codeString'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('activeAt')) {
                obj['activeAt'] = ApiClient.convertToType(data['activeAt'], 'Date');
            }
            if (data.hasOwnProperty('expireDate')) {
                obj['expireDate'] = ApiClient.convertToType(data['expireDate'], 'Date');
            }
            if (data.hasOwnProperty('accountIdRedeemedBy')) {
                obj['accountIdRedeemedBy'] = ApiClient.convertToType(data['accountIdRedeemedBy'], 'Number');
            }
            if (data.hasOwnProperty('userIdRedeemedBy')) {
                obj['userIdRedeemedBy'] = ApiClient.convertToType(data['userIdRedeemedBy'], 'Number');
            }
            if (data.hasOwnProperty('redeemedAt')) {
                obj['redeemedAt'] = ApiClient.convertToType(data['redeemedAt'], 'Date');
            }
            if (data.hasOwnProperty('resellerId')) {
                obj['resellerId'] = ApiClient.convertToType(data['resellerId'], 'Number');
            }
            if (data.hasOwnProperty('product')) {
                obj['product'] = Product.constructFromObject(data['product']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProductCode</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProductCode</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['productSku'] && !(typeof data['productSku'] === 'string' || data['productSku'] instanceof String)) {
            throw new Error("Expected the field `productSku` to be a primitive type in the JSON string but got " + data['productSku']);
        }
        // ensure the json data is a string
        if (data['codeString'] && !(typeof data['codeString'] === 'string' || data['codeString'] instanceof String)) {
            throw new Error("Expected the field `codeString` to be a primitive type in the JSON string but got " + data['codeString']);
        }
        // validate the optional field `product`
        if (data['product']) { // data not null
          Product.validateJSON(data['product']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
ProductCode.prototype['id'] = undefined;

/**
 * @member {String} productSku
 */
ProductCode.prototype['productSku'] = undefined;

/**
 * @member {String} codeString
 */
ProductCode.prototype['codeString'] = undefined;

/**
 * @member {Date} createdAt
 */
ProductCode.prototype['createdAt'] = undefined;

/**
 * @member {Date} activeAt
 */
ProductCode.prototype['activeAt'] = undefined;

/**
 * @member {Date} expireDate
 */
ProductCode.prototype['expireDate'] = undefined;

/**
 * @member {Number} accountIdRedeemedBy
 */
ProductCode.prototype['accountIdRedeemedBy'] = undefined;

/**
 * @member {Number} userIdRedeemedBy
 */
ProductCode.prototype['userIdRedeemedBy'] = undefined;

/**
 * @member {Date} redeemedAt
 */
ProductCode.prototype['redeemedAt'] = undefined;

/**
 * @member {Number} resellerId
 */
ProductCode.prototype['resellerId'] = undefined;

/**
 * @member {module:model/Product} product
 */
ProductCode.prototype['product'] = undefined;






export default ProductCode;

