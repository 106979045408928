import TemplateApi from "../generated/api/TemplateApi";
import SaveTemplateRequest from "../generated/model/SaveTemplateRequest";

class TemplateService {
  constructor() {
    this.TemplateApi = new TemplateApi();
  }

  async GetAll() {
    let response = await window.$A.HandleApiResponse(
      this.TemplateApi.getAll_1(),
      "",
      true
    );
    if (response != null) {
      return response;
    } else {
      return [];
    }
  }

  async Save(template) {
    if (!template.name || template.name.length < 3) {
      window.$A.AlertUser("Please include a name", "warning");
      return template;
    }
    if (template.subject === undefined || template.subject.length < 3) {
      window.$A.AlertUser("Please include a subject", "warning");
      return template;
    }
    if (template.html === undefined || template.html.length < 3) {
      window.$A.AlertUser("Please include the message", "warning");
      return template;
    }

    if (template.id !== undefined && template.id !== 0) {
      let response = await window.$A.HandleApiResponse(
        this.TemplateApi.updateTemplate(template.id, {
          saveTemplateRequest: new SaveTemplateRequest(
            template.name,
            template.subject,
            template.html,
            template.bccUserIds
          ),
        }),
        "Template " + template.name + " Saved!"
      );
      return response != null ? response : template;
    } else {
      let response = await window.$A.HandleApiResponse(
        this.TemplateApi.createTemplate({
          saveTemplateRequest: new SaveTemplateRequest(
            template.name,
            template.subject,
            template.html,
            template.bccUserIds
          ),
        }),
        "Template " + template.name + " Saved!"
      );
      return response != null ? response : template;
    }
  }

  async Delete(id) {
    let response = await window.$A.HandleApiResponse(
      this.TemplateApi.deleteTemplate(id, null),
      "Template Deleted!"
    );

    if (response != null) {
      return true;
    } else {
      return false;
    }
  }
  async Duplicate(id) {
    let response = await window.$A.HandleApiResponse(
      this.TemplateApi.duplicateTemplate(id),
      "Template Duplicated!"
    );

    if (response != null) {
      return true;
    } else {
      return false;
    }
  }
}

export default TemplateService;
