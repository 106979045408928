/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import IUrlHelper from './IUrlHelper';

/**
 * The RedirectResult model module.
 * @module model/RedirectResult
 * @version V1
 */
class RedirectResult {
    /**
     * Constructs a new <code>RedirectResult</code>.
     * @alias module:model/RedirectResult
     */
    constructor() { 
        
        RedirectResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>RedirectResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RedirectResult} obj Optional instance to populate.
     * @return {module:model/RedirectResult} The populated <code>RedirectResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RedirectResult();

            if (data.hasOwnProperty('permanent')) {
                obj['permanent'] = ApiClient.convertToType(data['permanent'], 'Boolean');
            }
            if (data.hasOwnProperty('preserveMethod')) {
                obj['preserveMethod'] = ApiClient.convertToType(data['preserveMethod'], 'Boolean');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('urlHelper')) {
                obj['urlHelper'] = IUrlHelper.constructFromObject(data['urlHelper']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RedirectResult</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RedirectResult</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['url'] && !(typeof data['url'] === 'string' || data['url'] instanceof String)) {
            throw new Error("Expected the field `url` to be a primitive type in the JSON string but got " + data['url']);
        }
        // validate the optional field `urlHelper`
        if (data['urlHelper']) { // data not null
          IUrlHelper.validateJSON(data['urlHelper']);
        }

        return true;
    }


}



/**
 * @member {Boolean} permanent
 */
RedirectResult.prototype['permanent'] = undefined;

/**
 * @member {Boolean} preserveMethod
 */
RedirectResult.prototype['preserveMethod'] = undefined;

/**
 * @member {String} url
 */
RedirectResult.prototype['url'] = undefined;

/**
 * @member {module:model/IUrlHelper} urlHelper
 */
RedirectResult.prototype['urlHelper'] = undefined;






export default RedirectResult;

