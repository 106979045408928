import EmailApi from "../generated/api/EmailApi";
import PreviewReminderEmailRequest from "@/generated/model/PreviewReminderEmailRequest";
import SmtpServerInfo from "../generated/model/SmtpServerInfo";

class EmailService {
    constructor() {
        this.EmailApi = new EmailApi();
    }

    async GetStatus() {
        return await window.$A.HandleApiResponse(
            this.EmailApi.getAuthStatus(null),
            "",
            true
        );
    }

    async Authorize() {
        let r = await window.$A.HandleApiResponse(
            this.EmailApi.registerSender(null),
            "You have Authorized Ashore To Send Email On Your Behalf"
        );

        return r;
    }

    async RegisterSmtpServer(userName, password, serverAddress, serverPort) {
        let r = await window.$A.HandleApiResponse(
            this.EmailApi.registerSmtpServer({
                smtpServerInfo: SmtpServerInfo.constructFromObject({userName, password, serverAddress, serverPort})
            }),
            ""
        );

        return r;
    }

    async GetSmtpServer() {
        return await window.$A.HandleApiResponse(
            this.EmailApi.getSmtpServer(null),
            "",
            true
        );
    }

    async UnAuthorize() {
        let r = await window.$A.HandleApiResponse(
            this.EmailApi.unAuthorizeSender(null),
            "You have Unauthorized Ashore To Send Email On Your Behalf"
        );

        return r;
    }

    async GetEmailLog(logId) {
        let r = await window.$A.HandleApiResponse(
            this.EmailApi.viewEmail(logId, null),
            "",
            true
        );

        return r;
    }

    async GetEmailPreview(subject, body) {
        let request = PreviewReminderEmailRequest.constructFromObject({
            subject: subject,
            body: body,
            approverId: null,
            proofId: null
        })

        let response = await window.$A.HandleApiResponse(
            this.EmailApi.previewEmail({
                previewReminderEmailRequest: request
            }),
            "",
            false
        );
        return response;

    }
}

export default EmailService;
