import ReviewApi from "../generated/api/ReviewApi";
import RegisterPublicApprover from "../generated/model/RegisterPublicApprover";
import ProofApproverResponse from "../generated/model/ProofApproverResponse";
import ApiClient from "../generated/ApiClient";
import moment from "moment-timezone";
const axios = require("axios");

class ReviewService {
  constructor() {
    this.ReviewApi = new ReviewApi();
    this.curLang = "en";
    this.curApproverKeyChain = null;
    this.curLangDict = {};
  }

  async TryToGetApproverIdentity(publicProofId, publicApproverId) {
    let keychain = window.$A.LoadDataCache(`approver_keychain_${publicProofId}_${publicApproverId}`, true);

    if (keychain == null) {
      keychain = {
        approver: {},
        lang: "en",
      };
    }

    if (keychain.keys !== undefined && keychain.keys.length > 0) {
      //fix old object pattern
      keychain.approver.publicId = keychain.keys[0].publicApproverId;
      keychain.keys = undefined;
      keychain.lang = "en";
      window.$A.SaveDataCache(`approver_keychain_${publicProofId}_${keychain.keys[0].publicApproverId}`, keychain, 90, true);
    }

    if (
      keychain.approver !== undefined &&
      keychain.approver.publicId !== undefined &&
      publicApproverId === undefined
    ) {
      publicApproverId = keychain.approver.publicId;
    }

    if (publicApproverId !== undefined && publicApproverId !== null) {
      let loadResponse = await this.LoadApprover(
        publicProofId,
        publicApproverId
      );
      if (
        loadResponse &&
        loadResponse.success === false
      ) {
        if (
          loadResponse.message.indexOf("does not exist") !== -1 &&
          loadResponse.message.indexOf("Proof") !== -1
        ) {
          return {
            error: "This proof has been deleted.",
          };
        }
      }
      if (loadResponse.id > 0) {
        return this.SaveApproverInKeyChainIfDoesNotExistAndReturnIdentity(
          loadResponse,
          publicProofId
        );
      }
    }

    //auto register
    if (
      keychain.approver.name !== undefined &&
      keychain.approver.email !== undefined
    ) {
      let response = await this.RegisterApprover(
        publicProofId,
        keychain.approver.name,
        keychain.approver.email
      );
      if (response && response.success === false) {
        if (
          response.message.indexOf("does not exist") >= 0 &&
          response.message.indexOf("Proof") >= 0
        ) {
          return {
            error: "This proof has been deleted.",
          };
        }
      }
      if (response.id > 0) {
        return this.SaveApproverInKeyChainIfDoesNotExistAndReturnIdentity(
          response,
          publicProofId
        );
      }
    }

    //need to manually register
    return null;
  }

  SaveApproverInKeyChainIfDoesNotExistAndReturnIdentity(approver, publicProofId) {
    if (approver && approver.success === false) {
      window.$A.AlertUser(approver.message, "warning");
      return "accessDenied";
    }
    let keychain = {
      approver: {
        name: approver.name,
        email: approver.email,
        publicId: approver.publicId,
      },
    };
    window.$A.SaveDataCache(`approver_keychain_${publicProofId}_${approver.publicId}`, keychain, 90, true);
    approver.token = approver.approverProofToken.token;
    approver.approverProofToken = null;
    this.curApproverKeyChain = approver;
    return approver;
  }
  SetLoggedInApprover(approverIdentity) {
    // console.log(approverIdentity)
    let token = approverIdentity.token;
    window.$A.Auth = null;
    ApiClient.instance.authentications["AccessToken"].apiKey = token;
    window.$A.Api = axios.create({
      baseURL: window.$A.Env.apiHost + "/",
      timeout: 1000,
      headers: {
        AccessToken: token,
      },
    });
  }

  async WhoAmI() {
    return await window.$A.HandleApiResponse(
      this.ReviewApi.whoAmi(null),
      "",
      true
    );
  }
  async MarkTourCompleted() {
    return await window.$A.HandleApiResponse(
      this.ReviewApi.markTourCompleted(null),
      "",
      true
    );
  }
  async GetCurrentProofAccountOwner(publicProofId) {
    return await window.$A.HandleApiResponse(
      this.ReviewApi.getOwnerOfProof(publicProofId),
      "",
      true
    );
  }
  async GetProofToReview(proofVersionId) {
    return await window.$A.HandleApiResponse(
      this.ReviewApi.getProofToReview(proofVersionId),
      "",
      true
    );
  }

  async RegisterApprover(publicProofId, name, email) {
    let obj = RegisterPublicApprover.constructFromObject({
      name: name,
      email: email,
      timeZone: moment.tz.zone(moment.tz.guess()).name,
    });
    return await window.$A.HandleApiResponse(
      this.ReviewApi.registerPublicApprover(
        publicProofId,
        {
          registerPublicApprover: obj,
        },
        null
      ),
      "",
      true
    );
  }
  async LoadApprover(publicProofId, publicApproverId) {
    return await window.$A.HandleApiResponse(
      this.ReviewApi.loadApproverByPublicId(
        publicProofId,
        publicApproverId,
        moment.tz.zone(moment.tz.guess()).name,
        null
      )
    );
  }

  async ProcessApproverDecision(approvalStatus) {
    let responseObj = ProofApproverResponse.constructFromObject(approvalStatus);

    return await window.$A.HandleApiResponse(
      this.ReviewApi.recordApproverResponse(
        {
          proofApproverResponse: responseObj,
        },
        null
      ),
      "",
      true
    );
  }

  async AlertServerOfUserClosingReview(publicProofId) {
    return await window.$A.HandleApiResponse(
      this.ReviewApi.proofClosed(publicProofId, null),
      "",
      true
    );
  }

  ChangeLang(lang) {
    if (lang !== undefined) {
      // console.log(lang)
      window.$A.SaveDataCache("lang", lang, 500);
      window.location.reload();
      return;
    }

    let savedLang = window.$A.LoadDataCache("lang");
    // console.log(savedLang)
    if (lang === undefined && savedLang !== null) {
      this.curLang = savedLang;
    }

    let l = [
      {
        lang: "en",
        "about-to-approve-with-changes": "You're about to approve with changes",
        "pls-approve-each-file":
          "Please make an approval decision on each file",
        "about-to-submit-choice": "You're about to submit a decision",
        "submit-choice": "Submit Decision",
        "submit-not-approved": "Submit Not Approved",
        "about-to-not-approve": "You're about to Not Approve",
        "submit-approval": "Submit Approval",
        cancel: "Cancel",
        "any-other-feedback": "Any other feedback?",
        "please-acknowledge": "Please acknowledge the following",
        "about-to-approve": "You're about to approve",
        "toggle-comments": "Toggle Comments",
        comments: "Comments",
        collapse: "Collapse",
        "files-to-review": "Files to Review",
        "not-approved": "Not Approved",
        "approved-with-changes": "Approved with Changes",
        approved: "Approved",
        version: "Versions",
        download: "Download",
        "anno-single-click": "Single Click to Add Comment",
        "anno-rect": "Draw Rectangle to Add Comment",
        "anno-arrow": "Draw Arrow to Add Comment",
        "anno-draw": "Draw to Add Comment",
        "link-expired": "Your Link Has Expired",
        "proof-deleted": "This proof has been deleted",
        "error-save-comment-later":
          "Error Encountered, Please Save This Comment Later.",
        error: "Error Encountered",
      },
      {
        lang: "es",
        "about-to-approve-with-changes":
          "Estas A Punto De Ser Aprobado Con Cambios",
        "pls-approve-each-file":
          "Por Favor De Tomar Una Decision De Aprobacion En Cada Archivo",
        "about-to-submit-choice": "Estás A Punto De Enviar Una Decisión",
        "submit-choice": "Enviar Decisión",
        "submit-not-approved": "Enviar No Aprobado",
        "about-to-not-approve": "Estas A Punto De No Ser Aprobado",
        "submit-approval": "Enviar Aprobación",
        cancel: "Cancelar",
        "any-other-feedback": "¿Algún otro comentario?",
        "please-acknowledge": "Por favor reconozca lo siguiente",
        "about-to-approve": "Estás a punto de aprobar",
        "toggle-comments": "Alternar comentarios",
        comments: "Comentarios",
        collapse: "Colapso",
        "files-to-review": "Archivos Para Revisar",
        "not-approved": "No Aprobado",
        "approved-with-changes": "Aprobado Con Cambios",
        approved: "Aprobado",
        version: "Versiones",
        download: "Descargar",
        "anno-single-click": "Un Solo Clic Para Agregar Un Comentario",
        "anno-rect": "Dibujar Un Rectángulo Para Agregar Un Comentario",
        "anno-arrow": "Dibujar Flecha Para Agregar Un Comentario",
        "anno-draw": "Dibujar para Agregar Un Comentario",
        "link-expired": "Tu enlace se ha expirado",
        "proof-deleted": "Esta prueba ha sido eliminada",
        "error-save-comment-later":
          "Se ha producido un error. Guarde este comentario más tarde.",
      },
    ];

    let dict = l.find((s) => s["lang"] === this.curLang);

    if (dict == null) {
      dict = l.find((s) => s["lang"] === "en");
      console.log("lang does not exist");
    }

    this.curLangDict = dict;
  }
  Lang(key) {
    if (this.curLangDict.hasOwnProperty(key)) {
      return this.curLangDict[key];
    } else {
      console.log("lang dict does not contain " + key);
      return "";
    }
  }
}

export default ReviewService;
