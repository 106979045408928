<script>
import _ from 'lodash';
import { getAccountLogo } from "../../utils/helpers";

export default {
  props: ['updatedAccountLogo'],
  name: "navigation",
  methods: {
    LogOut() {
      this.$A.LogOut(true);
    },
    ForceUpdateView() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    get(object, path, defaultValue) {
      return _.get(object, path, defaultValue);
    },
  },
  computed: {
    activeItem: function () {
      const matchedItem = this.menuitems.find(mi => _.get(window, 'location.pathname', '').includes(mi.link));
      return matchedItem ? matchedItem.id : 0;
    },
    shouldShowDivider: function () {
      return (
          _.get(this.user, 'permissions.role.roleId') === 3 ||
          this.$A.AdminImpersonating !== null
      );
    },
    shouldShowCaptainsDeck: function () {
      return _.get(this.user, 'permissions.role.roleId') === 3;
    },
    curAccount: function () {
      return _.get(this.$A, 'Auth.account', {});
    },
    accountLogo: function () {
      return this.updatedAccountLogo || getAccountLogo();
    }
  },
  data() {
    return {
      user: this.$A.Auth,
      loadingData: false,
      alertMessage: "",
      alertType: "success",
      alertPromise: null,
      menuitems: [
        {
          id: 1,
          name: "Overview",
          link: "/overview",
        },
        {
          id: 2,
          name: "Contacts",
          link: "/contact-archive",
        },
        {
          id: 3,
          name: "Automation",
          link: "/automation-archive",
        },
        {
          id: 4,
          name: "Proofs",
          link: "/proof-archive",
        },
      ],
      recentActivity: [
        {
          id: 1,
          name: "Proof #1234 was marked not approved by Cody Miles",
          link: "/proof-item",
        },
        {
          id: 2,
          name: "Amy Miles left comments on Proof #4948",
          link: "/proof-item",
        },
        {
          id: 3,
          name: "Proof #1594 missed its deadline",
          link: "/proof-item",
        },
        {
          id: 4,
          name: "Proof #2339 was marked approved by Daniel Noel",
          link: "/proof-item",
        },
        {
          id: 5,
          name: "Proof #2339 was marked approved with changes by Abby Nash",
          link: "/proof-item",
        },
      ],
    };
  },
  mounted() {
    let self = this;
    this.$root.$on("alert::user", (message, type, timeout) => {
      if (timeout === undefined) {
        timeout = 15000;
      }

      self.alertType = type;

      if (self.alertPromise !== null) {
        self.alertMessage += "<br/>" + message;
      } else {
        self.alertMessage = message;
      }

      clearTimeout(self.alertPromise);
      self.alertPromise = setTimeout(function () {
        self.alertMessage = "";
        self.alertType = "success";
        self.alertPromise = null;
      }, timeout);
    });
    this.$root.$on("api::working", () => {
      self.loadingData = true;
    });
    this.$root.$on("api::idle", () => {
      self.loadingData = false;
    });
    this.$root.$on("nav::reload", () => {
      self.ForceUpdateView();
    });
    if (document.getElementById("hubspot-messages-iframe-container") === null) {
      if (document.getElementById("nav_support") !== null) {
        let a = document.getElementById("nav_support");
        a.parentElement.removeChild(a);
      }
      if (_.get(this.curAccount, 'subscriptionState.sku', []).indexOf('enterprise-code-stacking-1') === -1) {
        let hubSpot = document.createElement("script");
        hubSpot.setAttribute("src", "https://js.hs-scripts.com/4439126.js");
        hubSpot.setAttribute("id", "nav_support");
        document.head.appendChild(hubSpot);
      }
    }
  },
  beforeCreate() {
  },
  beforeDestroy() {
    if (document.getElementById("hubspot-messages-iframe-container") !== null) {
      let a = document.getElementById("hubspot-messages-iframe-container");
      a.parentElement.removeChild(a);
    }
    if (document.getElementById("nav_support") !== null) {
      let a = document.getElementById("nav_support");
      a.parentElement.removeChild(a);
    }
  },
};
</script>

<template>
  <!-- Navigation -->
  <div class="fixed-navbar">
    <nav class="navbar navbar-expand-lg navbar-light ashore-nav">
      <div class="container-fluid">
        <a href="/overview" class="ashore-logo" id="overview-tour-step-0"  :style="`background-image: url(${accountLogo}) !important;`"></a>
        <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li v-for="item in menuitems" v-bind:key="item.id"
                :class="item.id > 4 ? 'nav-item ':'nav-item nav-item-border'" :data-cy="item.name">
              <a :href="item.link" :class="activeItem === item.id ? 'active':'nav-link'"
                 :id="'nav_' + item.name.toLowerCase()">
                {{ item.name }}
              </a>
            </li>
            <li>
              <a data-cy="createProofNavLink"
                 href="/create"
                 id="overview-tour-step-1"
                 class="nav-gray-btn border-bottom-0"
              >Create Proof</a
              >
            </li>
          </ul>

          <!-- Account Nav Dropdown -->

          <div class="ml-auto d-flex flex-row-reverse account-nav">
            <ul class="navbar-nav">
              <li class="nav-item dropdown ashore-profile-dropdown">
                <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  <div
                      class="nav-profile-image"
                      v-if="$A.Auth && $A.Auth.profileImageUrl" 
                      :style="{
                      backgroundImage: 'url(' + $A.Auth.profileImageUrl + ')',
                    }"
                  ></div>
                  <span v-if="curAccount">{{ curAccount.name }}</span>
                  <span class="caret"></span>
                </a>
                <div
                    class="dropdown-menu dropdown-menu-left"
                    id="account_profile_dropdown"
                    aria-labelledby="account_profile_dropdown"
                    right
                >
                  <ul>
                    <li class="dropdown-item">
                      <a href="/profile" class="nav-link" id="nav_profile"
                      >My Profile</a
                      >
                    </li>
                    <li
                        class="dropdown-item"
                        v-show="get(user, 'permissions.manageAccount', false)"
                    >
                      <a
                          href="/account"
                          class="nav-link"
                          id="nav_account_settings"
                      >Account Settings</a
                      >
                    </li>
                    <!--  Users  -->
                    <li
                        class="dropdown-item"
                        v-show="get(user, 'permissions.manageUsers', false)"
                    >
                      <a
                          href="/users"
                          class="nav-link"
                          id="nav_users_settings"
                      >
                        Users
                      </a>
                    </li>
                    <!-- End Users -->
                    <li class="dropdown-item">
                      <a
                          href="/billing"
                          class="nav-link"
                          id="nav_billing"
                          v-show="get(user, 'permissions.manageBilling', false)"
                      >Billing</a
                      >
                    </li>
                    <li class="dropdown-item">
                      <a
                          href="https://kb.ashoreapp.com/"
                          target="_blank"
                          class="nav-link"
                      >Knowledge Base</a
                      >
                    </li>

                    <li
                        class="dropdown-divider"
                        v-show="shouldShowDivider"></li>
                    <li class="dropdown-item">
                      <a
                          href="/captains-deck"
                          class="nav-link"
                          v-show="shouldShowCaptainsDeck"
                      >Captains Deck</a>
                    </li>
                    <li
                        class="dropdown-item"
                        v-if="$A.AdminImpersonating !== null"
                    >
                      <a v-on:click="$A.GoBackToAdminAccount()" class="nav-link">Back To Admin User</a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li class="dropdown-item">
                      <a v-on:click="LogOut()" class="nav-link"> Log Out</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>

            <ul class="navbar-nav">
              <li class="nav-item dropdown bell-icon" style="display: none">
                <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  <div class="bell-notification">
                    {{ recentActivity.length }}
                  </div>
                  <img src="/img/bell-icon.svg"/>
                </a>
                <div
                    class="dropdown-menu dropdown-menu-left"
                    aria-labelledby="navbarDropdownMenuLink2"
                    right
                >
                  <ul>
                    <li
                        v-for="activity in recentActivity"
                        v-bind:key="activity.id"
                        class="dropdown-item"
                    >
                      <a :href="activity.link" class="nav-link">{{ activity.name }}</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>

            <b-spinner
                type="grow"
                label="Spinning"
                class="loading_data_spinner"
                v-show="loadingData == true"
            ></b-spinner>
          </div>
        </div>
      </div>
    </nav>
    <!--    <b-alert variant="warning" id="account-alert" class="nav-alert" :show="alertMessage.length>0" dismissible><span v-html="alertMessage"></span></b-alert>-->

    <b-alert
        :show="true"
        v-for="banner in get(curAccount, 'banners', [])"
        v-bind:key="banner.text"
        class="nav-alert"
        :variant="banner.vueAlertVariant"
        :class="banner.cssClass"
        dismissible
    >
      <span v-html="banner.text"></span>
      <a :href="banner.uri" class="banner-nav-link">{{
          banner.callToAction
        }}</a>
    </b-alert>

    <b-alert
        :variant="alertType"
        id="nav-alert"
        class="nav-alert"
        :show="alertMessage.length > 0"
        dismissible
    ><span v-html="alertMessage"></span
    ></b-alert>
  </div>
</template>

<style>
.banner-nav-link {
  font-weight: bold;
  color: white;
  padding-left: 5px;
}

.active {
  font-weight: bold;
}


.fixed-navbar {
  position: fixed;
  min-height: 0;
  margin-bottom: 0;
  top: 0;
  width: 100%;
  z-index: 888;
}

.nav-alert {
  z-index: 999;
  position: relative;
  text-align: center;
}

.alert-trial {
  background: #019255;
  color: #ffffff;
  border-color: none;
  position: fixed;
  margin: 0 0 0 0;
  width: 100%;
  bottom: 0;
}

.alert-trial a {
  color: #ffffff;
}

.alert-trial a:visited {
  color: #ffffff;
}

.nav-gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.nav-gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
  opacity: 0.8;
}

.nav-item-border:hover {
  opacity: 0.8;
}

@media (max-width: 991px) {
  .fixed-navbar {
    position: relative;
  }
}

@media screen and (max-width: 992px) {

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: white;
    align-items: center !important;
    padding-top: 10px !important;
  }

  .proof-timeline-sidebar[data-v-7233b33a] {
    margin-top: 10px;
  }

  .dropdown-menu {
    border: none !important;
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;*/
    box-shadow: none !important;
  }

  .dropdown-divider {
    border: 0px !important;
  }

  .account-nav {
    border-bottom: 0.4px solid lightgray !important;
  }

  .ashore-nav {
    background: #ffffff !important;
    border-top: 5px solid #019255 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0px !important;
    background: white !important;
  }

  .navbar-toggler {
    margin: 10px !important;
  }


}


</style>
