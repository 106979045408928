/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AsymmetricAlgorithm from './AsymmetricAlgorithm';
import Oid from './Oid';
import PublicKey from './PublicKey';
import X500DistinguishedName from './X500DistinguishedName';
import X509Extension from './X509Extension';

/**
 * The X509Certificate2 model module.
 * @module model/X509Certificate2
 * @version V1
 */
class X509Certificate2 {
    /**
     * Constructs a new <code>X509Certificate2</code>.
     * @alias module:model/X509Certificate2
     */
    constructor() { 
        
        X509Certificate2.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>X509Certificate2</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/X509Certificate2} obj Optional instance to populate.
     * @return {module:model/X509Certificate2} The populated <code>X509Certificate2</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new X509Certificate2();

            if (data.hasOwnProperty('archived')) {
                obj['archived'] = ApiClient.convertToType(data['archived'], 'Boolean');
            }
            if (data.hasOwnProperty('extensions')) {
                obj['extensions'] = ApiClient.convertToType(data['extensions'], [X509Extension]);
            }
            if (data.hasOwnProperty('friendlyName')) {
                obj['friendlyName'] = ApiClient.convertToType(data['friendlyName'], 'String');
            }
            if (data.hasOwnProperty('hasPrivateKey')) {
                obj['hasPrivateKey'] = ApiClient.convertToType(data['hasPrivateKey'], 'Boolean');
            }
            if (data.hasOwnProperty('privateKey')) {
                obj['privateKey'] = AsymmetricAlgorithm.constructFromObject(data['privateKey']);
            }
            if (data.hasOwnProperty('issuerName')) {
                obj['issuerName'] = X500DistinguishedName.constructFromObject(data['issuerName']);
            }
            if (data.hasOwnProperty('notAfter')) {
                obj['notAfter'] = ApiClient.convertToType(data['notAfter'], 'Date');
            }
            if (data.hasOwnProperty('notBefore')) {
                obj['notBefore'] = ApiClient.convertToType(data['notBefore'], 'Date');
            }
            if (data.hasOwnProperty('publicKey')) {
                obj['publicKey'] = PublicKey.constructFromObject(data['publicKey']);
            }
            if (data.hasOwnProperty('rawData')) {
                obj['rawData'] = ApiClient.convertToType(data['rawData'], 'Blob');
            }
            if (data.hasOwnProperty('serialNumber')) {
                obj['serialNumber'] = ApiClient.convertToType(data['serialNumber'], 'String');
            }
            if (data.hasOwnProperty('signatureAlgorithm')) {
                obj['signatureAlgorithm'] = Oid.constructFromObject(data['signatureAlgorithm']);
            }
            if (data.hasOwnProperty('subjectName')) {
                obj['subjectName'] = X500DistinguishedName.constructFromObject(data['subjectName']);
            }
            if (data.hasOwnProperty('thumbprint')) {
                obj['thumbprint'] = ApiClient.convertToType(data['thumbprint'], 'String');
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'Number');
            }
            if (data.hasOwnProperty('handle')) {
                obj['handle'] = ApiClient.convertToType(data['handle'], Object);
            }
            if (data.hasOwnProperty('issuer')) {
                obj['issuer'] = ApiClient.convertToType(data['issuer'], 'String');
            }
            if (data.hasOwnProperty('subject')) {
                obj['subject'] = ApiClient.convertToType(data['subject'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>X509Certificate2</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>X509Certificate2</code>.
     */
    static validateJSON(data) {
        if (data['extensions']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['extensions'])) {
                throw new Error("Expected the field `extensions` to be an array in the JSON data but got " + data['extensions']);
            }
            // validate the optional field `extensions` (array)
            for (const item of data['extensions']) {
                X509Extension.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['friendlyName'] && !(typeof data['friendlyName'] === 'string' || data['friendlyName'] instanceof String)) {
            throw new Error("Expected the field `friendlyName` to be a primitive type in the JSON string but got " + data['friendlyName']);
        }
        // validate the optional field `privateKey`
        if (data['privateKey']) { // data not null
          AsymmetricAlgorithm.validateJSON(data['privateKey']);
        }
        // validate the optional field `issuerName`
        if (data['issuerName']) { // data not null
          X500DistinguishedName.validateJSON(data['issuerName']);
        }
        // validate the optional field `publicKey`
        if (data['publicKey']) { // data not null
          PublicKey.validateJSON(data['publicKey']);
        }
        // ensure the json data is a string
        if (data['serialNumber'] && !(typeof data['serialNumber'] === 'string' || data['serialNumber'] instanceof String)) {
            throw new Error("Expected the field `serialNumber` to be a primitive type in the JSON string but got " + data['serialNumber']);
        }
        // validate the optional field `signatureAlgorithm`
        if (data['signatureAlgorithm']) { // data not null
          Oid.validateJSON(data['signatureAlgorithm']);
        }
        // validate the optional field `subjectName`
        if (data['subjectName']) { // data not null
          X500DistinguishedName.validateJSON(data['subjectName']);
        }
        // ensure the json data is a string
        if (data['thumbprint'] && !(typeof data['thumbprint'] === 'string' || data['thumbprint'] instanceof String)) {
            throw new Error("Expected the field `thumbprint` to be a primitive type in the JSON string but got " + data['thumbprint']);
        }
        // ensure the json data is a string
        if (data['issuer'] && !(typeof data['issuer'] === 'string' || data['issuer'] instanceof String)) {
            throw new Error("Expected the field `issuer` to be a primitive type in the JSON string but got " + data['issuer']);
        }
        // ensure the json data is a string
        if (data['subject'] && !(typeof data['subject'] === 'string' || data['subject'] instanceof String)) {
            throw new Error("Expected the field `subject` to be a primitive type in the JSON string but got " + data['subject']);
        }

        return true;
    }


}



/**
 * @member {Boolean} archived
 */
X509Certificate2.prototype['archived'] = undefined;

/**
 * @member {Array.<module:model/X509Extension>} extensions
 */
X509Certificate2.prototype['extensions'] = undefined;

/**
 * @member {String} friendlyName
 */
X509Certificate2.prototype['friendlyName'] = undefined;

/**
 * @member {Boolean} hasPrivateKey
 */
X509Certificate2.prototype['hasPrivateKey'] = undefined;

/**
 * @member {module:model/AsymmetricAlgorithm} privateKey
 */
X509Certificate2.prototype['privateKey'] = undefined;

/**
 * @member {module:model/X500DistinguishedName} issuerName
 */
X509Certificate2.prototype['issuerName'] = undefined;

/**
 * @member {Date} notAfter
 */
X509Certificate2.prototype['notAfter'] = undefined;

/**
 * @member {Date} notBefore
 */
X509Certificate2.prototype['notBefore'] = undefined;

/**
 * @member {module:model/PublicKey} publicKey
 */
X509Certificate2.prototype['publicKey'] = undefined;

/**
 * @member {Blob} rawData
 */
X509Certificate2.prototype['rawData'] = undefined;

/**
 * @member {String} serialNumber
 */
X509Certificate2.prototype['serialNumber'] = undefined;

/**
 * @member {module:model/Oid} signatureAlgorithm
 */
X509Certificate2.prototype['signatureAlgorithm'] = undefined;

/**
 * @member {module:model/X500DistinguishedName} subjectName
 */
X509Certificate2.prototype['subjectName'] = undefined;

/**
 * @member {String} thumbprint
 */
X509Certificate2.prototype['thumbprint'] = undefined;

/**
 * @member {Number} version
 */
X509Certificate2.prototype['version'] = undefined;

/**
 * @member {Object} handle
 */
X509Certificate2.prototype['handle'] = undefined;

/**
 * @member {String} issuer
 */
X509Certificate2.prototype['issuer'] = undefined;

/**
 * @member {String} subject
 */
X509Certificate2.prototype['subject'] = undefined;






export default X509Certificate2;

