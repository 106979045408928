/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofVersionFile from './ProofVersionFile';
import ProofVersionStatus from './ProofVersionStatus';
import User from './User';
import WorkflowStage from './WorkflowStage';

/**
 * The ProofVersion model module.
 * @module model/ProofVersion
 * @version V1
 */
class ProofVersion {
    /**
     * Constructs a new <code>ProofVersion</code>.
     * @alias module:model/ProofVersion
     */
    constructor() { 
        
        ProofVersion.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofVersion</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofVersion} obj Optional instance to populate.
     * @return {module:model/ProofVersion} The populated <code>ProofVersion</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofVersion();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('workflowStageId')) {
                obj['workflowStageId'] = ApiClient.convertToType(data['workflowStageId'], 'Number');
            }
            if (data.hasOwnProperty('senderUserId')) {
                obj['senderUserId'] = ApiClient.convertToType(data['senderUserId'], 'Number');
            }
            if (data.hasOwnProperty('versionNumber')) {
                obj['versionNumber'] = ApiClient.convertToType(data['versionNumber'], 'Number');
            }
            if (data.hasOwnProperty('notes')) {
                obj['notes'] = ApiClient.convertToType(data['notes'], 'String');
            }
            if (data.hasOwnProperty('messageSentToApprovers')) {
                obj['messageSentToApprovers'] = ApiClient.convertToType(data['messageSentToApprovers'], 'String');
            }
            if (data.hasOwnProperty('messageSubjectLineToApprovers')) {
                obj['messageSubjectLineToApprovers'] = ApiClient.convertToType(data['messageSubjectLineToApprovers'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ProofVersionStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('hidden')) {
                obj['hidden'] = ApiClient.convertToType(data['hidden'], 'Boolean');
            }
            if (data.hasOwnProperty('currentStage')) {
                obj['currentStage'] = WorkflowStage.constructFromObject(data['currentStage']);
            }
            if (data.hasOwnProperty('stages')) {
                obj['stages'] = ApiClient.convertToType(data['stages'], [WorkflowStage]);
            }
            if (data.hasOwnProperty('sender')) {
                obj['sender'] = User.constructFromObject(data['sender']);
            }
            if (data.hasOwnProperty('versionFiles')) {
                obj['versionFiles'] = ApiClient.convertToType(data['versionFiles'], [ProofVersionFile]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProofVersion</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProofVersion</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['notes'] && !(typeof data['notes'] === 'string' || data['notes'] instanceof String)) {
            throw new Error("Expected the field `notes` to be a primitive type in the JSON string but got " + data['notes']);
        }
        // ensure the json data is a string
        if (data['messageSentToApprovers'] && !(typeof data['messageSentToApprovers'] === 'string' || data['messageSentToApprovers'] instanceof String)) {
            throw new Error("Expected the field `messageSentToApprovers` to be a primitive type in the JSON string but got " + data['messageSentToApprovers']);
        }
        // ensure the json data is a string
        if (data['messageSubjectLineToApprovers'] && !(typeof data['messageSubjectLineToApprovers'] === 'string' || data['messageSubjectLineToApprovers'] instanceof String)) {
            throw new Error("Expected the field `messageSubjectLineToApprovers` to be a primitive type in the JSON string but got " + data['messageSubjectLineToApprovers']);
        }
        // validate the optional field `currentStage`
        if (data['currentStage']) { // data not null
          WorkflowStage.validateJSON(data['currentStage']);
        }
        if (data['stages']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['stages'])) {
                throw new Error("Expected the field `stages` to be an array in the JSON data but got " + data['stages']);
            }
            // validate the optional field `stages` (array)
            for (const item of data['stages']) {
                WorkflowStage.validateJSON(item);
            };
        }
        // validate the optional field `sender`
        if (data['sender']) { // data not null
          User.validateJSON(data['sender']);
        }
        if (data['versionFiles']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['versionFiles'])) {
                throw new Error("Expected the field `versionFiles` to be an array in the JSON data but got " + data['versionFiles']);
            }
            // validate the optional field `versionFiles` (array)
            for (const item of data['versionFiles']) {
                ProofVersionFile.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
ProofVersion.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
ProofVersion.prototype['accountId'] = undefined;

/**
 * @member {Number} proofId
 */
ProofVersion.prototype['proofId'] = undefined;

/**
 * @member {Number} workflowStageId
 */
ProofVersion.prototype['workflowStageId'] = undefined;

/**
 * @member {Number} senderUserId
 */
ProofVersion.prototype['senderUserId'] = undefined;

/**
 * @member {Number} versionNumber
 */
ProofVersion.prototype['versionNumber'] = undefined;

/**
 * @member {String} notes
 */
ProofVersion.prototype['notes'] = undefined;

/**
 * @member {String} messageSentToApprovers
 */
ProofVersion.prototype['messageSentToApprovers'] = undefined;

/**
 * @member {String} messageSubjectLineToApprovers
 */
ProofVersion.prototype['messageSubjectLineToApprovers'] = undefined;

/**
 * @member {module:model/ProofVersionStatus} status
 */
ProofVersion.prototype['status'] = undefined;

/**
 * @member {Date} createdAt
 */
ProofVersion.prototype['createdAt'] = undefined;

/**
 * @member {Boolean} hidden
 */
ProofVersion.prototype['hidden'] = undefined;

/**
 * @member {module:model/WorkflowStage} currentStage
 */
ProofVersion.prototype['currentStage'] = undefined;

/**
 * @member {Array.<module:model/WorkflowStage>} stages
 */
ProofVersion.prototype['stages'] = undefined;

/**
 * @member {module:model/User} sender
 */
ProofVersion.prototype['sender'] = undefined;

/**
 * @member {Array.<module:model/ProofVersionFile>} versionFiles
 */
ProofVersion.prototype['versionFiles'] = undefined;






export default ProofVersion;

