/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import WebHookEventGroupType from './WebHookEventGroupType';
import WebHookEventType from './WebHookEventType';

/**
 * The WebHook model module.
 * @module model/WebHook
 * @version V1
 */
class WebHook {
    /**
     * Constructs a new <code>WebHook</code>.
     * @alias module:model/WebHook
     */
    constructor() { 
        
        WebHook.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>WebHook</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/WebHook} obj Optional instance to populate.
     * @return {module:model/WebHook} The populated <code>WebHook</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new WebHook();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('resellerId')) {
                obj['resellerId'] = ApiClient.convertToType(data['resellerId'], 'Number');
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('deleted')) {
                obj['deleted'] = ApiClient.convertToType(data['deleted'], 'Boolean');
            }
            if (data.hasOwnProperty('deactivationReason')) {
                obj['deactivationReason'] = ApiClient.convertToType(data['deactivationReason'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('rateLimitPerMin')) {
                obj['rateLimitPerMin'] = ApiClient.convertToType(data['rateLimitPerMin'], 'Number');
            }
            if (data.hasOwnProperty('headers')) {
                obj['headers'] = ApiClient.convertToType(data['headers'], {'String': 'String'});
            }
            if (data.hasOwnProperty('eventTypesToSend')) {
                obj['eventTypesToSend'] = ApiClient.convertToType(data['eventTypesToSend'], [WebHookEventType]);
            }
            if (data.hasOwnProperty('eventTypeGroupsToSend')) {
                obj['eventTypeGroupsToSend'] = ApiClient.convertToType(data['eventTypeGroupsToSend'], [WebHookEventGroupType]);
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>WebHook</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>WebHook</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['deactivationReason'] && !(typeof data['deactivationReason'] === 'string' || data['deactivationReason'] instanceof String)) {
            throw new Error("Expected the field `deactivationReason` to be a primitive type in the JSON string but got " + data['deactivationReason']);
        }
        // ensure the json data is a string
        if (data['url'] && !(typeof data['url'] === 'string' || data['url'] instanceof String)) {
            throw new Error("Expected the field `url` to be a primitive type in the JSON string but got " + data['url']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['eventTypesToSend'])) {
            throw new Error("Expected the field `eventTypesToSend` to be an array in the JSON data but got " + data['eventTypesToSend']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['eventTypeGroupsToSend'])) {
            throw new Error("Expected the field `eventTypeGroupsToSend` to be an array in the JSON data but got " + data['eventTypeGroupsToSend']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
WebHook.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
WebHook.prototype['accountId'] = undefined;

/**
 * @member {Number} resellerId
 */
WebHook.prototype['resellerId'] = undefined;

/**
 * @member {Boolean} active
 */
WebHook.prototype['active'] = undefined;

/**
 * @member {Boolean} deleted
 */
WebHook.prototype['deleted'] = undefined;

/**
 * @member {String} deactivationReason
 */
WebHook.prototype['deactivationReason'] = undefined;

/**
 * @member {String} url
 */
WebHook.prototype['url'] = undefined;

/**
 * @member {String} name
 */
WebHook.prototype['name'] = undefined;

/**
 * @member {Number} rateLimitPerMin
 */
WebHook.prototype['rateLimitPerMin'] = undefined;

/**
 * @member {Object.<String, String>} headers
 */
WebHook.prototype['headers'] = undefined;

/**
 * @member {Array.<module:model/WebHookEventType>} eventTypesToSend
 */
WebHook.prototype['eventTypesToSend'] = undefined;

/**
 * @member {Array.<module:model/WebHookEventGroupType>} eventTypeGroupsToSend
 */
WebHook.prototype['eventTypeGroupsToSend'] = undefined;

/**
 * @member {Date} createdAt
 */
WebHook.prototype['createdAt'] = undefined;






export default WebHook;

