class SuperAdminService {
  constructor() {}

  async GetPage(page, limit = 20, searchText = "") {
    if (page === undefined) page = 1;
    let pageResponse = await window.$A.Api.post(
      `/super-admin/customer/${page}/${limit}`,
      {
          searchAllFields: searchText,
          filter: {},
      },
      {
        headers: {
          "Content-Type": "application/json",
          AccessToken: window.$A.Auth.apiToken.token,
        },
        timeout: 10000,
      }
    )
      .then(function (res) {
        return res.data;
      })
      .catch(function (e) {
        console.log(e);
        return {results: []};
      });

    return pageResponse;
  }
}

export default SuperAdminService;
