/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import KeySizes from './KeySizes';

/**
 * The AsymmetricAlgorithm model module.
 * @module model/AsymmetricAlgorithm
 * @version V1
 */
class AsymmetricAlgorithm {
    /**
     * Constructs a new <code>AsymmetricAlgorithm</code>.
     * @alias module:model/AsymmetricAlgorithm
     */
    constructor() { 
        
        AsymmetricAlgorithm.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AsymmetricAlgorithm</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AsymmetricAlgorithm} obj Optional instance to populate.
     * @return {module:model/AsymmetricAlgorithm} The populated <code>AsymmetricAlgorithm</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AsymmetricAlgorithm();

            if (data.hasOwnProperty('keySize')) {
                obj['keySize'] = ApiClient.convertToType(data['keySize'], 'Number');
            }
            if (data.hasOwnProperty('legalKeySizes')) {
                obj['legalKeySizes'] = ApiClient.convertToType(data['legalKeySizes'], [KeySizes]);
            }
            if (data.hasOwnProperty('signatureAlgorithm')) {
                obj['signatureAlgorithm'] = ApiClient.convertToType(data['signatureAlgorithm'], 'String');
            }
            if (data.hasOwnProperty('keyExchangeAlgorithm')) {
                obj['keyExchangeAlgorithm'] = ApiClient.convertToType(data['keyExchangeAlgorithm'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AsymmetricAlgorithm</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AsymmetricAlgorithm</code>.
     */
    static validateJSON(data) {
        if (data['legalKeySizes']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['legalKeySizes'])) {
                throw new Error("Expected the field `legalKeySizes` to be an array in the JSON data but got " + data['legalKeySizes']);
            }
            // validate the optional field `legalKeySizes` (array)
            for (const item of data['legalKeySizes']) {
                KeySizes.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['signatureAlgorithm'] && !(typeof data['signatureAlgorithm'] === 'string' || data['signatureAlgorithm'] instanceof String)) {
            throw new Error("Expected the field `signatureAlgorithm` to be a primitive type in the JSON string but got " + data['signatureAlgorithm']);
        }
        // ensure the json data is a string
        if (data['keyExchangeAlgorithm'] && !(typeof data['keyExchangeAlgorithm'] === 'string' || data['keyExchangeAlgorithm'] instanceof String)) {
            throw new Error("Expected the field `keyExchangeAlgorithm` to be a primitive type in the JSON string but got " + data['keyExchangeAlgorithm']);
        }

        return true;
    }


}



/**
 * @member {Number} keySize
 */
AsymmetricAlgorithm.prototype['keySize'] = undefined;

/**
 * @member {Array.<module:model/KeySizes>} legalKeySizes
 */
AsymmetricAlgorithm.prototype['legalKeySizes'] = undefined;

/**
 * @member {String} signatureAlgorithm
 */
AsymmetricAlgorithm.prototype['signatureAlgorithm'] = undefined;

/**
 * @member {String} keyExchangeAlgorithm
 */
AsymmetricAlgorithm.prototype['keyExchangeAlgorithm'] = undefined;






export default AsymmetricAlgorithm;

