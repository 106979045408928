/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CustomAttributeData from './CustomAttributeData';
import MethodInfo from './MethodInfo';
import Module from './Module';
import SecurityRuleSet from './SecurityRuleSet';

/**
 * The Assembly model module.
 * @module model/Assembly
 * @version V1
 */
class Assembly {
    /**
     * Constructs a new <code>Assembly</code>.
     * @alias module:model/Assembly
     */
    constructor() { 
        
        Assembly.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Assembly</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Assembly} obj Optional instance to populate.
     * @return {module:model/Assembly} The populated <code>Assembly</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Assembly();

            if (data.hasOwnProperty('definedTypes')) {
                obj['definedTypes'] = ApiClient.convertToType(data['definedTypes'], ['String']);
            }
            if (data.hasOwnProperty('exportedTypes')) {
                obj['exportedTypes'] = ApiClient.convertToType(data['exportedTypes'], ['String']);
            }
            if (data.hasOwnProperty('codeBase')) {
                obj['codeBase'] = ApiClient.convertToType(data['codeBase'], 'String');
            }
            if (data.hasOwnProperty('entryPoint')) {
                obj['entryPoint'] = MethodInfo.constructFromObject(data['entryPoint']);
            }
            if (data.hasOwnProperty('fullName')) {
                obj['fullName'] = ApiClient.convertToType(data['fullName'], 'String');
            }
            if (data.hasOwnProperty('imageRuntimeVersion')) {
                obj['imageRuntimeVersion'] = ApiClient.convertToType(data['imageRuntimeVersion'], 'String');
            }
            if (data.hasOwnProperty('isDynamic')) {
                obj['isDynamic'] = ApiClient.convertToType(data['isDynamic'], 'Boolean');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = ApiClient.convertToType(data['location'], 'String');
            }
            if (data.hasOwnProperty('reflectionOnly')) {
                obj['reflectionOnly'] = ApiClient.convertToType(data['reflectionOnly'], 'Boolean');
            }
            if (data.hasOwnProperty('isCollectible')) {
                obj['isCollectible'] = ApiClient.convertToType(data['isCollectible'], 'Boolean');
            }
            if (data.hasOwnProperty('isFullyTrusted')) {
                obj['isFullyTrusted'] = ApiClient.convertToType(data['isFullyTrusted'], 'Boolean');
            }
            if (data.hasOwnProperty('customAttributes')) {
                obj['customAttributes'] = ApiClient.convertToType(data['customAttributes'], [CustomAttributeData]);
            }
            if (data.hasOwnProperty('escapedCodeBase')) {
                obj['escapedCodeBase'] = ApiClient.convertToType(data['escapedCodeBase'], 'String');
            }
            if (data.hasOwnProperty('manifestModule')) {
                obj['manifestModule'] = Module.constructFromObject(data['manifestModule']);
            }
            if (data.hasOwnProperty('modules')) {
                obj['modules'] = ApiClient.convertToType(data['modules'], [Module]);
            }
            if (data.hasOwnProperty('globalAssemblyCache')) {
                obj['globalAssemblyCache'] = ApiClient.convertToType(data['globalAssemblyCache'], 'Boolean');
            }
            if (data.hasOwnProperty('hostContext')) {
                obj['hostContext'] = ApiClient.convertToType(data['hostContext'], 'Number');
            }
            if (data.hasOwnProperty('securityRuleSet')) {
                obj['securityRuleSet'] = SecurityRuleSet.constructFromObject(data['securityRuleSet']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Assembly</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Assembly</code>.
     */
    static validateJSON(data) {
        // ensure the json data is an array
        if (!Array.isArray(data['definedTypes'])) {
            throw new Error("Expected the field `definedTypes` to be an array in the JSON data but got " + data['definedTypes']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['exportedTypes'])) {
            throw new Error("Expected the field `exportedTypes` to be an array in the JSON data but got " + data['exportedTypes']);
        }
        // ensure the json data is a string
        if (data['codeBase'] && !(typeof data['codeBase'] === 'string' || data['codeBase'] instanceof String)) {
            throw new Error("Expected the field `codeBase` to be a primitive type in the JSON string but got " + data['codeBase']);
        }
        // validate the optional field `entryPoint`
        if (data['entryPoint']) { // data not null
          MethodInfo.validateJSON(data['entryPoint']);
        }
        // ensure the json data is a string
        if (data['fullName'] && !(typeof data['fullName'] === 'string' || data['fullName'] instanceof String)) {
            throw new Error("Expected the field `fullName` to be a primitive type in the JSON string but got " + data['fullName']);
        }
        // ensure the json data is a string
        if (data['imageRuntimeVersion'] && !(typeof data['imageRuntimeVersion'] === 'string' || data['imageRuntimeVersion'] instanceof String)) {
            throw new Error("Expected the field `imageRuntimeVersion` to be a primitive type in the JSON string but got " + data['imageRuntimeVersion']);
        }
        // ensure the json data is a string
        if (data['location'] && !(typeof data['location'] === 'string' || data['location'] instanceof String)) {
            throw new Error("Expected the field `location` to be a primitive type in the JSON string but got " + data['location']);
        }
        if (data['customAttributes']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['customAttributes'])) {
                throw new Error("Expected the field `customAttributes` to be an array in the JSON data but got " + data['customAttributes']);
            }
            // validate the optional field `customAttributes` (array)
            for (const item of data['customAttributes']) {
                CustomAttributeData.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['escapedCodeBase'] && !(typeof data['escapedCodeBase'] === 'string' || data['escapedCodeBase'] instanceof String)) {
            throw new Error("Expected the field `escapedCodeBase` to be a primitive type in the JSON string but got " + data['escapedCodeBase']);
        }
        // validate the optional field `manifestModule`
        if (data['manifestModule']) { // data not null
          Module.validateJSON(data['manifestModule']);
        }
        if (data['modules']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['modules'])) {
                throw new Error("Expected the field `modules` to be an array in the JSON data but got " + data['modules']);
            }
            // validate the optional field `modules` (array)
            for (const item of data['modules']) {
                Module.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Array.<String>} definedTypes
 */
Assembly.prototype['definedTypes'] = undefined;

/**
 * @member {Array.<String>} exportedTypes
 */
Assembly.prototype['exportedTypes'] = undefined;

/**
 * @member {String} codeBase
 */
Assembly.prototype['codeBase'] = undefined;

/**
 * @member {module:model/MethodInfo} entryPoint
 */
Assembly.prototype['entryPoint'] = undefined;

/**
 * @member {String} fullName
 */
Assembly.prototype['fullName'] = undefined;

/**
 * @member {String} imageRuntimeVersion
 */
Assembly.prototype['imageRuntimeVersion'] = undefined;

/**
 * @member {Boolean} isDynamic
 */
Assembly.prototype['isDynamic'] = undefined;

/**
 * @member {String} location
 */
Assembly.prototype['location'] = undefined;

/**
 * @member {Boolean} reflectionOnly
 */
Assembly.prototype['reflectionOnly'] = undefined;

/**
 * @member {Boolean} isCollectible
 */
Assembly.prototype['isCollectible'] = undefined;

/**
 * @member {Boolean} isFullyTrusted
 */
Assembly.prototype['isFullyTrusted'] = undefined;

/**
 * @member {Array.<module:model/CustomAttributeData>} customAttributes
 */
Assembly.prototype['customAttributes'] = undefined;

/**
 * @member {String} escapedCodeBase
 */
Assembly.prototype['escapedCodeBase'] = undefined;

/**
 * @member {module:model/Module} manifestModule
 */
Assembly.prototype['manifestModule'] = undefined;

/**
 * @member {Array.<module:model/Module>} modules
 */
Assembly.prototype['modules'] = undefined;

/**
 * @member {Boolean} globalAssemblyCache
 */
Assembly.prototype['globalAssemblyCache'] = undefined;

/**
 * @member {Number} hostContext
 */
Assembly.prototype['hostContext'] = undefined;

/**
 * @member {module:model/SecurityRuleSet} securityRuleSet
 */
Assembly.prototype['securityRuleSet'] = undefined;






export default Assembly;

