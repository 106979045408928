/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Claim from './Claim';
import ClaimsIdentity from './ClaimsIdentity';
import IIdentity from './IIdentity';

/**
 * The ClaimsPrincipal model module.
 * @module model/ClaimsPrincipal
 * @version V1
 */
class ClaimsPrincipal {
    /**
     * Constructs a new <code>ClaimsPrincipal</code>.
     * @alias module:model/ClaimsPrincipal
     */
    constructor() { 
        
        ClaimsPrincipal.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ClaimsPrincipal</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ClaimsPrincipal} obj Optional instance to populate.
     * @return {module:model/ClaimsPrincipal} The populated <code>ClaimsPrincipal</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClaimsPrincipal();

            if (data.hasOwnProperty('claims')) {
                obj['claims'] = ApiClient.convertToType(data['claims'], [Claim]);
            }
            if (data.hasOwnProperty('identities')) {
                obj['identities'] = ApiClient.convertToType(data['identities'], [ClaimsIdentity]);
            }
            if (data.hasOwnProperty('identity')) {
                obj['identity'] = IIdentity.constructFromObject(data['identity']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ClaimsPrincipal</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ClaimsPrincipal</code>.
     */
    static validateJSON(data) {
        if (data['claims']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['claims'])) {
                throw new Error("Expected the field `claims` to be an array in the JSON data but got " + data['claims']);
            }
            // validate the optional field `claims` (array)
            for (const item of data['claims']) {
                Claim.validateJSON(item);
            };
        }
        if (data['identities']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['identities'])) {
                throw new Error("Expected the field `identities` to be an array in the JSON data but got " + data['identities']);
            }
            // validate the optional field `identities` (array)
            for (const item of data['identities']) {
                ClaimsIdentity.validateJSON(item);
            };
        }
        // validate the optional field `identity`
        if (data['identity']) { // data not null
          IIdentity.validateJSON(data['identity']);
        }

        return true;
    }


}



/**
 * @member {Array.<module:model/Claim>} claims
 */
ClaimsPrincipal.prototype['claims'] = undefined;

/**
 * @member {Array.<module:model/ClaimsIdentity>} identities
 */
ClaimsPrincipal.prototype['identities'] = undefined;

/**
 * @member {module:model/IIdentity} identity
 */
ClaimsPrincipal.prototype['identity'] = undefined;






export default ClaimsPrincipal;

