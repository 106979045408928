/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AddressFamily from './AddressFamily';

/**
 * The IPAddress model module.
 * @module model/IPAddress
 * @version V1
 */
class IPAddress {
    /**
     * Constructs a new <code>IPAddress</code>.
     * @alias module:model/IPAddress
     */
    constructor() { 
        
        IPAddress.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>IPAddress</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/IPAddress} obj Optional instance to populate.
     * @return {module:model/IPAddress} The populated <code>IPAddress</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new IPAddress();

            if (data.hasOwnProperty('addressFamily')) {
                obj['addressFamily'] = AddressFamily.constructFromObject(data['addressFamily']);
            }
            if (data.hasOwnProperty('scopeId')) {
                obj['scopeId'] = ApiClient.convertToType(data['scopeId'], 'Number');
            }
            if (data.hasOwnProperty('isIPv6Multicast')) {
                obj['isIPv6Multicast'] = ApiClient.convertToType(data['isIPv6Multicast'], 'Boolean');
            }
            if (data.hasOwnProperty('isIPv6LinkLocal')) {
                obj['isIPv6LinkLocal'] = ApiClient.convertToType(data['isIPv6LinkLocal'], 'Boolean');
            }
            if (data.hasOwnProperty('isIPv6SiteLocal')) {
                obj['isIPv6SiteLocal'] = ApiClient.convertToType(data['isIPv6SiteLocal'], 'Boolean');
            }
            if (data.hasOwnProperty('isIPv6Teredo')) {
                obj['isIPv6Teredo'] = ApiClient.convertToType(data['isIPv6Teredo'], 'Boolean');
            }
            if (data.hasOwnProperty('isIPv6UniqueLocal')) {
                obj['isIPv6UniqueLocal'] = ApiClient.convertToType(data['isIPv6UniqueLocal'], 'Boolean');
            }
            if (data.hasOwnProperty('isIPv4MappedToIPv6')) {
                obj['isIPv4MappedToIPv6'] = ApiClient.convertToType(data['isIPv4MappedToIPv6'], 'Boolean');
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = ApiClient.convertToType(data['address'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>IPAddress</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>IPAddress</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * @member {module:model/AddressFamily} addressFamily
 */
IPAddress.prototype['addressFamily'] = undefined;

/**
 * @member {Number} scopeId
 */
IPAddress.prototype['scopeId'] = undefined;

/**
 * @member {Boolean} isIPv6Multicast
 */
IPAddress.prototype['isIPv6Multicast'] = undefined;

/**
 * @member {Boolean} isIPv6LinkLocal
 */
IPAddress.prototype['isIPv6LinkLocal'] = undefined;

/**
 * @member {Boolean} isIPv6SiteLocal
 */
IPAddress.prototype['isIPv6SiteLocal'] = undefined;

/**
 * @member {Boolean} isIPv6Teredo
 */
IPAddress.prototype['isIPv6Teredo'] = undefined;

/**
 * @member {Boolean} isIPv6UniqueLocal
 */
IPAddress.prototype['isIPv6UniqueLocal'] = undefined;

/**
 * @member {Boolean} isIPv4MappedToIPv6
 */
IPAddress.prototype['isIPv4MappedToIPv6'] = undefined;

/**
 * @member {Number} address
 */
IPAddress.prototype['address'] = undefined;






export default IPAddress;

