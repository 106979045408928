/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The InvoiceLineItem model module.
 * @module model/InvoiceLineItem
 * @version V1
 */
class InvoiceLineItem {
    /**
     * Constructs a new <code>InvoiceLineItem</code>.
     * @alias module:model/InvoiceLineItem
     */
    constructor() { 
        
        InvoiceLineItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>InvoiceLineItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InvoiceLineItem} obj Optional instance to populate.
     * @return {module:model/InvoiceLineItem} The populated <code>InvoiceLineItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InvoiceLineItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('resellerId')) {
                obj['resellerId'] = ApiClient.convertToType(data['resellerId'], 'Number');
            }
            if (data.hasOwnProperty('invoiceId')) {
                obj['invoiceId'] = ApiClient.convertToType(data['invoiceId'], 'Number');
            }
            if (data.hasOwnProperty('providerInvoiceItemId')) {
                obj['providerInvoiceItemId'] = ApiClient.convertToType(data['providerInvoiceItemId'], 'String');
            }
            if (data.hasOwnProperty('providerSubscriptionItemId')) {
                obj['providerSubscriptionItemId'] = ApiClient.convertToType(data['providerSubscriptionItemId'], 'String');
            }
            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('proratedAmount')) {
                obj['proratedAmount'] = ApiClient.convertToType(data['proratedAmount'], 'Number');
            }
            if (data.hasOwnProperty('taxAmount')) {
                obj['taxAmount'] = ApiClient.convertToType(data['taxAmount'], 'Number');
            }
            if (data.hasOwnProperty('taxRatePercent')) {
                obj['taxRatePercent'] = ApiClient.convertToType(data['taxRatePercent'], 'Number');
            }
            if (data.hasOwnProperty('currency')) {
                obj['currency'] = ApiClient.convertToType(data['currency'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('metadata')) {
                obj['metadata'] = ApiClient.convertToType(data['metadata'], {'String': 'String'});
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('periodStart')) {
                obj['periodStart'] = ApiClient.convertToType(data['periodStart'], 'Date');
            }
            if (data.hasOwnProperty('periodEnd')) {
                obj['periodEnd'] = ApiClient.convertToType(data['periodEnd'], 'Date');
            }
            if (data.hasOwnProperty('proratedPeriodEnd')) {
                obj['proratedPeriodEnd'] = ApiClient.convertToType(data['proratedPeriodEnd'], 'Date');
            }
            if (data.hasOwnProperty('productSku')) {
                obj['productSku'] = ApiClient.convertToType(data['productSku'], 'String');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('subscriptionId')) {
                obj['subscriptionId'] = ApiClient.convertToType(data['subscriptionId'], 'Number');
            }
            if (data.hasOwnProperty('providerSubscriptionId')) {
                obj['providerSubscriptionId'] = ApiClient.convertToType(data['providerSubscriptionId'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('jsonObject')) {
                obj['jsonObject'] = ApiClient.convertToType(data['jsonObject'], 'String');
            }
            if (data.hasOwnProperty('accountName')) {
                obj['accountName'] = ApiClient.convertToType(data['accountName'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>InvoiceLineItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>InvoiceLineItem</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['providerInvoiceItemId'] && !(typeof data['providerInvoiceItemId'] === 'string' || data['providerInvoiceItemId'] instanceof String)) {
            throw new Error("Expected the field `providerInvoiceItemId` to be a primitive type in the JSON string but got " + data['providerInvoiceItemId']);
        }
        // ensure the json data is a string
        if (data['providerSubscriptionItemId'] && !(typeof data['providerSubscriptionItemId'] === 'string' || data['providerSubscriptionItemId'] instanceof String)) {
            throw new Error("Expected the field `providerSubscriptionItemId` to be a primitive type in the JSON string but got " + data['providerSubscriptionItemId']);
        }
        // ensure the json data is a string
        if (data['currency'] && !(typeof data['currency'] === 'string' || data['currency'] instanceof String)) {
            throw new Error("Expected the field `currency` to be a primitive type in the JSON string but got " + data['currency']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['productSku'] && !(typeof data['productSku'] === 'string' || data['productSku'] instanceof String)) {
            throw new Error("Expected the field `productSku` to be a primitive type in the JSON string but got " + data['productSku']);
        }
        // ensure the json data is a string
        if (data['providerSubscriptionId'] && !(typeof data['providerSubscriptionId'] === 'string' || data['providerSubscriptionId'] instanceof String)) {
            throw new Error("Expected the field `providerSubscriptionId` to be a primitive type in the JSON string but got " + data['providerSubscriptionId']);
        }
        // ensure the json data is a string
        if (data['type'] && !(typeof data['type'] === 'string' || data['type'] instanceof String)) {
            throw new Error("Expected the field `type` to be a primitive type in the JSON string but got " + data['type']);
        }
        // ensure the json data is a string
        if (data['jsonObject'] && !(typeof data['jsonObject'] === 'string' || data['jsonObject'] instanceof String)) {
            throw new Error("Expected the field `jsonObject` to be a primitive type in the JSON string but got " + data['jsonObject']);
        }
        // ensure the json data is a string
        if (data['accountName'] && !(typeof data['accountName'] === 'string' || data['accountName'] instanceof String)) {
            throw new Error("Expected the field `accountName` to be a primitive type in the JSON string but got " + data['accountName']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
InvoiceLineItem.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
InvoiceLineItem.prototype['accountId'] = undefined;

/**
 * @member {Number} resellerId
 */
InvoiceLineItem.prototype['resellerId'] = undefined;

/**
 * @member {Number} invoiceId
 */
InvoiceLineItem.prototype['invoiceId'] = undefined;

/**
 * @member {String} providerInvoiceItemId
 */
InvoiceLineItem.prototype['providerInvoiceItemId'] = undefined;

/**
 * @member {String} providerSubscriptionItemId
 */
InvoiceLineItem.prototype['providerSubscriptionItemId'] = undefined;

/**
 * @member {Number} amount
 */
InvoiceLineItem.prototype['amount'] = undefined;

/**
 * @member {Number} proratedAmount
 */
InvoiceLineItem.prototype['proratedAmount'] = undefined;

/**
 * @member {Number} taxAmount
 */
InvoiceLineItem.prototype['taxAmount'] = undefined;

/**
 * @member {Number} taxRatePercent
 */
InvoiceLineItem.prototype['taxRatePercent'] = undefined;

/**
 * @member {String} currency
 */
InvoiceLineItem.prototype['currency'] = undefined;

/**
 * @member {Date} createdAt
 */
InvoiceLineItem.prototype['createdAt'] = undefined;

/**
 * @member {Object.<String, String>} metadata
 */
InvoiceLineItem.prototype['metadata'] = undefined;

/**
 * @member {String} description
 */
InvoiceLineItem.prototype['description'] = undefined;

/**
 * @member {Date} periodStart
 */
InvoiceLineItem.prototype['periodStart'] = undefined;

/**
 * @member {Date} periodEnd
 */
InvoiceLineItem.prototype['periodEnd'] = undefined;

/**
 * @member {Date} proratedPeriodEnd
 */
InvoiceLineItem.prototype['proratedPeriodEnd'] = undefined;

/**
 * @member {String} productSku
 */
InvoiceLineItem.prototype['productSku'] = undefined;

/**
 * @member {Number} quantity
 */
InvoiceLineItem.prototype['quantity'] = undefined;

/**
 * @member {Number} subscriptionId
 */
InvoiceLineItem.prototype['subscriptionId'] = undefined;

/**
 * @member {String} providerSubscriptionId
 */
InvoiceLineItem.prototype['providerSubscriptionId'] = undefined;

/**
 * @member {String} type
 */
InvoiceLineItem.prototype['type'] = undefined;

/**
 * @member {String} jsonObject
 */
InvoiceLineItem.prototype['jsonObject'] = undefined;

/**
 * @member {String} accountName
 */
InvoiceLineItem.prototype['accountName'] = undefined;






export default InvoiceLineItem;

