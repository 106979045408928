/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import Approver from '../model/Approver';
import ApproverIndex from '../model/ApproverIndex';
import ApproverWithStats from '../model/ApproverWithStats';
import SaveApproverRequest from '../model/SaveApproverRequest';
import SuccessResponse from '../model/SuccessResponse';

/**
* Approver service.
* @module api/ApproverApi
* @version V1
*/
export default class ApproverApi {

    /**
    * Constructs a new ApproverApi. 
    * @alias module:api/ApproverApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the createApprover operation.
     * @callback module:api/ApproverApi~createApproverCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Approver} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveApproverRequest} [saveApproverRequest] 
     * @param {module:api/ApproverApi~createApproverCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Approver}
     */
    createApprover(opts, callback) {
      opts = opts || {};
      let postBody = opts['saveApproverRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Approver;
      return this.apiClient.callApi(
        '/approver', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteApprover operation.
     * @callback module:api/ApproverApi~deleteApproverCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/ApproverApi~deleteApproverCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteApprover(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteApprover");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/approver/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAll operation.
     * @callback module:api/ApproverApi~getAllCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Approver>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} [nameFilter = '')] 
     * @param {String} [emailFilter = '')] 
     * @param {module:api/ApproverApi~getAllCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Approver>}
     */
    getAll(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'nameFilter': opts['nameFilter'],
        'emailFilter': opts['emailFilter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Approver];
      return this.apiClient.callApi(
        '/approver/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllIndexed operation.
     * @callback module:api/ApproverApi~getAllIndexedCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ApproverIndex>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/ApproverApi~getAllIndexedCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ApproverIndex>}
     */
    getAllIndexed(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [ApproverIndex];
      return this.apiClient.callApi(
        '/approver/all/index', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getApproverWithStats operation.
     * @callback module:api/ApproverApi~getApproverWithStatsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ApproverWithStats} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} approverId 
     * @param {module:api/ApproverApi~getApproverWithStatsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ApproverWithStats}
     */
    getApproverWithStats(approverId, callback) {
      let postBody = null;
      // verify the required parameter 'approverId' is set
      if (approverId === undefined || approverId === null) {
        throw new Error("Missing the required parameter 'approverId' when calling getApproverWithStats");
      }

      let pathParams = {
        'approverId': approverId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ApproverWithStats;
      return this.apiClient.callApi(
        '/approver/{approverId}/with-stats', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateApprover operation.
     * @callback module:api/ApproverApi~updateApproverCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Approver} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveApproverRequest} [saveApproverRequest] 
     * @param {module:api/ApproverApi~updateApproverCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Approver}
     */
    updateApprover(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveApproverRequest'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateApprover");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Approver;
      return this.apiClient.callApi(
        '/approver/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
