class MarketingService {
    constructor() {
        this.sourcesLoaded = false;
    }

    async LoadSources() {
        await (function (i, s, o, g, r, a, m) {
            i["GoogleAnalyticsObject"] = r;
            (i[r] =
                i[r] ||
                function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }),
                (i[r].l = 1 * new Date());
            (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(
            window,
            document,
            "script",
            "https://www.google-analytics.com/analytics.js",
            "ga"
        );
        ga("create", "UA-82243024-1", "auto");
        ga("send", "pageview");


        if (document.getElementById("hubspot-messages-iframe-container") === null) {
            if (document.getElementById("nav_support") !== null) {
                let a = document.getElementById("nav_support");
                a.parentElement.removeChild(a);
            }
            let hubSpot = document.createElement("script");
            hubSpot.setAttribute("src", "https://js.hs-scripts.com/4439126.js");
            hubSpot.setAttribute("id", "nav_support");
            document.head.appendChild(hubSpot);
        }
        this.sourcesLoaded = true;
    }

    async SSOPreSignupEvent() {
        if (this.sourcesLoaded === false){
            await this.LoadSources();
        }
        window.$A.SaveDataCache('sso_sign_up', 'true');
        const params = new URLSearchParams(window.location.search);
        const paramsObj = {};
        for (const [key, value] of params) {
            paramsObj[key] = value;
        }
        localStorage.setItem('urlParamsGoogle', JSON.stringify(paramsObj));
    }
    async SSOPostLoginAttributionCheck(name, organization, email, title, phone) {
        if (this.sourcesLoaded === false){
            await this.LoadSources();
        }
        try {
            let ssoSignUp = await window.$A.LoadDataCache("sso_sign_up");
            if(ssoSignUp === 'true'){
                await this.SubmitHubSpotData(name, organization, email, title, phone);
                await this.SubmitGoogleData(name, organization, email, title, phone);
                await this.SubmitFacebookData(name, organization, email, title, phone);
                await window.$A.SaveDataCache("sso_sign_up", 'false');
            }
        } catch (e) {
            console.log(e);
        }
    }
    async AshoreAuthSignUpEvent(name, organization, email, title, phone) {

        if (this.sourcesLoaded === false){
            await this.LoadSources();
        }
        try {
            let self = this;
            await self.SubmitHubSpotData(name, organization, email, title, phone);
            await self.SubmitGoogleData(name, organization, email, title, phone);
            await self.SubmitFacebookData(name, organization, email, title, phone);
        } catch (e) {
            console.log(e);
        }
    }

    async SubmitHubSpotData(name, organization, email, title, phone){

        if (this.sourcesLoaded === false){
            await this.LoadSources();
        }
        let self = this;
        let GetHubSpotCookie = function() {
            const cookieName = 'hubspotutk';
            const cookieArray = document.cookie.split(';');
            for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i];
                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1);
                }
                if (cookie.indexOf(cookieName) === 0) {
                    return cookie.substring(cookieName.length + 1, cookie.length);
                }
            }
            return '';
        }

        const payload = {
            "fields": [
                {
                    "name": "email",
                    "value": email
                },
                {
                    "name": "firstname",
                    "value": name
                },
                {
                    "name": "phone",
                    "value": phone
                },
                {
                    "name": "jobtitle",
                    "value": title
                },
                {
                    "name": "company",
                    "value": organization
                },
                {
                    "name": "source",
                    "value": ""
                },
                {
                    "name": "hs_analytics_source",
                    "value": ""
                }
            ],
            "context": {
                "hutk": GetHubSpotCookie(),
                "pageUri": window.location.href,
                "pageName": document.title
            }
        };

        let storedParams = JSON.parse(localStorage.getItem('urlParams'));


        if (payload.context.pageUri.indexOf("?") === -1) {
            payload.context.pageUri += "?";
        }

        let utmSource = null;
        if(storedParams !== null){
            for (const [key, value] of storedParams) {
                payload.context.pageUri += key+"="+value+"&"
                payload.fields.push({
                    "name": key,
                    "value": value
                })
            }
            utmSource = storedParams.get("utm_source")
        }

        const url = new URL(payload.context.pageUri);
        const searchParams = new URLSearchParams(url.search);
        let us1 = searchParams.get('utm_source');
        utmSource = utmSource==null?us1:utmSource;
        if(utmSource === null){
            const us2 = new URLSearchParams(window.location.search)
            utmSource = us2.get("utm_source")
        }

        if(utmSource === null)
            utmSource = ""
        console.log("utmSource",utmSource); // Output: "utm_source=test"

        payload.fields[5].value = utmSource;
        payload.fields[6].value = this.mapQueryStringToHubSpotSource(utmSource);

        fetch('https://api.hsforms.com/submissions/v3/integration/submit/4439126/8806cbda-357c-4c71-91a0-f396918aaa44', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => console.log('Form submitted to HubSpot', data,  GetHubSpotCookie(), payload))
            .catch(error => console.error('Error submitting form to HubSpot', error));
    }
    async SubmitGoogleData(name, organization, email, title, phone){

        if (this.sourcesLoaded === false){
            await this.LoadSources();
        }
        try {
            let storedParams = JSON.parse(localStorage.getItem('urlParams'));
            if(storedParams === null){
                const params = new URLSearchParams(window.location.search);
                storedParams = {};
                for (const [key, value] of params) {
                    storedParams[key] = value;
                }
            }

            const eventData = {
                'event_category': 'engagement',
                'event_label': 'registration_form',
                'organization': organization,
                'name': name,
                'email': email,
                'phone': phone,
                'title': title
            };

            for (const param in storedParams) {
                if (storedParams.hasOwnProperty(param)) {
                    // You might want to ensure that the parameter name is valid for GA
                    // and map it to custom dimensions/metrics as necessary.
                    eventData[param] = storedParams[param];
                }
            }

            gtag('event', 'sign_up', eventData);
            ga('send', 'event', 'registration', 'sign_up');
            localStorage.removeItem('urlParams');
        } catch (e) {
            console.log(e);
        }
    }
    async SubmitFacebookData(name, organization, email, title, phone){

        if (this.sourcesLoaded === false){
            await this.LoadSources();
        }
        try {
            let storedParams = JSON.parse(localStorage.getItem('urlParams'));
            if(storedParams === null){
                const params = new URLSearchParams(window.location.search);
                storedParams = {};
                for (const [key, value] of params) {
                    storedParams[key] = value;
                }
            }

            const eventData = {
                'content_name': 'registration_form', // Example of a standard parameter for 'sign_up'
                // Custom parameters
                'organization': organization,
                'name': name,
                'email': email,
                'phone': phone,
                'title': title
            };

            for (const param in storedParams) {
                if (storedParams.hasOwnProperty(param)) {
                    // You might want to ensure that the parameter name is valid for GA
                    // and map it to custom dimensions/metrics as necessary.
                    eventData[param] = storedParams[param];
                }
            }
            fbq('track', 'sign_up', eventData);
            localStorage.removeItem('urlParams');
        } catch (e) {
            console.log(e);
        }
    }
    mapQueryStringToHubSpotSource(utmSource) {
        switch (utmSource) {
            case 'google':
            case 'bing':
            case 'yahoo':
                return 'ORGANIC_SEARCH'; // Maps to organic search sources
            case 'social':
                return 'SOCIAL_MEDIA'; // Maps to social media sources
            case 'paidsocial':
                return 'PAID_SOCIAL'; // Maps to paid social media campaigns
            case 'ppc':
                return 'PAID_SEARCH'; // Maps to paid search campaigns
            default:
                return 'DIRECT_TRAFFIC'; // Default case if utm_source is not recognized
        }
    }

}

export default MarketingService;