/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AppEventType from './AppEventType';
import WebHookEventType from './WebHookEventType';

/**
 * The AppEventBase model module.
 * @module model/AppEventBase
 * @version V1
 */
class AppEventBase {
    /**
     * Constructs a new <code>AppEventBase</code>.
     * @alias module:model/AppEventBase
     */
    constructor() { 
        
        AppEventBase.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AppEventBase</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AppEventBase} obj Optional instance to populate.
     * @return {module:model/AppEventBase} The populated <code>AppEventBase</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AppEventBase();

            if (data.hasOwnProperty('appEventType')) {
                obj['appEventType'] = AppEventType.constructFromObject(data['appEventType']);
            }
            if (data.hasOwnProperty('webHookEventType')) {
                obj['webHookEventType'] = WebHookEventType.constructFromObject(data['webHookEventType']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofFileId')) {
                obj['proofFileId'] = ApiClient.convertToType(data['proofFileId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('workflowStageId')) {
                obj['workflowStageId'] = ApiClient.convertToType(data['workflowStageId'], 'Number');
            }
            if (data.hasOwnProperty('emailLogId')) {
                obj['emailLogId'] = ApiClient.convertToType(data['emailLogId'], 'Number');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'Date');
            }
            if (data.hasOwnProperty('queuedActionAt')) {
                obj['queuedActionAt'] = ApiClient.convertToType(data['queuedActionAt'], 'Date');
            }
            if (data.hasOwnProperty('queuedActionCompletedAt')) {
                obj['queuedActionCompletedAt'] = ApiClient.convertToType(data['queuedActionCompletedAt'], 'Date');
            }
            if (data.hasOwnProperty('triggeredAt')) {
                obj['triggeredAt'] = ApiClient.convertToType(data['triggeredAt'], 'Date');
            }
            if (data.hasOwnProperty('additionalData')) {
                obj['additionalData'] = ApiClient.convertToType(data['additionalData'], {'String': Object});
            }
            if (data.hasOwnProperty('ipAddress')) {
                obj['ipAddress'] = ApiClient.convertToType(data['ipAddress'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AppEventBase</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AppEventBase</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['ipAddress'] && !(typeof data['ipAddress'] === 'string' || data['ipAddress'] instanceof String)) {
            throw new Error("Expected the field `ipAddress` to be a primitive type in the JSON string but got " + data['ipAddress']);
        }

        return true;
    }


}



/**
 * @member {module:model/AppEventType} appEventType
 */
AppEventBase.prototype['appEventType'] = undefined;

/**
 * @member {module:model/WebHookEventType} webHookEventType
 */
AppEventBase.prototype['webHookEventType'] = undefined;

/**
 * @member {Number} id
 */
AppEventBase.prototype['id'] = undefined;

/**
 * @member {Number} userId
 */
AppEventBase.prototype['userId'] = undefined;

/**
 * @member {Number} accountId
 */
AppEventBase.prototype['accountId'] = undefined;

/**
 * @member {Number} proofId
 */
AppEventBase.prototype['proofId'] = undefined;

/**
 * @member {Number} proofFileId
 */
AppEventBase.prototype['proofFileId'] = undefined;

/**
 * @member {Number} proofVersionId
 */
AppEventBase.prototype['proofVersionId'] = undefined;

/**
 * @member {Number} approverId
 */
AppEventBase.prototype['approverId'] = undefined;

/**
 * @member {Number} workflowStageId
 */
AppEventBase.prototype['workflowStageId'] = undefined;

/**
 * @member {Number} emailLogId
 */
AppEventBase.prototype['emailLogId'] = undefined;

/**
 * @member {String} description
 */
AppEventBase.prototype['description'] = undefined;

/**
 * @member {Date} deletedAt
 */
AppEventBase.prototype['deletedAt'] = undefined;

/**
 * @member {Date} queuedActionAt
 */
AppEventBase.prototype['queuedActionAt'] = undefined;

/**
 * @member {Date} queuedActionCompletedAt
 */
AppEventBase.prototype['queuedActionCompletedAt'] = undefined;

/**
 * @member {Date} triggeredAt
 */
AppEventBase.prototype['triggeredAt'] = undefined;

/**
 * @member {Object.<String, Object>} additionalData
 */
AppEventBase.prototype['additionalData'] = undefined;

/**
 * @member {String} ipAddress
 */
AppEventBase.prototype['ipAddress'] = undefined;






export default AppEventBase;

