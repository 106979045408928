/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UpdateAnnotationRequest model module.
 * @module model/UpdateAnnotationRequest
 * @version V1
 */
class UpdateAnnotationRequest {
    /**
     * Constructs a new <code>UpdateAnnotationRequest</code>.
     * @alias module:model/UpdateAnnotationRequest
     */
    constructor() { 
        
        UpdateAnnotationRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UpdateAnnotationRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UpdateAnnotationRequest} obj Optional instance to populate.
     * @return {module:model/UpdateAnnotationRequest} The populated <code>UpdateAnnotationRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UpdateAnnotationRequest();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('containerWidth')) {
                obj['containerWidth'] = ApiClient.convertToType(data['containerWidth'], 'Number');
            }
            if (data.hasOwnProperty('zoom')) {
                obj['zoom'] = ApiClient.convertToType(data['zoom'], 'Number');
            }
            if (data.hasOwnProperty('x')) {
                obj['x'] = ApiClient.convertToType(data['x'], 'Number');
            }
            if (data.hasOwnProperty('y')) {
                obj['y'] = ApiClient.convertToType(data['y'], 'Number');
            }
            if (data.hasOwnProperty('x2')) {
                obj['x2'] = ApiClient.convertToType(data['x2'], 'Number');
            }
            if (data.hasOwnProperty('y2')) {
                obj['y2'] = ApiClient.convertToType(data['y2'], 'Number');
            }
            if (data.hasOwnProperty('startX')) {
                obj['startX'] = ApiClient.convertToType(data['startX'], 'Number');
            }
            if (data.hasOwnProperty('startY')) {
                obj['startY'] = ApiClient.convertToType(data['startY'], 'Number');
            }
            if (data.hasOwnProperty('endX')) {
                obj['endX'] = ApiClient.convertToType(data['endX'], 'Number');
            }
            if (data.hasOwnProperty('endY')) {
                obj['endY'] = ApiClient.convertToType(data['endY'], 'Number');
            }
            if (data.hasOwnProperty('w')) {
                obj['w'] = ApiClient.convertToType(data['w'], 'Number');
            }
            if (data.hasOwnProperty('h')) {
                obj['h'] = ApiClient.convertToType(data['h'], 'Number');
            }
            if (data.hasOwnProperty('liveWebProofingUrl')) {
                obj['liveWebProofingUrl'] = ApiClient.convertToType(data['liveWebProofingUrl'], 'String');
            }
            if (data.hasOwnProperty('liveWebProofingResolution')) {
                obj['liveWebProofingResolution'] = ApiClient.convertToType(data['liveWebProofingResolution'], 'Number');
            }
            if (data.hasOwnProperty('contextImageBase64Encoded')) {
                obj['contextImageBase64Encoded'] = ApiClient.convertToType(data['contextImageBase64Encoded'], 'String');
            }
            if (data.hasOwnProperty('drawingLayerImageBase64Encoded')) {
                obj['drawingLayerImageBase64Encoded'] = ApiClient.convertToType(data['drawingLayerImageBase64Encoded'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UpdateAnnotationRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UpdateAnnotationRequest</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['liveWebProofingUrl'] && !(typeof data['liveWebProofingUrl'] === 'string' || data['liveWebProofingUrl'] instanceof String)) {
            throw new Error("Expected the field `liveWebProofingUrl` to be a primitive type in the JSON string but got " + data['liveWebProofingUrl']);
        }
        // ensure the json data is a string
        if (data['contextImageBase64Encoded'] && !(typeof data['contextImageBase64Encoded'] === 'string' || data['contextImageBase64Encoded'] instanceof String)) {
            throw new Error("Expected the field `contextImageBase64Encoded` to be a primitive type in the JSON string but got " + data['contextImageBase64Encoded']);
        }
        // ensure the json data is a string
        if (data['drawingLayerImageBase64Encoded'] && !(typeof data['drawingLayerImageBase64Encoded'] === 'string' || data['drawingLayerImageBase64Encoded'] instanceof String)) {
            throw new Error("Expected the field `drawingLayerImageBase64Encoded` to be a primitive type in the JSON string but got " + data['drawingLayerImageBase64Encoded']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
UpdateAnnotationRequest.prototype['id'] = undefined;

/**
 * @member {Number} containerWidth
 */
UpdateAnnotationRequest.prototype['containerWidth'] = undefined;

/**
 * @member {Number} zoom
 */
UpdateAnnotationRequest.prototype['zoom'] = undefined;

/**
 * @member {Number} x
 */
UpdateAnnotationRequest.prototype['x'] = undefined;

/**
 * @member {Number} y
 */
UpdateAnnotationRequest.prototype['y'] = undefined;

/**
 * @member {Number} x2
 */
UpdateAnnotationRequest.prototype['x2'] = undefined;

/**
 * @member {Number} y2
 */
UpdateAnnotationRequest.prototype['y2'] = undefined;

/**
 * @member {Number} startX
 */
UpdateAnnotationRequest.prototype['startX'] = undefined;

/**
 * @member {Number} startY
 */
UpdateAnnotationRequest.prototype['startY'] = undefined;

/**
 * @member {Number} endX
 */
UpdateAnnotationRequest.prototype['endX'] = undefined;

/**
 * @member {Number} endY
 */
UpdateAnnotationRequest.prototype['endY'] = undefined;

/**
 * @member {Number} w
 */
UpdateAnnotationRequest.prototype['w'] = undefined;

/**
 * @member {Number} h
 */
UpdateAnnotationRequest.prototype['h'] = undefined;

/**
 * @member {String} liveWebProofingUrl
 */
UpdateAnnotationRequest.prototype['liveWebProofingUrl'] = undefined;

/**
 * @member {Number} liveWebProofingResolution
 */
UpdateAnnotationRequest.prototype['liveWebProofingResolution'] = undefined;

/**
 * @member {String} contextImageBase64Encoded
 */
UpdateAnnotationRequest.prototype['contextImageBase64Encoded'] = undefined;

/**
 * @member {String} drawingLayerImageBase64Encoded
 */
UpdateAnnotationRequest.prototype['drawingLayerImageBase64Encoded'] = undefined;






export default UpdateAnnotationRequest;

