/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofCommentMention from './ProofCommentMention';

/**
 * The ProofAnnotationComment model module.
 * @module model/ProofAnnotationComment
 * @version V1
 */
class ProofAnnotationComment {
    /**
     * Constructs a new <code>ProofAnnotationComment</code>.
     * @alias module:model/ProofAnnotationComment
     */
    constructor() { 
        
        ProofAnnotationComment.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofAnnotationComment</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofAnnotationComment} obj Optional instance to populate.
     * @return {module:model/ProofAnnotationComment} The populated <code>ProofAnnotationComment</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofAnnotationComment();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('proofAnnotationId')) {
                obj['proofAnnotationId'] = ApiClient.convertToType(data['proofAnnotationId'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('ownerName')) {
                obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
            }
            if (data.hasOwnProperty('ownerInitials')) {
                obj['ownerInitials'] = ApiClient.convertToType(data['ownerInitials'], 'String');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofFileId')) {
                obj['proofFileId'] = ApiClient.convertToType(data['proofFileId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('notifiedAt')) {
                obj['notifiedAt'] = ApiClient.convertToType(data['notifiedAt'], 'Date');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'Date');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'Date');
            }
            if (data.hasOwnProperty('notifiedProofOwnerAt')) {
                obj['notifiedProofOwnerAt'] = ApiClient.convertToType(data['notifiedProofOwnerAt'], 'Date');
            }
            if (data.hasOwnProperty('notifiedApproversAt')) {
                obj['notifiedApproversAt'] = ApiClient.convertToType(data['notifiedApproversAt'], 'Date');
            }
            if (data.hasOwnProperty('drawingLayerImageS3Key')) {
                obj['drawingLayerImageS3Key'] = ApiClient.convertToType(data['drawingLayerImageS3Key'], 'String');
            }
            if (data.hasOwnProperty('mentions')) {
                obj['mentions'] = ApiClient.convertToType(data['mentions'], [ProofCommentMention]);
            }
            if (data.hasOwnProperty('drawingLayerImageUrl')) {
                obj['drawingLayerImageUrl'] = ApiClient.convertToType(data['drawingLayerImageUrl'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProofAnnotationComment</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProofAnnotationComment</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['ownerName'] && !(typeof data['ownerName'] === 'string' || data['ownerName'] instanceof String)) {
            throw new Error("Expected the field `ownerName` to be a primitive type in the JSON string but got " + data['ownerName']);
        }
        // ensure the json data is a string
        if (data['ownerInitials'] && !(typeof data['ownerInitials'] === 'string' || data['ownerInitials'] instanceof String)) {
            throw new Error("Expected the field `ownerInitials` to be a primitive type in the JSON string but got " + data['ownerInitials']);
        }
        // ensure the json data is a string
        if (data['text'] && !(typeof data['text'] === 'string' || data['text'] instanceof String)) {
            throw new Error("Expected the field `text` to be a primitive type in the JSON string but got " + data['text']);
        }
        // ensure the json data is a string
        if (data['drawingLayerImageS3Key'] && !(typeof data['drawingLayerImageS3Key'] === 'string' || data['drawingLayerImageS3Key'] instanceof String)) {
            throw new Error("Expected the field `drawingLayerImageS3Key` to be a primitive type in the JSON string but got " + data['drawingLayerImageS3Key']);
        }
        if (data['mentions']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['mentions'])) {
                throw new Error("Expected the field `mentions` to be an array in the JSON data but got " + data['mentions']);
            }
            // validate the optional field `mentions` (array)
            for (const item of data['mentions']) {
                ProofCommentMention.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['drawingLayerImageUrl'] && !(typeof data['drawingLayerImageUrl'] === 'string' || data['drawingLayerImageUrl'] instanceof String)) {
            throw new Error("Expected the field `drawingLayerImageUrl` to be a primitive type in the JSON string but got " + data['drawingLayerImageUrl']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
ProofAnnotationComment.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
ProofAnnotationComment.prototype['accountId'] = undefined;

/**
 * @member {Number} proofAnnotationId
 */
ProofAnnotationComment.prototype['proofAnnotationId'] = undefined;

/**
 * @member {Number} userId
 */
ProofAnnotationComment.prototype['userId'] = undefined;

/**
 * @member {Number} approverId
 */
ProofAnnotationComment.prototype['approverId'] = undefined;

/**
 * @member {String} ownerName
 */
ProofAnnotationComment.prototype['ownerName'] = undefined;

/**
 * @member {String} ownerInitials
 */
ProofAnnotationComment.prototype['ownerInitials'] = undefined;

/**
 * @member {Number} proofId
 */
ProofAnnotationComment.prototype['proofId'] = undefined;

/**
 * @member {Number} proofFileId
 */
ProofAnnotationComment.prototype['proofFileId'] = undefined;

/**
 * @member {Number} proofVersionId
 */
ProofAnnotationComment.prototype['proofVersionId'] = undefined;

/**
 * @member {String} text
 */
ProofAnnotationComment.prototype['text'] = undefined;

/**
 * @member {Date} notifiedAt
 */
ProofAnnotationComment.prototype['notifiedAt'] = undefined;

/**
 * @member {Date} createdAt
 */
ProofAnnotationComment.prototype['createdAt'] = undefined;

/**
 * @member {Date} updatedAt
 */
ProofAnnotationComment.prototype['updatedAt'] = undefined;

/**
 * @member {Date} deletedAt
 */
ProofAnnotationComment.prototype['deletedAt'] = undefined;

/**
 * @member {Date} notifiedProofOwnerAt
 */
ProofAnnotationComment.prototype['notifiedProofOwnerAt'] = undefined;

/**
 * @member {Date} notifiedApproversAt
 */
ProofAnnotationComment.prototype['notifiedApproversAt'] = undefined;

/**
 * @member {String} drawingLayerImageS3Key
 */
ProofAnnotationComment.prototype['drawingLayerImageS3Key'] = undefined;

/**
 * @member {Array.<module:model/ProofCommentMention>} mentions
 */
ProofAnnotationComment.prototype['mentions'] = undefined;

/**
 * @member {String} drawingLayerImageUrl
 */
ProofAnnotationComment.prototype['drawingLayerImageUrl'] = undefined;






export default ProofAnnotationComment;

