const axios = require("axios");

class ZapierService {
  constructor() {}

  async GetAll() {
    try {
      let response = await window.$A.Api.get("/zapier/connections");

      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async DeleteConnection(id) {
    try {
      let response = await window.$A.Api.delete("/zapier/connection/" + id);

      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
  async AuthorizeAccess(username, password, redirectUrl, state) {
    if (username.length === 0 || password.length === 0) {
      window.$A.AlertUser("Please Fill Out The Login Form First", "warning");
      return {
        data: {
          success: false,
        },
      };
    }

    try {
      let response = await window.$A.Api.post("/zapier/authorize", {
        Email: username,
        Password: password,
        redirectUrl: redirectUrl,
        state: state,
      });

      return response;
    } catch (error) {
      console.error(error);
      return {
        data: {
          success: false,
        },
      };
    }
  }
}

export default ZapierService;
