import AccountApi from "../generated/api/AccountApi";
import UserApi from "../generated/api/UserApi";
import AuthenticationApi from "../generated/api/AuthenticationApi";
import LoginRequest from "../generated/model/LoginRequest";
import RegisterRequest from "../generated/model/RegisterRequest";
import ResetPasswordRequest from "../generated/model/ResetPasswordRequest";
import UpdatePasswordRequest from "../generated/model/UpdatePasswordRequest";
import UpdatePasswordWithTokenRequest from "../generated/model/UpdatePasswordWithTokenRequest";
import UpdateUserRequest from "../generated/model/UpdateUserRequest";
import UpdateUserDefaultSettingsRequest from "../generated/model/UpdateUserDefaultSettingsRequest";

class UserService {
    constructor() {
        this.UserApi = new UserApi();
        this.AuthenticationApi = new AuthenticationApi();
        this.AccountApi = new AccountApi();
    }

    async GetUser(){
        return await window.$A.HandleApiResponse(
            this.UserApi.getUser()
        );
    }
    async GetUserSilent(){
        return await window.$A.HandleApiResponse(
            this.UserApi.getUser(),
            "",
            true
        );
    }

    async Login(username, password) {
        if (username.length === 0 || password.length === 0) {
            window.$A.AlertUser("Please Fill Out The Login Form First", "warning");
            return;
        }
        let AppUserDto = await window.$A.HandleApiResponse(
            this.AuthenticationApi.login(
                {loginRequest: new LoginRequest(username, password)},
                null
            ),
            "Signing In..."
        );
        if (
            AppUserDto != null &&
            AppUserDto.sucess !== false &&
            AppUserDto.id > 0
        ) {
            window.$A.SetLoggedInUser(AppUserDto);
        }
    }

    async GetDomainInfo(domain) {
        let response = (await window.$A.CreateApiInstance().get(`account/record?domainName=${domain}`, {}, {
            responseType: "json",
            timeout: 10000,
        }));

        return _.get(response, 'data', '');
    }

    async LoginWithToken(token, isTempToken, isFirstTimeUser = false) {
        if (isTempToken === undefined) {
            isTempToken = false;
        }
        if (isTempToken) {
            if (token.length === 0) {
                window.$A.AlertUser("Token Is Not Set", "warning");
                return;
            }
            try {
                let AppUserDto = (await window.$A.GetNewAxiosClient().post("/token/login", {}, {
                    headers: {'TempAccessToken': token},
                    responseType: "json",
                    timeout: 10000
                })).data;
                // console.log(AppUserDto)
                //
                // let AppUserDto = await window.$A.HandleApiResponse(
                //   this.AuthenticationApi.loginWithToken(token),
                //   "Signing In..."
                // );
                if (
                    AppUserDto != null &&
                    AppUserDto.id > 0
                ) {
                    window.$A.SetLoggedInUser(AppUserDto, false);
                } else {
                    //reload and remove query from url
                    window.location.href = window.location.origin + window.location.pathname;
                }
            } catch (e) {
                //reload and remove query from url
                window.location.href = window.location.origin + window.location.pathname;
            }

        } else {
            if (token.length === 0) {
                window.$A.AlertUser("Token Is Not Set", "warning");
                return;
            }
            let AppUserDto = await window.$A.HandleApiResponse(
                this.UserApi.validateAccountUser(token),
                "Signing In..."
            );
            if (AppUserDto != null) {
                window.$A.SetLoggedInUser(AppUserDto, true, false, isFirstTimeUser);
            }
        }
    }

    async Register(
        organization,
        name,
        email,
        password,
        phone,
        title,
        timezone,
        code,
        urlParamsJsonString
    ) {
        let dto = new RegisterRequest(
            organization,
            name,
            email,
            password,
            phone,
            title,
            timezone,
            urlParamsJsonString
        );
        dto.code = code;
        let response = await window.$A.HandleApiResponse(
            this.AccountApi.register({registerRequest: dto}),
            "Creating..."
        );

        if (
            response != null &&
            response.id !== undefined &&
            response.id > 0 &&
            response.emailVerified === true
        ) {
            // console.log(response);
            window.$A.SetLoggedInUser(response, false);
            await window.$VI.$router.push("/thanks");
            return;
        }

        if (
            response != null &&
            response.id !== undefined &&
            response.id > 0 &&
            response.emailVerified === false
        ) {
            await window.$VI.$router.push("/validate-account");
        } else {
            return response;
        }
    }

    async SaveProductTourState(productTourState) {
        let body = await window.$A.HandleApiResponse(
            this.UserApi.setProductTourData({
                requestBody: productTourState,
            }),
            "",
            true
        );
    }

    async SendResetPasswordLink(email) {
        return await window.$A.HandleApiResponse(
            this.UserApi.resetPassword({
                resetPasswordRequest: new ResetPasswordRequest(email),
            }),
            "Please check your email for a reset link."
        );
    }

    async ResetUserSendMethod(sendType) {
        return await window.$A.HandleApiResponse(
            this.UserApi.resetUserSendMethod({sendType})
        );
    }

    async UpdatePasswordViaToken(validationHash, password, password_confirm) {
        var body = await window.$A.HandleApiResponse(
            this.UserApi.updatePasswordViaToken(validationHash, {
                updatePasswordWithTokenRequest: UpdatePasswordWithTokenRequest.constructFromObject(
                    {password: password, passwordConfirmation: password_confirm}
                ),
            }),
            "Your Password Has Been Updated"
        );
        if (body.id > 0) {
            window.$A.SetLoggedInUser(body);
        }
        return body;
    }

    async UploadProfileImage(dataBlob) {
        let file = new File([dataBlob], "profile_photo.png", {
            type: "image/png",
        });
        let body = await window.$A.HandleApiResponse(
            this.UserApi.uploadProfileImage({
                file: file,
            }),
            "Your Profile Image Has Been Updated"
        );
        if (body && body.length > 10) {
            return body;
        } else {
            return "";
        }
    }

    async UpdateUser(id, userData) {
        let user = await window.$A.HandleApiResponse(
            this.UserApi.updateUser(id, {
                updateUserRequest: UpdateUserRequest.constructFromObject(userData),
            }),
            "User Updated"
        );
        return user;
    }

    async UpdateUserEmailSignature(userId, htmlEmailSignature) {
        let user = await window.$A.HandleApiResponse(
            this.UserApi.updateEmailSignature(
                userId,
                new File([htmlEmailSignature], {type: "text/html"}),
                null
            ),
            "User Email Signature Updated"
        );
        return user;
    }

    async UpdatePassword(currentPass, pass, passConfirm) {
        let success = await window.$A.HandleApiResponse(
            this.UserApi.updatePassword(
                {
                    updatePasswordRequest: UpdatePasswordRequest.constructFromObject({
                        currentPassword: currentPass,
                        password: pass,
                        passwordConfirmation: passConfirm,
                    }),
                },
                null
            ),
            "Your Password Has Been Updated"
        );
        return success;
    }

    async LogOut() {
        try {
            await this.AuthenticationApi.logout(null);
        } catch (e) {
            console.log("Issue Sending Logout Signal " + e);
        }
    }

    async GetSignature() {
        let sig = await window.$A.HandleApiResponse(
            this.UserApi.getUserSignature(null),
            "",
            true
        );
        return sig === null ? "" : sig;
    }

    async UpdateUserDefaultSettings(
        userId,
        downloadFiles,
        approveIndividualFiles,
        viewAllComments,
        approveWithChanges,
        notifyFilesSent,
        notifyFilesApproved,
        notifyNewComments,
        notifyMeNewComments,
        notifyMyApproversNewComments,
        limitVersionVisibility,
        blockCommentingAfterProofDecision,
        reminderDays,
        reminderTimeOfDay,
        reminderTimeZone
    ) {
        let user = await window.$A.HandleApiResponse(
            this.UserApi.updateUserDefaultSettings(
                userId,
                {
                    updateUserDefaultSettingsRequest: UpdateUserDefaultSettingsRequest.constructFromObject(
                        {
                            downloadFiles: downloadFiles,
                            approveIndividualFiles: approveIndividualFiles,
                            viewAllComments: viewAllComments,
                            approveWithChanges: approveWithChanges,
                            notifyFilesSent: notifyFilesSent,
                            notifyFilesApproved: notifyFilesApproved,
                            notifyNewComments: notifyNewComments,
                            notifyNewContactComments: notifyMeNewComments,
                            notifyNewReviewerComments: notifyMyApproversNewComments,
                            limitVersionVisibility: limitVersionVisibility,
                            blockCommentingAfterProofDecision: blockCommentingAfterProofDecision,
                            reminderDays: reminderDays,
                            reminderTimeOfDay: reminderTimeOfDay,
                            reminderTimeZone: reminderTimeZone,
                        }
                    ),
                },
                null
            ),
            "User Preferences Updated"
        );
        return user;
    }

    async GetUserSendMethodStatus(id, sendType) {
        return await window.$A.HandleApiResponse(
            this.UserApi.getUserSendMethodStatus(id, {sendType})
        );
    }

    async SaveUserSmtpSendMethod(smtpServerInfo) {
        return await window.$A.HandleApiResponse(
            this.UserApi.saveUserSmtpSendMethod({smtpServerInfo})
        );
    }

    async SaveUserPostmarkSendMethod() {
        return await window.$A.HandleApiResponse(
            this.UserApi.saveUserPostmarkSendMethod(null)
        );
    }

    async GetUserAllSendMethods() {
        return await window.$A.HandleApiResponse(
            this.UserApi.getUserAllSendMethods(null), "", true);
    }

}

export default UserService;
