import ProofApi from "../generated/api/ProofApi";
import ProofSearchOptions from "../generated/model/ProofSearchOptions";
import SaveProofRequest from "../generated/model/SaveProofRequest";
import SendMessageToCurrentApproversRequest from "../generated/model/SendMessageToCurrentApproversRequest";
import UpdateApproverStatusRequest from "../generated/model/UpdateApproverStatusRequest";
import CreateNewProofVersionRequest from "../generated/model/CreateNewProofVersionRequest";
import ProofCustomField from "../generated/model/ProofCustomField";
import ArchiveProofRequest from "../generated/model/ArchiveProofRequest";

class ProofService {
    constructor() {
        this.ProofApi = new ProofApi();
    }

    GetReviewLinkForProof(proof) {
        return window.$A.Env.webbApp + `/review/` + proof.publicId;
    }

    NavigateToProofReviewScreen(proof, blank = true) {
        window.$A.NavigateToView(
            `/review/` + proof.publicId,
            {proof: proof},
            blank
        );
    }

    NavigateToCompareScreen(proof) {
        window.$A.NavigateToView(
            "/compare/" + proof.publicId,
            {proof: proof},
            false
        );
    }

    async Delete(proofId) {
        return await this.DeleteProofs([proofId]);
    }

    async DeleteProofs(proofIds) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.deleteProofs(0, {requestBody: proofIds}, null),
            "Proof Has Been Deleted"
        );
    }

    async Archive(proofId, note) {
        return await this.ArchiveProofs([proofId], note);
    }

    async ArchiveProofs(proofIds, note) {
        const archiveProofRequest = ArchiveProofRequest.constructFromObject({proofIds, note});
        return await window.$A.HandleApiResponse(
            this.ProofApi.archiveProofsArchiveProofs({archiveProofRequest}),
            "Proof Has Been Archived"
        );
    }

    async HideProofVersionDisplay(proofId, proofVersionId, hide) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.changeProofVersionDisplay(proofId, proofVersionId, {hide: hide}),
            ""
        );
    }

    async Restore(proofId) {
        return await this.RestoreProofs([proofId]);
    }

    async RestoreProofs(proofIds) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.unArchiveProofs(0, {requestBody: proofIds}, null),
            "Proof Has Been Restored"
        );
    }

    async UpdateApproverStatus(proofId, approverId, stageId, statusObj) {
        let updateApproverStatusRequest = UpdateApproverStatusRequest.constructFromObject(
            statusObj
        );
        return await window.$A.HandleApiResponse(
            this.ProofApi.overRideApproverStatus(
                proofId,
                approverId,
                stageId,
                {
                    updateApproverStatusRequest: updateApproverStatusRequest,
                }
            ),
            "Status Has Been Updated"
        );
    }

    async GetProofsByFilter(filter, page) {
        if (page === undefined) page = 1;
        let proofs = [];
        let proofSearchOptions = ProofSearchOptions.constructFromObject(filter);
        let pageResponse = await window.$A.HandleApiResponse(
            this.ProofApi.searchProofs(
                page,
                {proofSearchOptions: proofSearchOptions},
                null
            ),
            "",
            true
        );
        if (Array.isArray(pageResponse)) {
            pageResponse.forEach(function (value, index, array) {
                proofs.push(value);
            });
        }
        return proofs;
    }

    async GetProofsByFilterYieldAll(filter, handleNewResults) {
        let i = 1;
        while (1 === 1) {
            let results = await this.GetProofsByFilter(filter, i);
            if (results.length === 0) {
                return;
            }
            handleNewResults(results);
            i++;
        }
    }

    async GetFullProof(proofId, proofVersionId, loadAllVersions) {
        if (proofVersionId === undefined) {
            proofVersionId = 0;
        }
        if (loadAllVersions === undefined) {
            loadAllVersions = false;
        }
        return await window.$A.HandleApiResponse(
            this.ProofApi.getFullProof(
                proofId,
                proofVersionId,
                {
                    loadAllVersions: loadAllVersions
                }
            ),
            "",
            true
        );
    }

    async GetProofFromPubId(publicProofId) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.getProofFromPubId(
                publicProofId,
                null
            ),
            "",
            true
        );
    }

    async AttachWorkflowToProof(proofId, workflowId) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.attachWorkflowToProof(
                proofId,
                workflowId
            ),
            "",
            true
        );
    }

    async StartWorkflowForProofVersion(proofId) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.startWorkflowForProofVersion(proofId),
            "Workflow Started For Proof"
        );
    }

    async UpdateWorkflowForProof(proofId) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.updateWorkflowForCurrentVersion(proofId),
            "Workflow Updated For Proof, New Users In Current Stage Will Receive An Email"
        );
    }

    async CreateUpdateProof(proofId, proof) {
        let saveProofRequest = SaveProofRequest.constructFromObject(proof);
        if (proofId > 0) {
            return await window.$A.HandleApiResponse(
                this.ProofApi.updateProof(
                    proofId,
                    {
                        saveProofRequest: saveProofRequest,
                    },
                    null
                ),
                "",
                true
            );
        } else {
            return await window.$A.HandleApiResponse(
                this.ProofApi.createNewProof(
                    {
                        saveProofRequest: saveProofRequest,
                    },
                    null
                ),
                "Proof Has Been Saved",
                false
            );
        }
    }

    async CreateNewVersion(createNewProofVersionRequest) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.createNewVersion(
                {
                    createNewProofVersionRequest: CreateNewProofVersionRequest.constructFromObject(
                        createNewProofVersionRequest,
                        null
                    ),
                },
                null
            ),
            "",
            true
        );
    }

    async AddNoteToTimeline(proofId, note) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.addNoteToProofTimeline(
                proofId,
                {
                    body: JSON.stringify(note),
                },
                null
            ),
            "Note Has Been Added"
        );
    }

    async UpdateSender(proofId, senderId) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.setCurrentSender(proofId, senderId, null),
            "Sender Has Been Updated"
        );
    }

    async SendMessageToCurrentApprovers(proofId, subject, message) {
        let sendMessageObj = SendMessageToCurrentApproversRequest.constructFromObject(
            {
                subject: subject,
                message: message,
            }
        );

        return await window.$A.HandleApiResponse(
            this.ProofApi.sendMessageToCurrentApprovers(
                proofId,
                {
                    sendMessageToCurrentApproversRequest: sendMessageObj,
                },
                null
            ),
            "The Message Has Been Queued, You will see a confirmation on the timeline shortly."
        );
    }

    async SendMessageApprover(proofId, approverContactIds, reviewerContactIds, sendOptions, subject, message) {
        let sendMessageObj = SendMessageToCurrentApproversRequest.constructFromObject(
            {
                approverContactIds,
                reviewerContactIds,
                sendOptions,
                subject,
                message,
            }
        );

        return await window.$A.HandleApiResponse(
            this.ProofApi.sendMessageToCurrentApprovers(
                proofId,
                {
                    sendMessageToCurrentApproversRequest: sendMessageObj,
                },
                null
            ),
            "The Message Has Been Queued, You will see a confirmation on the timeline shortly."
        );
    }

    async UpdateCustomFieldValue(proofId, field) {
        return await window.$A.HandleApiResponse(
            this.ProofApi.createOrUpdateCustomFieldValue(
                proofId,
                {
                    proofCustomField: ProofCustomField.constructFromObject(field)
                },
            ),
            "Property Has Been Updated"
        )
    }
}

export default ProofService;
