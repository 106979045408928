<script>
export default {
  props: [],
  name: "alertHeader",
  methods: {},
  computed: {},
  data() {
    return {
      alertMessage: "",
      alertType: "success",
      alertPromise: null,
    };
  },
  mounted() {
    let self = this;
    this.$root.$on("alert::user", (message, type, timeout) => {
      if (timeout === undefined) {
        timeout = 5000;
      }

      self.alertType = type;

      if (self.alertPromise !== null) {
        self.alertMessage += "<br/>" + message;
      } else {
        self.alertMessage = message;
      }

      clearTimeout(self.alertPromise);
      self.alertPromise = setTimeout(function () {
        self.alertMessage = "";
        self.alertType = "success";
        self.alertPromise = null;
      }, timeout);
    });
  },
};
</script>

<template>
  <div class="fixed-navbar-modal">
    <b-alert
      :variant="alertType"
      class="nav-alert-modal"
      :show="alertMessage.length > 0"
      dismissible
      ><span v-html="alertMessage"></span
    ></b-alert>
  </div>
</template>

<style>
.fixed-navbar-modal {
  position: absolute;
  min-height: 0;
  margin: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 888;
}

.nav-alert-modal {
  z-index: 999;
  font-size: 14px;
  position: relative;
  text-align: center;
}
</style>
