/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ReAssignUsersRequest model module.
 * @module model/ReAssignUsersRequest
 * @version V1
 */
class ReAssignUsersRequest {
    /**
     * Constructs a new <code>ReAssignUsersRequest</code>.
     * @alias module:model/ReAssignUsersRequest
     */
    constructor() { 
        
        ReAssignUsersRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ReAssignUsersRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ReAssignUsersRequest} obj Optional instance to populate.
     * @return {module:model/ReAssignUsersRequest} The populated <code>ReAssignUsersRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReAssignUsersRequest();

            if (data.hasOwnProperty('oldRoleId')) {
                obj['oldRoleId'] = ApiClient.convertToType(data['oldRoleId'], 'Number');
            }
            if (data.hasOwnProperty('newRoleId')) {
                obj['newRoleId'] = ApiClient.convertToType(data['newRoleId'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ReAssignUsersRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ReAssignUsersRequest</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * @member {Number} oldRoleId
 */
ReAssignUsersRequest.prototype['oldRoleId'] = undefined;

/**
 * @member {Number} newRoleId
 */
ReAssignUsersRequest.prototype['newRoleId'] = undefined;






export default ReAssignUsersRequest;

