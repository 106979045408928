import AppStringApi from "../generated/api/AppStringApi";
import AppStringLocalization from "../generated/model/AppStringLocalization";

class LangService {
    constructor(A) {
        this.$A = A;
        this.langs = {};
        this.AppStringApi = new AppStringApi()
        // will map any language that starts with key to value
        this.mappedLanguages = {
            'en-': 'en-us',
            'es-': 'es-us',
        }
        this.curLang = this.$A.LoadDataCache("lang") || 'en-us';
    }


    getBrowserLangMappedToSupportedLanguage() {
        let navLanguage = navigator.language.toLowerCase();

        if (navLanguage) {
            let langMatch = Object.entries(this.mappedLanguages).filter(entry => navLanguage.startsWith(entry[0]));
            if (langMatch) {
                return langMatch[0][1];
            }
        }

        return 'en-us';
    }

    getUserLang() {
        let savedLang = this.curLang || this.$A.LoadDataCache("lang");
        if (!savedLang) {
            savedLang = this.getBrowserLangMappedToSupportedLanguage();
            this.$A.SaveDataCache("lang", savedLang, 0)
        }
        return savedLang;
    }

    async setUserLang(lang) {
        window.$A.SaveDataCache("lang", lang, 0)
        await this.loadLanguage(lang);
    }

    async saveLanguage(langEntries) {
        let opts = {appStringLocalization: []}
        langEntries.forEach(entry => {
            opts.appStringLocalization.push(AppStringLocalization.constructFromObject(entry));
        });
        await this.$A.HandleApiResponse(
            this.AppStringApi.updateAppStrings(opts),
            null,
            true,
        );
        this.$A.AlertUser("The labels were saved successfully", "success");
    }

    async loadLanguage(accountId, lang) {
        lang = lang || this.getUserLang();
        let response = await this.$A.HandleApiResponse(
            this.AppStringApi.getAppStrings(accountId, {lang}),
            "",
            true
        );
        let self = this;
        _.isArray(response) && response.forEach(item => {
            if (!self.langs[item.language]) {
                self.langs[item.language] = {};
            }
            self.langs[item.language][item.key] = item.value;
        })
    }

    getLabel(group, key, lang) {
        lang = lang || this.getUserLang();
        return this.langs[lang] && this.langs[lang][key] ? this.langs[lang][key] : '';
    }
}

export default LangService;