import AnnotationApi from "../generated/api/AnnotationApi";
import CreateAnnotationRequest from "../generated/model/CreateAnnotationRequest";
import UpdateAnnotationRequest from "../generated/model/UpdateAnnotationRequest";
import CreateAnnotationCommentRequest from "../generated/model/CreateAnnotationCommentRequest";
import UpdateAnnotationCommentRequest from "../generated/model/UpdateAnnotationCommentRequest";

class AnnotationService {
  constructor() {
    this.AnnotationApi = new AnnotationApi();
  }

  async CreateAnnotation(annotation) {
    return await window.$A.HandleApiResponse(
      this.AnnotationApi.createNewAnnotation(
        {
          createAnnotationRequest: CreateAnnotationRequest.constructFromObject(
            annotation
          ),
        },
        null
      ),
      "",
      true
    );
  }
  async CreateComment(comment) {
    return await window.$A.HandleApiResponse(
      this.AnnotationApi.createNewAnnotationComment(
        {
          createAnnotationCommentRequest: CreateAnnotationCommentRequest.constructFromObject(
            comment
          ),
        },
        null
      ),
      "",
      true
    );
  }
  async UpdateAnnotation(annotation) {
      if(annotation.id===null){
          return;
      }
    return await window.$A.HandleApiResponse(
      this.AnnotationApi.updateAnnotation(
        {
          updateAnnotationRequest: UpdateAnnotationRequest.constructFromObject(
            annotation
          ),
        },
        null
      ),
      "",
      true
    );
  }
  async UpdateAnnotationResolvedStatus(annotationId, resolved) {
    return await window.$A.HandleApiResponse(
      this.AnnotationApi.updateAnnotationResolvedStatus(
        annotationId,
        resolved
      ),
      "",
      true
    );
  }
  async UpdateComment(comment) {
    return await window.$A.HandleApiResponse(
      this.AnnotationApi.updateAnnotationComment(
        {
          updateAnnotationCommentRequest: UpdateAnnotationCommentRequest.constructFromObject(
            comment
          ),
        },
        null
      ),
      "",
      true
    );
  }
  async DeleteAnnotation(annotationId) {
    return await window.$A.HandleApiResponse(
      this.AnnotationApi.deleteAnnotation(annotationId, null),
      "",
      true
    );
  }
  async DeleteComment(commentId) {
    return await window.$A.HandleApiResponse(
      this.AnnotationApi.deleteAnnotationComment(commentId, null),
      "",
      true
    );
  }
  async UploadCommentFileReturnUrl(bytes, authApiUri, uploadCompleteCallback, uploadProgress) {
    window.$A.ApiWorking();
    const data = new FormData();
    data.append("file", bytes, "pasted_image");
    let response = await window.$A.Api.post(authApiUri, data, {
      headers: {
        "Cache-Control": "no-cache",
        // 'Content-Type': fileObj.file.type,
        "Content-Type": "multipart/form-data",
        // 'AccessToken': window.$A.Auth.apiToken.token
        AccessToken: "",
      },
      timeout: 500 * 1000, //5min
      onUploadProgress: function (progressEvent) {
        let p = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if(uploadProgress!==undefined){
              uploadProgress(p)
          }
      },
    })
      .then(function (data) {
        window.$A.ApiIdle();
        uploadCompleteCallback(
          data && data.data && data.data["file-1"] !== undefined,
          data
        );
      })
      .catch(function (e) {
        let data = e.response;
        uploadCompleteCallback(false, {});
        window.$A.ApiIdle();
      });
  }
}

export default AnnotationService;
