/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofAnnotationComment from './ProofAnnotationComment';
import ProofVersionFile from './ProofVersionFile';

/**
 * The ProofAnnotation model module.
 * @module model/ProofAnnotation
 * @version V1
 */
class ProofAnnotation {
    /**
     * Constructs a new <code>ProofAnnotation</code>.
     * @alias module:model/ProofAnnotation
     */
    constructor() { 
        
        ProofAnnotation.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofAnnotation</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofAnnotation} obj Optional instance to populate.
     * @return {module:model/ProofAnnotation} The populated <code>ProofAnnotation</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofAnnotation();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('ownerName')) {
                obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
            }
            if (data.hasOwnProperty('ownerInitials')) {
                obj['ownerInitials'] = ApiClient.convertToType(data['ownerInitials'], 'String');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofFileId')) {
                obj['proofFileId'] = ApiClient.convertToType(data['proofFileId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('docType')) {
                obj['docType'] = ApiClient.convertToType(data['docType'], 'String');
            }
            if (data.hasOwnProperty('pdfPage')) {
                obj['pdfPage'] = ApiClient.convertToType(data['pdfPage'], 'Number');
            }
            if (data.hasOwnProperty('curTime')) {
                obj['curTime'] = ApiClient.convertToType(data['curTime'], 'Number');
            }
            if (data.hasOwnProperty('containerWidth')) {
                obj['containerWidth'] = ApiClient.convertToType(data['containerWidth'], 'Number');
            }
            if (data.hasOwnProperty('zoom')) {
                obj['zoom'] = ApiClient.convertToType(data['zoom'], 'Number');
            }
            if (data.hasOwnProperty('x')) {
                obj['x'] = ApiClient.convertToType(data['x'], 'Number');
            }
            if (data.hasOwnProperty('y')) {
                obj['y'] = ApiClient.convertToType(data['y'], 'Number');
            }
            if (data.hasOwnProperty('x2')) {
                obj['x2'] = ApiClient.convertToType(data['x2'], 'Number');
            }
            if (data.hasOwnProperty('y2')) {
                obj['y2'] = ApiClient.convertToType(data['y2'], 'Number');
            }
            if (data.hasOwnProperty('startX')) {
                obj['startX'] = ApiClient.convertToType(data['startX'], 'Number');
            }
            if (data.hasOwnProperty('startY')) {
                obj['startY'] = ApiClient.convertToType(data['startY'], 'Number');
            }
            if (data.hasOwnProperty('endX')) {
                obj['endX'] = ApiClient.convertToType(data['endX'], 'Number');
            }
            if (data.hasOwnProperty('endY')) {
                obj['endY'] = ApiClient.convertToType(data['endY'], 'Number');
            }
            if (data.hasOwnProperty('w')) {
                obj['w'] = ApiClient.convertToType(data['w'], 'Number');
            }
            if (data.hasOwnProperty('h')) {
                obj['h'] = ApiClient.convertToType(data['h'], 'Number');
            }
            if (data.hasOwnProperty('notifiedAt')) {
                obj['notifiedAt'] = ApiClient.convertToType(data['notifiedAt'], 'Date');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'Date');
            }
            if (data.hasOwnProperty('resolved')) {
                obj['resolved'] = ApiClient.convertToType(data['resolved'], 'Boolean');
            }
            if (data.hasOwnProperty('liveWebProofingUrl')) {
                obj['liveWebProofingUrl'] = ApiClient.convertToType(data['liveWebProofingUrl'], 'String');
            }
            if (data.hasOwnProperty('liveWebProofingResolution')) {
                obj['liveWebProofingResolution'] = ApiClient.convertToType(data['liveWebProofingResolution'], 'Number');
            }
            if (data.hasOwnProperty('contextImageS3Key')) {
                obj['contextImageS3Key'] = ApiClient.convertToType(data['contextImageS3Key'], 'String');
            }
            if (data.hasOwnProperty('drawingLayerImageS3Key')) {
                obj['drawingLayerImageS3Key'] = ApiClient.convertToType(data['drawingLayerImageS3Key'], 'String');
            }
            if (data.hasOwnProperty('contextImageUrl')) {
                obj['contextImageUrl'] = ApiClient.convertToType(data['contextImageUrl'], 'String');
            }
            if (data.hasOwnProperty('drawingLayerImageUrl')) {
                obj['drawingLayerImageUrl'] = ApiClient.convertToType(data['drawingLayerImageUrl'], 'String');
            }
            if (data.hasOwnProperty('proofFileAnnotationComments')) {
                obj['proofFileAnnotationComments'] = ApiClient.convertToType(data['proofFileAnnotationComments'], [ProofAnnotationComment]);
            }
            if (data.hasOwnProperty('proofVersionFile')) {
                obj['proofVersionFile'] = ProofVersionFile.constructFromObject(data['proofVersionFile']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProofAnnotation</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProofAnnotation</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['ownerName'] && !(typeof data['ownerName'] === 'string' || data['ownerName'] instanceof String)) {
            throw new Error("Expected the field `ownerName` to be a primitive type in the JSON string but got " + data['ownerName']);
        }
        // ensure the json data is a string
        if (data['ownerInitials'] && !(typeof data['ownerInitials'] === 'string' || data['ownerInitials'] instanceof String)) {
            throw new Error("Expected the field `ownerInitials` to be a primitive type in the JSON string but got " + data['ownerInitials']);
        }
        // ensure the json data is a string
        if (data['type'] && !(typeof data['type'] === 'string' || data['type'] instanceof String)) {
            throw new Error("Expected the field `type` to be a primitive type in the JSON string but got " + data['type']);
        }
        // ensure the json data is a string
        if (data['docType'] && !(typeof data['docType'] === 'string' || data['docType'] instanceof String)) {
            throw new Error("Expected the field `docType` to be a primitive type in the JSON string but got " + data['docType']);
        }
        // ensure the json data is a string
        if (data['liveWebProofingUrl'] && !(typeof data['liveWebProofingUrl'] === 'string' || data['liveWebProofingUrl'] instanceof String)) {
            throw new Error("Expected the field `liveWebProofingUrl` to be a primitive type in the JSON string but got " + data['liveWebProofingUrl']);
        }
        // ensure the json data is a string
        if (data['contextImageS3Key'] && !(typeof data['contextImageS3Key'] === 'string' || data['contextImageS3Key'] instanceof String)) {
            throw new Error("Expected the field `contextImageS3Key` to be a primitive type in the JSON string but got " + data['contextImageS3Key']);
        }
        // ensure the json data is a string
        if (data['drawingLayerImageS3Key'] && !(typeof data['drawingLayerImageS3Key'] === 'string' || data['drawingLayerImageS3Key'] instanceof String)) {
            throw new Error("Expected the field `drawingLayerImageS3Key` to be a primitive type in the JSON string but got " + data['drawingLayerImageS3Key']);
        }
        // ensure the json data is a string
        if (data['contextImageUrl'] && !(typeof data['contextImageUrl'] === 'string' || data['contextImageUrl'] instanceof String)) {
            throw new Error("Expected the field `contextImageUrl` to be a primitive type in the JSON string but got " + data['contextImageUrl']);
        }
        // ensure the json data is a string
        if (data['drawingLayerImageUrl'] && !(typeof data['drawingLayerImageUrl'] === 'string' || data['drawingLayerImageUrl'] instanceof String)) {
            throw new Error("Expected the field `drawingLayerImageUrl` to be a primitive type in the JSON string but got " + data['drawingLayerImageUrl']);
        }
        if (data['proofFileAnnotationComments']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['proofFileAnnotationComments'])) {
                throw new Error("Expected the field `proofFileAnnotationComments` to be an array in the JSON data but got " + data['proofFileAnnotationComments']);
            }
            // validate the optional field `proofFileAnnotationComments` (array)
            for (const item of data['proofFileAnnotationComments']) {
                ProofAnnotationComment.validateJSON(item);
            };
        }
        // validate the optional field `proofVersionFile`
        if (data['proofVersionFile']) { // data not null
          ProofVersionFile.validateJSON(data['proofVersionFile']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
ProofAnnotation.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
ProofAnnotation.prototype['accountId'] = undefined;

/**
 * @member {Number} userId
 */
ProofAnnotation.prototype['userId'] = undefined;

/**
 * @member {Number} approverId
 */
ProofAnnotation.prototype['approverId'] = undefined;

/**
 * @member {String} ownerName
 */
ProofAnnotation.prototype['ownerName'] = undefined;

/**
 * @member {String} ownerInitials
 */
ProofAnnotation.prototype['ownerInitials'] = undefined;

/**
 * @member {Number} proofId
 */
ProofAnnotation.prototype['proofId'] = undefined;

/**
 * @member {Number} proofFileId
 */
ProofAnnotation.prototype['proofFileId'] = undefined;

/**
 * @member {Number} proofVersionId
 */
ProofAnnotation.prototype['proofVersionId'] = undefined;

/**
 * @member {String} type
 */
ProofAnnotation.prototype['type'] = undefined;

/**
 * @member {String} docType
 */
ProofAnnotation.prototype['docType'] = undefined;

/**
 * @member {Number} pdfPage
 */
ProofAnnotation.prototype['pdfPage'] = undefined;

/**
 * @member {Number} curTime
 */
ProofAnnotation.prototype['curTime'] = undefined;

/**
 * @member {Number} containerWidth
 */
ProofAnnotation.prototype['containerWidth'] = undefined;

/**
 * @member {Number} zoom
 */
ProofAnnotation.prototype['zoom'] = undefined;

/**
 * @member {Number} x
 */
ProofAnnotation.prototype['x'] = undefined;

/**
 * @member {Number} y
 */
ProofAnnotation.prototype['y'] = undefined;

/**
 * @member {Number} x2
 */
ProofAnnotation.prototype['x2'] = undefined;

/**
 * @member {Number} y2
 */
ProofAnnotation.prototype['y2'] = undefined;

/**
 * @member {Number} startX
 */
ProofAnnotation.prototype['startX'] = undefined;

/**
 * @member {Number} startY
 */
ProofAnnotation.prototype['startY'] = undefined;

/**
 * @member {Number} endX
 */
ProofAnnotation.prototype['endX'] = undefined;

/**
 * @member {Number} endY
 */
ProofAnnotation.prototype['endY'] = undefined;

/**
 * @member {Number} w
 */
ProofAnnotation.prototype['w'] = undefined;

/**
 * @member {Number} h
 */
ProofAnnotation.prototype['h'] = undefined;

/**
 * @member {Date} notifiedAt
 */
ProofAnnotation.prototype['notifiedAt'] = undefined;

/**
 * @member {Date} createdAt
 */
ProofAnnotation.prototype['createdAt'] = undefined;

/**
 * @member {Date} deletedAt
 */
ProofAnnotation.prototype['deletedAt'] = undefined;

/**
 * @member {Boolean} resolved
 */
ProofAnnotation.prototype['resolved'] = undefined;

/**
 * @member {String} liveWebProofingUrl
 */
ProofAnnotation.prototype['liveWebProofingUrl'] = undefined;

/**
 * @member {Number} liveWebProofingResolution
 */
ProofAnnotation.prototype['liveWebProofingResolution'] = undefined;

/**
 * @member {String} contextImageS3Key
 */
ProofAnnotation.prototype['contextImageS3Key'] = undefined;

/**
 * @member {String} drawingLayerImageS3Key
 */
ProofAnnotation.prototype['drawingLayerImageS3Key'] = undefined;

/**
 * @member {String} contextImageUrl
 */
ProofAnnotation.prototype['contextImageUrl'] = undefined;

/**
 * @member {String} drawingLayerImageUrl
 */
ProofAnnotation.prototype['drawingLayerImageUrl'] = undefined;

/**
 * @member {Array.<module:model/ProofAnnotationComment>} proofFileAnnotationComments
 */
ProofAnnotation.prototype['proofFileAnnotationComments'] = undefined;

/**
 * @member {module:model/ProofVersionFile} proofVersionFile
 */
ProofAnnotation.prototype['proofVersionFile'] = undefined;






export default ProofAnnotation;

