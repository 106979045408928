/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import SaveWorkflowRequest from '../model/SaveWorkflowRequest';
import SaveWorkflowStageRequest from '../model/SaveWorkflowStageRequest';
import SuccessResponse from '../model/SuccessResponse';
import Workflow from '../model/Workflow';
import WorkflowStage from '../model/WorkflowStage';

/**
* Workflow service.
* @module api/WorkflowApi
* @version V1
*/
export default class WorkflowApi {

    /**
    * Constructs a new WorkflowApi. 
    * @alias module:api/WorkflowApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the createWorkflow operation.
     * @callback module:api/WorkflowApi~createWorkflowCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveWorkflowRequest} [saveWorkflowRequest] 
     * @param {module:api/WorkflowApi~createWorkflowCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    createWorkflow(opts, callback) {
      opts = opts || {};
      let postBody = opts['saveWorkflowRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createWorkflowApprover operation.
     * @callback module:api/WorkflowApi~createWorkflowApproverCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {Number} stageId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} [requestBody] 
     * @param {module:api/WorkflowApi~createWorkflowApproverCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    createWorkflowApprover(workflowId, stageId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling createWorkflowApprover");
      }
      // verify the required parameter 'stageId' is set
      if (stageId === undefined || stageId === null) {
        throw new Error("Missing the required parameter 'stageId' when calling createWorkflowApprover");
      }

      let pathParams = {
        'workflowId': workflowId,
        'stageId': stageId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow/{workflowId}/stage/{stageId}/approver', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createWorkflowReviewer operation.
     * @callback module:api/WorkflowApi~createWorkflowReviewerCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {Number} stageId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} [requestBody] 
     * @param {module:api/WorkflowApi~createWorkflowReviewerCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    createWorkflowReviewer(workflowId, stageId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling createWorkflowReviewer");
      }
      // verify the required parameter 'stageId' is set
      if (stageId === undefined || stageId === null) {
        throw new Error("Missing the required parameter 'stageId' when calling createWorkflowReviewer");
      }

      let pathParams = {
        'workflowId': workflowId,
        'stageId': stageId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow/{workflowId}/stage/{stageId}/reviewer', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createWorkflowStage operation.
     * @callback module:api/WorkflowApi~createWorkflowStageCallback
     * @param {String} error Error message, if any.
     * @param {module:model/WorkflowStage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveWorkflowStageRequest} [saveWorkflowStageRequest] 
     * @param {module:api/WorkflowApi~createWorkflowStageCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/WorkflowStage}
     */
    createWorkflowStage(workflowId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveWorkflowStageRequest'];
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling createWorkflowStage");
      }

      let pathParams = {
        'workflowId': workflowId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = WorkflowStage;
      return this.apiClient.callApi(
        '/workflow/{workflowId}/stage', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteWorkflow operation.
     * @callback module:api/WorkflowApi~deleteWorkflowCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {module:api/WorkflowApi~deleteWorkflowCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteWorkflow(workflowId, callback) {
      let postBody = null;
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling deleteWorkflow");
      }

      let pathParams = {
        'workflowId': workflowId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/workflow/{workflowId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteWorkflowApprover operation.
     * @callback module:api/WorkflowApi~deleteWorkflowApproverCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {Number} stageId 
     * @param {Number} approverId 
     * @param {module:api/WorkflowApi~deleteWorkflowApproverCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    deleteWorkflowApprover(workflowId, stageId, approverId, callback) {
      let postBody = null;
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling deleteWorkflowApprover");
      }
      // verify the required parameter 'stageId' is set
      if (stageId === undefined || stageId === null) {
        throw new Error("Missing the required parameter 'stageId' when calling deleteWorkflowApprover");
      }
      // verify the required parameter 'approverId' is set
      if (approverId === undefined || approverId === null) {
        throw new Error("Missing the required parameter 'approverId' when calling deleteWorkflowApprover");
      }

      let pathParams = {
        'workflowId': workflowId,
        'stageId': stageId,
        'approverId': approverId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow/{workflowId}/stage/{stageId}/approver/{approverId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteWorkflowApprovers operation.
     * @callback module:api/WorkflowApi~deleteWorkflowApproversCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {Number} stageId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} [requestBody] 
     * @param {module:api/WorkflowApi~deleteWorkflowApproversCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    deleteWorkflowApprovers(workflowId, stageId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling deleteWorkflowApprovers");
      }
      // verify the required parameter 'stageId' is set
      if (stageId === undefined || stageId === null) {
        throw new Error("Missing the required parameter 'stageId' when calling deleteWorkflowApprovers");
      }

      let pathParams = {
        'workflowId': workflowId,
        'stageId': stageId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow/{workflowId}/stage/{stageId}/approver/multiple', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteWorkflowStage operation.
     * @callback module:api/WorkflowApi~deleteWorkflowStageCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {Number} stageId 
     * @param {module:api/WorkflowApi~deleteWorkflowStageCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    deleteWorkflowStage(workflowId, stageId, callback) {
      let postBody = null;
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling deleteWorkflowStage");
      }
      // verify the required parameter 'stageId' is set
      if (stageId === undefined || stageId === null) {
        throw new Error("Missing the required parameter 'stageId' when calling deleteWorkflowStage");
      }

      let pathParams = {
        'workflowId': workflowId,
        'stageId': stageId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow/{workflowId}/stage/{stageId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the duplicate operation.
     * @callback module:api/WorkflowApi~duplicateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {module:api/WorkflowApi~duplicateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    duplicate(workflowId, callback) {
      let postBody = null;
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling duplicate");
      }

      let pathParams = {
        'workflowId': workflowId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow/{workflowId}/duplicate', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAll_2 operation.
     * @callback module:api/WorkflowApi~getAll_2Callback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Workflow>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/WorkflowApi~getAll_2Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Workflow>}
     */
    getAll_2(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Workflow];
      return this.apiClient.callApi(
        '/workflow', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getWorkflow operation.
     * @callback module:api/WorkflowApi~getWorkflowCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {module:api/WorkflowApi~getWorkflowCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    getWorkflow(workflowId, callback) {
      let postBody = null;
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling getWorkflow");
      }

      let pathParams = {
        'workflowId': workflowId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow/{workflowId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the reOrderWorkflowStages operation.
     * @callback module:api/WorkflowApi~reOrderWorkflowStagesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} [requestBody] 
     * @param {module:api/WorkflowApi~reOrderWorkflowStagesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    reOrderWorkflowStages(workflowId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling reOrderWorkflowStages");
      }

      let pathParams = {
        'workflowId': workflowId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow/{workflowId}/stage/reorder', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateWorkflow operation.
     * @callback module:api/WorkflowApi~updateWorkflowCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Workflow} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveWorkflowRequest} [saveWorkflowRequest] 
     * @param {module:api/WorkflowApi~updateWorkflowCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Workflow}
     */
    updateWorkflow(workflowId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveWorkflowRequest'];
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling updateWorkflow");
      }

      let pathParams = {
        'workflowId': workflowId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Workflow;
      return this.apiClient.callApi(
        '/workflow/{workflowId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateWorkflowStage operation.
     * @callback module:api/WorkflowApi~updateWorkflowStageCallback
     * @param {String} error Error message, if any.
     * @param {module:model/WorkflowStage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} workflowId 
     * @param {Number} stageId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveWorkflowStageRequest} [saveWorkflowStageRequest] 
     * @param {module:api/WorkflowApi~updateWorkflowStageCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/WorkflowStage}
     */
    updateWorkflowStage(workflowId, stageId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveWorkflowStageRequest'];
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling updateWorkflowStage");
      }
      // verify the required parameter 'stageId' is set
      if (stageId === undefined || stageId === null) {
        throw new Error("Missing the required parameter 'stageId' when calling updateWorkflowStage");
      }

      let pathParams = {
        'workflowId': workflowId,
        'stageId': stageId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = WorkflowStage;
      return this.apiClient.callApi(
        '/workflow/{workflowId}/stage/{stageId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
