/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofPermissions from './ProofPermissions';

/**
 * The AshoreUserRole model module.
 * @module model/AshoreUserRole
 * @version V1
 */
class AshoreUserRole {
    /**
     * Constructs a new <code>AshoreUserRole</code>.
     * @alias module:model/AshoreUserRole
     */
    constructor() { 
        
        AshoreUserRole.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AshoreUserRole</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AshoreUserRole} obj Optional instance to populate.
     * @return {module:model/AshoreUserRole} The populated <code>AshoreUserRole</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AshoreUserRole();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('sendProofs')) {
                obj['sendProofs'] = ApiClient.convertToType(data['sendProofs'], 'Boolean');
            }
            if (data.hasOwnProperty('createProofs')) {
                obj['createProofs'] = ApiClient.convertToType(data['createProofs'], 'Boolean');
            }
            if (data.hasOwnProperty('sendProofsNotOwned')) {
                obj['sendProofsNotOwned'] = ApiClient.convertToType(data['sendProofsNotOwned'], 'Boolean');
            }
            if (data.hasOwnProperty('manageTemplates')) {
                obj['manageTemplates'] = ApiClient.convertToType(data['manageTemplates'], 'Boolean');
            }
            if (data.hasOwnProperty('manageWorkflows')) {
                obj['manageWorkflows'] = ApiClient.convertToType(data['manageWorkflows'], 'Boolean');
            }
            if (data.hasOwnProperty('manageBilling')) {
                obj['manageBilling'] = ApiClient.convertToType(data['manageBilling'], 'Boolean');
            }
            if (data.hasOwnProperty('manageAccount')) {
                obj['manageAccount'] = ApiClient.convertToType(data['manageAccount'], 'Boolean');
            }
            if (data.hasOwnProperty('manageUsers')) {
                obj['manageUsers'] = ApiClient.convertToType(data['manageUsers'], 'Boolean');
            }
            if (data.hasOwnProperty('proofPermissions')) {
                obj['proofPermissions'] = ProofPermissions.constructFromObject(data['proofPermissions']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AshoreUserRole</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AshoreUserRole</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
AshoreUserRole.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
AshoreUserRole.prototype['accountId'] = undefined;

/**
 * @member {String} name
 */
AshoreUserRole.prototype['name'] = undefined;

/**
 * @member {Boolean} sendProofs
 */
AshoreUserRole.prototype['sendProofs'] = undefined;

/**
 * @member {Boolean} createProofs
 */
AshoreUserRole.prototype['createProofs'] = undefined;

/**
 * @member {Boolean} sendProofsNotOwned
 */
AshoreUserRole.prototype['sendProofsNotOwned'] = undefined;

/**
 * @member {Boolean} manageTemplates
 */
AshoreUserRole.prototype['manageTemplates'] = undefined;

/**
 * @member {Boolean} manageWorkflows
 */
AshoreUserRole.prototype['manageWorkflows'] = undefined;

/**
 * @member {Boolean} manageBilling
 */
AshoreUserRole.prototype['manageBilling'] = undefined;

/**
 * @member {Boolean} manageAccount
 */
AshoreUserRole.prototype['manageAccount'] = undefined;

/**
 * @member {Boolean} manageUsers
 */
AshoreUserRole.prototype['manageUsers'] = undefined;

/**
 * @member {module:model/ProofPermissions} proofPermissions
 */
AshoreUserRole.prototype['proofPermissions'] = undefined;






export default AshoreUserRole;

