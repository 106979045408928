<script>
export default {
  name: "pageheader",
  props: [
    "title",
    "buttontext",
    "buttonlink",
    "demostyle",
    "buttonstyle",
    "searchstyle",
    "columns",
  ],
  data() {
    return {
      searchValue: "",
      enterPressed: false,
      searchFunctionWaitForNoChanges: false,
    };
  },
  methods: {
    broadcastSearchValues: function (e) {
      let self = this;
      clearTimeout(self.searchFunctionWaitForNoChanges);
      if (this.searchValue.length === 0 || e.keyCode === 13) {
        self.searchFunctionWaitForNoChanges = setTimeout(async function () {
          self.$root.$emit("page::search::value::updated", self.searchValue);
        }, 100);
      }
    },
    emitButtonClick: function ($event) {
      if (this.$props.buttonlink.length > 0) {
        $A.NavigateToView(this.$props.buttonlink, {});
      }
      this.$emit("linkClicked", $event);
    },
  },
};
</script>

<template>
  <div :class="title === 'Captains Deck' ? 'container-fluid': 'container-fluid nav-margin'">
    <div style="margin-top: 24px">
      <div class="row">
        <div :class="title === 'Captains Deck' ? 'col-md-2 ml-auto pb-3 pr-0': 'col-md-2 ml-auto pb-3'" v-if="title === 'Captains Deck'">
          <div :style="searchstyle" >
            <input
                type="text"
                name="Search"
                :class="title === 'Captains Deck' ? 'form-control search-input':'form-control'"
                placeholder="Search"
                style="height: 50px"
                v-on:keyup="broadcastSearchValues"
                v-model="searchValue"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.search-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px !important;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

</style>
