/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CallingConventions from './CallingConventions';
import CustomAttributeData from './CustomAttributeData';
import MemberTypes from './MemberTypes';
import MethodAttributes from './MethodAttributes';
import MethodImplAttributes from './MethodImplAttributes';
import Module from './Module';
import RuntimeMethodHandle from './RuntimeMethodHandle';

/**
 * The ConstructorInfo model module.
 * @module model/ConstructorInfo
 * @version V1
 */
class ConstructorInfo {
    /**
     * Constructs a new <code>ConstructorInfo</code>.
     * @alias module:model/ConstructorInfo
     */
    constructor() { 
        
        ConstructorInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ConstructorInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ConstructorInfo} obj Optional instance to populate.
     * @return {module:model/ConstructorInfo} The populated <code>ConstructorInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ConstructorInfo();

            if (data.hasOwnProperty('memberType')) {
                obj['memberType'] = MemberTypes.constructFromObject(data['memberType']);
            }
            if (data.hasOwnProperty('attributes')) {
                obj['attributes'] = MethodAttributes.constructFromObject(data['attributes']);
            }
            if (data.hasOwnProperty('methodImplementationFlags')) {
                obj['methodImplementationFlags'] = MethodImplAttributes.constructFromObject(data['methodImplementationFlags']);
            }
            if (data.hasOwnProperty('callingConvention')) {
                obj['callingConvention'] = CallingConventions.constructFromObject(data['callingConvention']);
            }
            if (data.hasOwnProperty('isAbstract')) {
                obj['isAbstract'] = ApiClient.convertToType(data['isAbstract'], 'Boolean');
            }
            if (data.hasOwnProperty('isConstructor')) {
                obj['isConstructor'] = ApiClient.convertToType(data['isConstructor'], 'Boolean');
            }
            if (data.hasOwnProperty('isFinal')) {
                obj['isFinal'] = ApiClient.convertToType(data['isFinal'], 'Boolean');
            }
            if (data.hasOwnProperty('isHideBySig')) {
                obj['isHideBySig'] = ApiClient.convertToType(data['isHideBySig'], 'Boolean');
            }
            if (data.hasOwnProperty('isSpecialName')) {
                obj['isSpecialName'] = ApiClient.convertToType(data['isSpecialName'], 'Boolean');
            }
            if (data.hasOwnProperty('isStatic')) {
                obj['isStatic'] = ApiClient.convertToType(data['isStatic'], 'Boolean');
            }
            if (data.hasOwnProperty('isVirtual')) {
                obj['isVirtual'] = ApiClient.convertToType(data['isVirtual'], 'Boolean');
            }
            if (data.hasOwnProperty('isAssembly')) {
                obj['isAssembly'] = ApiClient.convertToType(data['isAssembly'], 'Boolean');
            }
            if (data.hasOwnProperty('isFamily')) {
                obj['isFamily'] = ApiClient.convertToType(data['isFamily'], 'Boolean');
            }
            if (data.hasOwnProperty('isFamilyAndAssembly')) {
                obj['isFamilyAndAssembly'] = ApiClient.convertToType(data['isFamilyAndAssembly'], 'Boolean');
            }
            if (data.hasOwnProperty('isFamilyOrAssembly')) {
                obj['isFamilyOrAssembly'] = ApiClient.convertToType(data['isFamilyOrAssembly'], 'Boolean');
            }
            if (data.hasOwnProperty('isPrivate')) {
                obj['isPrivate'] = ApiClient.convertToType(data['isPrivate'], 'Boolean');
            }
            if (data.hasOwnProperty('isPublic')) {
                obj['isPublic'] = ApiClient.convertToType(data['isPublic'], 'Boolean');
            }
            if (data.hasOwnProperty('isConstructedGenericMethod')) {
                obj['isConstructedGenericMethod'] = ApiClient.convertToType(data['isConstructedGenericMethod'], 'Boolean');
            }
            if (data.hasOwnProperty('isGenericMethod')) {
                obj['isGenericMethod'] = ApiClient.convertToType(data['isGenericMethod'], 'Boolean');
            }
            if (data.hasOwnProperty('isGenericMethodDefinition')) {
                obj['isGenericMethodDefinition'] = ApiClient.convertToType(data['isGenericMethodDefinition'], 'Boolean');
            }
            if (data.hasOwnProperty('containsGenericParameters')) {
                obj['containsGenericParameters'] = ApiClient.convertToType(data['containsGenericParameters'], 'Boolean');
            }
            if (data.hasOwnProperty('methodHandle')) {
                obj['methodHandle'] = RuntimeMethodHandle.constructFromObject(data['methodHandle']);
            }
            if (data.hasOwnProperty('isSecurityCritical')) {
                obj['isSecurityCritical'] = ApiClient.convertToType(data['isSecurityCritical'], 'Boolean');
            }
            if (data.hasOwnProperty('isSecuritySafeCritical')) {
                obj['isSecuritySafeCritical'] = ApiClient.convertToType(data['isSecuritySafeCritical'], 'Boolean');
            }
            if (data.hasOwnProperty('isSecurityTransparent')) {
                obj['isSecurityTransparent'] = ApiClient.convertToType(data['isSecurityTransparent'], 'Boolean');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('declaringType')) {
                obj['declaringType'] = ApiClient.convertToType(data['declaringType'], 'String');
            }
            if (data.hasOwnProperty('reflectedType')) {
                obj['reflectedType'] = ApiClient.convertToType(data['reflectedType'], 'String');
            }
            if (data.hasOwnProperty('module')) {
                obj['module'] = Module.constructFromObject(data['module']);
            }
            if (data.hasOwnProperty('customAttributes')) {
                obj['customAttributes'] = ApiClient.convertToType(data['customAttributes'], [CustomAttributeData]);
            }
            if (data.hasOwnProperty('isCollectible')) {
                obj['isCollectible'] = ApiClient.convertToType(data['isCollectible'], 'Boolean');
            }
            if (data.hasOwnProperty('metadataToken')) {
                obj['metadataToken'] = ApiClient.convertToType(data['metadataToken'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ConstructorInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ConstructorInfo</code>.
     */
    static validateJSON(data) {
        // validate the optional field `methodHandle`
        if (data['methodHandle']) { // data not null
          RuntimeMethodHandle.validateJSON(data['methodHandle']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['declaringType'] && !(typeof data['declaringType'] === 'string' || data['declaringType'] instanceof String)) {
            throw new Error("Expected the field `declaringType` to be a primitive type in the JSON string but got " + data['declaringType']);
        }
        // ensure the json data is a string
        if (data['reflectedType'] && !(typeof data['reflectedType'] === 'string' || data['reflectedType'] instanceof String)) {
            throw new Error("Expected the field `reflectedType` to be a primitive type in the JSON string but got " + data['reflectedType']);
        }
        // validate the optional field `module`
        if (data['module']) { // data not null
          Module.validateJSON(data['module']);
        }
        if (data['customAttributes']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['customAttributes'])) {
                throw new Error("Expected the field `customAttributes` to be an array in the JSON data but got " + data['customAttributes']);
            }
            // validate the optional field `customAttributes` (array)
            for (const item of data['customAttributes']) {
                CustomAttributeData.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {module:model/MemberTypes} memberType
 */
ConstructorInfo.prototype['memberType'] = undefined;

/**
 * @member {module:model/MethodAttributes} attributes
 */
ConstructorInfo.prototype['attributes'] = undefined;

/**
 * @member {module:model/MethodImplAttributes} methodImplementationFlags
 */
ConstructorInfo.prototype['methodImplementationFlags'] = undefined;

/**
 * @member {module:model/CallingConventions} callingConvention
 */
ConstructorInfo.prototype['callingConvention'] = undefined;

/**
 * @member {Boolean} isAbstract
 */
ConstructorInfo.prototype['isAbstract'] = undefined;

/**
 * @member {Boolean} isConstructor
 */
ConstructorInfo.prototype['isConstructor'] = undefined;

/**
 * @member {Boolean} isFinal
 */
ConstructorInfo.prototype['isFinal'] = undefined;

/**
 * @member {Boolean} isHideBySig
 */
ConstructorInfo.prototype['isHideBySig'] = undefined;

/**
 * @member {Boolean} isSpecialName
 */
ConstructorInfo.prototype['isSpecialName'] = undefined;

/**
 * @member {Boolean} isStatic
 */
ConstructorInfo.prototype['isStatic'] = undefined;

/**
 * @member {Boolean} isVirtual
 */
ConstructorInfo.prototype['isVirtual'] = undefined;

/**
 * @member {Boolean} isAssembly
 */
ConstructorInfo.prototype['isAssembly'] = undefined;

/**
 * @member {Boolean} isFamily
 */
ConstructorInfo.prototype['isFamily'] = undefined;

/**
 * @member {Boolean} isFamilyAndAssembly
 */
ConstructorInfo.prototype['isFamilyAndAssembly'] = undefined;

/**
 * @member {Boolean} isFamilyOrAssembly
 */
ConstructorInfo.prototype['isFamilyOrAssembly'] = undefined;

/**
 * @member {Boolean} isPrivate
 */
ConstructorInfo.prototype['isPrivate'] = undefined;

/**
 * @member {Boolean} isPublic
 */
ConstructorInfo.prototype['isPublic'] = undefined;

/**
 * @member {Boolean} isConstructedGenericMethod
 */
ConstructorInfo.prototype['isConstructedGenericMethod'] = undefined;

/**
 * @member {Boolean} isGenericMethod
 */
ConstructorInfo.prototype['isGenericMethod'] = undefined;

/**
 * @member {Boolean} isGenericMethodDefinition
 */
ConstructorInfo.prototype['isGenericMethodDefinition'] = undefined;

/**
 * @member {Boolean} containsGenericParameters
 */
ConstructorInfo.prototype['containsGenericParameters'] = undefined;

/**
 * @member {module:model/RuntimeMethodHandle} methodHandle
 */
ConstructorInfo.prototype['methodHandle'] = undefined;

/**
 * @member {Boolean} isSecurityCritical
 */
ConstructorInfo.prototype['isSecurityCritical'] = undefined;

/**
 * @member {Boolean} isSecuritySafeCritical
 */
ConstructorInfo.prototype['isSecuritySafeCritical'] = undefined;

/**
 * @member {Boolean} isSecurityTransparent
 */
ConstructorInfo.prototype['isSecurityTransparent'] = undefined;

/**
 * @member {String} name
 */
ConstructorInfo.prototype['name'] = undefined;

/**
 * @member {String} declaringType
 */
ConstructorInfo.prototype['declaringType'] = undefined;

/**
 * @member {String} reflectedType
 */
ConstructorInfo.prototype['reflectedType'] = undefined;

/**
 * @member {module:model/Module} module
 */
ConstructorInfo.prototype['module'] = undefined;

/**
 * @member {Array.<module:model/CustomAttributeData>} customAttributes
 */
ConstructorInfo.prototype['customAttributes'] = undefined;

/**
 * @member {Boolean} isCollectible
 */
ConstructorInfo.prototype['isCollectible'] = undefined;

/**
 * @member {Number} metadataToken
 */
ConstructorInfo.prototype['metadataToken'] = undefined;






export default ConstructorInfo;

