/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ConvertUrlIntoBase64ScreenShotRequest model module.
 * @module model/ConvertUrlIntoBase64ScreenShotRequest
 * @version V1
 */
class ConvertUrlIntoBase64ScreenShotRequest {
    /**
     * Constructs a new <code>ConvertUrlIntoBase64ScreenShotRequest</code>.
     * @alias module:model/ConvertUrlIntoBase64ScreenShotRequest
     */
    constructor() { 
        
        ConvertUrlIntoBase64ScreenShotRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ConvertUrlIntoBase64ScreenShotRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ConvertUrlIntoBase64ScreenShotRequest} obj Optional instance to populate.
     * @return {module:model/ConvertUrlIntoBase64ScreenShotRequest} The populated <code>ConvertUrlIntoBase64ScreenShotRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ConvertUrlIntoBase64ScreenShotRequest();

            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('width')) {
                obj['width'] = ApiClient.convertToType(data['width'], 'Number');
            }
            if (data.hasOwnProperty('quality')) {
                obj['quality'] = ApiClient.convertToType(data['quality'], 'Number');
            }
            if (data.hasOwnProperty('omitBackground')) {
                obj['omitBackground'] = ApiClient.convertToType(data['omitBackground'], 'Boolean');
            }
            if (data.hasOwnProperty('fullPage')) {
                obj['fullPage'] = ApiClient.convertToType(data['fullPage'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ConvertUrlIntoBase64ScreenShotRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ConvertUrlIntoBase64ScreenShotRequest</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['url'] && !(typeof data['url'] === 'string' || data['url'] instanceof String)) {
            throw new Error("Expected the field `url` to be a primitive type in the JSON string but got " + data['url']);
        }

        return true;
    }


}



/**
 * @member {String} url
 */
ConvertUrlIntoBase64ScreenShotRequest.prototype['url'] = undefined;

/**
 * @member {Number} width
 */
ConvertUrlIntoBase64ScreenShotRequest.prototype['width'] = undefined;

/**
 * @member {Number} quality
 */
ConvertUrlIntoBase64ScreenShotRequest.prototype['quality'] = undefined;

/**
 * @member {Boolean} omitBackground
 */
ConvertUrlIntoBase64ScreenShotRequest.prototype['omitBackground'] = undefined;

/**
 * @member {Boolean} fullPage
 */
ConvertUrlIntoBase64ScreenShotRequest.prototype['fullPage'] = undefined;






export default ConvertUrlIntoBase64ScreenShotRequest;

