import * as Sentry from "@sentry/vue";
import EnvService from "./env.settings";

const initializeSentry = (Vue) => {
    const dsn = EnvService.instance.sentryDSN;
    const environment = EnvService.instance.envName;

    if (!dsn) return;

    Sentry.init({
        Vue,
        environment,
        dsn,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/staging-sea\.ashoreapp\.com/, /^https:\/\/sea\.ashoreapp\.com/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

export default initializeSentry;
