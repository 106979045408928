/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ModelError from './ModelError';
import ModelValidationState from './ModelValidationState';

/**
 * The ModelStateEntry model module.
 * @module model/ModelStateEntry
 * @version V1
 */
class ModelStateEntry {
    /**
     * Constructs a new <code>ModelStateEntry</code>.
     * @alias module:model/ModelStateEntry
     */
    constructor() { 
        
        ModelStateEntry.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ModelStateEntry</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ModelStateEntry} obj Optional instance to populate.
     * @return {module:model/ModelStateEntry} The populated <code>ModelStateEntry</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ModelStateEntry();

            if (data.hasOwnProperty('rawValue')) {
                obj['rawValue'] = ApiClient.convertToType(data['rawValue'], Object);
            }
            if (data.hasOwnProperty('attemptedValue')) {
                obj['attemptedValue'] = ApiClient.convertToType(data['attemptedValue'], 'String');
            }
            if (data.hasOwnProperty('errors')) {
                obj['errors'] = ApiClient.convertToType(data['errors'], [ModelError]);
            }
            if (data.hasOwnProperty('validationState')) {
                obj['validationState'] = ModelValidationState.constructFromObject(data['validationState']);
            }
            if (data.hasOwnProperty('isContainerNode')) {
                obj['isContainerNode'] = ApiClient.convertToType(data['isContainerNode'], 'Boolean');
            }
            if (data.hasOwnProperty('children')) {
                obj['children'] = ApiClient.convertToType(data['children'], [ModelStateEntry]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ModelStateEntry</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ModelStateEntry</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['attemptedValue'] && !(typeof data['attemptedValue'] === 'string' || data['attemptedValue'] instanceof String)) {
            throw new Error("Expected the field `attemptedValue` to be a primitive type in the JSON string but got " + data['attemptedValue']);
        }
        if (data['errors']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['errors'])) {
                throw new Error("Expected the field `errors` to be an array in the JSON data but got " + data['errors']);
            }
            // validate the optional field `errors` (array)
            for (const item of data['errors']) {
                ModelError.validateJSON(item);
            };
        }
        if (data['children']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['children'])) {
                throw new Error("Expected the field `children` to be an array in the JSON data but got " + data['children']);
            }
            // validate the optional field `children` (array)
            for (const item of data['children']) {
                ModelStateEntry.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Object} rawValue
 */
ModelStateEntry.prototype['rawValue'] = undefined;

/**
 * @member {String} attemptedValue
 */
ModelStateEntry.prototype['attemptedValue'] = undefined;

/**
 * @member {Array.<module:model/ModelError>} errors
 */
ModelStateEntry.prototype['errors'] = undefined;

/**
 * @member {module:model/ModelValidationState} validationState
 */
ModelStateEntry.prototype['validationState'] = undefined;

/**
 * @member {Boolean} isContainerNode
 */
ModelStateEntry.prototype['isContainerNode'] = undefined;

/**
 * @member {Array.<module:model/ModelStateEntry>} children
 */
ModelStateEntry.prototype['children'] = undefined;






export default ModelStateEntry;

