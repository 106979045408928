/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Assembly from './Assembly';
import CustomAttributeData from './CustomAttributeData';
import ModuleHandle from './ModuleHandle';

/**
 * The Module model module.
 * @module model/Module
 * @version V1
 */
class Module {
    /**
     * Constructs a new <code>Module</code>.
     * @alias module:model/Module
     */
    constructor() { 
        
        Module.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Module</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Module} obj Optional instance to populate.
     * @return {module:model/Module} The populated <code>Module</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Module();

            if (data.hasOwnProperty('assembly')) {
                obj['assembly'] = Assembly.constructFromObject(data['assembly']);
            }
            if (data.hasOwnProperty('fullyQualifiedName')) {
                obj['fullyQualifiedName'] = ApiClient.convertToType(data['fullyQualifiedName'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('mdStreamVersion')) {
                obj['mdStreamVersion'] = ApiClient.convertToType(data['mdStreamVersion'], 'Number');
            }
            if (data.hasOwnProperty('moduleVersionId')) {
                obj['moduleVersionId'] = ApiClient.convertToType(data['moduleVersionId'], 'String');
            }
            if (data.hasOwnProperty('scopeName')) {
                obj['scopeName'] = ApiClient.convertToType(data['scopeName'], 'String');
            }
            if (data.hasOwnProperty('moduleHandle')) {
                obj['moduleHandle'] = ModuleHandle.constructFromObject(data['moduleHandle']);
            }
            if (data.hasOwnProperty('customAttributes')) {
                obj['customAttributes'] = ApiClient.convertToType(data['customAttributes'], [CustomAttributeData]);
            }
            if (data.hasOwnProperty('metadataToken')) {
                obj['metadataToken'] = ApiClient.convertToType(data['metadataToken'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Module</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Module</code>.
     */
    static validateJSON(data) {
        // validate the optional field `assembly`
        if (data['assembly']) { // data not null
          Assembly.validateJSON(data['assembly']);
        }
        // ensure the json data is a string
        if (data['fullyQualifiedName'] && !(typeof data['fullyQualifiedName'] === 'string' || data['fullyQualifiedName'] instanceof String)) {
            throw new Error("Expected the field `fullyQualifiedName` to be a primitive type in the JSON string but got " + data['fullyQualifiedName']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['moduleVersionId'] && !(typeof data['moduleVersionId'] === 'string' || data['moduleVersionId'] instanceof String)) {
            throw new Error("Expected the field `moduleVersionId` to be a primitive type in the JSON string but got " + data['moduleVersionId']);
        }
        // ensure the json data is a string
        if (data['scopeName'] && !(typeof data['scopeName'] === 'string' || data['scopeName'] instanceof String)) {
            throw new Error("Expected the field `scopeName` to be a primitive type in the JSON string but got " + data['scopeName']);
        }
        // validate the optional field `moduleHandle`
        if (data['moduleHandle']) { // data not null
          ModuleHandle.validateJSON(data['moduleHandle']);
        }
        if (data['customAttributes']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['customAttributes'])) {
                throw new Error("Expected the field `customAttributes` to be an array in the JSON data but got " + data['customAttributes']);
            }
            // validate the optional field `customAttributes` (array)
            for (const item of data['customAttributes']) {
                CustomAttributeData.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {module:model/Assembly} assembly
 */
Module.prototype['assembly'] = undefined;

/**
 * @member {String} fullyQualifiedName
 */
Module.prototype['fullyQualifiedName'] = undefined;

/**
 * @member {String} name
 */
Module.prototype['name'] = undefined;

/**
 * @member {Number} mdStreamVersion
 */
Module.prototype['mdStreamVersion'] = undefined;

/**
 * @member {String} moduleVersionId
 */
Module.prototype['moduleVersionId'] = undefined;

/**
 * @member {String} scopeName
 */
Module.prototype['scopeName'] = undefined;

/**
 * @member {module:model/ModuleHandle} moduleHandle
 */
Module.prototype['moduleHandle'] = undefined;

/**
 * @member {Array.<module:model/CustomAttributeData>} customAttributes
 */
Module.prototype['customAttributes'] = undefined;

/**
 * @member {Number} metadataToken
 */
Module.prototype['metadataToken'] = undefined;






export default Module;

