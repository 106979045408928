import ProofChecklistApi from "../generated/api/ProofChecklistApi";
import SaveChecklistRequest from "../generated/model/SaveChecklistRequest";
import SaveProofRequirementRequest from "../generated/model/SaveProofRequirementRequest";

class ProofChecklistService {

    constructor() {
        this.ProofChecklistApi = new ProofChecklistApi();
    }

    async SaveProofChecklist(checklist) {
        const saveChecklistRequest = SaveChecklistRequest.constructFromObject(checklist);

        if (checklist.id != null && checklist.id > 0) {
            return window.$A.HandleApiResponse(
                this.ProofChecklistApi.updateProofChecklist(checklist.id, {saveChecklistRequest}),
                "Proof Checklist Item Has Been Updated"
            );
        } else {
            return window.$A.HandleApiResponse(
                this.ProofChecklistApi.createProofChecklist(
                    {saveChecklistRequest},
                    null
                ),
                "Proof Checklist Item Has Been Created"
            );
        }
    }

    async DeleteProofChecklist(id) {
        return window.$A.HandleApiResponse(
            this.ProofChecklistApi.deleteProofChecklist(id,
                null
            ),
            "Proof Checklist Has Been Deleted"
        );
    }

    async GetProofChecklist(proofChecklistId) {
        return window.$A.HandleApiResponse(
            this.ProofChecklistApi.getProofChecklist(proofChecklistId), "", true
        );
    }

    async DuplicateProofChecklist(checklistId) {
        return await window.$A.HandleApiResponse(
            this.ProofChecklistApi.duplicateChecklist(checklistId),
            "Proof Checklist Has Been Duplicated!"
        );
    }

    async GetAllProofChecklistsItems() {
        return window.$A.HandleApiResponse(
            this.ProofChecklistApi.getAccountProofChecklists({}),
            "",
            true
        );
    }

    async SaveProofChecklistProperty(checklistId, propertyId, checklistProperty) {

        const saveProofRequirementRequest = SaveProofRequirementRequest.constructFromObject(checklistProperty);

        if (propertyId != null && propertyId > 0) {
            return window.$A.HandleApiResponse(
                this.ProofChecklistApi.updateProofChecklistProperty(checklistId, propertyId, {saveProofRequirementRequest}),
                "",
                true
            );
        } else {
            return window.$A.HandleApiResponse(
                this.ProofChecklistApi.createProofChecklistProperty(
                    checklistId,
                    {saveProofRequirementRequest},
                    null
                ),
                "",
                true
            );
        }
    }

    async DeleteProofChecklistProperty(checklistId, propertyId) {
        return window.$A.HandleApiResponse(
            this.ProofChecklistApi.deleteChecklistProperty(checklistId, propertyId,
                null),
            ""
        );
    }
}

export default ProofChecklistService;
