import WriteTeamRequest from "../generated/model/WriteTeamRequest";
import UpdateTeamRequest from "../generated/model/UpdateTeamRequest";
import UserRolesApi from "../generated/api/UserRolesApi";
import SaveUserRoleRequest from "../generated/model/SaveUserRoleRequest";
import ReAssignUsersRequest from "../generated/model/ReAssignUsersRequest";

class RoleService {
    constructor() {
        this.RolesApi = new UserRolesApi();
    }

    async CreateRole(name, manageBilling, manageUsers, manageAccount, manageTemplates, manageWorkflows, proofPermissions) {
        return await window.$A.HandleApiResponse(
            this.RolesApi.createAccountUserRole({
                saveUserRoleRequest: SaveUserRoleRequest.constructFromObject({
                    name,
                    manageBilling,
                    manageUsers,
                    manageAccount,
                    manageTemplates,
                    manageWorkflows,
                    proofPermissions
                }),
            })
        );
    }

    async GetRoles() {
        return await window.$A.HandleApiResponse(
            this.RolesApi.getAccountRoles()
        );
    }

    async DeleteRole(roleId) {
        return await window.$A.HandleApiResponse(
            this.RolesApi.deleteRole(roleId)
        );
    }

    async ReAssignRole(oldRoleId, newRoleId) {
        return await window.$A.HandleApiResponse(
            this.RolesApi.reAssignUsersToAnotherRole({
                reAssignUsersRequest: ReAssignUsersRequest.constructFromObject({
                    oldRoleId,
                    newRoleId
                })
            })
        );
    }

    async UpdateRole(roleId, name, manageBilling, manageUsers, manageAccount, manageTemplates, manageWorkflows, proofPermissions) {
        return await window.$A.HandleApiResponse(
            this.RolesApi.updateAccountUserRole(
                roleId,
                {
                    saveUserRoleRequest: SaveUserRoleRequest.constructFromObject({
                        name,
                        manageBilling,
                        manageUsers,
                        manageAccount,
                        manageTemplates,
                        manageWorkflows,
                        proofPermissions
                    })
                }
            )
        );
    }
}

export default RoleService;