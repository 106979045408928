/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TokenRefreshRequest model module.
 * @module model/TokenRefreshRequest
 * @version V1
 */
class TokenRefreshRequest {
    /**
     * Constructs a new <code>TokenRefreshRequest</code>.
     * @alias module:model/TokenRefreshRequest
     * @param refreshToken {String} 
     * @param token {String} 
     */
    constructor(refreshToken, token) { 
        
        TokenRefreshRequest.initialize(this, refreshToken, token);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, refreshToken, token) { 
        obj['refreshToken'] = refreshToken;
        obj['token'] = token;
    }

    /**
     * Constructs a <code>TokenRefreshRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TokenRefreshRequest} obj Optional instance to populate.
     * @return {module:model/TokenRefreshRequest} The populated <code>TokenRefreshRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TokenRefreshRequest();

            if (data.hasOwnProperty('refreshToken')) {
                obj['refreshToken'] = ApiClient.convertToType(data['refreshToken'], 'String');
            }
            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>TokenRefreshRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>TokenRefreshRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of TokenRefreshRequest.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['refreshToken'] && !(typeof data['refreshToken'] === 'string' || data['refreshToken'] instanceof String)) {
            throw new Error("Expected the field `refreshToken` to be a primitive type in the JSON string but got " + data['refreshToken']);
        }
        // ensure the json data is a string
        if (data['token'] && !(typeof data['token'] === 'string' || data['token'] instanceof String)) {
            throw new Error("Expected the field `token` to be a primitive type in the JSON string but got " + data['token']);
        }

        return true;
    }


}

TokenRefreshRequest.RequiredProperties = ["refreshToken", "token"];

/**
 * @member {String} refreshToken
 */
TokenRefreshRequest.prototype['refreshToken'] = undefined;

/**
 * @member {String} token
 */
TokenRefreshRequest.prototype['token'] = undefined;






export default TokenRefreshRequest;

