/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PaymentMethod model module.
 * @module model/PaymentMethod
 * @version V1
 */
class PaymentMethod {
    /**
     * Constructs a new <code>PaymentMethod</code>.
     * @alias module:model/PaymentMethod
     */
    constructor() { 
        
        PaymentMethod.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PaymentMethod</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PaymentMethod} obj Optional instance to populate.
     * @return {module:model/PaymentMethod} The populated <code>PaymentMethod</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaymentMethod();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('providerUserId')) {
                obj['providerUserId'] = ApiClient.convertToType(data['providerUserId'], 'String');
            }
            if (data.hasOwnProperty('env')) {
                obj['env'] = ApiClient.convertToType(data['env'], 'String');
            }
            if (data.hasOwnProperty('provider')) {
                obj['provider'] = ApiClient.convertToType(data['provider'], 'String');
            }
            if (data.hasOwnProperty('providerId')) {
                obj['providerId'] = ApiClient.convertToType(data['providerId'], 'String');
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('expireDate')) {
                obj['expireDate'] = ApiClient.convertToType(data['expireDate'], 'Date');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('data')) {
                obj['data'] = ApiClient.convertToType(data['data'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PaymentMethod</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PaymentMethod</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['providerUserId'] && !(typeof data['providerUserId'] === 'string' || data['providerUserId'] instanceof String)) {
            throw new Error("Expected the field `providerUserId` to be a primitive type in the JSON string but got " + data['providerUserId']);
        }
        // ensure the json data is a string
        if (data['env'] && !(typeof data['env'] === 'string' || data['env'] instanceof String)) {
            throw new Error("Expected the field `env` to be a primitive type in the JSON string but got " + data['env']);
        }
        // ensure the json data is a string
        if (data['provider'] && !(typeof data['provider'] === 'string' || data['provider'] instanceof String)) {
            throw new Error("Expected the field `provider` to be a primitive type in the JSON string but got " + data['provider']);
        }
        // ensure the json data is a string
        if (data['providerId'] && !(typeof data['providerId'] === 'string' || data['providerId'] instanceof String)) {
            throw new Error("Expected the field `providerId` to be a primitive type in the JSON string but got " + data['providerId']);
        }
        // ensure the json data is a string
        if (data['data'] && !(typeof data['data'] === 'string' || data['data'] instanceof String)) {
            throw new Error("Expected the field `data` to be a primitive type in the JSON string but got " + data['data']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
PaymentMethod.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
PaymentMethod.prototype['accountId'] = undefined;

/**
 * @member {String} providerUserId
 */
PaymentMethod.prototype['providerUserId'] = undefined;

/**
 * @member {String} env
 */
PaymentMethod.prototype['env'] = undefined;

/**
 * @member {String} provider
 */
PaymentMethod.prototype['provider'] = undefined;

/**
 * @member {String} providerId
 */
PaymentMethod.prototype['providerId'] = undefined;

/**
 * @member {Boolean} active
 */
PaymentMethod.prototype['active'] = undefined;

/**
 * @member {Date} expireDate
 */
PaymentMethod.prototype['expireDate'] = undefined;

/**
 * @member {Date} createdAt
 */
PaymentMethod.prototype['createdAt'] = undefined;

/**
 * @member {String} data
 */
PaymentMethod.prototype['data'] = undefined;






export default PaymentMethod;

