import EnvService from "../env.settings";
import { isSupported, setup } from "@loomhq/loom-sdk";

class LoomService {
    constructor() {
        this.supported = false;
        this.isLoomAvailable = false;
        this.configureButton = null;
        this.init();
    }

    async init() {
        try {
            await this.checkLoomSupport();
            if (this.supported) {
                await this.setupLoomWithRetries();
            }
        } catch (error) {
            console.log("Loom initialization error:", error.message);
            this.supported = false;
            this.isLoomAvailable = false;
        }
    }

    async checkLoomSupport() {
        const supportedResponse = await isSupported();
        if (supportedResponse.error){
             console.log(`Loom support check failed: ${supportedResponse.log}`);
             return;            
            }

        this.supported = supportedResponse.supported;
        if (!this.supported){
             console.warn("Loom is not supported in this environment");
             return;
            }
    }

    async setupLoomWithRetries() {
        const maxRetries = 3;
        const retryInterval = 5000;

        for (let attempt = 1; attempt <= maxRetries; attempt++) {
            try {
                await this.attemptLoomSetup();
                return;
            } catch (error) {
                console.warn(`Loom setup attempt ${attempt} failed:`, error.message);
                if (attempt < maxRetries) {
                    console.log(`Retrying in ${retryInterval / 1000} seconds...`);
                    await this.delay(retryInterval);
                } else {
                    console.log("Max retries reached. Loom setup failed.");
                    this.isLoomAvailable = false;
                }
            }
        }
    }

    async attemptLoomSetup() {
        const setupResponse = await setup({ apiKey: EnvService.instance.loomKey });
        if (!this.isSetupSuccessful(setupResponse)) {
            throw new Error("Loom setup was not successful");
        } else {
            this.configureButton = setupResponse.configureButton;
            this.isLoomAvailable = true;
        }
    }

    isSetupSuccessful(setupResponse) {
        const status = _.get(setupResponse, 'status');
        return status ? status().success : false;
    }

    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    configureSdkButton() {
        return this.configureButton({});
    }
}

export default LoomService;