/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AttributeRouteInfo from './AttributeRouteInfo';
import FilterDescriptor from './FilterDescriptor';
import ParameterDescriptor from './ParameterDescriptor';

/**
 * The ActionDescriptor model module.
 * @module model/ActionDescriptor
 * @version V1
 */
class ActionDescriptor {
    /**
     * Constructs a new <code>ActionDescriptor</code>.
     * @alias module:model/ActionDescriptor
     */
    constructor() { 
        
        ActionDescriptor.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ActionDescriptor</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ActionDescriptor} obj Optional instance to populate.
     * @return {module:model/ActionDescriptor} The populated <code>ActionDescriptor</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ActionDescriptor();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('routeValues')) {
                obj['routeValues'] = ApiClient.convertToType(data['routeValues'], {'String': 'String'});
            }
            if (data.hasOwnProperty('attributeRouteInfo')) {
                obj['attributeRouteInfo'] = AttributeRouteInfo.constructFromObject(data['attributeRouteInfo']);
            }
            if (data.hasOwnProperty('actionConstraints')) {
                obj['actionConstraints'] = ApiClient.convertToType(data['actionConstraints'], [Object]);
            }
            if (data.hasOwnProperty('endpointMetadata')) {
                obj['endpointMetadata'] = ApiClient.convertToType(data['endpointMetadata'], [Object]);
            }
            if (data.hasOwnProperty('parameters')) {
                obj['parameters'] = ApiClient.convertToType(data['parameters'], [ParameterDescriptor]);
            }
            if (data.hasOwnProperty('boundProperties')) {
                obj['boundProperties'] = ApiClient.convertToType(data['boundProperties'], [ParameterDescriptor]);
            }
            if (data.hasOwnProperty('filterDescriptors')) {
                obj['filterDescriptors'] = ApiClient.convertToType(data['filterDescriptors'], [FilterDescriptor]);
            }
            if (data.hasOwnProperty('displayName')) {
                obj['displayName'] = ApiClient.convertToType(data['displayName'], 'String');
            }
            if (data.hasOwnProperty('properties')) {
                obj['properties'] = ApiClient.convertToType(data['properties'], {'String': Object});
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ActionDescriptor</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ActionDescriptor</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // validate the optional field `attributeRouteInfo`
        if (data['attributeRouteInfo']) { // data not null
          AttributeRouteInfo.validateJSON(data['attributeRouteInfo']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['actionConstraints'])) {
            throw new Error("Expected the field `actionConstraints` to be an array in the JSON data but got " + data['actionConstraints']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['endpointMetadata'])) {
            throw new Error("Expected the field `endpointMetadata` to be an array in the JSON data but got " + data['endpointMetadata']);
        }
        if (data['parameters']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['parameters'])) {
                throw new Error("Expected the field `parameters` to be an array in the JSON data but got " + data['parameters']);
            }
            // validate the optional field `parameters` (array)
            for (const item of data['parameters']) {
                ParameterDescriptor.validateJSON(item);
            };
        }
        if (data['boundProperties']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['boundProperties'])) {
                throw new Error("Expected the field `boundProperties` to be an array in the JSON data but got " + data['boundProperties']);
            }
            // validate the optional field `boundProperties` (array)
            for (const item of data['boundProperties']) {
                ParameterDescriptor.validateJSON(item);
            };
        }
        if (data['filterDescriptors']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['filterDescriptors'])) {
                throw new Error("Expected the field `filterDescriptors` to be an array in the JSON data but got " + data['filterDescriptors']);
            }
            // validate the optional field `filterDescriptors` (array)
            for (const item of data['filterDescriptors']) {
                FilterDescriptor.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['displayName'] && !(typeof data['displayName'] === 'string' || data['displayName'] instanceof String)) {
            throw new Error("Expected the field `displayName` to be a primitive type in the JSON string but got " + data['displayName']);
        }

        return true;
    }


}



/**
 * @member {String} id
 */
ActionDescriptor.prototype['id'] = undefined;

/**
 * @member {Object.<String, String>} routeValues
 */
ActionDescriptor.prototype['routeValues'] = undefined;

/**
 * @member {module:model/AttributeRouteInfo} attributeRouteInfo
 */
ActionDescriptor.prototype['attributeRouteInfo'] = undefined;

/**
 * @member {Array.<Object>} actionConstraints
 */
ActionDescriptor.prototype['actionConstraints'] = undefined;

/**
 * @member {Array.<Object>} endpointMetadata
 */
ActionDescriptor.prototype['endpointMetadata'] = undefined;

/**
 * @member {Array.<module:model/ParameterDescriptor>} parameters
 */
ActionDescriptor.prototype['parameters'] = undefined;

/**
 * @member {Array.<module:model/ParameterDescriptor>} boundProperties
 */
ActionDescriptor.prototype['boundProperties'] = undefined;

/**
 * @member {Array.<module:model/FilterDescriptor>} filterDescriptors
 */
ActionDescriptor.prototype['filterDescriptors'] = undefined;

/**
 * @member {String} displayName
 */
ActionDescriptor.prototype['displayName'] = undefined;

/**
 * @member {Object.<String, Object>} properties
 */
ActionDescriptor.prototype['properties'] = undefined;






export default ActionDescriptor;

