/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AppEventType from './AppEventType';
import WebHook from './WebHook';
import WebHookDeliveryAttempt from './WebHookDeliveryAttempt';
import WebHookDeliveryStatusType from './WebHookDeliveryStatusType';
import WebHookEventGroupType from './WebHookEventGroupType';

/**
 * The WebHookEvent model module.
 * @module model/WebHookEvent
 * @version V1
 */
class WebHookEvent {
    /**
     * Constructs a new <code>WebHookEvent</code>.
     * @alias module:model/WebHookEvent
     */
    constructor() { 
        
        WebHookEvent.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>WebHookEvent</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/WebHookEvent} obj Optional instance to populate.
     * @return {module:model/WebHookEvent} The populated <code>WebHookEvent</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new WebHookEvent();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('resellerId')) {
                obj['resellerId'] = ApiClient.convertToType(data['resellerId'], 'Number');
            }
            if (data.hasOwnProperty('webHookId')) {
                obj['webHookId'] = ApiClient.convertToType(data['webHookId'], 'Number');
            }
            if (data.hasOwnProperty('lastDeliveryAttemptId')) {
                obj['lastDeliveryAttemptId'] = ApiClient.convertToType(data['lastDeliveryAttemptId'], 'Number');
            }
            if (data.hasOwnProperty('eventType')) {
                obj['eventType'] = AppEventType.constructFromObject(data['eventType']);
            }
            if (data.hasOwnProperty('eventGroupType')) {
                obj['eventGroupType'] = WebHookEventGroupType.constructFromObject(data['eventGroupType']);
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'Number');
            }
            if (data.hasOwnProperty('eventJson')) {
                obj['eventJson'] = ApiClient.convertToType(data['eventJson'], 'String');
            }
            if (data.hasOwnProperty('statusType')) {
                obj['statusType'] = WebHookDeliveryStatusType.constructFromObject(data['statusType']);
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('sentAt')) {
                obj['sentAt'] = ApiClient.convertToType(data['sentAt'], 'Date');
            }
            if (data.hasOwnProperty('processLastStartedAt')) {
                obj['processLastStartedAt'] = ApiClient.convertToType(data['processLastStartedAt'], 'Date');
            }
            if (data.hasOwnProperty('debugData')) {
                obj['debugData'] = ApiClient.convertToType(data['debugData'], 'String');
            }
            if (data.hasOwnProperty('deliveryAttempts')) {
                obj['deliveryAttempts'] = ApiClient.convertToType(data['deliveryAttempts'], [WebHookDeliveryAttempt]);
            }
            if (data.hasOwnProperty('webHook')) {
                obj['webHook'] = WebHook.constructFromObject(data['webHook']);
            }
            if (data.hasOwnProperty('lastAttempt')) {
                obj['lastAttempt'] = WebHookDeliveryAttempt.constructFromObject(data['lastAttempt']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>WebHookEvent</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>WebHookEvent</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['eventJson'] && !(typeof data['eventJson'] === 'string' || data['eventJson'] instanceof String)) {
            throw new Error("Expected the field `eventJson` to be a primitive type in the JSON string but got " + data['eventJson']);
        }
        // ensure the json data is a string
        if (data['debugData'] && !(typeof data['debugData'] === 'string' || data['debugData'] instanceof String)) {
            throw new Error("Expected the field `debugData` to be a primitive type in the JSON string but got " + data['debugData']);
        }
        if (data['deliveryAttempts']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['deliveryAttempts'])) {
                throw new Error("Expected the field `deliveryAttempts` to be an array in the JSON data but got " + data['deliveryAttempts']);
            }
            // validate the optional field `deliveryAttempts` (array)
            for (const item of data['deliveryAttempts']) {
                WebHookDeliveryAttempt.validateJSON(item);
            };
        }
        // validate the optional field `webHook`
        if (data['webHook']) { // data not null
          WebHook.validateJSON(data['webHook']);
        }
        // validate the optional field `lastAttempt`
        if (data['lastAttempt']) { // data not null
          WebHookDeliveryAttempt.validateJSON(data['lastAttempt']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
WebHookEvent.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
WebHookEvent.prototype['accountId'] = undefined;

/**
 * @member {Number} resellerId
 */
WebHookEvent.prototype['resellerId'] = undefined;

/**
 * @member {Number} webHookId
 */
WebHookEvent.prototype['webHookId'] = undefined;

/**
 * @member {Number} lastDeliveryAttemptId
 */
WebHookEvent.prototype['lastDeliveryAttemptId'] = undefined;

/**
 * @member {module:model/AppEventType} eventType
 */
WebHookEvent.prototype['eventType'] = undefined;

/**
 * @member {module:model/WebHookEventGroupType} eventGroupType
 */
WebHookEvent.prototype['eventGroupType'] = undefined;

/**
 * @member {Number} version
 */
WebHookEvent.prototype['version'] = undefined;

/**
 * @member {String} eventJson
 */
WebHookEvent.prototype['eventJson'] = undefined;

/**
 * @member {module:model/WebHookDeliveryStatusType} statusType
 */
WebHookEvent.prototype['statusType'] = undefined;

/**
 * @member {Date} createdAt
 */
WebHookEvent.prototype['createdAt'] = undefined;

/**
 * @member {Date} sentAt
 */
WebHookEvent.prototype['sentAt'] = undefined;

/**
 * @member {Date} processLastStartedAt
 */
WebHookEvent.prototype['processLastStartedAt'] = undefined;

/**
 * @member {String} debugData
 */
WebHookEvent.prototype['debugData'] = undefined;

/**
 * @member {Array.<module:model/WebHookDeliveryAttempt>} deliveryAttempts
 */
WebHookEvent.prototype['deliveryAttempts'] = undefined;

/**
 * @member {module:model/WebHook} webHook
 */
WebHookEvent.prototype['webHook'] = undefined;

/**
 * @member {module:model/WebHookDeliveryAttempt} lastAttempt
 */
WebHookEvent.prototype['lastAttempt'] = undefined;






export default WebHookEvent;

