import ApproverApi from "../generated/api/ApproverApi";
import SaveApproverRequest from "../generated/model/SaveApproverRequest";

class ApproverService {
  constructor() {
    this.ApproverApi = new ApproverApi();
  }

  async All(nameFilter, emailFilter) {
    if (nameFilter === undefined) nameFilter = "";
    if (emailFilter === undefined) emailFilter = "";
    return await window.$A.HandleApiResponse(
      this.ApproverApi.getAll(nameFilter, emailFilter)
    );
  }
  async AllIndexed() {
    return await window.$A.HandleApiResponse(
      this.ApproverApi.getAllIndexed()
    );
  }

  async GetWithStats(approverId) {
    return await window.$A.HandleApiResponse(
      this.ApproverApi.getApproverWithStats(approverId)
    );
  }

  async Save(approver) {
    if (approver.name.length < 3 || !window.$A.IsEmailValid(approver.email)) {
      window.$A.AlertUser("Please Fill Out The Approver Form Before Saving");
      return;
    }

    let obj = SaveApproverRequest.constructFromObject({
      name: approver.name,
      email: approver.email,
      frequency: approver.frequency,
      timeOfDayToSend: approver.timeOfDayToSend,
      timeZone: approver.timeZone,
      utcOffsetMin: approver.utcOffsetMin,
    });

    if (approver.id !== undefined && approver.id > 0) {
      return await window.$A.HandleApiResponse(
        this.ApproverApi.updateApprover(approver.id, {
          saveApproverRequest: obj,
        }),
        "Contact Updated"
      );
    } else {
      return await window.$A.HandleApiResponse(
        this.ApproverApi.createApprover({
          saveApproverRequest: obj,
        }),
        "Contact Updated"
      );
    }
  }

  async DeleteSingle(id) {
    return await window.$A.HandleApiResponse(
      this.ApproverApi.deleteApprover(id),
      "Contact Deleted"
    );
  }

  async Delete(ids) {
    window.$A.ApiWorking();
    let allPromises = [];
    for (const approverIdToDelete of ids) {
      allPromises.push(this.ApproverApi.deleteApprover(approverIdToDelete));
    }
    return await Promise.all(allPromises);
  }
}

export default ApproverService;
