/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AccountThemeSettings from './AccountThemeSettings';
import ProofRequirement from './ProofRequirement';

/**
 * The PublicAccountInfo model module.
 * @module model/PublicAccountInfo
 * @version V1
 */
class PublicAccountInfo {
    /**
     * Constructs a new <code>PublicAccountInfo</code>.
     * @alias module:model/PublicAccountInfo
     */
    constructor() { 
        
        PublicAccountInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PublicAccountInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PublicAccountInfo} obj Optional instance to populate.
     * @return {module:model/PublicAccountInfo} The populated <code>PublicAccountInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PublicAccountInfo();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('css')) {
                obj['css'] = ApiClient.convertToType(data['css'], 'String');
            }
            if (data.hasOwnProperty('whiteLabelDomainName')) {
                obj['whiteLabelDomainName'] = ApiClient.convertToType(data['whiteLabelDomainName'], 'String');
            }
            if (data.hasOwnProperty('whiteLabelLogoUri')) {
                obj['whiteLabelLogoUri'] = ApiClient.convertToType(data['whiteLabelLogoUri'], 'String');
            }
            if (data.hasOwnProperty('noReplyEmail')) {
                obj['noReplyEmail'] = ApiClient.convertToType(data['noReplyEmail'], 'String');
            }
            if (data.hasOwnProperty('timeZone')) {
                obj['timeZone'] = ApiClient.convertToType(data['timeZone'], 'String');
            }
            if (data.hasOwnProperty('countryCode')) {
                obj['countryCode'] = ApiClient.convertToType(data['countryCode'], 'String');
            }
            if (data.hasOwnProperty('timeZoneId')) {
                obj['timeZoneId'] = ApiClient.convertToType(data['timeZoneId'], 'String');
            }
            if (data.hasOwnProperty('dateFormat')) {
                obj['dateFormat'] = ApiClient.convertToType(data['dateFormat'], 'String');
            }
            if (data.hasOwnProperty('isFreeSku')) {
                obj['isFreeSku'] = ApiClient.convertToType(data['isFreeSku'], 'Boolean');
            }
            if (data.hasOwnProperty('whiteLabelEnabled')) {
                obj['whiteLabelEnabled'] = ApiClient.convertToType(data['whiteLabelEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('domainWhiteLabelEnabled')) {
                obj['domainWhiteLabelEnabled'] = ApiClient.convertToType(data['domainWhiteLabelEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('proofRequirements')) {
                obj['proofRequirements'] = ApiClient.convertToType(data['proofRequirements'], [ProofRequirement]);
            }
            if (data.hasOwnProperty('themeSettings')) {
                obj['themeSettings'] = AccountThemeSettings.constructFromObject(data['themeSettings']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PublicAccountInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PublicAccountInfo</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['css'] && !(typeof data['css'] === 'string' || data['css'] instanceof String)) {
            throw new Error("Expected the field `css` to be a primitive type in the JSON string but got " + data['css']);
        }
        // ensure the json data is a string
        if (data['whiteLabelDomainName'] && !(typeof data['whiteLabelDomainName'] === 'string' || data['whiteLabelDomainName'] instanceof String)) {
            throw new Error("Expected the field `whiteLabelDomainName` to be a primitive type in the JSON string but got " + data['whiteLabelDomainName']);
        }
        // ensure the json data is a string
        if (data['whiteLabelLogoUri'] && !(typeof data['whiteLabelLogoUri'] === 'string' || data['whiteLabelLogoUri'] instanceof String)) {
            throw new Error("Expected the field `whiteLabelLogoUri` to be a primitive type in the JSON string but got " + data['whiteLabelLogoUri']);
        }
        // ensure the json data is a string
        if (data['noReplyEmail'] && !(typeof data['noReplyEmail'] === 'string' || data['noReplyEmail'] instanceof String)) {
            throw new Error("Expected the field `noReplyEmail` to be a primitive type in the JSON string but got " + data['noReplyEmail']);
        }
        // ensure the json data is a string
        if (data['timeZone'] && !(typeof data['timeZone'] === 'string' || data['timeZone'] instanceof String)) {
            throw new Error("Expected the field `timeZone` to be a primitive type in the JSON string but got " + data['timeZone']);
        }
        // ensure the json data is a string
        if (data['countryCode'] && !(typeof data['countryCode'] === 'string' || data['countryCode'] instanceof String)) {
            throw new Error("Expected the field `countryCode` to be a primitive type in the JSON string but got " + data['countryCode']);
        }
        // ensure the json data is a string
        if (data['timeZoneId'] && !(typeof data['timeZoneId'] === 'string' || data['timeZoneId'] instanceof String)) {
            throw new Error("Expected the field `timeZoneId` to be a primitive type in the JSON string but got " + data['timeZoneId']);
        }
        // ensure the json data is a string
        if (data['dateFormat'] && !(typeof data['dateFormat'] === 'string' || data['dateFormat'] instanceof String)) {
            throw new Error("Expected the field `dateFormat` to be a primitive type in the JSON string but got " + data['dateFormat']);
        }
        if (data['proofRequirements']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['proofRequirements'])) {
                throw new Error("Expected the field `proofRequirements` to be an array in the JSON data but got " + data['proofRequirements']);
            }
            // validate the optional field `proofRequirements` (array)
            for (const item of data['proofRequirements']) {
                ProofRequirement.validateJSON(item);
            };
        }
        // validate the optional field `themeSettings`
        if (data['themeSettings']) { // data not null
          AccountThemeSettings.validateJSON(data['themeSettings']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
PublicAccountInfo.prototype['id'] = undefined;

/**
 * @member {String} name
 */
PublicAccountInfo.prototype['name'] = undefined;

/**
 * @member {String} css
 */
PublicAccountInfo.prototype['css'] = undefined;

/**
 * @member {String} whiteLabelDomainName
 */
PublicAccountInfo.prototype['whiteLabelDomainName'] = undefined;

/**
 * @member {String} whiteLabelLogoUri
 */
PublicAccountInfo.prototype['whiteLabelLogoUri'] = undefined;

/**
 * @member {String} noReplyEmail
 */
PublicAccountInfo.prototype['noReplyEmail'] = undefined;

/**
 * @member {String} timeZone
 */
PublicAccountInfo.prototype['timeZone'] = undefined;

/**
 * @member {String} countryCode
 */
PublicAccountInfo.prototype['countryCode'] = undefined;

/**
 * @member {String} timeZoneId
 */
PublicAccountInfo.prototype['timeZoneId'] = undefined;

/**
 * @member {String} dateFormat
 */
PublicAccountInfo.prototype['dateFormat'] = undefined;

/**
 * @member {Boolean} isFreeSku
 */
PublicAccountInfo.prototype['isFreeSku'] = undefined;

/**
 * @member {Boolean} whiteLabelEnabled
 */
PublicAccountInfo.prototype['whiteLabelEnabled'] = undefined;

/**
 * @member {Boolean} domainWhiteLabelEnabled
 */
PublicAccountInfo.prototype['domainWhiteLabelEnabled'] = undefined;

/**
 * @member {Array.<module:model/ProofRequirement>} proofRequirements
 */
PublicAccountInfo.prototype['proofRequirements'] = undefined;

/**
 * @member {module:model/AccountThemeSettings} themeSettings
 */
PublicAccountInfo.prototype['themeSettings'] = undefined;






export default PublicAccountInfo;

