/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Claim from './Claim';

/**
 * The ClaimsIdentity model module.
 * @module model/ClaimsIdentity
 * @version V1
 */
class ClaimsIdentity {
    /**
     * Constructs a new <code>ClaimsIdentity</code>.
     * @alias module:model/ClaimsIdentity
     */
    constructor() { 
        
        ClaimsIdentity.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ClaimsIdentity</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ClaimsIdentity} obj Optional instance to populate.
     * @return {module:model/ClaimsIdentity} The populated <code>ClaimsIdentity</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClaimsIdentity();

            if (data.hasOwnProperty('authenticationType')) {
                obj['authenticationType'] = ApiClient.convertToType(data['authenticationType'], 'String');
            }
            if (data.hasOwnProperty('isAuthenticated')) {
                obj['isAuthenticated'] = ApiClient.convertToType(data['isAuthenticated'], 'Boolean');
            }
            if (data.hasOwnProperty('actor')) {
                obj['actor'] = ClaimsIdentity.constructFromObject(data['actor']);
            }
            if (data.hasOwnProperty('bootstrapContext')) {
                obj['bootstrapContext'] = ApiClient.convertToType(data['bootstrapContext'], Object);
            }
            if (data.hasOwnProperty('claims')) {
                obj['claims'] = ApiClient.convertToType(data['claims'], [Claim]);
            }
            if (data.hasOwnProperty('label')) {
                obj['label'] = ApiClient.convertToType(data['label'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('nameClaimType')) {
                obj['nameClaimType'] = ApiClient.convertToType(data['nameClaimType'], 'String');
            }
            if (data.hasOwnProperty('roleClaimType')) {
                obj['roleClaimType'] = ApiClient.convertToType(data['roleClaimType'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ClaimsIdentity</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ClaimsIdentity</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['authenticationType'] && !(typeof data['authenticationType'] === 'string' || data['authenticationType'] instanceof String)) {
            throw new Error("Expected the field `authenticationType` to be a primitive type in the JSON string but got " + data['authenticationType']);
        }
        // validate the optional field `actor`
        if (data['actor']) { // data not null
          ClaimsIdentity.validateJSON(data['actor']);
        }
        if (data['claims']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['claims'])) {
                throw new Error("Expected the field `claims` to be an array in the JSON data but got " + data['claims']);
            }
            // validate the optional field `claims` (array)
            for (const item of data['claims']) {
                Claim.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['label'] && !(typeof data['label'] === 'string' || data['label'] instanceof String)) {
            throw new Error("Expected the field `label` to be a primitive type in the JSON string but got " + data['label']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['nameClaimType'] && !(typeof data['nameClaimType'] === 'string' || data['nameClaimType'] instanceof String)) {
            throw new Error("Expected the field `nameClaimType` to be a primitive type in the JSON string but got " + data['nameClaimType']);
        }
        // ensure the json data is a string
        if (data['roleClaimType'] && !(typeof data['roleClaimType'] === 'string' || data['roleClaimType'] instanceof String)) {
            throw new Error("Expected the field `roleClaimType` to be a primitive type in the JSON string but got " + data['roleClaimType']);
        }

        return true;
    }


}



/**
 * @member {String} authenticationType
 */
ClaimsIdentity.prototype['authenticationType'] = undefined;

/**
 * @member {Boolean} isAuthenticated
 */
ClaimsIdentity.prototype['isAuthenticated'] = undefined;

/**
 * @member {module:model/ClaimsIdentity} actor
 */
ClaimsIdentity.prototype['actor'] = undefined;

/**
 * @member {Object} bootstrapContext
 */
ClaimsIdentity.prototype['bootstrapContext'] = undefined;

/**
 * @member {Array.<module:model/Claim>} claims
 */
ClaimsIdentity.prototype['claims'] = undefined;

/**
 * @member {String} label
 */
ClaimsIdentity.prototype['label'] = undefined;

/**
 * @member {String} name
 */
ClaimsIdentity.prototype['name'] = undefined;

/**
 * @member {String} nameClaimType
 */
ClaimsIdentity.prototype['nameClaimType'] = undefined;

/**
 * @member {String} roleClaimType
 */
ClaimsIdentity.prototype['roleClaimType'] = undefined;






export default ClaimsIdentity;

