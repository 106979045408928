import moment from "moment-timezone";

class TimeZoneService {
  constructor() {}

  GetTimeZoneByText(timeZone) {
    try {
      let tz = moment.tz.zone(timeZone);
      if (tz === null) {
        return this.GetTimeZoneFromCurrentBrowserZone();
      }
      return tz;
    } catch (e) {
      return this.GetTimeZoneFromCurrentBrowserZone();
    }
  }

  GetAllTimeZoneNames() {
    return moment.tz.names();
  }

  GetTimeZoneFromCurrentBrowserZone() {
    return moment.tz.zone(moment.tz.guess());
  }
}

export default TimeZoneService;
