<template>
  <router-link :to="href" v-bind:class="{ active: isActive }">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
  },
  computed: {
    isActive() {
      return this.href === this.$root.currentRoute;
    },
  },
};
</script>

<style scoped>
.active {
  font-weight: 700;
}
</style>
