/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofFileApprovalItem from './ProofFileApprovalItem';
import WorkflowStageApproverStatus from './WorkflowStageApproverStatus';

/**
 * The ApproverAuditRecord model module.
 * @module model/ApproverAuditRecord
 * @version V1
 */
class ApproverAuditRecord {
    /**
     * Constructs a new <code>ApproverAuditRecord</code>.
     * @alias module:model/ApproverAuditRecord
     */
    constructor() { 
        
        ApproverAuditRecord.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ApproverAuditRecord</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ApproverAuditRecord} obj Optional instance to populate.
     * @return {module:model/ApproverAuditRecord} The populated <code>ApproverAuditRecord</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ApproverAuditRecord();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('workflowStageId')) {
                obj['workflowStageId'] = ApiClient.convertToType(data['workflowStageId'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('ipAddress')) {
                obj['ipAddress'] = ApiClient.convertToType(data['ipAddress'], 'String');
            }
            if (data.hasOwnProperty('feedback')) {
                obj['feedback'] = ApiClient.convertToType(data['feedback'], 'String');
            }
            if (data.hasOwnProperty('filesApproved')) {
                obj['filesApproved'] = ApiClient.convertToType(data['filesApproved'], [ProofFileApprovalItem]);
            }
            if (data.hasOwnProperty('proofActionTaken')) {
                obj['proofActionTaken'] = WorkflowStageApproverStatus.constructFromObject(data['proofActionTaken']);
            }
            if (data.hasOwnProperty('actionTakenAt')) {
                obj['actionTakenAt'] = ApiClient.convertToType(data['actionTakenAt'], 'Date');
            }
            if (data.hasOwnProperty('userIdOverWrote')) {
                obj['userIdOverWrote'] = ApiClient.convertToType(data['userIdOverWrote'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ApproverAuditRecord</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ApproverAuditRecord</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['ipAddress'] && !(typeof data['ipAddress'] === 'string' || data['ipAddress'] instanceof String)) {
            throw new Error("Expected the field `ipAddress` to be a primitive type in the JSON string but got " + data['ipAddress']);
        }
        // ensure the json data is a string
        if (data['feedback'] && !(typeof data['feedback'] === 'string' || data['feedback'] instanceof String)) {
            throw new Error("Expected the field `feedback` to be a primitive type in the JSON string but got " + data['feedback']);
        }
        if (data['filesApproved']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['filesApproved'])) {
                throw new Error("Expected the field `filesApproved` to be an array in the JSON data but got " + data['filesApproved']);
            }
            // validate the optional field `filesApproved` (array)
            for (const item of data['filesApproved']) {
                ProofFileApprovalItem.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
ApproverAuditRecord.prototype['id'] = undefined;

/**
 * @member {Number} approverId
 */
ApproverAuditRecord.prototype['approverId'] = undefined;

/**
 * @member {Number} proofId
 */
ApproverAuditRecord.prototype['proofId'] = undefined;

/**
 * @member {Number} proofVersionId
 */
ApproverAuditRecord.prototype['proofVersionId'] = undefined;

/**
 * @member {Number} workflowStageId
 */
ApproverAuditRecord.prototype['workflowStageId'] = undefined;

/**
 * @member {Number} accountId
 */
ApproverAuditRecord.prototype['accountId'] = undefined;

/**
 * @member {String} ipAddress
 */
ApproverAuditRecord.prototype['ipAddress'] = undefined;

/**
 * @member {String} feedback
 */
ApproverAuditRecord.prototype['feedback'] = undefined;

/**
 * @member {Array.<module:model/ProofFileApprovalItem>} filesApproved
 */
ApproverAuditRecord.prototype['filesApproved'] = undefined;

/**
 * @member {module:model/WorkflowStageApproverStatus} proofActionTaken
 */
ApproverAuditRecord.prototype['proofActionTaken'] = undefined;

/**
 * @member {Date} actionTakenAt
 */
ApproverAuditRecord.prototype['actionTakenAt'] = undefined;

/**
 * @member {Number} userIdOverWrote
 */
ApproverAuditRecord.prototype['userIdOverWrote'] = undefined;






export default ApproverAuditRecord;

