import ProofRequirementApi from "../generated/api/ProofRequirementApi";
import SaveProofRequirementRequest from "../generated/model/SaveProofRequirementRequest";



class ProofRequirementService {
    constructor() {
        this.ProofRequirementApi = new ProofRequirementApi();
    }

    async Save(proofRequirement) {
        const saveRequest = SaveProofRequirementRequest.constructFromObject(
            proofRequirement
        );

        if (proofRequirement.id !== undefined && proofRequirement.id > 0) {
            return window.$A.HandleApiResponse(
                this.ProofRequirementApi.updateProofRequirement(
                    proofRequirement.id,
                    {saveProofRequirementRequest: saveRequest},
                    null
                ),
                "Proof Requirement Has Been Saved"
            );
        } else {
            return window.$A.HandleApiResponse(
                this.ProofRequirementApi.createProofRequirement(
                    {saveProofRequirementRequest: saveRequest},
                    null
                ),
                "Proof Requirement Has Been Saved"
            );
        }
    }

    async Delete(proofRequirementId) {
        return window.$A.HandleApiResponse(
            this.ProofRequirementApi.deleteProofRequirement(
                proofRequirementId,
                null
            ),
            "Proof Requirement Has Been Deleted"
        );
    }

    async GetAll(workflowId) {
        if (workflowId === undefined) {
            workflowId = 0;
        }
        return window.$A.HandleApiResponse(
            this.ProofRequirementApi.getAllProofRequirements({
                workflowId: workflowId,
            }),
            "",
            true
        );
    }

}

export default ProofRequirementService;
