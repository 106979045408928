import EnvService from "../env.settings.js";
import ApiClient from "../generated/ApiClient";
import  "../generated/index.js";
import Cookies from "js-cookie";
import WorkflowService from "../services/WorkflowService";
import ProofService from "../services/ProofService";
import ApproverService from "../services/ApproverService";
import UserService from "../services/UserService";
import TemplateService from "../services/TemplateService";
import AccountService from "../services/AccountService";
import SuperAdminService from "../services/SuperAdminService";
import TagsService from "../services/TagsService";
import EmailService from "../services/EmailService";
import ReviewService from "../services/ReviewService";
import BillingService from "../services/BillingService";
import ProductCodeService from "../services/ProductCodeService";
import ProofFileService from "../services/ProofFileService";
import ProofRequirementService from "../services/ProofRequirementService";
import ProofChecklistService from "./ProofChecklistService";
import TimeZoneService from "../services/TimeZoneService";
import AnnotationService from "../services/AnnotationService";
import ZapierService from "./ZapierService";
import LoomService from "./LoomService";
import LiveProofService from "./LiveProofService";
import FeedbackService from "./FeedbackService";
import LangService from "./LangService";
import RealtimeService from "./RealtimeService";
import RealtimeCursorService from "./RealtimeCursorService.js";

import moment from "moment";
import TeamService from "./TeamService";
import RoleService from "./RoleService";
import MarketingService from "../services/MarketingService";
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {warning} from "@riophae/vue-treeselect/src/utils";

Vue.use(BootstrapVue);

const axios = require("axios");

const STRING = 'string';

class AshoreApp {
    constructor() {
        // console.log('Init App')

        var self = this;
        this.CacheBreakLoop();
        this.InactivityLoop();
        this.uuidCounter = 1;
        // public data
        try {
            this.viewToViewData =
                sessionStorage.viewToViewData !== undefined
                    ? JSON.parse(sessionStorage.viewToViewData)
                    : null; // can be any object that you want to pass to another view
        } catch (ex) {
            this.viewToViewData = null;
        }
        this.Auth = null;
        this.Env = EnvService.instance;
        if (window.location.protocol !== "https:" && !this.Env.isLocalDev) {
            location.href = location.href.replace("http://", "https://");
        }
        this.SessionKey = "ashore.app.session" + this.Env.envName;
        this.Api = axios.create({
            baseURL: this.Env.apiHost + "/",
            timeout: 1000,
            headers: {},
        });

        ApiClient.instance.baseURL = this.Env.apiHost.replace(/\/+$/, '');

        this.AdminImpersonating = this.LoadDataCache("admin_impersonate");

        this.logOutChannel = new BroadcastChannel('logoutChannel');

        this.logOutChannel.onmessage = (event) => {
            if (event.data === 'logout') {
                // Execute logout action, e.g., redirecting to the login screen.
                self.LogOut(true, true);
            }
        };
        this.logInChannel = new BroadcastChannel('logInChannel');

        this.logInChannel.onmessage = (event) => {
            if (event.data === 'login') {
                // Execute login action, e.g., redirecting to the login screen.
                // window.$A.$router.push("/overview");
                window.location = "/overview"
            }
        };

        // services
        this.VueApp = null;
        this.BillingService = new BillingService();
        this.ProductCodeService = new ProductCodeService();
        this.ReviewService = new ReviewService();
        this.ApproverService = new ApproverService();
        this.UserService = new UserService();
        this.ProofService = new ProofService();
        this.ProofFileService = new ProofFileService(this.Api);
        this.TemplateService = new TemplateService();
        this.AccountService = new AccountService();
        this.WorkflowService = new WorkflowService();
        this.TagsService = new TagsService();
        this.EmailService = new EmailService();
        this.ProofRequirementService = new ProofRequirementService();
        this.ProofChecklistService = new ProofChecklistService();
        this.AnnotationService = new AnnotationService();
        this.SuperAdminService = new SuperAdminService();
        this.ZapierService = new ZapierService();
        this.TimeZoneService = new TimeZoneService();
        this.LoomService = new LoomService();
        this.LiveProofService = new LiveProofService();
        this.FeedbackService = new FeedbackService();
        this.LangService = new LangService(this);
        this.TeamService = new TeamService();
        this.RoleService = new RoleService();
        this.MarketingService = new MarketingService();
        this.RealtimeService = new RealtimeService();
        this.RealtimeCursorService = new RealtimeCursorService();

        // init methods
        this.LoadSessionData();

        console.log(
            "Running v" + this.Env.build + " Ashore in " + this.Env.envName + " mode"
        );

        String.prototype.capitalize = function () {
            return this.replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
            });
        };
        Array.prototype.select = function (closure) {
            for (let n = 0; n < this.length; n++) {
                if (closure(this[n])) {
                    return this[n];
                }
            }
            return null;
        };
    }

    static CdnUrl(key) {
        return key.indexOf("http") > -1
            ? key
            : "'https://cdn.ashoreapp.com/'" + key;
    }

    CreateApiInstance(headers, timeout) {
        return axios.create({
            baseURL: this.Env.apiHost + "/",
            timeout: timeout,
            headers: headers,
        });
    }

    GetAppUrl() {
        if (this.Auth.account.whiteLabelDomainName !== null) {
            return "https://" + this.Auth.account.whiteLabelDomainName;
        } else {
            return "https://" + window.location.hostname;
        }
    }

    GoBackToAdminAccount() {
        if (this.AdminImpersonating !== null) {
            this.SetLoggedInUser(this.AdminImpersonating, true, true);
        }
    }

    CurLogo() {
        const userAccount = _.get(this, 'Auth.account', {});
        const isWhiteLabelEnabled = _.get(userAccount, 'subscriptionState.whiteLabelEnabled', false);
        return userAccount.whiteLabelLogoUri && isWhiteLabelEnabled
            ? userAccount.whiteLabelLogoUri
            : "https://high-seas.s3.amazonaws.com/email/logo.svg";
    }

    async GetCurAccountData() {
        let auth = await this.AccountService.Get();
        const subscriptionState = await this.BillingService.GetCurrentSubscriptionState()
        _.set(auth, "subscriptionState", subscriptionState);
        _.set(this, "Auth.account", auth);
        this.UpdateUserObjectAndBustCache(this.Auth);
    }

    async UpdateCurrentSubscriptionState(subState) {
        if (subState === undefined) {
            subState = await this.BillingService.GetCurrentSubscriptionState();
        }

        _.set(this, "Auth.account.subscriptionState", subState);

        this.UpdateUserObjectAndBustCache(this.Auth);
    }

    GetUUID() {
        this.uuidCounter++;
        let uniqueId = "uuid_" + this.uuidCounter;
        return uniqueId;
    }

    TimeSinceDateTime(date) {
        let datePast = moment.utc(date);
        let dateNow = moment.utc();

        let seconds = Math.floor((dateNow.toDate() - datePast.toDate()) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
        return {
            days: days,
            hours: hours < 10 ? "0" + hours : hours,
            minutes: minutes < 10 ? "0" + minutes : minutes,
            seconds: seconds < 10 ? "0" + seconds : seconds,
        };
    }

    FormatDate(date, includeTime) {
        if (date === null || date === undefined) {
            return "N/A";
        }
        let format = this.Auth.account.dateFormat;
        if (includeTime !== undefined) {
            format = format + " h:mm a";
        }
        let accountTimeZone = this.Auth.account.timeZoneId;
        let hours = moment.duration(new Date().getTimezoneOffset() / 60, "h");
        return moment.utc(date).subtract(hours).format(format);
    }

    FormatDateToString(date, showNAIfNull) {
        if (showNAIfNull && !date) {
            return "N/A";
        }
        
        const localDate = new Date(date);
        const isUTC = typeof date === STRING && date.endsWith('Z');

        const timezoneOffset = localDate.getTimezoneOffset() * 60000;
        const adjustedDate = isUTC ? localDate : new Date(localDate.getTime() - timezoneOffset);
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        
        return adjustedDate.toLocaleDateString(undefined, options);
    }

    FormatDateToTime(date) {
        let format = "h:mm a";
        let hours = moment.duration(new Date().getTimezoneOffset() / 60, "h");
        return moment.utc(date).subtract(hours).format(format);
    }

    ProductTourProcess(tour, containerInstance) {
        if (this.Auth && this.Auth.productTourState.indexOf(tour) === -1) {
            if (containerInstance.$tours[tour] !== undefined) {
                containerInstance.$tours[tour].start();
            }
        } else {
        }
    }

    NavigateToView(path, props, blank) {
        if (blank === undefined) {
            blank = false;
        }
        if (blank) {
            this.SaveDataCache("new_tab", {path: path, params: props});
            window.open(path, "_blank");
            return;
        }
        // https://router.vuejs.org/guide/essentials/navigation.html
        const currentPath = window.$VI.$route.path;
        const currentParams = window.$VI.$route.params;
        if (currentPath !== path || JSON.stringify(currentParams) !== JSON.stringify(props)) {
            this.viewToViewData = props;
            sessionStorage.viewToViewData = JSON.stringify(this.viewToViewData);
            window.$VI.$router.push({ path: path, params: props });
        }
    }

    CheckIfShouldNavigateToView() {
        let data = this.LoadDataCache("new_tab");
        this.SaveDataCache("new_tab", null);
        if (
            data !== undefined &&
            data !== null &&
            data.path !== undefined &&
            data.params !== undefined
        ) {
            this.viewToViewData = data.params;
        }
    }

    BuyNow() {
        if (this.Auth.permissions.manageBilling) {
            this.viewToViewData = {};
            sessionStorage.viewToViewData = JSON.stringify(this.viewToViewData);
            window.$VI.$router.push({path: "/billing", params: {}});
        } else {
            this.AlertUser(
                "An Admin Must Upgrade Your Account To Access This Feature",
                "danger",
                20000
            );
        }
    }

    async SaveCompletionOfProductTours(tourName) {
        const productTourState = _.get(this, 'Auth.productTourState', [])
        if (productTourState.indexOf(tourName) === -1) {
            productTourState.push(tourName);
            this.SaveSessionData(this.Auth);
            await this.UserService.SaveProductTourState(productTourState);
        }
    }

    SetRememberMe(email) {
        try {
            this.SaveDataCache(this.SessionKey + "_remember", email);
        } catch (e) {
            return null;
        }
    }

    CheckRememberMe() {
        try {
            return this.LoadDataCache(this.SessionKey + "_remember");
        } catch (e) {
            return null;
        }
    }

    UpdateUserObjectAndBustCache(user) {
        this.Auth = user;
        if (this.BrowserHasLocalStorage()) {
            localStorage.auth = JSON.stringify(this.Auth);
        } else {
            Cookies.set(this.SessionKey, JSON.stringify(this.Auth), {expires: 1});
        }
    }

    BrowserHasSessionStorage() {
        let mod = "test";
        try {
            sessionStorage.setItem(mod, mod);
            sessionStorage.removeItem(mod);
            return true;
        } catch (e) {
            return false;
        }
    }

    BrowserHasLocalStorage() {
        let mod = "test";
        try {
            localStorage.setItem(mod, mod);
            localStorage.removeItem(mod);
            return true;
        } catch (e) {
            return false;
        }
    }

    SaveSessionData(auth) {
        let authString = JSON.stringify(auth);
        if (this.BrowserHasLocalStorage()) {
            localStorage.auth = authString;
        } else {
            Cookies.set(this.SessionKey, authString, {expires: 1});
        }
    }

    DeleteSessionData() {
        if (this.BrowserHasLocalStorage()) {
            localStorage.auth = undefined;
        } else {
            Cookies.remove(this.SessionKey);
        }
    }

    LoadSessionData() {
        try {
            if (
                this.BrowserHasLocalStorage() &&
                localStorage.auth !== undefined &&
                localStorage.auth.length > 10
            ) {
                this.Auth = JSON.parse(localStorage.auth);
            } else {
                let cookieAuth = Cookies.get(this.SessionKey);
                if (cookieAuth != null) {
                    this.Auth = JSON.parse(cookieAuth);
                    localStorage.auth = cookieAuth;
                } else {
                    return;
                }
            }

            if (this.Auth != null && this.Auth.apiToken.token) {
                ApiClient.instance.authentications[
                    "AccessToken"
                    ].apiKey = this.Auth.apiToken.token;
                this.Api = axios.create({
                    baseURL: this.Env.apiHost + "/",
                    timeout: 1000,
                    headers: {
                        AccessToken: this.Auth.apiToken.token,
                    },
                });
            }
        } catch (e) {
            this.DeleteSessionData();
        }
    }

    SetLoggedInUser(auth, followRedirect = true, isGoingBack = false, isFirstTimeUser = false) {
        if (auth.apiToken === undefined) {
            return;
        }
        if (followRedirect === undefined) {
            followRedirect = true;
        }
        this.Auth = auth;
        this.Auth.logOutTime = new Date().addHours(24);
        ApiClient.instance.authentications["AccessToken"].apiKey =
            auth.apiToken.token;
        this.Api = axios.create({
            baseURL: this.Env.apiHost + "/",
            timeout: 1000,
            headers: {
                AccessToken: auth.apiToken.token,
            },
        });

        try {
            window.hj('identify', this.Auth.id, { //dates  in ISO-8601 format.
                'sku': this.Auth.account.subscriptionState.sku
            });
        } catch (a) {
            console.log(a)
        }

        if (isGoingBack) {
            this.AdminImpersonating = null;
            this.DeleteDataCache("admin_impersonate");
            this.DeleteAllCookies();
        }

        this.DeleteSessionData();
        this.SaveSessionData(auth);
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let query = window.$VI.$router.currentRoute.query;
        // console.log(params)

        this.logInChannel.postMessage('login');

        if (followRedirect) {
            if (params.g !== undefined) {
                let linkdata = atob(params.g).split(",");
                if (
                    linkdata.length === 2 &&
                    linkdata[0] === "proof-item" &&
                    linkdata[1] > 0
                ) {
                    query.g = undefined;
                    this.NavigateToView(linkdata[0], {proof: {id: linkdata[1]}});
                    return;
                }
                if (
                    linkdata.length === 3 &&
                    linkdata[0] === "review-page" &&
                    linkdata[2] > 0
                ) {
                    query.g = undefined;
                    sessionStorage.viewToViewData = JSON.stringify({
                        proof: {id: parseInt(linkdata[2])},
                    });
                    window.location = `/review/`  + linkdata[1];
                    return;
                }
            }

            if (isFirstTimeUser && !this.Auth.lastLogin) {
                window.location = "/create?welcome=true";
            } else {
                window.location = "/overview";
            }
            // window.$VI.$router.push('/overview');

        }
    }

    InactivityLoop() {
        let self = this;
        clearInterval(window.as_inactivity_loop);
        window.as_inactivity_loop = setInterval(function () {
            if (self.Auth == null) {
                self.InactivityLoop();
            } else if (new Date(self.Auth.logOutTime) < new Date()) {
                self.LogOut(true);
            } else {
                self.InactivityLoop();
            }
        }, 1000 * 60);
    }

    CacheBreakLoop() {
        let self = this;
        clearInterval(window.as_cache_break_loop);
        window.as_cache_break_loop = setInterval(function () {
            self.BreakCache();
            self.CacheBreakLoop();
        }, 1000 * 60);
    }

    //break the permission and user settings cache often without touching the auth token
    async BreakCache() {
        // don't do anything if user is not authenticated
        if (!this.Auth) return;
        if(!this.Auth.user) return;
        let AppUserDto = await this.UserService.GetUserSilent();
        // console.log("breaking cache", this.Auth, AppUserDto)
        if (
            AppUserDto != null &&
            AppUserDto.success !== false &&
            AppUserDto.id > 0
        ) {
            let logouttimebefore = this.Auth.logOutTime
            this.Auth.permissions = AppUserDto.permissions;
            this.Auth.userSettings = AppUserDto.userSettings;
            this.Auth.logOutTime = logouttimebefore;
            this.SaveSessionData(this.Auth);
        }
    }

    async LogOut(redirect = true, calledByChannel = false) {
        if(!calledByChannel){
            this.logOutChannel.postMessage('logout');
        }
        try {
            await this.UserService.LogOut();
        } catch (ex) {
        }
        this.AccessToken = "";
        ApiClient.instance.authentications["AccessToken"].apiKey = "";
        this.DeleteSessionData();
        this.Auth = null;
        this.Api = axios.create({
            baseURL: this.Env.apiHost + "/",
            timeout: 1000,
            headers: {},
        });

        sessionStorage.viewToViewData = null;
        localStorage[`accountLogo${window.location.hostname}`] = null;
        this.DeleteSessionData();
        clearInterval(window.as_cache_break_loop);
        clearInterval(window.as_inactivity_loop);
        if (redirect) {
            window.location = "/";
            // window.$VI.$router.push("/");
        }
    }

    // utility methods

    IsWebUrl(url) {
        return url.match(
            new RegExp(
                /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,16}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
            )
        );
    }

    GetNewAxiosClient(){
        return axios.create({
            baseURL: this.Env.apiHost + "/",
            timeout: 1000,
            headers: {},
        });
    }

    CopyStringToClipboard(str) {
        let fallbackCopyTextToClipboard = function (text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.position = "fixed"; // avoid scrolling to bottom
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand("copy");
                var msg = successful ? "successful" : "unsuccessful";
            } catch (err) {
                console.error("Fallback: Oops, unable to copy", err);
            }
            document.body.removeChild(textArea);
        };

        let copyTextToClipboard = function (text) {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(
                function () {
                    console.log("Async: Copying to clipboard was successful!");
                },
                function (err) {
                    console.error("Async: Could not copy text: ", err);
                }
            );
        };
        copyTextToClipboard(str);
    }

    AddClass(name, element) {
        var classesString;
        classesString = element.className || "";
        if (classesString.indexOf(name) === -1) {
            element.className += " " + name;
        }
    }

    RemoveClass(name, element) {
        element.classList.remove(name);
    }

    wordToCamelCase(word) {
        // Make the first letter uppercase
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    AlertUser(message, type, timeout, title, position) {
        const content = this.VueApp.$createElement('div', {
            domProps: {
                innerHTML: message
            },
        });

        const toasterPosition = position ?  `b-toaster-${position}` : 'b-toaster-top-right';

        let _title = title || type || 'Attention';
        if (_title === 'warning') _title = 'Attention';

        this.VueApp.$bvToast.toast(content, {
            title: this.wordToCamelCase(_title),
            autoHideDelay: timeout || 5000,
            variant: type || 'warning',
            appendToast: true,
            toaster: toasterPosition
        })

        // window.$VI.$emit("alert::user", message, type, timeout);
        // let self = this;
        // setTimeout(function () {
        //   let el = document.getElementById("nav-alert");
        //   let cancelFunction = self.VueApp.$scrollTo(el, 500);
        // },0);
    }

    async AsyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    ApiWorking() {
        setTimeout(function () {
            if (window.$VI === undefined) {
                return;
            }
            window.$VI.$emit("api::working");
        }, 100);
    }

    ApiIdle() {
        setTimeout(function () {
            if (window.$VI === undefined) {
                return;
            }
            window.$VI.$emit("api::idle");
        }, 100);
    }

    IsEmailValid(email) {
        if (email === undefined) {
            return false;
        }
        let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        return email.length === 0 ? true : reg.test(email) && email.length > 5;
    }

    SaveCookie(key, data, expires) {
        if (expires === undefined) {
            expires = 1;
        }
        Cookies.set(key, data, {expires: expires});
    }

    LoadCookie(key) {
        let data = Cookies.get(key);
        return (data != null) ? JSON.parse(data) : null;
    }

    SaveDataCache(key, data, expires, shouldUtilizeSessionStorage = false) {
        if (expires === undefined) {
            expires = 1;
        }
        try {
            if (shouldUtilizeSessionStorage ? this.BrowserHasSessionStorage() : this.BrowserHasLocalStorage()) {
                try {
                    if (shouldUtilizeSessionStorage) sessionStorage[key] = JSON.stringify(data);
                    else localStorage[key] = JSON.stringify(data);
                } catch (error) {
                    Cookies.set(key, data, {expires: expires});
                }
            } else {
                Cookies.set(key, data, {expires: expires});
            }
        } catch (error) {
            console.log("error saving data to cache");
        }
    }

    DeleteCookie(key) {
        Cookies.remove(key);
    }

    LoadDataCache(key, shouldUtilizeSessionStorage = false) {
        try {
            if (shouldUtilizeSessionStorage ? this.BrowserHasSessionStorage() : this.BrowserHasLocalStorage()) {
                if (shouldUtilizeSessionStorage ? sessionStorage[key] : localStorage[key]) {
                    return JSON.parse(shouldUtilizeSessionStorage ? sessionStorage[key] : localStorage[key]);
                }
            } else {
                let data = Cookies.get(key);
                if (data != null) {
                    return JSON.parse(data);
                }
            }
        } catch (e) {
            return null;
        }
        return null;
    }

    DeleteDataCache(key) {
        if (this.BrowserHasLocalStorage()) {
            localStorage[key] = undefined;
        }
        Cookies.remove(key + this.Env.envName);
    }

    DeleteAllCookies() {
        let cookies = Cookies.get();
        Cookies.remove(cookies);
    }

    InsertUrlParam(key, value) {
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        // kvp looks like ['key1=value1', 'key2=value2', ...]
        let kvp = document.location.search.substr(1).split("&");
        let i = 0;

        for (; i < kvp.length; i++) {
            if (kvp[i].startsWith(key + "=")) {
                let pair = kvp[i].split("=");
                pair[1] = value;
                kvp[i] = pair.join("=");
                break;
            }
        }

        if (i >= kvp.length) {
            kvp[kvp.length] = [key, value].join("=");
        }

        // can return this or...
        let params = kvp.join("&");
        if (params[0] === "&") {
            params = params.substring(1, params.length);
        }

        // reload page with new params
        document.location.search = params;
    }

    async HandleApiResponse(apiPromise, successMessage, preventAlert) {
        let error = null;
        let headers = null;
        let status = null;
        let message = null;
        let body = null;
        if (preventAlert === undefined) {
            preventAlert = false;
        }

        try {
            this.ApiWorking();
            let res = await apiPromise;

            if (
                res.body !== null &&
                res.body.id === undefined &&
                res.body.message !== undefined
            ) {
                message = res.body.message;
                body = res.body;
            } else {
                body = res.body;
            }

            if (res.status === 400) {
                message = res.body.title;
            } else if (res.status === 400 && res.body.success !== undefined) {
                message = "Error: Check your data";
            }

            // console.log(res.status);
            if (res.status === 401) {
                console.log("Error: User Unauthorized...");
                message = "Error: User Unauthorized...";
                this.DeleteAllCookies();
                this.DeleteSessionData()
                // reload once if in review screen
                if (window.location &&
                    window.location.href &&
                    window.location.href.includes('/review') &&
                    !window.location.href.includes('?') &&
                    !window.location.href.includes('reload=true')
                ) {
                    // await this.LogOut(false);
                    window.location = window.location + '?reload=true';
                    return;
                }
                window.location.href = "/";
                console.log("Error: User Unauthorized...");
                // this.AlertUser(
                //     "Error: User Unauthorized...  Log out, then log back in. If this does not work please contact support.",
                //     "warning",
                //     3600
                // );
                return null;
            }

            status = res.status;
            headers = res.headers;
        } catch (err) {
            if (err.status === 401) {
                console.log("Error: User Unauthorized...");
                message = "Error: User Unauthorized...";
                this.DeleteAllCookies();
                this.DeleteSessionData()
                // reload once if in review screen
                if (window.location &&
                    window.location.href &&
                    window.location.href.includes('/review/') &&
                    !window.location.href.includes('?') &&
                    !window.location.href.includes('reload=true')
                ) {
                    // await this.LogOut(false);
                    window.location = window.location + '?reload=true';
                    return;
                }
                window.location.href = "/";
                console.log("Error: User Unauthorized...");
                return null;
            }

            if ((err.status === 400 || err.status === 500) && err.response && err.response.body) {
                message = err.response.body.message;
            }

            window.err = err;
            if (err.message.indexOf("Request has been terminated") !== -1) {
                this.AlertUser(
                    "Network Error. Please check your internet connection, Log out, clear your cache, then log back in. If this does not work please contact support.",
                    "warning",
                    3600
                );
                return null;
            }
            error = err;
            console.log("error", err)
        } finally {
            this.ApiIdle();
        }

        // console.log(body);
        // console.log(status);
        // console.log(error);
        // console.log(headers);
        if (!preventAlert) {
            if (body == null && message == null) {
                this.AlertUser(
                    "Network Error. Please Log out and clear your cache, then log back in. If this does not work please contact support.",
                    "warning"
                );
            } else if (message != null && message != "") {
                this.AlertUser(message, "warning");
            } else if (successMessage) {
                this.AlertUser(successMessage, "success");
            }
        }

        //update the inactivty time
        if (this.Auth)
            this.Auth.logOutTime = new Date().addHours(24);

        return body;
    }

    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    ClearViewData() {
        this.viewToViewData = null;
        sessionStorage.viewToViewData = null
    }

    SetViewData(viewData) {
        this.viewToViewData = viewData;
        sessionStorage.viewToViewData = viewData;
    }
}

export default AshoreApp;
