class LiveProofService {

    constructor() {
        this.scriptUrl = "https://cdn.ashoreapp.com/scripts/live_web_proofing_script.js";
        this.urlStatus = {};
    }

    addHiddenIframeWithUrl(url) {
        let self = this;
        this.urlStatus[url] = {
            loaded: false,
            scriptFound: false,
        };
        let i = document.createElement("iframe");
        i.style.display = 'none';
        i.src = url;
        i.onload = () => {
            let message = JSON.stringify({key: 'ashore', id: url, method: 'get'});
            i.contentWindow.postMessage(message, "*");
            setTimeout(() => {
                self.urlStatus[url].loaded = true;
                i.parentNode.removeChild(i);
            }, 200);
        }
        document.body.appendChild(i);
    }

    addOnMessageHandlerForUrl(url) {
        let self = this;
        let existingMessageHandler = window.onmessage
        window.onmessage = function (e) {
            if (e && e.data) {
                try {
                    let payload = JSON.parse(e.data);
                    if (payload.key && payload.key === 'ashore' && payload.id === url && payload.iframe_url) {
                        self.urlStatus[url].scriptFound = true;
                        window.onmessage = existingMessageHandler;
                    }
                } catch (e) {
                    //
                }
            }
            if (existingMessageHandler) {
                existingMessageHandler(e);
            }
        };
    }

    waitForIframeToMessageUrlOrTimeout(url) {
        let self = this;
        let cycles = 0;
        let sleepBetweenChecksMs = 100;
        let maxWaitCycles = 50;
        return new Promise((resolve) => {
            // wait for page to load, up to 10 seconds
            let interval = setInterval(() => {
                // once the page is loaded, stop the cycles
                if (self.urlStatus[url].loaded) {
                    clearInterval(interval);
                    resolve();
                }
                cycles++;
                // stop the cycles if we reach limit
                if (cycles >= maxWaitCycles) {
                    clearInterval(interval);
                    resolve();
                }
            }, sleepBetweenChecksMs);
        });
    }

    async checkUrl(url) {
        this.addOnMessageHandlerForUrl(url);
        this.addHiddenIframeWithUrl(url);
        await this.waitForIframeToMessageUrlOrTimeout(url);
        return this.urlStatus[url];
    }
}

export default LiveProofService;