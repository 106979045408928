/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AppEventBase from './AppEventBase';

/**
 * The AppEvent model module.
 * @module model/AppEvent
 * @version V1
 */
class AppEvent {
    /**
     * Constructs a new <code>AppEvent</code>.
     * @alias module:model/AppEvent
     */
    constructor() { 
        
        AppEvent.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AppEvent</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AppEvent} obj Optional instance to populate.
     * @return {module:model/AppEvent} The populated <code>AppEvent</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AppEvent();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('proofFileId')) {
                obj['proofFileId'] = ApiClient.convertToType(data['proofFileId'], 'Number');
            }
            if (data.hasOwnProperty('emailLogId')) {
                obj['emailLogId'] = ApiClient.convertToType(data['emailLogId'], 'Number');
            }
            if (data.hasOwnProperty('workflowStageId')) {
                obj['workflowStageId'] = ApiClient.convertToType(data['workflowStageId'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('eventName')) {
                obj['eventName'] = ApiClient.convertToType(data['eventName'], 'String');
            }
            if (data.hasOwnProperty('eventType')) {
                obj['eventType'] = ApiClient.convertToType(data['eventType'], 'Number');
            }
            if (data.hasOwnProperty('contextData')) {
                obj['contextData'] = ApiClient.convertToType(data['contextData'], {'String': Object});
            }
            if (data.hasOwnProperty('appEventObject')) {
                obj['appEventObject'] = AppEventBase.constructFromObject(data['appEventObject']);
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'Date');
            }
            if (data.hasOwnProperty('queuedActionAt')) {
                obj['queuedActionAt'] = ApiClient.convertToType(data['queuedActionAt'], 'Date');
            }
            if (data.hasOwnProperty('queuedActionCompletedAt')) {
                obj['queuedActionCompletedAt'] = ApiClient.convertToType(data['queuedActionCompletedAt'], 'Date');
            }
            if (data.hasOwnProperty('startedProcessingQueuedAction')) {
                obj['startedProcessingQueuedAction'] = ApiClient.convertToType(data['startedProcessingQueuedAction'], 'Date');
            }
            if (data.hasOwnProperty('proofName')) {
                obj['proofName'] = ApiClient.convertToType(data['proofName'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AppEvent</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AppEvent</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['eventName'] && !(typeof data['eventName'] === 'string' || data['eventName'] instanceof String)) {
            throw new Error("Expected the field `eventName` to be a primitive type in the JSON string but got " + data['eventName']);
        }
        // validate the optional field `appEventObject`
        if (data['appEventObject']) { // data not null
          AppEventBase.validateJSON(data['appEventObject']);
        }
        // ensure the json data is a string
        if (data['proofName'] && !(typeof data['proofName'] === 'string' || data['proofName'] instanceof String)) {
            throw new Error("Expected the field `proofName` to be a primitive type in the JSON string but got " + data['proofName']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
AppEvent.prototype['id'] = undefined;

/**
 * @member {Number} userId
 */
AppEvent.prototype['userId'] = undefined;

/**
 * @member {Number} proofId
 */
AppEvent.prototype['proofId'] = undefined;

/**
 * @member {Number} proofVersionId
 */
AppEvent.prototype['proofVersionId'] = undefined;

/**
 * @member {Number} proofFileId
 */
AppEvent.prototype['proofFileId'] = undefined;

/**
 * @member {Number} emailLogId
 */
AppEvent.prototype['emailLogId'] = undefined;

/**
 * @member {Number} workflowStageId
 */
AppEvent.prototype['workflowStageId'] = undefined;

/**
 * @member {Number} approverId
 */
AppEvent.prototype['approverId'] = undefined;

/**
 * @member {String} description
 */
AppEvent.prototype['description'] = undefined;

/**
 * @member {String} eventName
 */
AppEvent.prototype['eventName'] = undefined;

/**
 * @member {Number} eventType
 */
AppEvent.prototype['eventType'] = undefined;

/**
 * @member {Object.<String, Object>} contextData
 */
AppEvent.prototype['contextData'] = undefined;

/**
 * @member {module:model/AppEventBase} appEventObject
 */
AppEvent.prototype['appEventObject'] = undefined;

/**
 * @member {Date} createdAt
 */
AppEvent.prototype['createdAt'] = undefined;

/**
 * @member {Date} deletedAt
 */
AppEvent.prototype['deletedAt'] = undefined;

/**
 * @member {Date} queuedActionAt
 */
AppEvent.prototype['queuedActionAt'] = undefined;

/**
 * @member {Date} queuedActionCompletedAt
 */
AppEvent.prototype['queuedActionCompletedAt'] = undefined;

/**
 * @member {Date} startedProcessingQueuedAction
 */
AppEvent.prototype['startedProcessingQueuedAction'] = undefined;

/**
 * @member {String} proofName
 */
AppEvent.prototype['proofName'] = undefined;






export default AppEvent;

