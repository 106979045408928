import ProductCodesApi from "../generated/api/ProductCodesApi";

class ProductCodeService {
  constructor() {
    this.ProductCodesApi = new ProductCodesApi();
  }

  async GetAllCodesOnAccount() {
    return await window.$A.HandleApiResponse(
      this.ProductCodesApi.getAllRedeemedProductCodesOnAccount(null),
      "",
      true
    );
  }

  async RedeemProductCode(code) {
    return await window.$A.HandleApiResponse(
      this.ProductCodesApi.redeemNewProductCode(code, null),
      "",
      true
    );
  }
}

export default ProductCodeService;
