/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollectionMethod from './CollectionMethod';
import InvoiceLineItem from './InvoiceLineItem';

/**
 * The Invoice model module.
 * @module model/Invoice
 * @version V1
 */
class Invoice {
    /**
     * Constructs a new <code>Invoice</code>.
     * @alias module:model/Invoice
     */
    constructor() { 
        
        Invoice.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Invoice</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Invoice} obj Optional instance to populate.
     * @return {module:model/Invoice} The populated <code>Invoice</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Invoice();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('resellerId')) {
                obj['resellerId'] = ApiClient.convertToType(data['resellerId'], 'Number');
            }
            if (data.hasOwnProperty('emailToSendTo')) {
                obj['emailToSendTo'] = ApiClient.convertToType(data['emailToSendTo'], 'String');
            }
            if (data.hasOwnProperty('providerInvoiceNumber')) {
                obj['providerInvoiceNumber'] = ApiClient.convertToType(data['providerInvoiceNumber'], 'String');
            }
            if (data.hasOwnProperty('providerInvoiceId')) {
                obj['providerInvoiceId'] = ApiClient.convertToType(data['providerInvoiceId'], 'String');
            }
            if (data.hasOwnProperty('providerCustomerId')) {
                obj['providerCustomerId'] = ApiClient.convertToType(data['providerCustomerId'], 'String');
            }
            if (data.hasOwnProperty('providerPaymentMethodId')) {
                obj['providerPaymentMethodId'] = ApiClient.convertToType(data['providerPaymentMethodId'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('createdOnProviderAt')) {
                obj['createdOnProviderAt'] = ApiClient.convertToType(data['createdOnProviderAt'], 'Date');
            }
            if (data.hasOwnProperty('lastProviderSyncAt')) {
                obj['lastProviderSyncAt'] = ApiClient.convertToType(data['lastProviderSyncAt'], 'Date');
            }
            if (data.hasOwnProperty('jsonObject')) {
                obj['jsonObject'] = ApiClient.convertToType(data['jsonObject'], 'String');
            }
            if (data.hasOwnProperty('metaData')) {
                obj['metaData'] = ApiClient.convertToType(data['metaData'], {'String': 'String'});
            }
            if (data.hasOwnProperty('jsonInvoiceLineItems')) {
                obj['jsonInvoiceLineItems'] = ApiClient.convertToType(data['jsonInvoiceLineItems'], 'String');
            }
            if (data.hasOwnProperty('collectionMethod')) {
                obj['collectionMethod'] = CollectionMethod.constructFromObject(data['collectionMethod']);
            }
            if (data.hasOwnProperty('paid')) {
                obj['paid'] = ApiClient.convertToType(data['paid'], 'Boolean');
            }
            if (data.hasOwnProperty('periodEnd')) {
                obj['periodEnd'] = ApiClient.convertToType(data['periodEnd'], 'Date');
            }
            if (data.hasOwnProperty('periodStart')) {
                obj['periodStart'] = ApiClient.convertToType(data['periodStart'], 'Date');
            }
            if (data.hasOwnProperty('amountDue')) {
                obj['amountDue'] = ApiClient.convertToType(data['amountDue'], 'Number');
            }
            if (data.hasOwnProperty('amountPaid')) {
                obj['amountPaid'] = ApiClient.convertToType(data['amountPaid'], 'Number');
            }
            if (data.hasOwnProperty('attempted')) {
                obj['attempted'] = ApiClient.convertToType(data['attempted'], 'Boolean');
            }
            if (data.hasOwnProperty('attemptCount')) {
                obj['attemptCount'] = ApiClient.convertToType(data['attemptCount'], 'Number');
            }
            if (data.hasOwnProperty('discount')) {
                obj['discount'] = ApiClient.convertToType(data['discount'], 'String');
            }
            if (data.hasOwnProperty('billingReason')) {
                obj['billingReason'] = ApiClient.convertToType(data['billingReason'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('hostedInvoiceUrl')) {
                obj['hostedInvoiceUrl'] = ApiClient.convertToType(data['hostedInvoiceUrl'], 'String');
            }
            if (data.hasOwnProperty('invoicePdf')) {
                obj['invoicePdf'] = ApiClient.convertToType(data['invoicePdf'], 'String');
            }
            if (data.hasOwnProperty('subtotal')) {
                obj['subtotal'] = ApiClient.convertToType(data['subtotal'], 'Number');
            }
            if (data.hasOwnProperty('tax')) {
                obj['tax'] = ApiClient.convertToType(data['tax'], 'Number');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
            if (data.hasOwnProperty('dueDate')) {
                obj['dueDate'] = ApiClient.convertToType(data['dueDate'], 'Date');
            }
            if (data.hasOwnProperty('subscriptionProrationDate')) {
                obj['subscriptionProrationDate'] = ApiClient.convertToType(data['subscriptionProrationDate'], 'Date');
            }
            if (data.hasOwnProperty('calculatedTotal')) {
                obj['calculatedTotal'] = ApiClient.convertToType(data['calculatedTotal'], 'Number');
            }
            if (data.hasOwnProperty('amountRemaining')) {
                obj['amountRemaining'] = ApiClient.convertToType(data['amountRemaining'], 'Number');
            }
            if (data.hasOwnProperty('endingBalance')) {
                obj['endingBalance'] = ApiClient.convertToType(data['endingBalance'], 'Number');
            }
            if (data.hasOwnProperty('startingBalance')) {
                obj['startingBalance'] = ApiClient.convertToType(data['startingBalance'], 'Number');
            }
            if (data.hasOwnProperty('lineItems')) {
                obj['lineItems'] = ApiClient.convertToType(data['lineItems'], [InvoiceLineItem]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Invoice</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Invoice</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['emailToSendTo'] && !(typeof data['emailToSendTo'] === 'string' || data['emailToSendTo'] instanceof String)) {
            throw new Error("Expected the field `emailToSendTo` to be a primitive type in the JSON string but got " + data['emailToSendTo']);
        }
        // ensure the json data is a string
        if (data['providerInvoiceNumber'] && !(typeof data['providerInvoiceNumber'] === 'string' || data['providerInvoiceNumber'] instanceof String)) {
            throw new Error("Expected the field `providerInvoiceNumber` to be a primitive type in the JSON string but got " + data['providerInvoiceNumber']);
        }
        // ensure the json data is a string
        if (data['providerInvoiceId'] && !(typeof data['providerInvoiceId'] === 'string' || data['providerInvoiceId'] instanceof String)) {
            throw new Error("Expected the field `providerInvoiceId` to be a primitive type in the JSON string but got " + data['providerInvoiceId']);
        }
        // ensure the json data is a string
        if (data['providerCustomerId'] && !(typeof data['providerCustomerId'] === 'string' || data['providerCustomerId'] instanceof String)) {
            throw new Error("Expected the field `providerCustomerId` to be a primitive type in the JSON string but got " + data['providerCustomerId']);
        }
        // ensure the json data is a string
        if (data['providerPaymentMethodId'] && !(typeof data['providerPaymentMethodId'] === 'string' || data['providerPaymentMethodId'] instanceof String)) {
            throw new Error("Expected the field `providerPaymentMethodId` to be a primitive type in the JSON string but got " + data['providerPaymentMethodId']);
        }
        // ensure the json data is a string
        if (data['jsonObject'] && !(typeof data['jsonObject'] === 'string' || data['jsonObject'] instanceof String)) {
            throw new Error("Expected the field `jsonObject` to be a primitive type in the JSON string but got " + data['jsonObject']);
        }
        // ensure the json data is a string
        if (data['jsonInvoiceLineItems'] && !(typeof data['jsonInvoiceLineItems'] === 'string' || data['jsonInvoiceLineItems'] instanceof String)) {
            throw new Error("Expected the field `jsonInvoiceLineItems` to be a primitive type in the JSON string but got " + data['jsonInvoiceLineItems']);
        }
        // ensure the json data is a string
        if (data['discount'] && !(typeof data['discount'] === 'string' || data['discount'] instanceof String)) {
            throw new Error("Expected the field `discount` to be a primitive type in the JSON string but got " + data['discount']);
        }
        // ensure the json data is a string
        if (data['billingReason'] && !(typeof data['billingReason'] === 'string' || data['billingReason'] instanceof String)) {
            throw new Error("Expected the field `billingReason` to be a primitive type in the JSON string but got " + data['billingReason']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
            throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
        }
        // ensure the json data is a string
        if (data['hostedInvoiceUrl'] && !(typeof data['hostedInvoiceUrl'] === 'string' || data['hostedInvoiceUrl'] instanceof String)) {
            throw new Error("Expected the field `hostedInvoiceUrl` to be a primitive type in the JSON string but got " + data['hostedInvoiceUrl']);
        }
        // ensure the json data is a string
        if (data['invoicePdf'] && !(typeof data['invoicePdf'] === 'string' || data['invoicePdf'] instanceof String)) {
            throw new Error("Expected the field `invoicePdf` to be a primitive type in the JSON string but got " + data['invoicePdf']);
        }
        if (data['lineItems']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['lineItems'])) {
                throw new Error("Expected the field `lineItems` to be an array in the JSON data but got " + data['lineItems']);
            }
            // validate the optional field `lineItems` (array)
            for (const item of data['lineItems']) {
                InvoiceLineItem.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
Invoice.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
Invoice.prototype['accountId'] = undefined;

/**
 * @member {Number} resellerId
 */
Invoice.prototype['resellerId'] = undefined;

/**
 * @member {String} emailToSendTo
 */
Invoice.prototype['emailToSendTo'] = undefined;

/**
 * @member {String} providerInvoiceNumber
 */
Invoice.prototype['providerInvoiceNumber'] = undefined;

/**
 * @member {String} providerInvoiceId
 */
Invoice.prototype['providerInvoiceId'] = undefined;

/**
 * @member {String} providerCustomerId
 */
Invoice.prototype['providerCustomerId'] = undefined;

/**
 * @member {String} providerPaymentMethodId
 */
Invoice.prototype['providerPaymentMethodId'] = undefined;

/**
 * @member {Date} createdAt
 */
Invoice.prototype['createdAt'] = undefined;

/**
 * @member {Date} createdOnProviderAt
 */
Invoice.prototype['createdOnProviderAt'] = undefined;

/**
 * @member {Date} lastProviderSyncAt
 */
Invoice.prototype['lastProviderSyncAt'] = undefined;

/**
 * @member {String} jsonObject
 */
Invoice.prototype['jsonObject'] = undefined;

/**
 * @member {Object.<String, String>} metaData
 */
Invoice.prototype['metaData'] = undefined;

/**
 * @member {String} jsonInvoiceLineItems
 */
Invoice.prototype['jsonInvoiceLineItems'] = undefined;

/**
 * @member {module:model/CollectionMethod} collectionMethod
 */
Invoice.prototype['collectionMethod'] = undefined;

/**
 * @member {Boolean} paid
 */
Invoice.prototype['paid'] = undefined;

/**
 * @member {Date} periodEnd
 */
Invoice.prototype['periodEnd'] = undefined;

/**
 * @member {Date} periodStart
 */
Invoice.prototype['periodStart'] = undefined;

/**
 * @member {Number} amountDue
 */
Invoice.prototype['amountDue'] = undefined;

/**
 * @member {Number} amountPaid
 */
Invoice.prototype['amountPaid'] = undefined;

/**
 * @member {Boolean} attempted
 */
Invoice.prototype['attempted'] = undefined;

/**
 * @member {Number} attemptCount
 */
Invoice.prototype['attemptCount'] = undefined;

/**
 * @member {String} discount
 */
Invoice.prototype['discount'] = undefined;

/**
 * @member {String} billingReason
 */
Invoice.prototype['billingReason'] = undefined;

/**
 * @member {String} description
 */
Invoice.prototype['description'] = undefined;

/**
 * @member {String} status
 */
Invoice.prototype['status'] = undefined;

/**
 * @member {String} hostedInvoiceUrl
 */
Invoice.prototype['hostedInvoiceUrl'] = undefined;

/**
 * @member {String} invoicePdf
 */
Invoice.prototype['invoicePdf'] = undefined;

/**
 * @member {Number} subtotal
 */
Invoice.prototype['subtotal'] = undefined;

/**
 * @member {Number} tax
 */
Invoice.prototype['tax'] = undefined;

/**
 * @member {Number} total
 */
Invoice.prototype['total'] = undefined;

/**
 * @member {Date} dueDate
 */
Invoice.prototype['dueDate'] = undefined;

/**
 * @member {Date} subscriptionProrationDate
 */
Invoice.prototype['subscriptionProrationDate'] = undefined;

/**
 * @member {Number} calculatedTotal
 */
Invoice.prototype['calculatedTotal'] = undefined;

/**
 * @member {Number} amountRemaining
 */
Invoice.prototype['amountRemaining'] = undefined;

/**
 * @member {Number} endingBalance
 */
Invoice.prototype['endingBalance'] = undefined;

/**
 * @member {Number} startingBalance
 */
Invoice.prototype['startingBalance'] = undefined;

/**
 * @member {Array.<module:model/InvoiceLineItem>} lineItems
 */
Invoice.prototype['lineItems'] = undefined;






export default Invoice;

