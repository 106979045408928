/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofCustomField from './ProofCustomField';

/**
 * The SaveProofRequest model module.
 * @module model/SaveProofRequest
 * @version V1
 */
class SaveProofRequest {
    /**
     * Constructs a new <code>SaveProofRequest</code>.
     * @alias module:model/SaveProofRequest
     */
    constructor() { 
        
        SaveProofRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SaveProofRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SaveProofRequest} obj Optional instance to populate.
     * @return {module:model/SaveProofRequest} The populated <code>SaveProofRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SaveProofRequest();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('dueDate')) {
                obj['dueDate'] = ApiClient.convertToType(data['dueDate'], 'Date');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('allowDownload')) {
                obj['allowDownload'] = ApiClient.convertToType(data['allowDownload'], 'Boolean');
            }
            if (data.hasOwnProperty('allowEdit')) {
                obj['allowEdit'] = ApiClient.convertToType(data['allowEdit'], 'Boolean');
            }
            if (data.hasOwnProperty('allowApproveWithChanges')) {
                obj['allowApproveWithChanges'] = ApiClient.convertToType(data['allowApproveWithChanges'], 'Boolean');
            }
            if (data.hasOwnProperty('allowViewAllComments')) {
                obj['allowViewAllComments'] = ApiClient.convertToType(data['allowViewAllComments'], 'Boolean');
            }
            if (data.hasOwnProperty('allowApproveIndividualFiles')) {
                obj['allowApproveIndividualFiles'] = ApiClient.convertToType(data['allowApproveIndividualFiles'], 'Boolean');
            }
            if (data.hasOwnProperty('limitVersionVisibility')) {
                obj['limitVersionVisibility'] = ApiClient.convertToType(data['limitVersionVisibility'], 'Boolean');
            }
            if (data.hasOwnProperty('blockCommentingAfterProofDecision')) {
                obj['blockCommentingAfterProofDecision'] = ApiClient.convertToType(data['blockCommentingAfterProofDecision'], 'Boolean');
            }
            if (data.hasOwnProperty('tagIds')) {
                obj['tagIds'] = ApiClient.convertToType(data['tagIds'], ['Number']);
            }
            if (data.hasOwnProperty('externalMetaData')) {
                obj['externalMetaData'] = ApiClient.convertToType(data['externalMetaData'], {'String': Object});
            }
            if (data.hasOwnProperty('customFields')) {
                obj['customFields'] = ApiClient.convertToType(data['customFields'], [ProofCustomField]);
            }
            if (data.hasOwnProperty('usersTeamIds')) {
                obj['usersTeamIds'] = ApiClient.convertToType(data['usersTeamIds'], ['Number']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SaveProofRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SaveProofRequest</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
            throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['tagIds'])) {
            throw new Error("Expected the field `tagIds` to be an array in the JSON data but got " + data['tagIds']);
        }
        if (data['customFields']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['customFields'])) {
                throw new Error("Expected the field `customFields` to be an array in the JSON data but got " + data['customFields']);
            }
            // validate the optional field `customFields` (array)
            for (const item of data['customFields']) {
                ProofCustomField.validateJSON(item);
            };
        }
        // ensure the json data is an array
        if (!Array.isArray(data['usersTeamIds'])) {
            throw new Error("Expected the field `usersTeamIds` to be an array in the JSON data but got " + data['usersTeamIds']);
        }

        return true;
    }


}



/**
 * @member {String} name
 */
SaveProofRequest.prototype['name'] = undefined;

/**
 * @member {Date} dueDate
 */
SaveProofRequest.prototype['dueDate'] = undefined;

/**
 * @member {String} password
 */
SaveProofRequest.prototype['password'] = undefined;

/**
 * @member {Boolean} allowDownload
 */
SaveProofRequest.prototype['allowDownload'] = undefined;

/**
 * @member {Boolean} allowEdit
 */
SaveProofRequest.prototype['allowEdit'] = undefined;

/**
 * @member {Boolean} allowApproveWithChanges
 */
SaveProofRequest.prototype['allowApproveWithChanges'] = undefined;

/**
 * @member {Boolean} allowViewAllComments
 */
SaveProofRequest.prototype['allowViewAllComments'] = undefined;

/**
 * @member {Boolean} allowApproveIndividualFiles
 */
SaveProofRequest.prototype['allowApproveIndividualFiles'] = undefined;

/**
 * @member {Boolean} limitVersionVisibility
 */
SaveProofRequest.prototype['limitVersionVisibility'] = undefined;

/**
 * @member {Boolean} blockCommentingAfterProofDecision
 */
SaveProofRequest.prototype['blockCommentingAfterProofDecision'] = undefined;

/**
 * @member {Array.<Number>} tagIds
 */
SaveProofRequest.prototype['tagIds'] = undefined;

/**
 * @member {Object.<String, Object>} externalMetaData
 */
SaveProofRequest.prototype['externalMetaData'] = undefined;

/**
 * @member {Array.<module:model/ProofCustomField>} customFields
 */
SaveProofRequest.prototype['customFields'] = undefined;

/**
 * @member {Array.<Number>} usersTeamIds
 */
SaveProofRequest.prototype['usersTeamIds'] = undefined;






export default SaveProofRequest;

