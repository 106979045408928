/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ConstructorInfo from './ConstructorInfo';
import CustomAttributeNamedArgument from './CustomAttributeNamedArgument';
import CustomAttributeTypedArgument from './CustomAttributeTypedArgument';

/**
 * The CustomAttributeData model module.
 * @module model/CustomAttributeData
 * @version V1
 */
class CustomAttributeData {
    /**
     * Constructs a new <code>CustomAttributeData</code>.
     * @alias module:model/CustomAttributeData
     */
    constructor() { 
        
        CustomAttributeData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomAttributeData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomAttributeData} obj Optional instance to populate.
     * @return {module:model/CustomAttributeData} The populated <code>CustomAttributeData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomAttributeData();

            if (data.hasOwnProperty('attributeType')) {
                obj['attributeType'] = ApiClient.convertToType(data['attributeType'], 'String');
            }
            if (data.hasOwnProperty('constructor')) {
                obj['constructor'] = ConstructorInfo.constructFromObject(data['constructor']);
            }
            if (data.hasOwnProperty('constructorArguments')) {
                obj['constructorArguments'] = ApiClient.convertToType(data['constructorArguments'], [CustomAttributeTypedArgument]);
            }
            if (data.hasOwnProperty('namedArguments')) {
                obj['namedArguments'] = ApiClient.convertToType(data['namedArguments'], [CustomAttributeNamedArgument]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CustomAttributeData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CustomAttributeData</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['attributeType'] && !(typeof data['attributeType'] === 'string' || data['attributeType'] instanceof String)) {
            throw new Error("Expected the field `attributeType` to be a primitive type in the JSON string but got " + data['attributeType']);
        }
        // validate the optional field `constructor`
        if (data['constructor']) { // data not null
          ConstructorInfo.validateJSON(data['constructor']);
        }
        if (data['constructorArguments']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['constructorArguments'])) {
                throw new Error("Expected the field `constructorArguments` to be an array in the JSON data but got " + data['constructorArguments']);
            }
            // validate the optional field `constructorArguments` (array)
            for (const item of data['constructorArguments']) {
                CustomAttributeTypedArgument.validateJSON(item);
            };
        }
        if (data['namedArguments']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['namedArguments'])) {
                throw new Error("Expected the field `namedArguments` to be an array in the JSON data but got " + data['namedArguments']);
            }
            // validate the optional field `namedArguments` (array)
            for (const item of data['namedArguments']) {
                CustomAttributeNamedArgument.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {String} attributeType
 */
CustomAttributeData.prototype['attributeType'] = undefined;

/**
 * @member {module:model/ConstructorInfo} constructor
 */
CustomAttributeData.prototype['constructor'] = undefined;

/**
 * @member {Array.<module:model/CustomAttributeTypedArgument>} constructorArguments
 */
CustomAttributeData.prototype['constructorArguments'] = undefined;

/**
 * @member {Array.<module:model/CustomAttributeNamedArgument>} namedArguments
 */
CustomAttributeData.prototype['namedArguments'] = undefined;






export default CustomAttributeData;

