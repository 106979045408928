/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import WebHookEventGroupType from './WebHookEventGroupType';
import WebHookEventType from './WebHookEventType';

/**
 * The AbstractWebHookEventDto model module.
 * @module model/AbstractWebHookEventDto
 * @version V1
 */
class AbstractWebHookEventDto {
    /**
     * Constructs a new <code>AbstractWebHookEventDto</code>.
     * @alias module:model/AbstractWebHookEventDto
     */
    constructor() { 
        
        AbstractWebHookEventDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AbstractWebHookEventDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AbstractWebHookEventDto} obj Optional instance to populate.
     * @return {module:model/AbstractWebHookEventDto} The populated <code>AbstractWebHookEventDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AbstractWebHookEventDto();

            if (data.hasOwnProperty('webHookEventGroupType')) {
                obj['webHookEventGroupType'] = WebHookEventGroupType.constructFromObject(data['webHookEventGroupType']);
            }
            if (data.hasOwnProperty('webHookEventType')) {
                obj['webHookEventType'] = WebHookEventType.constructFromObject(data['webHookEventType']);
            }
            if (data.hasOwnProperty('modelVersion')) {
                obj['modelVersion'] = ApiClient.convertToType(data['modelVersion'], 'Number');
            }
            if (data.hasOwnProperty('eventType')) {
                obj['eventType'] = ApiClient.convertToType(data['eventType'], 'String');
            }
            if (data.hasOwnProperty('eventDescription')) {
                obj['eventDescription'] = ApiClient.convertToType(data['eventDescription'], 'String');
            }
            if (data.hasOwnProperty('eventData')) {
                obj['eventData'] = ApiClient.convertToType(data['eventData'], {'String': Object});
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AbstractWebHookEventDto</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AbstractWebHookEventDto</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['eventType'] && !(typeof data['eventType'] === 'string' || data['eventType'] instanceof String)) {
            throw new Error("Expected the field `eventType` to be a primitive type in the JSON string but got " + data['eventType']);
        }
        // ensure the json data is a string
        if (data['eventDescription'] && !(typeof data['eventDescription'] === 'string' || data['eventDescription'] instanceof String)) {
            throw new Error("Expected the field `eventDescription` to be a primitive type in the JSON string but got " + data['eventDescription']);
        }

        return true;
    }


}



/**
 * @member {module:model/WebHookEventGroupType} webHookEventGroupType
 */
AbstractWebHookEventDto.prototype['webHookEventGroupType'] = undefined;

/**
 * @member {module:model/WebHookEventType} webHookEventType
 */
AbstractWebHookEventDto.prototype['webHookEventType'] = undefined;

/**
 * @member {Number} modelVersion
 */
AbstractWebHookEventDto.prototype['modelVersion'] = undefined;

/**
 * @member {String} eventType
 */
AbstractWebHookEventDto.prototype['eventType'] = undefined;

/**
 * @member {String} eventDescription
 */
AbstractWebHookEventDto.prototype['eventDescription'] = undefined;

/**
 * @member {Object.<String, Object>} eventData
 */
AbstractWebHookEventDto.prototype['eventData'] = undefined;

/**
 * @member {Number} accountId
 */
AbstractWebHookEventDto.prototype['accountId'] = undefined;






export default AbstractWebHookEventDto;

