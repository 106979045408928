/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import AppStringLocalization from '../model/AppStringLocalization';
import AppStringLocalizationExtended from '../model/AppStringLocalizationExtended';

/**
* AppString service.
* @module api/AppStringApi
* @version V1
*/
export default class AppStringApi {

    /**
    * Constructs a new AppStringApi. 
    * @alias module:api/AppStringApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getAppStrings operation.
     * @callback module:api/AppStringApi~getAppStringsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AppStringLocalization} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns all strings for specific accountId (default or overriden). Depends on parameter.  If default-only equals false then return overriden strings if there are some.
     * @param {Number} accountId 
     * @param {Object} opts Optional parameters
     * @param {String} [lang] 
     * @param {Boolean} [defaultOnly = false)] 
     * @param {module:api/AppStringApi~getAppStringsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AppStringLocalization}
     */
    getAppStrings(accountId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'accountId' is set
      if (accountId === undefined || accountId === null) {
        throw new Error("Missing the required parameter 'accountId' when calling getAppStrings");
      }

      let pathParams = {
        'accountId': accountId
      };
      let queryParams = {
        'lang': opts['lang'],
        'defaultOnly': opts['defaultOnly']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AppStringLocalization;
      return this.apiClient.callApi(
        '/appstrings/{accountId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getOverridableAppStrings operation.
     * @callback module:api/AppStringApi~getOverridableAppStringsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AppStringLocalizationExtended} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns all ONLY overriden strings for specific accountId
     * @param {Number} accountId 
     * @param {Object} opts Optional parameters
     * @param {String} [lang] 
     * @param {module:api/AppStringApi~getOverridableAppStringsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AppStringLocalizationExtended}
     */
    getOverridableAppStrings(accountId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'accountId' is set
      if (accountId === undefined || accountId === null) {
        throw new Error("Missing the required parameter 'accountId' when calling getOverridableAppStrings");
      }

      let pathParams = {
        'accountId': accountId
      };
      let queryParams = {
        'lang': opts['lang']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AppStringLocalizationExtended;
      return this.apiClient.callApi(
        '/appstrings/overridable/{accountId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateAppStrings operation.
     * @callback module:api/AppStringApi~updateAppStringsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AppStringLocalization} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates value of specified localization list
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/AppStringLocalization>} [appStringLocalization] 
     * @param {module:api/AppStringApi~updateAppStringsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AppStringLocalization}
     */
    updateAppStrings(opts, callback) {
      opts = opts || {};
      let postBody = opts['appStringLocalization'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AppStringLocalization;
      return this.apiClient.callApi(
        '/appstrings/overridable', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
