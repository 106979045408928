import AccountApi from "../generated/api/AccountApi";
import AuthenticationApi from "../generated/api/AuthenticationApi";
import UpdateAccountRequest from "../generated/model/UpdateAccountRequest";
import SaveSubUserRequest from "../generated/model/SaveSubUserRequest";
import AccountProofProperty from "../generated/model/AccountProofProperty";
import UpdateAccountStringOptionRequest from "../generated/model/UpdateAccountStringOptionRequest"

class AccountService {
    constructor() {
        this.AccountUsers = {
            lastUpdated: null,
            users: [],
        };
        this.AccountApi = new AccountApi();
        this.AuthenticationApi = new AuthenticationApi();
    }

    async GetTempToken() {
        return await window.$A.HandleApiResponse(
            this.AuthenticationApi.getTempToken()
        );
    }

    async DeleteUser(userIdToDelete, userIdToTransferTo) {
        return await window.$A.HandleApiResponse(
            this.AccountApi.deleteSubUser(
                userIdToDelete,
                userIdToTransferTo
            )
        );
    }

    async All() {
        this.AccountUsers.users = await window.$A.HandleApiResponse(
            this.AccountApi.allUsers(null)
        );
        return this.AccountUsers.users;
    }

    async Get() {
        return await window.$A.HandleApiResponse(this.AccountApi.getAccount(null));
    }

    async AccountStats() {
        return await window.$A.HandleApiResponse(
            this.AccountApi.getStats(null)
        );
    }

    async UploadLogoPhoto(dataBlob) {
        let file = new File([dataBlob], "account_logo_photo.png", {
            type: "image/png",
        });
        let body = await window.$A.HandleApiResponse(
            this.AccountApi.uploadAccountLogo({
                file: file,
            }),
            "Your Logo Has Been Updated"
        );
        if (body.length > 10) {
            return body;
        } else {
            return "";
        }
    }

    async UpdateAccount(name, noReplyEmail, timeZone, dateFormat, whiteLabelDomainName, smtpServerInfo) {
        let account = await window.$A.HandleApiResponse(
            this.AccountApi.updateAccount({
                updateAccountRequest: UpdateAccountRequest.constructFromObject({
                    name: name,
                    noReplyEmail: noReplyEmail,
                    timeZone: timeZone,
                    dateFormat: dateFormat,
                    whiteLabelDomainName: whiteLabelDomainName,
                    smtpServerInfo: smtpServerInfo
                }),
            }),
            "Account Updated"
        );
        return account;
    }

    async GetAllPendingInvites() {
        return await window.$A.HandleApiResponse(
            this.AccountApi.getAllPendingUserInvites(null),
            "",
            true
        );
    }

    async DeleteAccount() {
        let response = await window.$A.HandleApiResponse(
            this.AccountApi.deleteAccount(null),
            "Account Deleted"
        );
        if (response !== null) window.$A.LogOut(true);
    }

    async SaveAccountUser(user) {
        if (user.id > 0) {
            return await window.$A.HandleApiResponse(
                this.AccountApi.updateSubUser(user.id, {
                    saveSubUserRequest: SaveSubUserRequest.constructFromObject({
                        name: user.name,
                        title: user.title,
                        email: user.email,
                        phone: user.phone,
                        role: user.role || 2,
                        teams: user.teams
                    }),
                }),
                "User Updated"
            );
        } else {
            return await window.$A.HandleApiResponse(
                this.AccountApi.createSubUser({
                    saveSubUserRequest: SaveSubUserRequest.constructFromObject({
                        name: user.name,
                        title: user.title,
                        email: user.email,
                        phone: user.phone,
                        role: user.role || 2,
                        teams: user.teams
                    }),
                }),
                "User Created. An Invite Email Was Sent To " + user.email
            );
        }
    }

    async GetReviewStylesheet() {
        return this.GetAccountOption("review.stylesheet");
    }

    async SaveReviewStylesheet(style) {
        return this.SaveAccountOption("review.stylesheet", style);
    }

    async GetAccountOption(option) {
        return await window.$A.HandleApiResponse(this.AccountApi.getAccountOption({"option": option}), null, true);
    }

    async SaveAccountSmtpSendMethod(smtpServerInfo) {
        return await window.$A.HandleApiResponse(
            this.AccountApi.saveAccountSmtpSendMethod({smtpServerInfo})
        );
    }

    async SetAccountReviewScreenTheme(screenTheme) {
        return await window.$A.HandleApiResponse(
            this.AccountApi.setReviewScreenTheme({screenTheme})
        );
    }

    async SaveAccountPostmarkSendMethod() {
        return await window.$A.HandleApiResponse(
            this.AccountApi.saveAccountPostmarkSendMethod(null)
        );
    }

    async GetAccountSendMethodStatus(id, sendType) {
        return await window.$A.HandleApiResponse(
            this.AccountApi.getAccountSendMethodStatus(id, {sendType})
        );
    }

    async GetAccountAllSendMethods() {
        return await window.$A.HandleApiResponse(
            this.AccountApi.getAccountAllSendMethods(null), "", true);
    }

    async ResetAccountSendMethod(sendType) {
        return await window.$A.HandleApiResponse(
            this.AccountApi.resetAccountSendMethod({sendType})
        );
    }

    async SaveAccountOption(option, value) {
        return await window.$A.HandleApiResponse(
            this.AccountApi.setAccountOption(
                {
                    "updateAccountStringOptionRequest": UpdateAccountStringOptionRequest.constructFromObject({
                        option: option,
                        value: value
                    })
                },
            )
        );
    }

    async GetAllCustomProofProperties() {
        return await window.$A.HandleApiResponse(
            this.AccountApi.getAllCustomProofProperties(
                null), "", true);
    }

    async SaveCustomProperty(label, dataType, values, id, accountId) {
        if (id && id > 0) {
            return this.UpdateCustomProperty(id, accountId, values)
        }
        return await window.$A.HandleApiResponse(
            this.AccountApi.createCustomProperty(
                {
                    "accountProofProperty": AccountProofProperty.constructFromObject(
                        {
                            label,
                            dataType,
                            'enum': values,
                            id,
                            accountId
                        }
                    )
                }
            )
        )
    }

    async UpdateCustomProperty(id, accountId, values,) {
        return await window.$A.HandleApiResponse(
            this.AccountApi.updateCustomProperty(id, {
                "requestBody": values
            }, null))
    }

    async DeleteCustomProperty(id) {
        return await window.$A.HandleApiResponse(
            this.AccountApi.deleteCustomProperty(id),
            'Property Deleted',
        );
    }

    async GetChartMetrics(months = 12) {
        return await window.$A.HandleApiResponse(
            this.AccountApi.getChartMetrics(months), "", true
        );
    }

    async GetTimelineEvents() {
        return await window.$A.HandleApiResponse(this.AccountApi.getAccountTimeline(), "", true);
    }
}

export default AccountService;
