Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};
Date.prototype.addMin = function (m) {
  this.setTime(this.getTime() + m * 60 * 1000);
  return this;
};
window.$ = window.jQuery = require('jquery');

import Vue from "vue";
import router from "./router";
import initializeSentry from "./sentry";
import PortalVue from "portal-vue";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
import VueTour from "vue-tour";
import vIcon from "vue-icon";
import VueApexCharts from 'vue-apexcharts'
import Meta from 'vue-meta';

Vue.use(vIcon);
Vue.use(PortalVue);
Vue.use(BootstrapVue);
Vue.use(VueTour);
Vue.use(BootstrapVueIcons);
Vue.use(VueApexCharts);
Vue.use(Meta);

Vue.config.productionTip = false;

import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-tour/dist/vue-tour.css";
import "vue-select/dist/vue-select.css";
import "popper.js";
import "./assets/css/main.css";

let VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

//Global Libraries
import moment from "moment";
Object.defineProperty(Vue.prototype, "$moment", { value: moment });

import jquery from "jquery";
Object.defineProperty(Vue.prototype, "$", { value: jquery });

// Global Components
import pageheader from "./components/global/pageheader.vue";
import modal from "./components/modals/modal-confirmation.vue";
import VLink from "./components/global/VLink.vue";
import navigation from "./components/global/navigation.vue";
import copyright from "./components/global/copyright.vue";
import alertHeader from "./components/global/alert-header.vue";
import vSelect from "vue-select";

Vue.component("pageheader", pageheader);
Vue.component("modal", modal);
Vue.component("navigation", navigation);
Vue.component("copyright", copyright);
Vue.component("VLink", VLink);
Vue.component("v-select", vSelect);
Vue.component("alertHeader", alertHeader);

import App from "./App";

import AshoreAppServices from "./services/AshoreAppServices";
window.$A = new AshoreAppServices();


Object.defineProperty(Vue.prototype, "$A", { value: window.$A });

import { ApmVuePlugin } from '@elastic/apm-rum-vue'
// Vue.use(ApmVuePlugin, {
//   router,
//   config: {
//     serviceName: "web_client_"+window.$A.Env.envName,
//     // agent configuration
//     serverUrl: 'https://client-metrics.ashoreapp.com'
//   }
// })

initializeSentry(Vue);

window.$VI = new Vue({
  el: "#app",
  router,
  template: "<App/>",
  components: { App },
});

window.$A.VueApp = window.$VI;
