/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import NotificationFrequencyOption from './NotificationFrequencyOption';

/**
 * The UserSetting model module.
 * @module model/UserSetting
 * @version V1
 */
class UserSetting {
    /**
     * Constructs a new <code>UserSetting</code>.
     * @alias module:model/UserSetting
     */
    constructor() { 
        
        UserSetting.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UserSetting</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserSetting} obj Optional instance to populate.
     * @return {module:model/UserSetting} The populated <code>UserSetting</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserSetting();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('downloadFiles')) {
                obj['downloadFiles'] = ApiClient.convertToType(data['downloadFiles'], 'Boolean');
            }
            if (data.hasOwnProperty('approveIndividualFiles')) {
                obj['approveIndividualFiles'] = ApiClient.convertToType(data['approveIndividualFiles'], 'Boolean');
            }
            if (data.hasOwnProperty('viewAllComments')) {
                obj['viewAllComments'] = ApiClient.convertToType(data['viewAllComments'], 'Boolean');
            }
            if (data.hasOwnProperty('approveWithChanges')) {
                obj['approveWithChanges'] = ApiClient.convertToType(data['approveWithChanges'], 'Boolean');
            }
            if (data.hasOwnProperty('notifyFilesSent')) {
                obj['notifyFilesSent'] = ApiClient.convertToType(data['notifyFilesSent'], 'Boolean');
            }
            if (data.hasOwnProperty('notifyFilesApproved')) {
                obj['notifyFilesApproved'] = ApiClient.convertToType(data['notifyFilesApproved'], 'Boolean');
            }
            if (data.hasOwnProperty('notifyNewComments')) {
                obj['notifyNewComments'] = ApiClient.convertToType(data['notifyNewComments'], 'Boolean');
            }
            if (data.hasOwnProperty('notifyMeNewComments')) {
                obj['notifyMeNewComments'] = NotificationFrequencyOption.constructFromObject(data['notifyMeNewComments']);
            }
            if (data.hasOwnProperty('notifyMyApproversNewComments')) {
                obj['notifyMyApproversNewComments'] = NotificationFrequencyOption.constructFromObject(data['notifyMyApproversNewComments']);
            }
            if (data.hasOwnProperty('limitVersionVisibility')) {
                obj['limitVersionVisibility'] = ApiClient.convertToType(data['limitVersionVisibility'], 'Boolean');
            }
            if (data.hasOwnProperty('blockCommentingAfterProofDecision')) {
                obj['blockCommentingAfterProofDecision'] = ApiClient.convertToType(data['blockCommentingAfterProofDecision'], 'Boolean');
            }
            if (data.hasOwnProperty('reminderDays')) {
                obj['reminderDays'] = ApiClient.convertToType(data['reminderDays'], 'String');
            }
            if (data.hasOwnProperty('reminderTimeOfDay')) {
                obj['reminderTimeOfDay'] = ApiClient.convertToType(data['reminderTimeOfDay'], 'String');
            }
            if (data.hasOwnProperty('reminderTimeZone')) {
                obj['reminderTimeZone'] = ApiClient.convertToType(data['reminderTimeZone'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'Date');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserSetting</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserSetting</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['reminderDays'] && !(typeof data['reminderDays'] === 'string' || data['reminderDays'] instanceof String)) {
            throw new Error("Expected the field `reminderDays` to be a primitive type in the JSON string but got " + data['reminderDays']);
        }
        // ensure the json data is a string
        if (data['reminderTimeOfDay'] && !(typeof data['reminderTimeOfDay'] === 'string' || data['reminderTimeOfDay'] instanceof String)) {
            throw new Error("Expected the field `reminderTimeOfDay` to be a primitive type in the JSON string but got " + data['reminderTimeOfDay']);
        }
        // ensure the json data is a string
        if (data['reminderTimeZone'] && !(typeof data['reminderTimeZone'] === 'string' || data['reminderTimeZone'] instanceof String)) {
            throw new Error("Expected the field `reminderTimeZone` to be a primitive type in the JSON string but got " + data['reminderTimeZone']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
UserSetting.prototype['id'] = undefined;

/**
 * @member {Number} userId
 */
UserSetting.prototype['userId'] = undefined;

/**
 * @member {Number} accountId
 */
UserSetting.prototype['accountId'] = undefined;

/**
 * @member {Boolean} downloadFiles
 */
UserSetting.prototype['downloadFiles'] = undefined;

/**
 * @member {Boolean} approveIndividualFiles
 */
UserSetting.prototype['approveIndividualFiles'] = undefined;

/**
 * @member {Boolean} viewAllComments
 */
UserSetting.prototype['viewAllComments'] = undefined;

/**
 * @member {Boolean} approveWithChanges
 */
UserSetting.prototype['approveWithChanges'] = undefined;

/**
 * @member {Boolean} notifyFilesSent
 */
UserSetting.prototype['notifyFilesSent'] = undefined;

/**
 * @member {Boolean} notifyFilesApproved
 */
UserSetting.prototype['notifyFilesApproved'] = undefined;

/**
 * @member {Boolean} notifyNewComments
 */
UserSetting.prototype['notifyNewComments'] = undefined;

/**
 * @member {module:model/NotificationFrequencyOption} notifyMeNewComments
 */
UserSetting.prototype['notifyMeNewComments'] = undefined;

/**
 * @member {module:model/NotificationFrequencyOption} notifyMyApproversNewComments
 */
UserSetting.prototype['notifyMyApproversNewComments'] = undefined;

/**
 * @member {Boolean} limitVersionVisibility
 */
UserSetting.prototype['limitVersionVisibility'] = undefined;

/**
 * @member {Boolean} blockCommentingAfterProofDecision
 */
UserSetting.prototype['blockCommentingAfterProofDecision'] = undefined;

/**
 * @member {String} reminderDays
 */
UserSetting.prototype['reminderDays'] = undefined;

/**
 * @member {String} reminderTimeOfDay
 */
UserSetting.prototype['reminderTimeOfDay'] = undefined;

/**
 * @member {String} reminderTimeZone
 */
UserSetting.prototype['reminderTimeZone'] = undefined;

/**
 * @member {Date} updatedAt
 */
UserSetting.prototype['updatedAt'] = undefined;

/**
 * @member {Date} createdAt
 */
UserSetting.prototype['createdAt'] = undefined;






export default UserSetting;

