import PROOF_EVENTS from "./proofEvents";
import { TYPES_ENUM } from "./constants";
import EnvService from "../env.settings"

const { AnnotationCommentThreadUpdated } = PROOF_EVENTS
const { STRING } = TYPES_ENUM;

export const getAppEventProperty = (event, field, defaultValue) => {
    return _.get(event, `appEventObject.${field}`, defaultValue);
}

export const hasCommentAnnotation = (event) => {
    return !!getAppEventProperty(event, 'commentAnnotation') || !!getAppEventProperty(event, 'additionalData.commentAnnotation');
}

export const sortEventsChronologically = (events) => {
    return events.sort((a, b) => {
      const getCreatedAt = (event) => {
        let eventCreatedAt = event.createdAt;
        if (event.eventType === AnnotationCommentThreadUpdated && hasCommentAnnotation(event)) 
          eventCreatedAt = getAppEventProperty(event, "proofAnnotation.createdAt") || getAppEventProperty(event, "additionalData.commentAnnotation.createdAt");

        const localDateTime = new Date(eventCreatedAt);
        const isUTC = typeof eventCreatedAt === STRING && eventCreatedAt.endsWith('Z');

        const timezoneOffset = localDateTime.getTimezoneOffset() * 60000;
        const adjustedDateTime = isUTC ? localDateTime : new Date(localDateTime.getTime() - timezoneOffset);

        return adjustedDateTime;
      };

      return getCreatedAt(b) - getCreatedAt(a);
    });
}

const { webbApp } = EnvService.instance;
export const isCustomDomain = window.location.hostname !== new URL(webbApp).hostname && window.location.hostname !== "localhost";

export const updateFaviconAndTitle = ({ accountLogo = "", accountTitle = "" }) => {
  try {
    if (accountTitle) document.title = `${accountTitle} App`;

    if (accountLogo) {
      localStorage.setItem(
        `accountLogo${window.location.hostname}`,
        accountLogo
      );
      let link = document.querySelector("link[rel*='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "shortcut icon";
        link.type = "image/x-icon";
        document.head.appendChild(link);
      }
      link.href = accountLogo;
    }
  } catch (error) {
    console.error("Error updating Meta Details:", error);
  }
};

export const getAccountLogo = () => {
  return localStorage.getItem(`accountLogo${window.location.hostname}`) || 'img/logo-large.svg';
}
