/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import ConvertUrlIntoBase64ScreenShotRequest from '../model/ConvertUrlIntoBase64ScreenShotRequest';
import CreateAnnotationCommentRequest from '../model/CreateAnnotationCommentRequest';
import CreateAnnotationRequest from '../model/CreateAnnotationRequest';
import GetScreenShotFromVideoRequest from '../model/GetScreenShotFromVideoRequest';
import ProofAnnotation from '../model/ProofAnnotation';
import ProofAnnotationComment from '../model/ProofAnnotationComment';
import RedactorAnnotationFile from '../model/RedactorAnnotationFile';
import SuccessResponse from '../model/SuccessResponse';
import UpdateAnnotationCommentRequest from '../model/UpdateAnnotationCommentRequest';
import UpdateAnnotationRequest from '../model/UpdateAnnotationRequest';

/**
* Annotation service.
* @module api/AnnotationApi
* @version V1
*/
export default class AnnotationApi {

    /**
    * Constructs a new AnnotationApi. 
    * @alias module:api/AnnotationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the convertUrlIntoBase64ScreenShot operation.
     * @callback module:api/AnnotationApi~convertUrlIntoBase64ScreenShotCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ConvertUrlIntoBase64ScreenShotRequest} [convertUrlIntoBase64ScreenShotRequest] 
     * @param {module:api/AnnotationApi~convertUrlIntoBase64ScreenShotCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    convertUrlIntoBase64ScreenShot(opts, callback) {
      opts = opts || {};
      let postBody = opts['convertUrlIntoBase64ScreenShotRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/annotation/convertUrlIntoBase64ScreenShot', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createNewAnnotation operation.
     * @callback module:api/AnnotationApi~createNewAnnotationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofAnnotation} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CreateAnnotationRequest} [createAnnotationRequest] 
     * @param {module:api/AnnotationApi~createNewAnnotationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofAnnotation}
     */
    createNewAnnotation(opts, callback) {
      opts = opts || {};
      let postBody = opts['createAnnotationRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofAnnotation;
      return this.apiClient.callApi(
        '/annotation', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createNewAnnotationComment operation.
     * @callback module:api/AnnotationApi~createNewAnnotationCommentCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofAnnotationComment} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CreateAnnotationCommentRequest} [createAnnotationCommentRequest] 
     * @param {module:api/AnnotationApi~createNewAnnotationCommentCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofAnnotationComment}
     */
    createNewAnnotationComment(opts, callback) {
      opts = opts || {};
      let postBody = opts['createAnnotationCommentRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofAnnotationComment;
      return this.apiClient.callApi(
        '/annotation/comment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteAnnotation operation.
     * @callback module:api/AnnotationApi~deleteAnnotationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} annotationId 
     * @param {module:api/AnnotationApi~deleteAnnotationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteAnnotation(annotationId, callback) {
      let postBody = null;
      // verify the required parameter 'annotationId' is set
      if (annotationId === undefined || annotationId === null) {
        throw new Error("Missing the required parameter 'annotationId' when calling deleteAnnotation");
      }

      let pathParams = {
        'annotationId': annotationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/annotation/{annotationId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteAnnotationComment operation.
     * @callback module:api/AnnotationApi~deleteAnnotationCommentCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} commentId 
     * @param {module:api/AnnotationApi~deleteAnnotationCommentCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteAnnotationComment(commentId, callback) {
      let postBody = null;
      // verify the required parameter 'commentId' is set
      if (commentId === undefined || commentId === null) {
        throw new Error("Missing the required parameter 'commentId' when calling deleteAnnotationComment");
      }

      let pathParams = {
        'commentId': commentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/annotation/comment/{commentId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBase64ScreenShotFromVideo operation.
     * @callback module:api/AnnotationApi~getBase64ScreenShotFromVideoCallback
     * @param {String} error Error message, if any.
     * @param {Array.<String>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/GetScreenShotFromVideoRequest} [getScreenShotFromVideoRequest] 
     * @param {module:api/AnnotationApi~getBase64ScreenShotFromVideoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<String>}
     */
    getBase64ScreenShotFromVideo(opts, callback) {
      opts = opts || {};
      let postBody = opts['getScreenShotFromVideoRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/annotation/getBase64ScreenShotFromVideo', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateAnnotation operation.
     * @callback module:api/AnnotationApi~updateAnnotationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofAnnotation} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateAnnotationRequest} [updateAnnotationRequest] 
     * @param {module:api/AnnotationApi~updateAnnotationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofAnnotation}
     */
    updateAnnotation(opts, callback) {
      opts = opts || {};
      let postBody = opts['updateAnnotationRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofAnnotation;
      return this.apiClient.callApi(
        '/annotation', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateAnnotationComment operation.
     * @callback module:api/AnnotationApi~updateAnnotationCommentCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofAnnotationComment} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateAnnotationCommentRequest} [updateAnnotationCommentRequest] 
     * @param {module:api/AnnotationApi~updateAnnotationCommentCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofAnnotationComment}
     */
    updateAnnotationComment(opts, callback) {
      opts = opts || {};
      let postBody = opts['updateAnnotationCommentRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofAnnotationComment;
      return this.apiClient.callApi(
        '/annotation/comment', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateAnnotationResolvedStatus operation.
     * @callback module:api/AnnotationApi~updateAnnotationResolvedStatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofAnnotation} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} annotationId 
     * @param {Boolean} newStatus 
     * @param {module:api/AnnotationApi~updateAnnotationResolvedStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofAnnotation}
     */
    updateAnnotationResolvedStatus(annotationId, newStatus, callback) {
      let postBody = null;
      // verify the required parameter 'annotationId' is set
      if (annotationId === undefined || annotationId === null) {
        throw new Error("Missing the required parameter 'annotationId' when calling updateAnnotationResolvedStatus");
      }
      // verify the required parameter 'newStatus' is set
      if (newStatus === undefined || newStatus === null) {
        throw new Error("Missing the required parameter 'newStatus' when calling updateAnnotationResolvedStatus");
      }

      let pathParams = {
        'annotationId': annotationId,
        'newStatus': newStatus
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofAnnotation;
      return this.apiClient.callApi(
        '/annotation/{annotationId}/set-resolved-status/{newStatus}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadProofCommentFile_0 operation.
     * @callback module:api/AnnotationApi~uploadProofCommentFile_0Callback
     * @param {String} error Error message, if any.
     * @param {Object.<String, module:model/{String: RedactorAnnotationFile}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {File} body Upload Binary
     * @param {Object} opts Optional parameters
     * @param {String} [u = '')] 
     * @param {String} [a = '')] 
     * @param {module:api/AnnotationApi~uploadProofCommentFile_0Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, module:model/{String: RedactorAnnotationFile}>}
     */
    uploadProofCommentFile_0(publicProofId, body, opts, callback) {
      opts = opts || {};
      let postBody = body;
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling uploadProofCommentFile_0");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling uploadProofCommentFile_0");
      }

      let pathParams = {
        'publicProofId': publicProofId
      };
      let queryParams = {
        'u': opts['u'],
        'a': opts['a']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = {'String': RedactorAnnotationFile};
      return this.apiClient.callApi(
        '/annotation/comment-file/{publicProofId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
