/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Account from '../model/Account';
import AccountChartData from '../model/AccountChartData';
import AccountOption from '../model/AccountOption';
import AccountProofProperty from '../model/AccountProofProperty';
import ApiError from '../model/ApiError';
import AppEvent from '../model/AppEvent';
import EmailSendData from '../model/EmailSendData';
import PendingUserInvite from '../model/PendingUserInvite';
import RedactorFile from '../model/RedactorFile';
import RegisterRequest from '../model/RegisterRequest';
import SaveSubUserRequest from '../model/SaveSubUserRequest';
import SmtpServerInfo from '../model/SmtpServerInfo';
import SuccessResponse from '../model/SuccessResponse';
import UpdateAccountRequest from '../model/UpdateAccountRequest';
import UpdateAccountStringOptionRequest from '../model/UpdateAccountStringOptionRequest';
import User from '../model/User';

/**
* Account service.
* @module api/AccountApi
* @version V1
*/
export default class AccountApi {

    /**
    * Constructs a new AccountApi. 
    * @alias module:api/AccountApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the allUsers operation.
     * @callback module:api/AccountApi~allUsersCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/User>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~allUsersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/User>}
     */
    allUsers(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [User];
      return this.apiClient.callApi(
        '/account/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createCustomProperty operation.
     * @callback module:api/AccountApi~createCustomPropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccountProofProperty} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/AccountProofProperty} [accountProofProperty] 
     * @param {module:api/AccountApi~createCustomPropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccountProofProperty}
     */
    createCustomProperty(opts, callback) {
      opts = opts || {};
      let postBody = opts['accountProofProperty'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AccountProofProperty;
      return this.apiClient.callApi(
        '/account/custom-properties', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createSubUser operation.
     * @callback module:api/AccountApi~createSubUserCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/User>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveSubUserRequest} [saveSubUserRequest] 
     * @param {module:api/AccountApi~createSubUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/User>}
     */
    createSubUser(opts, callback) {
      opts = opts || {};
      let postBody = opts['saveSubUserRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [User];
      return this.apiClient.callApi(
        '/account/user', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteAccount operation.
     * @callback module:api/AccountApi~deleteAccountCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~deleteAccountCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteAccount(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteCustomProperty operation.
     * @callback module:api/AccountApi~deleteCustomPropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/AccountApi~deleteCustomPropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteCustomProperty(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteCustomProperty");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account/custom-properties/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteSubUser operation.
     * @callback module:api/AccountApi~deleteSubUserCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/User>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} userId 
     * @param {Number} inheritingUserId 
     * @param {module:api/AccountApi~deleteSubUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/User>}
     */
    deleteSubUser(userId, inheritingUserId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling deleteSubUser");
      }
      // verify the required parameter 'inheritingUserId' is set
      if (inheritingUserId === undefined || inheritingUserId === null) {
        throw new Error("Missing the required parameter 'inheritingUserId' when calling deleteSubUser");
      }

      let pathParams = {
        'userId': userId,
        'inheritingUserId': inheritingUserId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [User];
      return this.apiClient.callApi(
        '/account/user/{userId}/{inheritingUserId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccount operation.
     * @callback module:api/AccountApi~getAccountCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~getAccountCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    getAccount(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountAllSendMethods operation.
     * @callback module:api/AccountApi~getAccountAllSendMethodsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/EmailSendData>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~getAccountAllSendMethodsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/EmailSendData>}
     */
    getAccountAllSendMethods(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [EmailSendData];
      return this.apiClient.callApi(
        '/account/send-method', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountLogo operation.
     * @callback module:api/AccountApi~getAccountLogoCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} [domainName] 
     * @param {module:api/AccountApi~getAccountLogoCallback} callback The callback function, accepting three arguments: error, data, response
     */
    getAccountLogo(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'domainName': opts['domainName']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/account/logo', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountOption operation.
     * @callback module:api/AccountApi~getAccountOptionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccountOption} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} [option] 
     * @param {module:api/AccountApi~getAccountOptionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccountOption}
     */
    getAccountOption(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'option': opts['option']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AccountOption;
      return this.apiClient.callApi(
        '/account/option', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountOptions operation.
     * @callback module:api/AccountApi~getAccountOptionsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AccountOption>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~getAccountOptionsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AccountOption>}
     */
    getAccountOptions(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [AccountOption];
      return this.apiClient.callApi(
        '/account/options', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountRecord operation.
     * @callback module:api/AccountApi~getAccountRecordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} [domainName] 
     * @param {module:api/AccountApi~getAccountRecordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    getAccountRecord(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'domainName': opts['domainName']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/account/record', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountSendMethodStatus operation.
     * @callback module:api/AccountApi~getAccountSendMethodStatusCallback
     * @param {String} error Error message, if any.
     * @param {Boolean} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [sendType] 
     * @param {module:api/AccountApi~getAccountSendMethodStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Boolean}
     */
    getAccountSendMethodStatus(id, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getAccountSendMethodStatus");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'sendType': opts['sendType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/account/send-method/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountSmtp operation.
     * @callback module:api/AccountApi~getAccountSmtpCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~getAccountSmtpCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    getAccountSmtp(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account/smtp', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountTimeline operation.
     * @callback module:api/AccountApi~getAccountTimelineCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AppEvent>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} [page = 1)] 
     * @param {Number} [resultsPerPage = 100)] 
     * @param {module:api/AccountApi~getAccountTimelineCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AppEvent>}
     */
    getAccountTimeline(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'resultsPerPage': opts['resultsPerPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [AppEvent];
      return this.apiClient.callApi(
        '/account/timeline', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllCustomProofProperties operation.
     * @callback module:api/AccountApi~getAllCustomProofPropertiesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AccountProofProperty>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~getAllCustomProofPropertiesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AccountProofProperty>}
     */
    getAllCustomProofProperties(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [AccountProofProperty];
      return this.apiClient.callApi(
        '/account/custom-properties', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllPendingUserInvites operation.
     * @callback module:api/AccountApi~getAllPendingUserInvitesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/PendingUserInvite>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~getAllPendingUserInvitesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/PendingUserInvite>}
     */
    getAllPendingUserInvites(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [PendingUserInvite];
      return this.apiClient.callApi(
        '/account/users/all-pending-invites', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getChartMetrics operation.
     * @callback module:api/AccountApi~getChartMetricsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccountChartData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} months 
     * @param {module:api/AccountApi~getChartMetricsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccountChartData}
     */
    getChartMetrics(months, callback) {
      let postBody = null;
      // verify the required parameter 'months' is set
      if (months === undefined || months === null) {
        throw new Error("Missing the required parameter 'months' when calling getChartMetrics");
      }

      let pathParams = {
        'months': months
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AccountChartData;
      return this.apiClient.callApi(
        '/account/chart-metrics/{months}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getReviewScreenTheme operation.
     * @callback module:api/AccountApi~getReviewScreenThemeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~getReviewScreenThemeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    getReviewScreenTheme(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account/review-screen', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getStats operation.
     * @callback module:api/AccountApi~getStatsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~getStatsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    getStats(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account/stats', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the register operation.
     * @callback module:api/AccountApi~registerCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Registers A New Account
     * Sample request:                    POST /account/register      {         \"companyName\": \"ACME\",         \"name\": \"John Doe\",         \"email\": \"john@doe.com\",         \"password\": \"1234ABCD\",         \"phoneNumber\": \"123.123.1234\",         \"title\": \"Owner\",         \"timeZone\": \"America/Chicago\"      }
     * @param {Object} opts Optional parameters
     * @param {module:model/RegisterRequest} [registerRequest] 
     * @param {module:api/AccountApi~registerCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    register(opts, callback) {
      opts = opts || {};
      let postBody = opts['registerRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['application/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/account/register', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the resetAccountSendMethod operation.
     * @callback module:api/AccountApi~resetAccountSendMethodCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} [sendType] 
     * @param {module:api/AccountApi~resetAccountSendMethodCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    resetAccountSendMethod(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'sendType': opts['sendType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account/send-method/reset', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the saveAccountPostmarkSendMethod operation.
     * @callback module:api/AccountApi~saveAccountPostmarkSendMethodCallback
     * @param {String} error Error message, if any.
     * @param {Number} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~saveAccountPostmarkSendMethodCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Number}
     */
    saveAccountPostmarkSendMethod(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Number';
      return this.apiClient.callApi(
        '/account/send-method/settings/postmark', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the saveAccountSmtpSendMethod operation.
     * @callback module:api/AccountApi~saveAccountSmtpSendMethodCallback
     * @param {String} error Error message, if any.
     * @param {Number} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SmtpServerInfo} [smtpServerInfo] 
     * @param {module:api/AccountApi~saveAccountSmtpSendMethodCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Number}
     */
    saveAccountSmtpSendMethod(opts, callback) {
      opts = opts || {};
      let postBody = opts['smtpServerInfo'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Number';
      return this.apiClient.callApi(
        '/account/send-method/settings/smtp-provider', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setAccountOption operation.
     * @callback module:api/AccountApi~setAccountOptionCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AccountOption>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateAccountStringOptionRequest} [updateAccountStringOptionRequest] 
     * @param {module:api/AccountApi~setAccountOptionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AccountOption>}
     */
    setAccountOption(opts, callback) {
      opts = opts || {};
      let postBody = opts['updateAccountStringOptionRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [AccountOption];
      return this.apiClient.callApi(
        '/account/option', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setEmailPolicy operation.
     * @callback module:api/AccountApi~setEmailPolicyCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates the account's email policy.
     * Sample request:      Prevents ashore from creating and sending emails except the automatic reminders      POST /account/set-email-policy/-1      Prevents ashore from creating and sending all emails      POST /account/set-email-policy/-2      Allows emails to be sent from ashore      POST /account/set-email-policy/0
     * @param {Number} policy 
     * @param {module:api/AccountApi~setEmailPolicyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setEmailPolicy(policy, callback) {
      let postBody = null;
      // verify the required parameter 'policy' is set
      if (policy === undefined || policy === null) {
        throw new Error("Missing the required parameter 'policy' when calling setEmailPolicy");
      }

      let pathParams = {
        'policy': policy
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/account/set-email-policy/{policy}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setReviewScreenTheme operation.
     * @callback module:api/AccountApi~setReviewScreenThemeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} [screenTheme] 
     * @param {module:api/AccountApi~setReviewScreenThemeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    setReviewScreenTheme(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'screenTheme': opts['screenTheme']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account/review-screen', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateAccount operation.
     * @callback module:api/AccountApi~updateAccountCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Account} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateAccountRequest} [updateAccountRequest] 
     * @param {module:api/AccountApi~updateAccountCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Account}
     */
    updateAccount(opts, callback) {
      opts = opts || {};
      let postBody = opts['updateAccountRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Account;
      return this.apiClient.callApi(
        '/account', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateCustomProperty operation.
     * @callback module:api/AccountApi~updateCustomPropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccountProofProperty} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} [requestBody] 
     * @param {module:api/AccountApi~updateCustomPropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccountProofProperty}
     */
    updateCustomProperty(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateCustomProperty");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AccountProofProperty;
      return this.apiClient.callApi(
        '/account/custom-properties/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateSubUser operation.
     * @callback module:api/AccountApi~updateSubUserCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/User>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} userId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveSubUserRequest} [saveSubUserRequest] 
     * @param {module:api/AccountApi~updateSubUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/User>}
     */
    updateSubUser(userId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveSubUserRequest'];
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling updateSubUser");
      }

      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [User];
      return this.apiClient.callApi(
        '/account/user/{userId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadAccountLogo operation.
     * @callback module:api/AccountApi~uploadAccountLogoCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {File} [file] 
     * @param {module:api/AccountApi~uploadAccountLogoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    uploadAccountLogo(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/account/set-logo-image', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadProofCommentFile operation.
     * @callback module:api/AccountApi~uploadProofCommentFileCallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, module:model/{String: RedactorFile}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {File} body Upload Binary
     * @param {module:api/AccountApi~uploadProofCommentFileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, module:model/{String: RedactorFile}>}
     */
    uploadProofCommentFile(body, callback) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling uploadProofCommentFile");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = {'String': RedactorFile};
      return this.apiClient.callApi(
        '/account/public-file', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
