/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RegisterRequest model module.
 * @module model/RegisterRequest
 * @version V1
 */
class RegisterRequest {
    /**
     * Constructs a new <code>RegisterRequest</code>.
     * @alias module:model/RegisterRequest
     * @param companyName {String} 
     * @param name {String} 
     * @param email {String} 
     * @param password {String} 
     * @param phoneNumber {String} 
     * @param title {String} 
     * @param timeZone {String} 
     */
    constructor(companyName, name, email, password, phoneNumber, title, timeZone) { 
        
        RegisterRequest.initialize(this, companyName, name, email, password, phoneNumber, title, timeZone);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, companyName, name, email, password, phoneNumber, title, timeZone) { 
        obj['companyName'] = companyName;
        obj['name'] = name;
        obj['email'] = email;
        obj['password'] = password;
        obj['phoneNumber'] = phoneNumber;
        obj['title'] = title;
        obj['timeZone'] = timeZone;
    }

    /**
     * Constructs a <code>RegisterRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RegisterRequest} obj Optional instance to populate.
     * @return {module:model/RegisterRequest} The populated <code>RegisterRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RegisterRequest();

            if (data.hasOwnProperty('companyName')) {
                obj['companyName'] = ApiClient.convertToType(data['companyName'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('phoneNumber')) {
                obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('timeZone')) {
                obj['timeZone'] = ApiClient.convertToType(data['timeZone'], 'String');
            }
            if (data.hasOwnProperty('urlParamsJsonString')) {
                obj['urlParamsJsonString'] = ApiClient.convertToType(data['urlParamsJsonString'], 'String');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RegisterRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RegisterRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of RegisterRequest.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['companyName'] && !(typeof data['companyName'] === 'string' || data['companyName'] instanceof String)) {
            throw new Error("Expected the field `companyName` to be a primitive type in the JSON string but got " + data['companyName']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        // ensure the json data is a string
        if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
            throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
        }
        // ensure the json data is a string
        if (data['phoneNumber'] && !(typeof data['phoneNumber'] === 'string' || data['phoneNumber'] instanceof String)) {
            throw new Error("Expected the field `phoneNumber` to be a primitive type in the JSON string but got " + data['phoneNumber']);
        }
        // ensure the json data is a string
        if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
            throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
        }
        // ensure the json data is a string
        if (data['timeZone'] && !(typeof data['timeZone'] === 'string' || data['timeZone'] instanceof String)) {
            throw new Error("Expected the field `timeZone` to be a primitive type in the JSON string but got " + data['timeZone']);
        }
        // ensure the json data is a string
        if (data['urlParamsJsonString'] && !(typeof data['urlParamsJsonString'] === 'string' || data['urlParamsJsonString'] instanceof String)) {
            throw new Error("Expected the field `urlParamsJsonString` to be a primitive type in the JSON string but got " + data['urlParamsJsonString']);
        }
        // ensure the json data is a string
        if (data['code'] && !(typeof data['code'] === 'string' || data['code'] instanceof String)) {
            throw new Error("Expected the field `code` to be a primitive type in the JSON string but got " + data['code']);
        }

        return true;
    }


}

RegisterRequest.RequiredProperties = ["companyName", "name", "email", "password", "phoneNumber", "title", "timeZone"];

/**
 * @member {String} companyName
 */
RegisterRequest.prototype['companyName'] = undefined;

/**
 * @member {String} name
 */
RegisterRequest.prototype['name'] = undefined;

/**
 * @member {String} email
 */
RegisterRequest.prototype['email'] = undefined;

/**
 * @member {String} password
 */
RegisterRequest.prototype['password'] = undefined;

/**
 * @member {String} phoneNumber
 */
RegisterRequest.prototype['phoneNumber'] = undefined;

/**
 * @member {String} title
 */
RegisterRequest.prototype['title'] = undefined;

/**
 * @member {String} timeZone
 */
RegisterRequest.prototype['timeZone'] = undefined;

/**
 * @member {String} urlParamsJsonString
 */
RegisterRequest.prototype['urlParamsJsonString'] = undefined;

/**
 * @member {String} code
 */
RegisterRequest.prototype['code'] = undefined;






export default RegisterRequest;

