/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The HostString model module.
 * @module model/HostString
 * @version V1
 */
class HostString {
    /**
     * Constructs a new <code>HostString</code>.
     * @alias module:model/HostString
     */
    constructor() { 
        
        HostString.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>HostString</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/HostString} obj Optional instance to populate.
     * @return {module:model/HostString} The populated <code>HostString</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new HostString();

            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'String');
            }
            if (data.hasOwnProperty('hasValue')) {
                obj['hasValue'] = ApiClient.convertToType(data['hasValue'], 'Boolean');
            }
            if (data.hasOwnProperty('host')) {
                obj['host'] = ApiClient.convertToType(data['host'], 'String');
            }
            if (data.hasOwnProperty('port')) {
                obj['port'] = ApiClient.convertToType(data['port'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>HostString</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>HostString</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['value'] && !(typeof data['value'] === 'string' || data['value'] instanceof String)) {
            throw new Error("Expected the field `value` to be a primitive type in the JSON string but got " + data['value']);
        }
        // ensure the json data is a string
        if (data['host'] && !(typeof data['host'] === 'string' || data['host'] instanceof String)) {
            throw new Error("Expected the field `host` to be a primitive type in the JSON string but got " + data['host']);
        }

        return true;
    }


}



/**
 * @member {String} value
 */
HostString.prototype['value'] = undefined;

/**
 * @member {Boolean} hasValue
 */
HostString.prototype['hasValue'] = undefined;

/**
 * @member {String} host
 */
HostString.prototype['host'] = undefined;

/**
 * @member {Number} port
 */
HostString.prototype['port'] = undefined;






export default HostString;

