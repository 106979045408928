/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofRequirement from './ProofRequirement';

/**
 * The ProofChecklist model module.
 * @module model/ProofChecklist
 * @version V1
 */
class ProofChecklist {
    /**
     * Constructs a new <code>ProofChecklist</code>.
     * @alias module:model/ProofChecklist
     */
    constructor() { 
        
        ProofChecklist.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofChecklist</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofChecklist} obj Optional instance to populate.
     * @return {module:model/ProofChecklist} The populated <code>ProofChecklist</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofChecklist();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [ProofRequirement]);
            }
            if (data.hasOwnProperty('accountWide')) {
                obj['accountWide'] = ApiClient.convertToType(data['accountWide'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProofChecklist</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProofChecklist</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        if (data['items']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['items'])) {
                throw new Error("Expected the field `items` to be an array in the JSON data but got " + data['items']);
            }
            // validate the optional field `items` (array)
            for (const item of data['items']) {
                ProofRequirement.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
ProofChecklist.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ProofChecklist.prototype['name'] = undefined;

/**
 * @member {Number} accountId
 */
ProofChecklist.prototype['accountId'] = undefined;

/**
 * @member {Array.<module:model/ProofRequirement>} items
 */
ProofChecklist.prototype['items'] = undefined;

/**
 * @member {Boolean} accountWide
 */
ProofChecklist.prototype['accountWide'] = undefined;






export default ProofChecklist;

