/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The EmailLog model module.
 * @module model/EmailLog
 * @version V1
 */
class EmailLog {
    /**
     * Constructs a new <code>EmailLog</code>.
     * @alias module:model/EmailLog
     */
    constructor() { 
        
        EmailLog.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>EmailLog</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/EmailLog} obj Optional instance to populate.
     * @return {module:model/EmailLog} The populated <code>EmailLog</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new EmailLog();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('emailTo')) {
                obj['emailTo'] = ApiClient.convertToType(data['emailTo'], 'String');
            }
            if (data.hasOwnProperty('emailSubject')) {
                obj['emailSubject'] = ApiClient.convertToType(data['emailSubject'], 'String');
            }
            if (data.hasOwnProperty('emailType')) {
                obj['emailType'] = ApiClient.convertToType(data['emailType'], 'Number');
            }
            if (data.hasOwnProperty('emailTypeStr')) {
                obj['emailTypeStr'] = ApiClient.convertToType(data['emailTypeStr'], 'String');
            }
            if (data.hasOwnProperty('textBody')) {
                obj['textBody'] = ApiClient.convertToType(data['textBody'], 'String');
            }
            if (data.hasOwnProperty('htmlBody')) {
                obj['htmlBody'] = ApiClient.convertToType(data['htmlBody'], 'String');
            }
            if (data.hasOwnProperty('emailFrom')) {
                obj['emailFrom'] = ApiClient.convertToType(data['emailFrom'], 'String');
            }
            if (data.hasOwnProperty('sendStatus')) {
                obj['sendStatus'] = ApiClient.convertToType(data['sendStatus'], 'String');
            }
            if (data.hasOwnProperty('sendMethod')) {
                obj['sendMethod'] = ApiClient.convertToType(data['sendMethod'], 'String');
            }
            if (data.hasOwnProperty('sendResponse')) {
                obj['sendResponse'] = ApiClient.convertToType(data['sendResponse'], 'String');
            }
            if (data.hasOwnProperty('sendMethodId')) {
                obj['sendMethodId'] = ApiClient.convertToType(data['sendMethodId'], 'String');
            }
            if (data.hasOwnProperty('sendTag')) {
                obj['sendTag'] = ApiClient.convertToType(data['sendTag'], 'String');
            }
            if (data.hasOwnProperty('sendJobId')) {
                obj['sendJobId'] = ApiClient.convertToType(data['sendJobId'], 'String');
            }
            if (data.hasOwnProperty('dupeHashKey')) {
                obj['dupeHashKey'] = ApiClient.convertToType(data['dupeHashKey'], 'String');
            }
            if (data.hasOwnProperty('sentAt')) {
                obj['sentAt'] = ApiClient.convertToType(data['sentAt'], 'Date');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('ashoreSendId')) {
                obj['ashoreSendId'] = ApiClient.convertToType(data['ashoreSendId'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>EmailLog</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>EmailLog</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['emailTo'] && !(typeof data['emailTo'] === 'string' || data['emailTo'] instanceof String)) {
            throw new Error("Expected the field `emailTo` to be a primitive type in the JSON string but got " + data['emailTo']);
        }
        // ensure the json data is a string
        if (data['emailSubject'] && !(typeof data['emailSubject'] === 'string' || data['emailSubject'] instanceof String)) {
            throw new Error("Expected the field `emailSubject` to be a primitive type in the JSON string but got " + data['emailSubject']);
        }
        // ensure the json data is a string
        if (data['emailTypeStr'] && !(typeof data['emailTypeStr'] === 'string' || data['emailTypeStr'] instanceof String)) {
            throw new Error("Expected the field `emailTypeStr` to be a primitive type in the JSON string but got " + data['emailTypeStr']);
        }
        // ensure the json data is a string
        if (data['textBody'] && !(typeof data['textBody'] === 'string' || data['textBody'] instanceof String)) {
            throw new Error("Expected the field `textBody` to be a primitive type in the JSON string but got " + data['textBody']);
        }
        // ensure the json data is a string
        if (data['htmlBody'] && !(typeof data['htmlBody'] === 'string' || data['htmlBody'] instanceof String)) {
            throw new Error("Expected the field `htmlBody` to be a primitive type in the JSON string but got " + data['htmlBody']);
        }
        // ensure the json data is a string
        if (data['emailFrom'] && !(typeof data['emailFrom'] === 'string' || data['emailFrom'] instanceof String)) {
            throw new Error("Expected the field `emailFrom` to be a primitive type in the JSON string but got " + data['emailFrom']);
        }
        // ensure the json data is a string
        if (data['sendStatus'] && !(typeof data['sendStatus'] === 'string' || data['sendStatus'] instanceof String)) {
            throw new Error("Expected the field `sendStatus` to be a primitive type in the JSON string but got " + data['sendStatus']);
        }
        // ensure the json data is a string
        if (data['sendMethod'] && !(typeof data['sendMethod'] === 'string' || data['sendMethod'] instanceof String)) {
            throw new Error("Expected the field `sendMethod` to be a primitive type in the JSON string but got " + data['sendMethod']);
        }
        // ensure the json data is a string
        if (data['sendResponse'] && !(typeof data['sendResponse'] === 'string' || data['sendResponse'] instanceof String)) {
            throw new Error("Expected the field `sendResponse` to be a primitive type in the JSON string but got " + data['sendResponse']);
        }
        // ensure the json data is a string
        if (data['sendMethodId'] && !(typeof data['sendMethodId'] === 'string' || data['sendMethodId'] instanceof String)) {
            throw new Error("Expected the field `sendMethodId` to be a primitive type in the JSON string but got " + data['sendMethodId']);
        }
        // ensure the json data is a string
        if (data['sendTag'] && !(typeof data['sendTag'] === 'string' || data['sendTag'] instanceof String)) {
            throw new Error("Expected the field `sendTag` to be a primitive type in the JSON string but got " + data['sendTag']);
        }
        // ensure the json data is a string
        if (data['sendJobId'] && !(typeof data['sendJobId'] === 'string' || data['sendJobId'] instanceof String)) {
            throw new Error("Expected the field `sendJobId` to be a primitive type in the JSON string but got " + data['sendJobId']);
        }
        // ensure the json data is a string
        if (data['dupeHashKey'] && !(typeof data['dupeHashKey'] === 'string' || data['dupeHashKey'] instanceof String)) {
            throw new Error("Expected the field `dupeHashKey` to be a primitive type in the JSON string but got " + data['dupeHashKey']);
        }
        // ensure the json data is a string
        if (data['ashoreSendId'] && !(typeof data['ashoreSendId'] === 'string' || data['ashoreSendId'] instanceof String)) {
            throw new Error("Expected the field `ashoreSendId` to be a primitive type in the JSON string but got " + data['ashoreSendId']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
EmailLog.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
EmailLog.prototype['accountId'] = undefined;

/**
 * @member {Number} proofId
 */
EmailLog.prototype['proofId'] = undefined;

/**
 * @member {Number} proofVersionId
 */
EmailLog.prototype['proofVersionId'] = undefined;

/**
 * @member {Number} approverId
 */
EmailLog.prototype['approverId'] = undefined;

/**
 * @member {String} emailTo
 */
EmailLog.prototype['emailTo'] = undefined;

/**
 * @member {String} emailSubject
 */
EmailLog.prototype['emailSubject'] = undefined;

/**
 * @member {Number} emailType
 */
EmailLog.prototype['emailType'] = undefined;

/**
 * @member {String} emailTypeStr
 */
EmailLog.prototype['emailTypeStr'] = undefined;

/**
 * @member {String} textBody
 */
EmailLog.prototype['textBody'] = undefined;

/**
 * @member {String} htmlBody
 */
EmailLog.prototype['htmlBody'] = undefined;

/**
 * @member {String} emailFrom
 */
EmailLog.prototype['emailFrom'] = undefined;

/**
 * @member {String} sendStatus
 */
EmailLog.prototype['sendStatus'] = undefined;

/**
 * @member {String} sendMethod
 */
EmailLog.prototype['sendMethod'] = undefined;

/**
 * @member {String} sendResponse
 */
EmailLog.prototype['sendResponse'] = undefined;

/**
 * @member {String} sendMethodId
 */
EmailLog.prototype['sendMethodId'] = undefined;

/**
 * @member {String} sendTag
 */
EmailLog.prototype['sendTag'] = undefined;

/**
 * @member {String} sendJobId
 */
EmailLog.prototype['sendJobId'] = undefined;

/**
 * @member {String} dupeHashKey
 */
EmailLog.prototype['dupeHashKey'] = undefined;

/**
 * @member {Date} sentAt
 */
EmailLog.prototype['sentAt'] = undefined;

/**
 * @member {Date} createdAt
 */
EmailLog.prototype['createdAt'] = undefined;

/**
 * @member {String} ashoreSendId
 */
EmailLog.prototype['ashoreSendId'] = undefined;






export default EmailLog;

