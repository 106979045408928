/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SubscriptionState model module.
 * @module model/SubscriptionState
 * @version V1
 */
class SubscriptionState {
    /**
     * Constructs a new <code>SubscriptionState</code>.
     * @alias module:model/SubscriptionState
     */
    constructor() { 
        
        SubscriptionState.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SubscriptionState</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SubscriptionState} obj Optional instance to populate.
     * @return {module:model/SubscriptionState} The populated <code>SubscriptionState</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SubscriptionState();

            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
            if (data.hasOwnProperty('countOfRedeemedCodesOfTheSameSku')) {
                obj['countOfRedeemedCodesOfTheSameSku'] = ApiClient.convertToType(data['countOfRedeemedCodesOfTheSameSku'], 'Number');
            }
            if (data.hasOwnProperty('isLifeTime')) {
                obj['isLifeTime'] = ApiClient.convertToType(data['isLifeTime'], 'Boolean');
            }
            if (data.hasOwnProperty('isUnlimited')) {
                obj['isUnlimited'] = ApiClient.convertToType(data['isUnlimited'], 'Boolean');
            }
            if (data.hasOwnProperty('isSuspended')) {
                obj['isSuspended'] = ApiClient.convertToType(data['isSuspended'], 'Boolean');
            }
            if (data.hasOwnProperty('isPastDue')) {
                obj['isPastDue'] = ApiClient.convertToType(data['isPastDue'], 'Boolean');
            }
            if (data.hasOwnProperty('isOutOfFiles')) {
                obj['isOutOfFiles'] = ApiClient.convertToType(data['isOutOfFiles'], 'Boolean');
            }
            if (data.hasOwnProperty('isOutOfStorage')) {
                obj['isOutOfStorage'] = ApiClient.convertToType(data['isOutOfStorage'], 'Boolean');
            }
            if (data.hasOwnProperty('stackable')) {
                obj['stackable'] = ApiClient.convertToType(data['stackable'], 'Boolean');
            }
            if (data.hasOwnProperty('subState')) {
                obj['subState'] = ApiClient.convertToType(data['subState'], 'String');
            }
            if (data.hasOwnProperty('whiteLabelEnabled')) {
                obj['whiteLabelEnabled'] = ApiClient.convertToType(data['whiteLabelEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('domainWhiteLabelEnabled')) {
                obj['domainWhiteLabelEnabled'] = ApiClient.convertToType(data['domainWhiteLabelEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('canSendANewFile')) {
                obj['canSendANewFile'] = ApiClient.convertToType(data['canSendANewFile'], 'Boolean');
            }
            if (data.hasOwnProperty('canNotSendFileReason')) {
                obj['canNotSendFileReason'] = ApiClient.convertToType(data['canNotSendFileReason'], 'String');
            }
            if (data.hasOwnProperty('remainingFilesOnPlanThisCycle')) {
                obj['remainingFilesOnPlanThisCycle'] = ApiClient.convertToType(data['remainingFilesOnPlanThisCycle'], 'Number');
            }
            if (data.hasOwnProperty('userLimit')) {
                obj['userLimit'] = ApiClient.convertToType(data['userLimit'], 'Number');
            }
            if (data.hasOwnProperty('gbLimit')) {
                obj['gbLimit'] = ApiClient.convertToType(data['gbLimit'], 'Number');
            }
            if (data.hasOwnProperty('curGbUsage')) {
                obj['curGbUsage'] = ApiClient.convertToType(data['curGbUsage'], 'Number');
            }
            if (data.hasOwnProperty('remainingGb')) {
                obj['remainingGb'] = ApiClient.convertToType(data['remainingGb'], 'Number');
            }
            if (data.hasOwnProperty('remainingUsers')) {
                obj['remainingUsers'] = ApiClient.convertToType(data['remainingUsers'], 'Number');
            }
            if (data.hasOwnProperty('userCount')) {
                obj['userCount'] = ApiClient.convertToType(data['userCount'], 'Number');
            }
            if (data.hasOwnProperty('maxFilesToSend')) {
                obj['maxFilesToSend'] = ApiClient.convertToType(data['maxFilesToSend'], 'Number');
            }
            if (data.hasOwnProperty('filesSentThisCycle')) {
                obj['filesSentThisCycle'] = ApiClient.convertToType(data['filesSentThisCycle'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SubscriptionState</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SubscriptionState</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['sku'] && !(typeof data['sku'] === 'string' || data['sku'] instanceof String)) {
            throw new Error("Expected the field `sku` to be a primitive type in the JSON string but got " + data['sku']);
        }
        // ensure the json data is a string
        if (data['subState'] && !(typeof data['subState'] === 'string' || data['subState'] instanceof String)) {
            throw new Error("Expected the field `subState` to be a primitive type in the JSON string but got " + data['subState']);
        }
        // ensure the json data is a string
        if (data['canNotSendFileReason'] && !(typeof data['canNotSendFileReason'] === 'string' || data['canNotSendFileReason'] instanceof String)) {
            throw new Error("Expected the field `canNotSendFileReason` to be a primitive type in the JSON string but got " + data['canNotSendFileReason']);
        }

        return true;
    }


}



/**
 * @member {String} sku
 */
SubscriptionState.prototype['sku'] = undefined;

/**
 * @member {Number} countOfRedeemedCodesOfTheSameSku
 */
SubscriptionState.prototype['countOfRedeemedCodesOfTheSameSku'] = undefined;

/**
 * @member {Boolean} isLifeTime
 */
SubscriptionState.prototype['isLifeTime'] = undefined;

/**
 * @member {Boolean} isUnlimited
 */
SubscriptionState.prototype['isUnlimited'] = undefined;

/**
 * @member {Boolean} isSuspended
 */
SubscriptionState.prototype['isSuspended'] = undefined;

/**
 * @member {Boolean} isPastDue
 */
SubscriptionState.prototype['isPastDue'] = undefined;

/**
 * @member {Boolean} isOutOfFiles
 */
SubscriptionState.prototype['isOutOfFiles'] = undefined;

/**
 * @member {Boolean} isOutOfStorage
 */
SubscriptionState.prototype['isOutOfStorage'] = undefined;

/**
 * @member {Boolean} stackable
 */
SubscriptionState.prototype['stackable'] = undefined;

/**
 * @member {String} subState
 */
SubscriptionState.prototype['subState'] = undefined;

/**
 * @member {Boolean} whiteLabelEnabled
 */
SubscriptionState.prototype['whiteLabelEnabled'] = undefined;

/**
 * @member {Boolean} domainWhiteLabelEnabled
 */
SubscriptionState.prototype['domainWhiteLabelEnabled'] = undefined;

/**
 * @member {Boolean} canSendANewFile
 */
SubscriptionState.prototype['canSendANewFile'] = undefined;

/**
 * @member {String} canNotSendFileReason
 */
SubscriptionState.prototype['canNotSendFileReason'] = undefined;

/**
 * @member {Number} remainingFilesOnPlanThisCycle
 */
SubscriptionState.prototype['remainingFilesOnPlanThisCycle'] = undefined;

/**
 * @member {Number} userLimit
 */
SubscriptionState.prototype['userLimit'] = undefined;

/**
 * @member {Number} gbLimit
 */
SubscriptionState.prototype['gbLimit'] = undefined;

/**
 * @member {Number} curGbUsage
 */
SubscriptionState.prototype['curGbUsage'] = undefined;

/**
 * @member {Number} remainingGb
 */
SubscriptionState.prototype['remainingGb'] = undefined;

/**
 * @member {Number} remainingUsers
 */
SubscriptionState.prototype['remainingUsers'] = undefined;

/**
 * @member {Number} userCount
 */
SubscriptionState.prototype['userCount'] = undefined;

/**
 * @member {Number} maxFilesToSend
 */
SubscriptionState.prototype['maxFilesToSend'] = undefined;

/**
 * @member {Number} filesSentThisCycle
 */
SubscriptionState.prototype['filesSentThisCycle'] = undefined;






export default SubscriptionState;

