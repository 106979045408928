/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AccountBannerItem model module.
 * @module model/AccountBannerItem
 * @version V1
 */
class AccountBannerItem {
    /**
     * Constructs a new <code>AccountBannerItem</code>.
     * @alias module:model/AccountBannerItem
     */
    constructor() { 
        
        AccountBannerItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AccountBannerItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AccountBannerItem} obj Optional instance to populate.
     * @return {module:model/AccountBannerItem} The populated <code>AccountBannerItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccountBannerItem();

            if (data.hasOwnProperty('uri')) {
                obj['uri'] = ApiClient.convertToType(data['uri'], 'String');
            }
            if (data.hasOwnProperty('callToAction')) {
                obj['callToAction'] = ApiClient.convertToType(data['callToAction'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('cssClass')) {
                obj['cssClass'] = ApiClient.convertToType(data['cssClass'], 'String');
            }
            if (data.hasOwnProperty('vueAlertVariant')) {
                obj['vueAlertVariant'] = ApiClient.convertToType(data['vueAlertVariant'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AccountBannerItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AccountBannerItem</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['uri'] && !(typeof data['uri'] === 'string' || data['uri'] instanceof String)) {
            throw new Error("Expected the field `uri` to be a primitive type in the JSON string but got " + data['uri']);
        }
        // ensure the json data is a string
        if (data['callToAction'] && !(typeof data['callToAction'] === 'string' || data['callToAction'] instanceof String)) {
            throw new Error("Expected the field `callToAction` to be a primitive type in the JSON string but got " + data['callToAction']);
        }
        // ensure the json data is a string
        if (data['text'] && !(typeof data['text'] === 'string' || data['text'] instanceof String)) {
            throw new Error("Expected the field `text` to be a primitive type in the JSON string but got " + data['text']);
        }
        // ensure the json data is a string
        if (data['cssClass'] && !(typeof data['cssClass'] === 'string' || data['cssClass'] instanceof String)) {
            throw new Error("Expected the field `cssClass` to be a primitive type in the JSON string but got " + data['cssClass']);
        }
        // ensure the json data is a string
        if (data['vueAlertVariant'] && !(typeof data['vueAlertVariant'] === 'string' || data['vueAlertVariant'] instanceof String)) {
            throw new Error("Expected the field `vueAlertVariant` to be a primitive type in the JSON string but got " + data['vueAlertVariant']);
        }

        return true;
    }


}



/**
 * @member {String} uri
 */
AccountBannerItem.prototype['uri'] = undefined;

/**
 * @member {String} callToAction
 */
AccountBannerItem.prototype['callToAction'] = undefined;

/**
 * @member {String} text
 */
AccountBannerItem.prototype['text'] = undefined;

/**
 * @member {String} cssClass
 */
AccountBannerItem.prototype['cssClass'] = undefined;

/**
 * @member {String} vueAlertVariant
 */
AccountBannerItem.prototype['vueAlertVariant'] = undefined;






export default AccountBannerItem;

