/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import StringStringArrayKeyValuePair from './StringStringArrayKeyValuePair';

/**
 * The ProofSearchOptions model module.
 * @module model/ProofSearchOptions
 * @version V1
 */
class ProofSearchOptions {
    /**
     * Constructs a new <code>ProofSearchOptions</code>.
     * @alias module:model/ProofSearchOptions
     */
    constructor() { 
        
        ProofSearchOptions.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofSearchOptions</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofSearchOptions} obj Optional instance to populate.
     * @return {module:model/ProofSearchOptions} The populated <code>ProofSearchOptions</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofSearchOptions();

            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('senderId')) {
                obj['senderId'] = ApiClient.convertToType(data['senderId'], 'Number');
            }
            if (data.hasOwnProperty('tagIds')) {
                obj['tagIds'] = ApiClient.convertToType(data['tagIds'], ['Number']);
            }
            if (data.hasOwnProperty('teamId')) {
                obj['teamId'] = ApiClient.convertToType(data['teamId'], 'Number');
            }
            if (data.hasOwnProperty('tagFilterId')) {
                obj['tagFilterId'] = ApiClient.convertToType(data['tagFilterId'], 'Number');
            }
            if (data.hasOwnProperty('workflowId')) {
                obj['workflowId'] = ApiClient.convertToType(data['workflowId'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'Number');
            }
            if (data.hasOwnProperty('overdue')) {
                obj['overdue'] = ApiClient.convertToType(data['overdue'], 'Boolean');
            }
            if (data.hasOwnProperty('archived')) {
                obj['archived'] = ApiClient.convertToType(data['archived'], 'Boolean');
            }
            if (data.hasOwnProperty('sortByDeadlineDesc')) {
                obj['sortByDeadlineDesc'] = ApiClient.convertToType(data['sortByDeadlineDesc'], 'Boolean');
            }
            if (data.hasOwnProperty('customFieldValues')) {
                obj['customFieldValues'] = ApiClient.convertToType(data['customFieldValues'], [StringStringArrayKeyValuePair]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProofSearchOptions</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProofSearchOptions</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['text'] && !(typeof data['text'] === 'string' || data['text'] instanceof String)) {
            throw new Error("Expected the field `text` to be a primitive type in the JSON string but got " + data['text']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['tagIds'])) {
            throw new Error("Expected the field `tagIds` to be an array in the JSON data but got " + data['tagIds']);
        }
        if (data['customFieldValues']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['customFieldValues'])) {
                throw new Error("Expected the field `customFieldValues` to be an array in the JSON data but got " + data['customFieldValues']);
            }
            // validate the optional field `customFieldValues` (array)
            for (const item of data['customFieldValues']) {
                StringStringArrayKeyValuePair.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {String} text
 */
ProofSearchOptions.prototype['text'] = undefined;

/**
 * @member {Number} proofId
 */
ProofSearchOptions.prototype['proofId'] = undefined;

/**
 * @member {Number} approverId
 */
ProofSearchOptions.prototype['approverId'] = undefined;

/**
 * @member {Number} senderId
 */
ProofSearchOptions.prototype['senderId'] = undefined;

/**
 * @member {Array.<Number>} tagIds
 */
ProofSearchOptions.prototype['tagIds'] = undefined;

/**
 * @member {Number} teamId
 */
ProofSearchOptions.prototype['teamId'] = undefined;

/**
 * @member {Number} tagFilterId
 */
ProofSearchOptions.prototype['tagFilterId'] = undefined;

/**
 * @member {Number} workflowId
 */
ProofSearchOptions.prototype['workflowId'] = undefined;

/**
 * @member {Number} status
 */
ProofSearchOptions.prototype['status'] = undefined;

/**
 * @member {Boolean} overdue
 */
ProofSearchOptions.prototype['overdue'] = undefined;

/**
 * @member {Boolean} archived
 */
ProofSearchOptions.prototype['archived'] = undefined;

/**
 * @member {Boolean} sortByDeadlineDesc
 */
ProofSearchOptions.prototype['sortByDeadlineDesc'] = undefined;

/**
 * @member {Array.<module:model/StringStringArrayKeyValuePair>} customFieldValues
 */
ProofSearchOptions.prototype['customFieldValues'] = undefined;






export default ProofSearchOptions;

