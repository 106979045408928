import TeamsApi from "../generated/api/TeamsApi";
import WriteTeamRequest from "../generated/model/WriteTeamRequest";
import UpdateTeamRequest from "../generated/model/UpdateTeamRequest";
import ReAssignUsersTeamRequest from "../generated/model/ReAssignUsersTeamRequest";

class TeamService {
    constructor() {
        this.TeamApi = new TeamsApi();
    }

    async CreateTeam(name) {
        return await window.$A.HandleApiResponse(
            this.TeamApi.createUsersTeam({
                writeTeamRequest: WriteTeamRequest.constructFromObject({name}),
            })
        );
    }

    async GetTeams() {
        return await window.$A.HandleApiResponse(
            this.TeamApi.getCurrentUserTeams()
        );
    }

    async DeleteTeam(teamId) {
        return await window.$A.HandleApiResponse(
            this.TeamApi.deleteUsersTeam(teamId)
        );
    }

    async ReAssignUsersToAnotherTeam(oldTeamId, newTeamId) {
        return await window.$A.HandleApiResponse(
            this.TeamApi.reAssignUsersToAnotherTeam(
                {reAssignUsersTeamRequest: ReAssignUsersTeamRequest.constructFromObject({oldTeamId, newTeamId})}
            )
        );
    }

    async UpdateTeam(teamId, name) {
        return await window.$A.HandleApiResponse(
            this.TeamApi.updateUsersTeam(
                teamId,
                {updateTeamRequest: UpdateTeamRequest.constructFromObject({name})}
            )
        );
    }

    async GetAccountTeams() {
        return await window.$A.HandleApiResponse(
            this.TeamApi.getAllTeamsForAccount()
        );
    }
}

export default TeamService;