import FeedbackApi from "../generated/api/FeedbackApi";
import FeedbackRequest from "../generated/model/FeedbackRequest";

class FeedbackService {
    constructor() {
        this.FeedbackApi = new FeedbackApi();
    }

    async CreateSlackMessage(slackMessage) {
        return await window.$A.HandleApiResponse(
            this.FeedbackApi.createSlackMessage(
                {
                    feedbackRequest: FeedbackRequest.constructFromObject(
                        slackMessage
                    ),
                },
                null
            ),
            "",
            true
        );
    }
}

export default FeedbackService;