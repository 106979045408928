/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import ProofChecklist from '../model/ProofChecklist';
import ProofRequirement from '../model/ProofRequirement';
import SaveChecklistRequest from '../model/SaveChecklistRequest';
import SaveProofRequirementRequest from '../model/SaveProofRequirementRequest';

/**
* ProofChecklist service.
* @module api/ProofChecklistApi
* @version V1
*/
export default class ProofChecklistApi {

    /**
    * Constructs a new ProofChecklistApi. 
    * @alias module:api/ProofChecklistApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the createProofChecklist operation.
     * @callback module:api/ProofChecklistApi~createProofChecklistCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofChecklist} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveChecklistRequest} [saveChecklistRequest] 
     * @param {module:api/ProofChecklistApi~createProofChecklistCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofChecklist}
     */
    createProofChecklist(opts, callback) {
      opts = opts || {};
      let postBody = opts['saveChecklistRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofChecklist;
      return this.apiClient.callApi(
        '/proof-checklist', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createProofChecklistProperty operation.
     * @callback module:api/ProofChecklistApi~createProofChecklistPropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofRequirement} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} checklistId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveProofRequirementRequest} [saveProofRequirementRequest] 
     * @param {module:api/ProofChecklistApi~createProofChecklistPropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofRequirement}
     */
    createProofChecklistProperty(checklistId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveProofRequirementRequest'];
      // verify the required parameter 'checklistId' is set
      if (checklistId === undefined || checklistId === null) {
        throw new Error("Missing the required parameter 'checklistId' when calling createProofChecklistProperty");
      }

      let pathParams = {
        'checklistId': checklistId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofRequirement;
      return this.apiClient.callApi(
        '/proof-checklist/{checklistId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteChecklistProperty operation.
     * @callback module:api/ProofChecklistApi~deleteChecklistPropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofRequirement} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} checklistId 
     * @param {Number} id 
     * @param {module:api/ProofChecklistApi~deleteChecklistPropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofRequirement}
     */
    deleteChecklistProperty(checklistId, id, callback) {
      let postBody = null;
      // verify the required parameter 'checklistId' is set
      if (checklistId === undefined || checklistId === null) {
        throw new Error("Missing the required parameter 'checklistId' when calling deleteChecklistProperty");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteChecklistProperty");
      }

      let pathParams = {
        'checklistId': checklistId,
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofRequirement;
      return this.apiClient.callApi(
        '/proof-checklist/{checklistId}/property/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteProofChecklist operation.
     * @callback module:api/ProofChecklistApi~deleteProofChecklistCallback
     * @param {String} error Error message, if any.
     * @param {Boolean} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/ProofChecklistApi~deleteProofChecklistCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Boolean}
     */
    deleteProofChecklist(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteProofChecklist");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/proof-checklist/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the duplicateChecklist operation.
     * @callback module:api/ProofChecklistApi~duplicateChecklistCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofChecklist} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} checklistId 
     * @param {module:api/ProofChecklistApi~duplicateChecklistCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofChecklist}
     */
    duplicateChecklist(checklistId, callback) {
      let postBody = null;
      // verify the required parameter 'checklistId' is set
      if (checklistId === undefined || checklistId === null) {
        throw new Error("Missing the required parameter 'checklistId' when calling duplicateChecklist");
      }

      let pathParams = {
        'checklistId': checklistId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofChecklist;
      return this.apiClient.callApi(
        '/proof-checklist/{checklistId}/duplicate', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountProofChecklists operation.
     * @callback module:api/ProofChecklistApi~getAccountProofChecklistsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofChecklist} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/ProofChecklistApi~getAccountProofChecklistsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofChecklist}
     */
    getAccountProofChecklists(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofChecklist;
      return this.apiClient.callApi(
        '/proof-checklists', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getChecklistProperty operation.
     * @callback module:api/ProofChecklistApi~getChecklistPropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofRequirement} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} checklistId 
     * @param {Number} id 
     * @param {module:api/ProofChecklistApi~getChecklistPropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofRequirement}
     */
    getChecklistProperty(checklistId, id, callback) {
      let postBody = null;
      // verify the required parameter 'checklistId' is set
      if (checklistId === undefined || checklistId === null) {
        throw new Error("Missing the required parameter 'checklistId' when calling getChecklistProperty");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getChecklistProperty");
      }

      let pathParams = {
        'checklistId': checklistId,
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofRequirement;
      return this.apiClient.callApi(
        '/proof-checklist/{checklistId}/property/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProofChecklist operation.
     * @callback module:api/ProofChecklistApi~getProofChecklistCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofChecklist} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/ProofChecklistApi~getProofChecklistCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofChecklist}
     */
    getProofChecklist(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getProofChecklist");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofChecklist;
      return this.apiClient.callApi(
        '/proof-checklist/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateProofChecklist operation.
     * @callback module:api/ProofChecklistApi~updateProofChecklistCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofChecklist} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveChecklistRequest} [saveChecklistRequest] 
     * @param {module:api/ProofChecklistApi~updateProofChecklistCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofChecklist}
     */
    updateProofChecklist(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveChecklistRequest'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateProofChecklist");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofChecklist;
      return this.apiClient.callApi(
        '/proof-checklist/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateProofChecklistProperty operation.
     * @callback module:api/ProofChecklistApi~updateProofChecklistPropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofRequirement} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} checklistId 
     * @param {Number} id 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveProofRequirementRequest} [saveProofRequirementRequest] 
     * @param {module:api/ProofChecklistApi~updateProofChecklistPropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofRequirement}
     */
    updateProofChecklistProperty(checklistId, id, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveProofRequirementRequest'];
      // verify the required parameter 'checklistId' is set
      if (checklistId === undefined || checklistId === null) {
        throw new Error("Missing the required parameter 'checklistId' when calling updateProofChecklistProperty");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateProofChecklistProperty");
      }

      let pathParams = {
        'checklistId': checklistId,
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofRequirement;
      return this.apiClient.callApi(
        '/proof-checklist/{checklistId}/property/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
