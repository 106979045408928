/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class MethodImplAttributes.
* @enum {}
* @readonly
*/
export default class MethodImplAttributes {
    
        /**
         * value: 0
         * @const
         */
        "0" = 0;

    
        /**
         * value: 1
         * @const
         */
        "1" = 1;

    
        /**
         * value: 2
         * @const
         */
        "2" = 2;

    
        /**
         * value: 3
         * @const
         */
        "3" = 3;

    
        /**
         * value: 4
         * @const
         */
        "4" = 4;

    
        /**
         * value: 8
         * @const
         */
        "8" = 8;

    
        /**
         * value: 16
         * @const
         */
        "16" = 16;

    
        /**
         * value: 32
         * @const
         */
        "32" = 32;

    
        /**
         * value: 64
         * @const
         */
        "64" = 64;

    
        /**
         * value: 128
         * @const
         */
        "128" = 128;

    
        /**
         * value: 256
         * @const
         */
        "256" = 256;

    
        /**
         * value: 512
         * @const
         */
        "512" = 512;

    
        /**
         * value: 4096
         * @const
         */
        "4096" = 4096;

    
        /**
         * value: 65535
         * @const
         */
        "65535" = 65535;

    

    /**
    * Returns a <code>MethodImplAttributes</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/MethodImplAttributes} The enum <code>MethodImplAttributes</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

