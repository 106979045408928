/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import AshoreUserRole from '../model/AshoreUserRole';
import ReAssignUsersRequest from '../model/ReAssignUsersRequest';
import SaveUserRoleRequest from '../model/SaveUserRoleRequest';
import SuccessResponse from '../model/SuccessResponse';

/**
* UserRoles service.
* @module api/UserRolesApi
* @version V1
*/
export default class UserRolesApi {

    /**
    * Constructs a new UserRolesApi. 
    * @alias module:api/UserRolesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the createAccountUserRole operation.
     * @callback module:api/UserRolesApi~createAccountUserRoleCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AshoreUserRole} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveUserRoleRequest} [saveUserRoleRequest] 
     * @param {module:api/UserRolesApi~createAccountUserRoleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AshoreUserRole}
     */
    createAccountUserRole(opts, callback) {
      opts = opts || {};
      let postBody = opts['saveUserRoleRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AshoreUserRole;
      return this.apiClient.callApi(
        '/account/role', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteRole operation.
     * @callback module:api/UserRolesApi~deleteRoleCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} roleId 
     * @param {module:api/UserRolesApi~deleteRoleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteRole(roleId, callback) {
      let postBody = null;
      // verify the required parameter 'roleId' is set
      if (roleId === undefined || roleId === null) {
        throw new Error("Missing the required parameter 'roleId' when calling deleteRole");
      }

      let pathParams = {
        'roleId': roleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account/role/{roleId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the duplicateRole operation.
     * @callback module:api/UserRolesApi~duplicateRoleCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AshoreUserRole} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} roleId 
     * @param {module:api/UserRolesApi~duplicateRoleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AshoreUserRole}
     */
    duplicateRole(roleId, callback) {
      let postBody = null;
      // verify the required parameter 'roleId' is set
      if (roleId === undefined || roleId === null) {
        throw new Error("Missing the required parameter 'roleId' when calling duplicateRole");
      }

      let pathParams = {
        'roleId': roleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AshoreUserRole;
      return this.apiClient.callApi(
        '/account/role/{roleId}/duplicate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountRoles operation.
     * @callback module:api/UserRolesApi~getAccountRolesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AshoreUserRole>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/UserRolesApi~getAccountRolesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AshoreUserRole>}
     */
    getAccountRoles(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [AshoreUserRole];
      return this.apiClient.callApi(
        '/account/roles/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the reAssignUsersToAnotherRole operation.
     * @callback module:api/UserRolesApi~reAssignUsersToAnotherRoleCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ReAssignUsersRequest} [reAssignUsersRequest] 
     * @param {module:api/UserRolesApi~reAssignUsersToAnotherRoleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    reAssignUsersToAnotherRole(opts, callback) {
      opts = opts || {};
      let postBody = opts['reAssignUsersRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account/role/reassign-users', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateAccountUserRole operation.
     * @callback module:api/UserRolesApi~updateAccountUserRoleCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AshoreUserRole} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} roleId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveUserRoleRequest} [saveUserRoleRequest] 
     * @param {module:api/UserRolesApi~updateAccountUserRoleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AshoreUserRole}
     */
    updateAccountUserRole(roleId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveUserRoleRequest'];
      // verify the required parameter 'roleId' is set
      if (roleId === undefined || roleId === null) {
        throw new Error("Missing the required parameter 'roleId' when calling updateAccountUserRole");
      }

      let pathParams = {
        'roleId': roleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AshoreUserRole;
      return this.apiClient.callApi(
        '/account/role/{roleId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
