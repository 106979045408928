/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AshoreUserShortInfo from './AshoreUserShortInfo';

/**
 * The AshoreUsersTeam model module.
 * @module model/AshoreUsersTeam
 * @version V1
 */
class AshoreUsersTeam {
    /**
     * Constructs a new <code>AshoreUsersTeam</code>.
     * @alias module:model/AshoreUsersTeam
     */
    constructor() { 
        
        AshoreUsersTeam.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AshoreUsersTeam</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AshoreUsersTeam} obj Optional instance to populate.
     * @return {module:model/AshoreUsersTeam} The populated <code>AshoreUsersTeam</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AshoreUsersTeam();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('teamOwner')) {
                obj['teamOwner'] = AshoreUserShortInfo.constructFromObject(data['teamOwner']);
            }
            if (data.hasOwnProperty('users')) {
                obj['users'] = ApiClient.convertToType(data['users'], [AshoreUserShortInfo]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AshoreUsersTeam</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AshoreUsersTeam</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // validate the optional field `teamOwner`
        if (data['teamOwner']) { // data not null
          AshoreUserShortInfo.validateJSON(data['teamOwner']);
        }
        if (data['users']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['users'])) {
                throw new Error("Expected the field `users` to be an array in the JSON data but got " + data['users']);
            }
            // validate the optional field `users` (array)
            for (const item of data['users']) {
                AshoreUserShortInfo.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
AshoreUsersTeam.prototype['id'] = undefined;

/**
 * @member {String} name
 */
AshoreUsersTeam.prototype['name'] = undefined;

/**
 * @member {module:model/AshoreUserShortInfo} teamOwner
 */
AshoreUsersTeam.prototype['teamOwner'] = undefined;

/**
 * @member {Array.<module:model/AshoreUserShortInfo>} users
 */
AshoreUsersTeam.prototype['users'] = undefined;






export default AshoreUsersTeam;

