import ProofFileApi from "../generated/api/ProofFileApi";
import EnvService from "../env.settings";

class ProofFileService {
    constructor() {
        this.ProofFileApi = new ProofFileApi();
    }

    async DownloadAll(
        publicProofId,
        proofVersionId,
        userToken,
        publicApproverId,
        fileName
    ) {
        let append =
            userToken !== undefined && userToken.length > 0
                ? "u=" + userToken
                : publicApproverId !== undefined && publicApproverId.length > 0
                    ? "a=" + publicApproverId
                    : "";
        let url =
            EnvService.instance.apiHost +
            "/pfv/z/a/" +
            publicProofId +
            "/" +
            proofVersionId +
            "?" +
            append;
        let a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        a.setAttribute("target", "_blank");
        a.setAttribute("href", url);
        a.setAttribute("download", fileName);
        a.click();
        document.body.removeChild(a);
    }

    async DownloadSingle(
        publicProofId,
        proofVersionId,
        proofFileId,
        userToken,
        publicApproverId,
        fileName
    ) {
        let append =
            userToken !== undefined && userToken.length > 0
                ? "u=" + userToken
                : publicApproverId !== undefined && publicApproverId.length > 0
                    ? "a=" + publicApproverId
                    : "";
        let url =
            EnvService.instance.apiHost +
            "/pfv/z/a/" +
            publicProofId +
            "/" +
            proofVersionId +
            "/" +
            proofFileId +
            "?" +
            append;
        let a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        a.setAttribute("target", "_blank");
        a.setAttribute("href", url);
        a.setAttribute("download", fileName);
        a.click();
        document.body.removeChild(a);
    }

    async WaitForThumbnailToLoad(proofFileId) {
        return await window.$A.Api.get(
            "/proof-version-file/" + proofFileId + "/long-poll-for-thumbnail",
            {
                headers: {
                    "Content-Type": "application/json",
                    AccessToken: window.$A.Auth.apiToken.token,
                },
                timeout: 170000,
            }
        )
            .then(function (data) {
                return data.data;
            })
            .catch(function (e) {
                console.log(e);
                return false;
            });
    }

    async UploadFile(
        proofId,
        proofVersionId,
        fileObj,
        uploadCompleteCallback,
        tries
    ) {
        fileObj.uploadProgress = 0;
        if (tries === undefined) {
            tries = 1;
        }
        if (tries === 2 && fileObj.error === "Please Try Again Later") {
            uploadCompleteCallback();
            return false;
        }
        if (tries === 3) {
            uploadCompleteCallback();
            return false;
        }
        let self = this;
        const data = new FormData();
        let secsGoneBySinceFileUploaded = 0;
        fileObj.walk = null;
        data.append("file", fileObj.file, fileObj.file.name);
        let response = await window.$A.Api.put(
            "/proof/" + proofId + "/proof-version/" + proofVersionId + "/file",
            data,
            {
                headers: {
                    "Cache-Control": "no-cache",
                    // 'Content-Type': fileObj.file.type,
                    "Content-Type": "multipart/form-data",
                    AccessToken: window.$A.Auth.apiToken.token,
                },
                timeout: 99999999,
                onUploadProgress: function (progressEvent) {
                    let p = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    if (p > 60) {
                        p = 60;
                        let up = function () {
                            clearTimeout(fileObj.walk);
                            secsGoneBySinceFileUploaded += 0.1;
                            let pp = 60 + secsGoneBySinceFileUploaded;
                            if (pp > 98.9) {
                                pp = 98.9;
                            }
                            fileObj.uploadProgress = pp;
                            fileObj.walk = setTimeout(up, 100);
                        };
                        up();
                    }
                    fileObj.uploadProgress = p;
                },
            }
        )
            .then(function (data) {
                clearTimeout(fileObj.walk);
                if (
                    data &&
                    data.data &&
                    data.data.id !== undefined &&
                    data.data.id > 0
                ) {
                    fileObj.id = data.data.id;
                    uploadCompleteCallback();
                    fileObj.uploadProgress = 100;
                    return true;
                }
                if (data && data.data && data.data.message !== undefined) {
                    fileObj.error = data.data.message;
                    if (fileObj.error === "You Have Run Out Of Files")
                        fileObj.noFilesLeft = true;
                }
                fileObj.uploadProgress = 0;
                return false;
            })
            .catch(function (e) {
                let data = e.response;
                console.log(e.response);
                clearTimeout(fileObj.walk);
                fileObj.err = e;
                fileObj.uploadProgress = 0;
                if (data && data.data && data.data.message !== undefined) {
                    fileObj.error = data.data.message;
                    if (fileObj.error === "You Have Run Out Of Files")
                        fileObj.noFilesLeft = true;
                }
                return false;
            });

        return response;

        // return await this.ProofFileApi.proofProofIdProofVersionProofVersionIdFilePost(proofId, proofVersionId, fileObj);
    }

    async UploadUrl(proofId, proofVersionId, fileObj, uploadCompleteCallback, isLiveWebProofing) {
        if (isLiveWebProofing === undefined) {
            isLiveWebProofing = false;
        }
        let response = await window.$A.Api.post(
            "/proof/" + proofId + "/proof-version/" + proofVersionId + "/url?isLiveWebProofing=" + isLiveWebProofing,
            '"' + fileObj.name + '"',
            {
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/json; charset=utf-8",
                    AccessToken: window.$A.Auth.apiToken.token,
                },
                timeout: 9999999,
            }
        )
            .then(function (data) {
                if (
                    data &&
                    data.data &&
                    data.data.id !== undefined &&
                    data.data.message === "You Have Run Out Of Files"
                ) {
                    fileObj.noFilesLeft = true;
                    fileObj.error = data.data.message;
                    return true;
                }
                if (
                    data &&
                    data.data &&
                    data.data.id !== undefined &&
                    data.data.id > 0
                ) {
                    fileObj.id = data.data.id;
                    uploadCompleteCallback();
                    fileObj.uploadProgress = 100;
                    return true;
                } else {
                    return false;
                }
            })
            .catch(function (e) {
                fileObj.uploadProgress = 0;
                uploadCompleteCallback();
                return false;
            });

        return response;

        // return await this.ProofFileApi.proofProofIdProofVersionProofVersionIdFilePost(proofId, proofVersionId, fileObj);
    }

    async FileUrlExists(url) {
        // console.log(url)
        if (url === "") {
            return false;
        }
        let reader = new XMLHttpRequest();
        reader.crossOrigin = "Anonymous";
        await reader.open("get", url += "?" + new Date().getTime(), true);
        reader.setRequestHeader("Range", "bytes=1-2");
        let result = false;

        function checkReadyState() {
            if (reader.readyState === 4) {
                //check to see whether request for the file failed or succeeded
                if (reader.status == 200 || reader.status === 0) {
                    result = true;
                }
            }
        }

        reader.onreadystatechange = checkReadyState;
        await reader.send();

        return result;
    }

    async DeleteProofVersionFile(proofId, proofVersionId, proofVersionFileId) {
        return await window.$A.HandleApiResponse(
            this.ProofFileApi.deleteProofFile(proofId, proofVersionId, proofVersionFileId),
            "Proof File Has Been Deleted",
            true
        );
    }

    async UpdateOrderForProofVersionFiles(proofId, proofVersionId, orderObj) {
        return await window.$A.HandleApiResponse(
            this.ProofFileApi.updateOrderForProofVersionFiles(
                proofId,
                proofVersionId,
                {
                    requestBody: orderObj,
                }
            ),
            "",
            true
        );
    }

    async UploadMessageFileReturnUrl(bytes, publicProofId, uploadCompleteCallback, uploadProgress) {
        if (publicProofId === undefined || publicProofId === null){
            publicProofId = "";
        }else{
            publicProofId = "/"+publicProofId;
        }
        window.$A.ApiWorking();
        const data = new FormData();
        data.append("file", bytes, "pasted_image");
        let response = await window.$A.Api.post(window.$A.Env.apiHost + "/proof/message-file"+publicProofId, data, {
            headers: {
                "Cache-Control": "no-cache",
                // 'Content-Type': fileObj.file.type,
                "Content-Type": "multipart/form-data",
                // 'AccessToken': window.$A.Auth.apiToken.token
                AccessToken: window.$A.Auth.apiToken.token,
            },
            timeout: 500 * 1000, //5min
            onUploadProgress: function (progressEvent) {
                let p = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if(uploadProgress!==undefined){
                    uploadProgress(p)
                }
            },
        })
            .then(function (data) {
                window.$A.ApiIdle();
                uploadCompleteCallback(
                    data && data.data && data.data["file-1"] !== undefined,
                    data
                );
            })
            .catch(function (e) {
                let data = e.response;
                console.log(e);
                uploadCompleteCallback(false, {});
                window.$A.ApiIdle();
            });
    }

}

export default ProofFileService;
