<script>
export default {
  name: "copyright",
  methods: {
    fixAtBottom: function () {
      return window.innerHeight < document.body.scrollHeight;
    }
  },
};
</script>

<template>
  <!-- Copyright -->
  <div :class="'ml-3' + (fixAtBottom ? ' fixed_footer' : '')">
    <div class="row">
      <div class="col-md-12 ">
        <span>©{{ new Date().getFullYear() }} Ashore App, LLC. All rights reserved.
          <a target="_blank" href="https://ashoreapp.com/privacy/"
          >Privacy and Terms.</a></span>
      </div>
    </div>
  </div>
  <!-- /Copyright -->
</template>

<style scoped>

.fixed_footer {
  position: fixed;
  bottom: 5px;
}

</style>
