export default {
    ProofCreated: 3,
    ProofSent: 4,
    ProofViewedByApprover: 5,
    ProofApprovedWithChanges: 6,
    ProofApproved: 7,
    ProofNotApproved: 8,
    ProofDeleted: 9,
    ProofArchived: 10,
    ProofUnArchived: 11,
    ProofNoteEvent: 32,
    EmailSent: 33,
    NewProofVersion: 34,
    UserOverWroteApproverStatus: 35,
    ProofViewedPublicly: 36,
    ProofVersionFilesDownloaded: 37,
    ApproverRegisteredToStageFromPublicLink: 38,
    ApproverRespondedWithFeedbackForProof: 39,
    WorkflowProofStageStarted: 40,
    ApproverAutoReminderQueued: 41,
    AccountAlertEvent: 43,
    ProofViewedByUser: 44,
    AnnotationCreated: 45,
    AnnotationCommentThreadUpdated: 49,
    AnnotationUpdated: 50,
    MentionedInAnnotationCommentThread: 52,
    ProofFileUploadedEvent: 55,
    ProofFileDeletedEvent: 56,
    ProofVersionHiddenEvent: 57,
    ProofVersionRevealedEvent: 58,
    FileProcessedEvent: 62,
};
