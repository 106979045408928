/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PipeWriter model module.
 * @module model/PipeWriter
 * @version V1
 */
class PipeWriter {
    /**
     * Constructs a new <code>PipeWriter</code>.
     * @alias module:model/PipeWriter
     */
    constructor() { 
        
        PipeWriter.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PipeWriter</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PipeWriter} obj Optional instance to populate.
     * @return {module:model/PipeWriter} The populated <code>PipeWriter</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PipeWriter();

            if (data.hasOwnProperty('canGetUnflushedBytes')) {
                obj['canGetUnflushedBytes'] = ApiClient.convertToType(data['canGetUnflushedBytes'], 'Boolean');
            }
            if (data.hasOwnProperty('unflushedBytes')) {
                obj['unflushedBytes'] = ApiClient.convertToType(data['unflushedBytes'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PipeWriter</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PipeWriter</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * @member {Boolean} canGetUnflushedBytes
 */
PipeWriter.prototype['canGetUnflushedBytes'] = undefined;

/**
 * @member {Number} unflushedBytes
 */
PipeWriter.prototype['unflushedBytes'] = undefined;






export default PipeWriter;

