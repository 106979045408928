/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AshoreTeamShortInfo from './AshoreTeamShortInfo';

/**
 * The SaveSubUserRequest model module.
 * @module model/SaveSubUserRequest
 * @version V1
 */
class SaveSubUserRequest {
    /**
     * Constructs a new <code>SaveSubUserRequest</code>.
     * @alias module:model/SaveSubUserRequest
     * @param name {String} 
     * @param email {String} 
     * @param title {String} 
     * @param phone {String} 
     * @param role {Number} 
     */
    constructor(name, email, title, phone, role) { 
        
        SaveSubUserRequest.initialize(this, name, email, title, phone, role);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, email, title, phone, role) { 
        obj['name'] = name;
        obj['email'] = email;
        obj['title'] = title;
        obj['phone'] = phone;
        obj['role'] = role;
    }

    /**
     * Constructs a <code>SaveSubUserRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SaveSubUserRequest} obj Optional instance to populate.
     * @return {module:model/SaveSubUserRequest} The populated <code>SaveSubUserRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SaveSubUserRequest();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'Number');
            }
            if (data.hasOwnProperty('teams')) {
                obj['teams'] = ApiClient.convertToType(data['teams'], [AshoreTeamShortInfo]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SaveSubUserRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SaveSubUserRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SaveSubUserRequest.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        // ensure the json data is a string
        if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
            throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
        }
        // ensure the json data is a string
        if (data['phone'] && !(typeof data['phone'] === 'string' || data['phone'] instanceof String)) {
            throw new Error("Expected the field `phone` to be a primitive type in the JSON string but got " + data['phone']);
        }
        if (data['teams']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['teams'])) {
                throw new Error("Expected the field `teams` to be an array in the JSON data but got " + data['teams']);
            }
            // validate the optional field `teams` (array)
            for (const item of data['teams']) {
                AshoreTeamShortInfo.validateJSON(item);
            };
        }

        return true;
    }


}

SaveSubUserRequest.RequiredProperties = ["name", "email", "title", "phone", "role"];

/**
 * @member {String} name
 */
SaveSubUserRequest.prototype['name'] = undefined;

/**
 * @member {String} email
 */
SaveSubUserRequest.prototype['email'] = undefined;

/**
 * @member {String} title
 */
SaveSubUserRequest.prototype['title'] = undefined;

/**
 * @member {String} phone
 */
SaveSubUserRequest.prototype['phone'] = undefined;

/**
 * @member {Number} role
 */
SaveSubUserRequest.prototype['role'] = undefined;

/**
 * @member {Array.<module:model/AshoreTeamShortInfo>} teams
 */
SaveSubUserRequest.prototype['teams'] = undefined;






export default SaveSubUserRequest;

