import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

class RealtimeCursorService {
    Connect(hub = "cursor_hub", reconnectTime = 7000, logLevel = LogLevel.Information) {
        return new HubConnectionBuilder()
            .withUrl(window.$A.Env.apiHost + "/" + hub, {
                transport: HttpTransportType.WebSockets,
                skipNegotiation: false,
            })
            .withAutomaticReconnect([reconnectTime])
            .configureLogging(logLevel)
            .build();
    }
}

export default RealtimeCursorService;
