/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import EmailAuthorization from './EmailAuthorization';
import EmailSendMethodType from './EmailSendMethodType';
import SmtpServerInfo from './SmtpServerInfo';

/**
 * The EmailConfiguration model module.
 * @module model/EmailConfiguration
 * @version V1
 */
class EmailConfiguration {
    /**
     * Constructs a new <code>EmailConfiguration</code>.
     * @alias module:model/EmailConfiguration
     */
    constructor() { 
        
        EmailConfiguration.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>EmailConfiguration</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/EmailConfiguration} obj Optional instance to populate.
     * @return {module:model/EmailConfiguration} The populated <code>EmailConfiguration</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new EmailConfiguration();

            if (data.hasOwnProperty('sendMethodType')) {
                obj['sendMethodType'] = EmailSendMethodType.constructFromObject(data['sendMethodType']);
            }
            if (data.hasOwnProperty('smtpServerInfo')) {
                obj['smtpServerInfo'] = SmtpServerInfo.constructFromObject(data['smtpServerInfo']);
            }
            if (data.hasOwnProperty('postmarkInfo')) {
                obj['postmarkInfo'] = EmailAuthorization.constructFromObject(data['postmarkInfo']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>EmailConfiguration</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>EmailConfiguration</code>.
     */
    static validateJSON(data) {
        // validate the optional field `smtpServerInfo`
        if (data['smtpServerInfo']) { // data not null
          SmtpServerInfo.validateJSON(data['smtpServerInfo']);
        }
        // validate the optional field `postmarkInfo`
        if (data['postmarkInfo']) { // data not null
          EmailAuthorization.validateJSON(data['postmarkInfo']);
        }

        return true;
    }


}



/**
 * @member {module:model/EmailSendMethodType} sendMethodType
 */
EmailConfiguration.prototype['sendMethodType'] = undefined;

/**
 * @member {module:model/SmtpServerInfo} smtpServerInfo
 */
EmailConfiguration.prototype['smtpServerInfo'] = undefined;

/**
 * @member {module:model/EmailAuthorization} postmarkInfo
 */
EmailConfiguration.prototype['postmarkInfo'] = undefined;






export default EmailConfiguration;

