/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AccountProofProperty model module.
 * @module model/AccountProofProperty
 * @version V1
 */
class AccountProofProperty {
    /**
     * Constructs a new <code>AccountProofProperty</code>.
     * @alias module:model/AccountProofProperty
     */
    constructor() { 
        
        AccountProofProperty.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AccountProofProperty</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AccountProofProperty} obj Optional instance to populate.
     * @return {module:model/AccountProofProperty} The populated <code>AccountProofProperty</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccountProofProperty();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('label')) {
                obj['label'] = ApiClient.convertToType(data['label'], 'String');
            }
            if (data.hasOwnProperty('dataType')) {
                obj['dataType'] = ApiClient.convertToType(data['dataType'], 'String');
            }
            if (data.hasOwnProperty('enum')) {
                obj['enum'] = ApiClient.convertToType(data['enum'], ['String']);
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AccountProofProperty</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AccountProofProperty</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['label'] && !(typeof data['label'] === 'string' || data['label'] instanceof String)) {
            throw new Error("Expected the field `label` to be a primitive type in the JSON string but got " + data['label']);
        }
        // ensure the json data is a string
        if (data['dataType'] && !(typeof data['dataType'] === 'string' || data['dataType'] instanceof String)) {
            throw new Error("Expected the field `dataType` to be a primitive type in the JSON string but got " + data['dataType']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['enum'])) {
            throw new Error("Expected the field `enum` to be an array in the JSON data but got " + data['enum']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
AccountProofProperty.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
AccountProofProperty.prototype['accountId'] = undefined;

/**
 * @member {String} label
 */
AccountProofProperty.prototype['label'] = undefined;

/**
 * @member {String} dataType
 */
AccountProofProperty.prototype['dataType'] = undefined;

/**
 * @member {Array.<String>} enum
 */
AccountProofProperty.prototype['enum'] = undefined;

/**
 * @member {Date} createdAt
 */
AccountProofProperty.prototype['createdAt'] = undefined;






export default AccountProofProperty;

