/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AccountChartCategoryData from './AccountChartCategoryData';
import AccountChartSeries from './AccountChartSeries';

/**
 * The AccountChartData model module.
 * @module model/AccountChartData
 * @version V1
 */
class AccountChartData {
    /**
     * Constructs a new <code>AccountChartData</code>.
     * @alias module:model/AccountChartData
     */
    constructor() { 
        
        AccountChartData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AccountChartData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AccountChartData} obj Optional instance to populate.
     * @return {module:model/AccountChartData} The populated <code>AccountChartData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccountChartData();

            if (data.hasOwnProperty('categories')) {
                obj['categories'] = ApiClient.convertToType(data['categories'], [AccountChartCategoryData]);
            }
            if (data.hasOwnProperty('series')) {
                obj['series'] = ApiClient.convertToType(data['series'], [AccountChartSeries]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AccountChartData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AccountChartData</code>.
     */
    static validateJSON(data) {
        if (data['categories']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['categories'])) {
                throw new Error("Expected the field `categories` to be an array in the JSON data but got " + data['categories']);
            }
            // validate the optional field `categories` (array)
            for (const item of data['categories']) {
                AccountChartCategoryData.validateJSON(item);
            };
        }
        if (data['series']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['series'])) {
                throw new Error("Expected the field `series` to be an array in the JSON data but got " + data['series']);
            }
            // validate the optional field `series` (array)
            for (const item of data['series']) {
                AccountChartSeries.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Array.<module:model/AccountChartCategoryData>} categories
 */
AccountChartData.prototype['categories'] = undefined;

/**
 * @member {Array.<module:model/AccountChartSeries>} series
 */
AccountChartData.prototype['series'] = undefined;






export default AccountChartData;

