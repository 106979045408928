/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import HttpContext from './HttpContext';
import PipeWriter from './PipeWriter';
import Stream from './Stream';

/**
 * The HttpResponse model module.
 * @module model/HttpResponse
 * @version V1
 */
class HttpResponse {
    /**
     * Constructs a new <code>HttpResponse</code>.
     * @alias module:model/HttpResponse
     */
    constructor() { 
        
        HttpResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>HttpResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/HttpResponse} obj Optional instance to populate.
     * @return {module:model/HttpResponse} The populated <code>HttpResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new HttpResponse();

            if (data.hasOwnProperty('httpContext')) {
                obj['httpContext'] = HttpContext.constructFromObject(data['httpContext']);
            }
            if (data.hasOwnProperty('statusCode')) {
                obj['statusCode'] = ApiClient.convertToType(data['statusCode'], 'Number');
            }
            if (data.hasOwnProperty('headers')) {
                obj['headers'] = ApiClient.convertToType(data['headers'], {'String': ['String']});
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = Stream.constructFromObject(data['body']);
            }
            if (data.hasOwnProperty('bodyWriter')) {
                obj['bodyWriter'] = PipeWriter.constructFromObject(data['bodyWriter']);
            }
            if (data.hasOwnProperty('contentLength')) {
                obj['contentLength'] = ApiClient.convertToType(data['contentLength'], 'Number');
            }
            if (data.hasOwnProperty('contentType')) {
                obj['contentType'] = ApiClient.convertToType(data['contentType'], 'String');
            }
            if (data.hasOwnProperty('cookies')) {
                obj['cookies'] = ApiClient.convertToType(data['cookies'], Object);
            }
            if (data.hasOwnProperty('hasStarted')) {
                obj['hasStarted'] = ApiClient.convertToType(data['hasStarted'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>HttpResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>HttpResponse</code>.
     */
    static validateJSON(data) {
        // validate the optional field `httpContext`
        if (data['httpContext']) { // data not null
          HttpContext.validateJSON(data['httpContext']);
        }
        // validate the optional field `body`
        if (data['body']) { // data not null
          Stream.validateJSON(data['body']);
        }
        // validate the optional field `bodyWriter`
        if (data['bodyWriter']) { // data not null
          PipeWriter.validateJSON(data['bodyWriter']);
        }
        // ensure the json data is a string
        if (data['contentType'] && !(typeof data['contentType'] === 'string' || data['contentType'] instanceof String)) {
            throw new Error("Expected the field `contentType` to be a primitive type in the JSON string but got " + data['contentType']);
        }

        return true;
    }


}



/**
 * @member {module:model/HttpContext} httpContext
 */
HttpResponse.prototype['httpContext'] = undefined;

/**
 * @member {Number} statusCode
 */
HttpResponse.prototype['statusCode'] = undefined;

/**
 * @member {Object.<String, Array.<String>>} headers
 */
HttpResponse.prototype['headers'] = undefined;

/**
 * @member {module:model/Stream} body
 */
HttpResponse.prototype['body'] = undefined;

/**
 * @member {module:model/PipeWriter} bodyWriter
 */
HttpResponse.prototype['bodyWriter'] = undefined;

/**
 * @member {Number} contentLength
 */
HttpResponse.prototype['contentLength'] = undefined;

/**
 * @member {String} contentType
 */
HttpResponse.prototype['contentType'] = undefined;

/**
 * @member {Object} cookies
 */
HttpResponse.prototype['cookies'] = undefined;

/**
 * @member {Boolean} hasStarted
 */
HttpResponse.prototype['hasStarted'] = undefined;






export default HttpResponse;

