<script>
export default {
  name: "confirmationModal",
  props: {
    eventToEmitOnConfirmation: {
      required: false,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    question: {
      required: true,
      type: String,
    },
    body: {
      required: true,
      type: String,
    },
    confirmButtonText: {
      required: false,
      type: String,
    },
    buttonCss: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      confirmButton: "Confirm",
      buttonCssType: "button-green",
    };
  },
  methods: {
    openModal() {
      this.open();
    },
    open() {
      this.$bvModal.show(this.name);
    },
    modalConfirmed() {
      if (this.eventToEmitOnConfirmation !== undefined)
        this.$root.$emit(this.eventToEmitOnConfirmation);
      this.$emit("confirmed");
      this.$bvModal.hide(this.name);
    },
  },
  mounted() {
    if (this.confirmButtonText !== undefined) {
      this.confirmButton = this.confirmButtonText;
    }
    if (this.buttonCss !== undefined) {
      this.buttonCssType = this.buttonCss;
    }
  },
};
</script>

<template>
  <div>
    <b-modal :id="name" size="lg">
      <template v-slot:modal-header>
        <alertHeader/>
        <div class="w-100">
          <h4>{{ question }}</h4>
          <div v-html="body"></div>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100 col-md-12 d-flex justify-content-md-end px-0">
          <b-button
              class="button gray-btn"
              @click="cancel()"
          >Cancel
          </b-button
          >
          <b-button
              class="green-btn"
              :class="buttonCssType"
              @click="modalConfirmed()"
          >{{ confirmButtonText }}
          </b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  width: 18%;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 16px 9px 16px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  min-width: 18%;
}

.gray-btn:hover {
  color: white;
}

</style>
