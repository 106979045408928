/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AuthTokenRefreshedResponse model module.
 * @module model/AuthTokenRefreshedResponse
 * @version V1
 */
class AuthTokenRefreshedResponse {
    /**
     * Constructs a new <code>AuthTokenRefreshedResponse</code>.
     * @alias module:model/AuthTokenRefreshedResponse
     */
    constructor() { 
        
        AuthTokenRefreshedResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AuthTokenRefreshedResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AuthTokenRefreshedResponse} obj Optional instance to populate.
     * @return {module:model/AuthTokenRefreshedResponse} The populated <code>AuthTokenRefreshedResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AuthTokenRefreshedResponse();

            if (data.hasOwnProperty('newToken')) {
                obj['newToken'] = ApiClient.convertToType(data['newToken'], 'String');
            }
            if (data.hasOwnProperty('refreshToken')) {
                obj['refreshToken'] = ApiClient.convertToType(data['refreshToken'], 'String');
            }
            if (data.hasOwnProperty('validTo')) {
                obj['validTo'] = ApiClient.convertToType(data['validTo'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AuthTokenRefreshedResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AuthTokenRefreshedResponse</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['newToken'] && !(typeof data['newToken'] === 'string' || data['newToken'] instanceof String)) {
            throw new Error("Expected the field `newToken` to be a primitive type in the JSON string but got " + data['newToken']);
        }
        // ensure the json data is a string
        if (data['refreshToken'] && !(typeof data['refreshToken'] === 'string' || data['refreshToken'] instanceof String)) {
            throw new Error("Expected the field `refreshToken` to be a primitive type in the JSON string but got " + data['refreshToken']);
        }

        return true;
    }


}



/**
 * @member {String} newToken
 */
AuthTokenRefreshedResponse.prototype['newToken'] = undefined;

/**
 * @member {String} refreshToken
 */
AuthTokenRefreshedResponse.prototype['refreshToken'] = undefined;

/**
 * @member {Date} validTo
 */
AuthTokenRefreshedResponse.prototype['validTo'] = undefined;






export default AuthTokenRefreshedResponse;

