/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofFileApprovalItem from './ProofFileApprovalItem';
import WorkflowStageApproverStatus from './WorkflowStageApproverStatus';

/**
 * The ProofApproverResponse model module.
 * @module model/ProofApproverResponse
 * @version V1
 */
class ProofApproverResponse {
    /**
     * Constructs a new <code>ProofApproverResponse</code>.
     * @alias module:model/ProofApproverResponse
     */
    constructor() { 
        
        ProofApproverResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofApproverResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofApproverResponse} obj Optional instance to populate.
     * @return {module:model/ProofApproverResponse} The populated <code>ProofApproverResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofApproverResponse();

            if (data.hasOwnProperty('approvalStatus')) {
                obj['approvalStatus'] = WorkflowStageApproverStatus.constructFromObject(data['approvalStatus']);
            }
            if (data.hasOwnProperty('feedBackText')) {
                obj['feedBackText'] = ApiClient.convertToType(data['feedBackText'], 'String');
            }
            if (data.hasOwnProperty('filesApprovalStatus')) {
                obj['filesApprovalStatus'] = ApiClient.convertToType(data['filesApprovalStatus'], [ProofFileApprovalItem]);
            }
            if (data.hasOwnProperty('confirmedChecklistItems')) {
                obj['confirmedChecklistItems'] = ApiClient.convertToType(data['confirmedChecklistItems'], ['String']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProofApproverResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProofApproverResponse</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['feedBackText'] && !(typeof data['feedBackText'] === 'string' || data['feedBackText'] instanceof String)) {
            throw new Error("Expected the field `feedBackText` to be a primitive type in the JSON string but got " + data['feedBackText']);
        }
        if (data['filesApprovalStatus']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['filesApprovalStatus'])) {
                throw new Error("Expected the field `filesApprovalStatus` to be an array in the JSON data but got " + data['filesApprovalStatus']);
            }
            // validate the optional field `filesApprovalStatus` (array)
            for (const item of data['filesApprovalStatus']) {
                ProofFileApprovalItem.validateJSON(item);
            };
        }
        // ensure the json data is an array
        if (!Array.isArray(data['confirmedChecklistItems'])) {
            throw new Error("Expected the field `confirmedChecklistItems` to be an array in the JSON data but got " + data['confirmedChecklistItems']);
        }

        return true;
    }


}



/**
 * @member {module:model/WorkflowStageApproverStatus} approvalStatus
 */
ProofApproverResponse.prototype['approvalStatus'] = undefined;

/**
 * @member {String} feedBackText
 */
ProofApproverResponse.prototype['feedBackText'] = undefined;

/**
 * @member {Array.<module:model/ProofFileApprovalItem>} filesApprovalStatus
 */
ProofApproverResponse.prototype['filesApprovalStatus'] = undefined;

/**
 * @member {Array.<String>} confirmedChecklistItems
 */
ProofApproverResponse.prototype['confirmedChecklistItems'] = undefined;






export default ProofApproverResponse;

