/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PdfExtractedJpgPage from './PdfExtractedJpgPage';
import ProofAnnotation from './ProofAnnotation';
import ProofFileMetadata from './ProofFileMetadata';

/**
 * The ProofVersionFile model module.
 * @module model/ProofVersionFile
 * @version V1
 */
class ProofVersionFile {
    /**
     * Constructs a new <code>ProofVersionFile</code>.
     * @alias module:model/ProofVersionFile
     */
    constructor() { 
        
        ProofVersionFile.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofVersionFile</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofVersionFile} obj Optional instance to populate.
     * @return {module:model/ProofVersionFile} The populated <code>ProofVersionFile</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofVersionFile();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionsAttachedTo')) {
                obj['proofVersionsAttachedTo'] = ApiClient.convertToType(data['proofVersionsAttachedTo'], ['Number']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('originalFileKey')) {
                obj['originalFileKey'] = ApiClient.convertToType(data['originalFileKey'], 'String');
            }
            if (data.hasOwnProperty('originalFileBytes')) {
                obj['originalFileBytes'] = ApiClient.convertToType(data['originalFileBytes'], 'Number');
            }
            if (data.hasOwnProperty('convertedFileKey')) {
                obj['convertedFileKey'] = ApiClient.convertToType(data['convertedFileKey'], 'String');
            }
            if (data.hasOwnProperty('convertedFileBytes')) {
                obj['convertedFileBytes'] = ApiClient.convertToType(data['convertedFileBytes'], 'Number');
            }
            if (data.hasOwnProperty('thumbnailFileKey')) {
                obj['thumbnailFileKey'] = ApiClient.convertToType(data['thumbnailFileKey'], 'String');
            }
            if (data.hasOwnProperty('deleted')) {
                obj['deleted'] = ApiClient.convertToType(data['deleted'], 'Boolean');
            }
            if (data.hasOwnProperty('isLiveWebProofing')) {
                obj['isLiveWebProofing'] = ApiClient.convertToType(data['isLiveWebProofing'], 'Boolean');
            }
            if (data.hasOwnProperty('mimeType')) {
                obj['mimeType'] = ApiClient.convertToType(data['mimeType'], 'String');
            }
            if (data.hasOwnProperty('convertedMimeType')) {
                obj['convertedMimeType'] = ApiClient.convertToType(data['convertedMimeType'], 'String');
            }
            if (data.hasOwnProperty('docTypeGroup')) {
                obj['docTypeGroup'] = ApiClient.convertToType(data['docTypeGroup'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'Date');
            }
            if (data.hasOwnProperty('toBeDeletedAt')) {
                obj['toBeDeletedAt'] = ApiClient.convertToType(data['toBeDeletedAt'], 'Date');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('conversionJobQueuedAt')) {
                obj['conversionJobQueuedAt'] = ApiClient.convertToType(data['conversionJobQueuedAt'], 'Date');
            }
            if (data.hasOwnProperty('conversionJobCompletedAt')) {
                obj['conversionJobCompletedAt'] = ApiClient.convertToType(data['conversionJobCompletedAt'], 'Date');
            }
            if (data.hasOwnProperty('conversionJobId')) {
                obj['conversionJobId'] = ApiClient.convertToType(data['conversionJobId'], 'String');
            }
            if (data.hasOwnProperty('conversionMessage')) {
                obj['conversionMessage'] = ApiClient.convertToType(data['conversionMessage'], 'String');
            }
            if (data.hasOwnProperty('failed')) {
                obj['failed'] = ApiClient.convertToType(data['failed'], 'Boolean');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'Number');
            }
            if (data.hasOwnProperty('pdfExtractedJpgPages')) {
                obj['pdfExtractedJpgPages'] = ApiClient.convertToType(data['pdfExtractedJpgPages'], [PdfExtractedJpgPage]);
            }
            if (data.hasOwnProperty('proofFileAnnotations')) {
                obj['proofFileAnnotations'] = ApiClient.convertToType(data['proofFileAnnotations'], [ProofAnnotation]);
            }
            if (data.hasOwnProperty('proofFileMetadata')) {
                obj['proofFileMetadata'] = ProofFileMetadata.constructFromObject(data['proofFileMetadata']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProofVersionFile</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProofVersionFile</code>.
     */
    static validateJSON(data) {
        // ensure the json data is an array
        if (!Array.isArray(data['proofVersionsAttachedTo'])) {
            throw new Error("Expected the field `proofVersionsAttachedTo` to be an array in the JSON data but got " + data['proofVersionsAttachedTo']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['originalFileKey'] && !(typeof data['originalFileKey'] === 'string' || data['originalFileKey'] instanceof String)) {
            throw new Error("Expected the field `originalFileKey` to be a primitive type in the JSON string but got " + data['originalFileKey']);
        }
        // ensure the json data is a string
        if (data['convertedFileKey'] && !(typeof data['convertedFileKey'] === 'string' || data['convertedFileKey'] instanceof String)) {
            throw new Error("Expected the field `convertedFileKey` to be a primitive type in the JSON string but got " + data['convertedFileKey']);
        }
        // ensure the json data is a string
        if (data['thumbnailFileKey'] && !(typeof data['thumbnailFileKey'] === 'string' || data['thumbnailFileKey'] instanceof String)) {
            throw new Error("Expected the field `thumbnailFileKey` to be a primitive type in the JSON string but got " + data['thumbnailFileKey']);
        }
        // ensure the json data is a string
        if (data['mimeType'] && !(typeof data['mimeType'] === 'string' || data['mimeType'] instanceof String)) {
            throw new Error("Expected the field `mimeType` to be a primitive type in the JSON string but got " + data['mimeType']);
        }
        // ensure the json data is a string
        if (data['convertedMimeType'] && !(typeof data['convertedMimeType'] === 'string' || data['convertedMimeType'] instanceof String)) {
            throw new Error("Expected the field `convertedMimeType` to be a primitive type in the JSON string but got " + data['convertedMimeType']);
        }
        // ensure the json data is a string
        if (data['docTypeGroup'] && !(typeof data['docTypeGroup'] === 'string' || data['docTypeGroup'] instanceof String)) {
            throw new Error("Expected the field `docTypeGroup` to be a primitive type in the JSON string but got " + data['docTypeGroup']);
        }
        // ensure the json data is a string
        if (data['conversionJobId'] && !(typeof data['conversionJobId'] === 'string' || data['conversionJobId'] instanceof String)) {
            throw new Error("Expected the field `conversionJobId` to be a primitive type in the JSON string but got " + data['conversionJobId']);
        }
        // ensure the json data is a string
        if (data['conversionMessage'] && !(typeof data['conversionMessage'] === 'string' || data['conversionMessage'] instanceof String)) {
            throw new Error("Expected the field `conversionMessage` to be a primitive type in the JSON string but got " + data['conversionMessage']);
        }
        if (data['pdfExtractedJpgPages']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['pdfExtractedJpgPages'])) {
                throw new Error("Expected the field `pdfExtractedJpgPages` to be an array in the JSON data but got " + data['pdfExtractedJpgPages']);
            }
            // validate the optional field `pdfExtractedJpgPages` (array)
            for (const item of data['pdfExtractedJpgPages']) {
                PdfExtractedJpgPage.validateJSON(item);
            };
        }
        if (data['proofFileAnnotations']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['proofFileAnnotations'])) {
                throw new Error("Expected the field `proofFileAnnotations` to be an array in the JSON data but got " + data['proofFileAnnotations']);
            }
            // validate the optional field `proofFileAnnotations` (array)
            for (const item of data['proofFileAnnotations']) {
                ProofAnnotation.validateJSON(item);
            };
        }
        // validate the optional field `proofFileMetadata`
        if (data['proofFileMetadata']) { // data not null
          ProofFileMetadata.validateJSON(data['proofFileMetadata']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
ProofVersionFile.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
ProofVersionFile.prototype['accountId'] = undefined;

/**
 * @member {Number} proofId
 */
ProofVersionFile.prototype['proofId'] = undefined;

/**
 * @member {Number} proofVersionId
 */
ProofVersionFile.prototype['proofVersionId'] = undefined;

/**
 * @member {Array.<Number>} proofVersionsAttachedTo
 */
ProofVersionFile.prototype['proofVersionsAttachedTo'] = undefined;

/**
 * @member {String} name
 */
ProofVersionFile.prototype['name'] = undefined;

/**
 * @member {String} originalFileKey
 */
ProofVersionFile.prototype['originalFileKey'] = undefined;

/**
 * @member {Number} originalFileBytes
 */
ProofVersionFile.prototype['originalFileBytes'] = undefined;

/**
 * @member {String} convertedFileKey
 */
ProofVersionFile.prototype['convertedFileKey'] = undefined;

/**
 * @member {Number} convertedFileBytes
 */
ProofVersionFile.prototype['convertedFileBytes'] = undefined;

/**
 * @member {String} thumbnailFileKey
 */
ProofVersionFile.prototype['thumbnailFileKey'] = undefined;

/**
 * @member {Boolean} deleted
 */
ProofVersionFile.prototype['deleted'] = undefined;

/**
 * @member {Boolean} isLiveWebProofing
 */
ProofVersionFile.prototype['isLiveWebProofing'] = undefined;

/**
 * @member {String} mimeType
 */
ProofVersionFile.prototype['mimeType'] = undefined;

/**
 * @member {String} convertedMimeType
 */
ProofVersionFile.prototype['convertedMimeType'] = undefined;

/**
 * @member {String} docTypeGroup
 */
ProofVersionFile.prototype['docTypeGroup'] = undefined;

/**
 * @member {Date} deletedAt
 */
ProofVersionFile.prototype['deletedAt'] = undefined;

/**
 * @member {Date} toBeDeletedAt
 */
ProofVersionFile.prototype['toBeDeletedAt'] = undefined;

/**
 * @member {Date} createdAt
 */
ProofVersionFile.prototype['createdAt'] = undefined;

/**
 * @member {Date} conversionJobQueuedAt
 */
ProofVersionFile.prototype['conversionJobQueuedAt'] = undefined;

/**
 * @member {Date} conversionJobCompletedAt
 */
ProofVersionFile.prototype['conversionJobCompletedAt'] = undefined;

/**
 * @member {String} conversionJobId
 */
ProofVersionFile.prototype['conversionJobId'] = undefined;

/**
 * @member {String} conversionMessage
 */
ProofVersionFile.prototype['conversionMessage'] = undefined;

/**
 * @member {Boolean} failed
 */
ProofVersionFile.prototype['failed'] = undefined;

/**
 * @member {Number} order
 */
ProofVersionFile.prototype['order'] = undefined;

/**
 * @member {Array.<module:model/PdfExtractedJpgPage>} pdfExtractedJpgPages
 */
ProofVersionFile.prototype['pdfExtractedJpgPages'] = undefined;

/**
 * @member {Array.<module:model/ProofAnnotation>} proofFileAnnotations
 */
ProofVersionFile.prototype['proofFileAnnotations'] = undefined;

/**
 * @member {module:model/ProofFileMetadata} proofFileMetadata
 */
ProofVersionFile.prototype['proofFileMetadata'] = undefined;






export default ProofVersionFile;

