/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CreateResellerSubAccountRequest model module.
 * @module model/CreateResellerSubAccountRequest
 * @version V1
 */
class CreateResellerSubAccountRequest {
    /**
     * Constructs a new <code>CreateResellerSubAccountRequest</code>.
     * @alias module:model/CreateResellerSubAccountRequest
     * @param accountName {String} 
     * @param firstUserEmail {String} 
     * @param firstUserName {String} 
     * @param firstUserPhoneNumber {String} 
     * @param firstUserTitle {String} 
     */
    constructor(accountName, firstUserEmail, firstUserName, firstUserPhoneNumber, firstUserTitle) { 
        
        CreateResellerSubAccountRequest.initialize(this, accountName, firstUserEmail, firstUserName, firstUserPhoneNumber, firstUserTitle);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, accountName, firstUserEmail, firstUserName, firstUserPhoneNumber, firstUserTitle) { 
        obj['accountName'] = accountName;
        obj['firstUserEmail'] = firstUserEmail;
        obj['firstUserName'] = firstUserName;
        obj['firstUserPhoneNumber'] = firstUserPhoneNumber;
        obj['firstUserTitle'] = firstUserTitle;
    }

    /**
     * Constructs a <code>CreateResellerSubAccountRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CreateResellerSubAccountRequest} obj Optional instance to populate.
     * @return {module:model/CreateResellerSubAccountRequest} The populated <code>CreateResellerSubAccountRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateResellerSubAccountRequest();

            if (data.hasOwnProperty('accountName')) {
                obj['accountName'] = ApiClient.convertToType(data['accountName'], 'String');
            }
            if (data.hasOwnProperty('firstUserEmail')) {
                obj['firstUserEmail'] = ApiClient.convertToType(data['firstUserEmail'], 'String');
            }
            if (data.hasOwnProperty('firstUserName')) {
                obj['firstUserName'] = ApiClient.convertToType(data['firstUserName'], 'String');
            }
            if (data.hasOwnProperty('firstUserPhoneNumber')) {
                obj['firstUserPhoneNumber'] = ApiClient.convertToType(data['firstUserPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('firstUserTitle')) {
                obj['firstUserTitle'] = ApiClient.convertToType(data['firstUserTitle'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CreateResellerSubAccountRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CreateResellerSubAccountRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CreateResellerSubAccountRequest.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['accountName'] && !(typeof data['accountName'] === 'string' || data['accountName'] instanceof String)) {
            throw new Error("Expected the field `accountName` to be a primitive type in the JSON string but got " + data['accountName']);
        }
        // ensure the json data is a string
        if (data['firstUserEmail'] && !(typeof data['firstUserEmail'] === 'string' || data['firstUserEmail'] instanceof String)) {
            throw new Error("Expected the field `firstUserEmail` to be a primitive type in the JSON string but got " + data['firstUserEmail']);
        }
        // ensure the json data is a string
        if (data['firstUserName'] && !(typeof data['firstUserName'] === 'string' || data['firstUserName'] instanceof String)) {
            throw new Error("Expected the field `firstUserName` to be a primitive type in the JSON string but got " + data['firstUserName']);
        }
        // ensure the json data is a string
        if (data['firstUserPhoneNumber'] && !(typeof data['firstUserPhoneNumber'] === 'string' || data['firstUserPhoneNumber'] instanceof String)) {
            throw new Error("Expected the field `firstUserPhoneNumber` to be a primitive type in the JSON string but got " + data['firstUserPhoneNumber']);
        }
        // ensure the json data is a string
        if (data['firstUserTitle'] && !(typeof data['firstUserTitle'] === 'string' || data['firstUserTitle'] instanceof String)) {
            throw new Error("Expected the field `firstUserTitle` to be a primitive type in the JSON string but got " + data['firstUserTitle']);
        }

        return true;
    }


}

CreateResellerSubAccountRequest.RequiredProperties = ["accountName", "firstUserEmail", "firstUserName", "firstUserPhoneNumber", "firstUserTitle"];

/**
 * @member {String} accountName
 */
CreateResellerSubAccountRequest.prototype['accountName'] = undefined;

/**
 * @member {String} firstUserEmail
 */
CreateResellerSubAccountRequest.prototype['firstUserEmail'] = undefined;

/**
 * @member {String} firstUserName
 */
CreateResellerSubAccountRequest.prototype['firstUserName'] = undefined;

/**
 * @member {String} firstUserPhoneNumber
 */
CreateResellerSubAccountRequest.prototype['firstUserPhoneNumber'] = undefined;

/**
 * @member {String} firstUserTitle
 */
CreateResellerSubAccountRequest.prototype['firstUserTitle'] = undefined;






export default CreateResellerSubAccountRequest;

