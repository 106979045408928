/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ApiToken from './ApiToken';
import ApproverAuditRecord from './ApproverAuditRecord';
import ApproverType from './ApproverType';
import ReviewerAuditRecord from './ReviewerAuditRecord';
import WorkflowStageApproverStatus from './WorkflowStageApproverStatus';

/**
 * The Approver model module.
 * @module model/Approver
 * @version V1
 */
class Approver {
    /**
     * Constructs a new <code>Approver</code>.
     * @alias module:model/Approver
     */
    constructor() { 
        
        Approver.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Approver</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Approver} obj Optional instance to populate.
     * @return {module:model/Approver} The populated <code>Approver</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Approver();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('publicId')) {
                obj['publicId'] = ApiClient.convertToType(data['publicId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('initials')) {
                obj['initials'] = ApiClient.convertToType(data['initials'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('frequency')) {
                obj['frequency'] = ApiClient.convertToType(data['frequency'], 'String');
            }
            if (data.hasOwnProperty('timeOfDayToSend')) {
                obj['timeOfDayToSend'] = ApiClient.convertToType(data['timeOfDayToSend'], 'String');
            }
            if (data.hasOwnProperty('utcOffsetMin')) {
                obj['utcOffsetMin'] = ApiClient.convertToType(data['utcOffsetMin'], 'Number');
            }
            if (data.hasOwnProperty('timeZone')) {
                obj['timeZone'] = ApiClient.convertToType(data['timeZone'], 'String');
            }
            if (data.hasOwnProperty('totalProofs')) {
                obj['totalProofs'] = ApiClient.convertToType(data['totalProofs'], 'Number');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('completedProductTour')) {
                obj['completedProductTour'] = ApiClient.convertToType(data['completedProductTour'], 'Boolean');
            }
            if (data.hasOwnProperty('auditLog')) {
                obj['auditLog'] = ApiClient.convertToType(data['auditLog'], [ApproverAuditRecord]);
            }
            if (data.hasOwnProperty('reviewerAuditLog')) {
                obj['reviewerAuditLog'] = ApiClient.convertToType(data['reviewerAuditLog'], [ReviewerAuditRecord]);
            }
            if (data.hasOwnProperty('workflowStageApproverStatusLabel')) {
                obj['workflowStageApproverStatusLabel'] = ApiClient.convertToType(data['workflowStageApproverStatusLabel'], 'String');
            }
            if (data.hasOwnProperty('approverProofToken')) {
                obj['approverProofToken'] = ApiToken.constructFromObject(data['approverProofToken']);
            }
            if (data.hasOwnProperty('workflowStageApproverStatus')) {
                obj['workflowStageApproverStatus'] = WorkflowStageApproverStatus.constructFromObject(data['workflowStageApproverStatus']);
            }
            if (data.hasOwnProperty('approverType')) {
                obj['approverType'] = ApproverType.constructFromObject(data['approverType']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Approver</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Approver</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['publicId'] && !(typeof data['publicId'] === 'string' || data['publicId'] instanceof String)) {
            throw new Error("Expected the field `publicId` to be a primitive type in the JSON string but got " + data['publicId']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['initials'] && !(typeof data['initials'] === 'string' || data['initials'] instanceof String)) {
            throw new Error("Expected the field `initials` to be a primitive type in the JSON string but got " + data['initials']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        // ensure the json data is a string
        if (data['frequency'] && !(typeof data['frequency'] === 'string' || data['frequency'] instanceof String)) {
            throw new Error("Expected the field `frequency` to be a primitive type in the JSON string but got " + data['frequency']);
        }
        // ensure the json data is a string
        if (data['timeOfDayToSend'] && !(typeof data['timeOfDayToSend'] === 'string' || data['timeOfDayToSend'] instanceof String)) {
            throw new Error("Expected the field `timeOfDayToSend` to be a primitive type in the JSON string but got " + data['timeOfDayToSend']);
        }
        // ensure the json data is a string
        if (data['timeZone'] && !(typeof data['timeZone'] === 'string' || data['timeZone'] instanceof String)) {
            throw new Error("Expected the field `timeZone` to be a primitive type in the JSON string but got " + data['timeZone']);
        }
        if (data['auditLog']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['auditLog'])) {
                throw new Error("Expected the field `auditLog` to be an array in the JSON data but got " + data['auditLog']);
            }
            // validate the optional field `auditLog` (array)
            for (const item of data['auditLog']) {
                ApproverAuditRecord.validateJSON(item);
            };
        }
        if (data['reviewerAuditLog']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['reviewerAuditLog'])) {
                throw new Error("Expected the field `reviewerAuditLog` to be an array in the JSON data but got " + data['reviewerAuditLog']);
            }
            // validate the optional field `reviewerAuditLog` (array)
            for (const item of data['reviewerAuditLog']) {
                ReviewerAuditRecord.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['workflowStageApproverStatusLabel'] && !(typeof data['workflowStageApproverStatusLabel'] === 'string' || data['workflowStageApproverStatusLabel'] instanceof String)) {
            throw new Error("Expected the field `workflowStageApproverStatusLabel` to be a primitive type in the JSON string but got " + data['workflowStageApproverStatusLabel']);
        }
        // validate the optional field `approverProofToken`
        if (data['approverProofToken']) { // data not null
          ApiToken.validateJSON(data['approverProofToken']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
Approver.prototype['id'] = undefined;

/**
 * @member {String} publicId
 */
Approver.prototype['publicId'] = undefined;

/**
 * @member {String} name
 */
Approver.prototype['name'] = undefined;

/**
 * @member {String} initials
 */
Approver.prototype['initials'] = undefined;

/**
 * @member {String} email
 */
Approver.prototype['email'] = undefined;

/**
 * @member {String} frequency
 */
Approver.prototype['frequency'] = undefined;

/**
 * @member {String} timeOfDayToSend
 */
Approver.prototype['timeOfDayToSend'] = undefined;

/**
 * @member {Number} utcOffsetMin
 */
Approver.prototype['utcOffsetMin'] = undefined;

/**
 * @member {String} timeZone
 */
Approver.prototype['timeZone'] = undefined;

/**
 * @member {Number} totalProofs
 */
Approver.prototype['totalProofs'] = undefined;

/**
 * @member {Date} createdAt
 */
Approver.prototype['createdAt'] = undefined;

/**
 * @member {Boolean} completedProductTour
 */
Approver.prototype['completedProductTour'] = undefined;

/**
 * @member {Array.<module:model/ApproverAuditRecord>} auditLog
 */
Approver.prototype['auditLog'] = undefined;

/**
 * @member {Array.<module:model/ReviewerAuditRecord>} reviewerAuditLog
 */
Approver.prototype['reviewerAuditLog'] = undefined;

/**
 * @member {String} workflowStageApproverStatusLabel
 */
Approver.prototype['workflowStageApproverStatusLabel'] = undefined;

/**
 * @member {module:model/ApiToken} approverProofToken
 */
Approver.prototype['approverProofToken'] = undefined;

/**
 * @member {module:model/WorkflowStageApproverStatus} workflowStageApproverStatus
 */
Approver.prototype['workflowStageApproverStatus'] = undefined;

/**
 * @member {module:model/ApproverType} approverType
 */
Approver.prototype['approverType'] = undefined;






export default Approver;

