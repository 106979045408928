/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProofCustomField model module.
 * @module model/ProofCustomField
 * @version V1
 */
class ProofCustomField {
    /**
     * Constructs a new <code>ProofCustomField</code>.
     * @alias module:model/ProofCustomField
     */
    constructor() { 
        
        ProofCustomField.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofCustomField</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofCustomField} obj Optional instance to populate.
     * @return {module:model/ProofCustomField} The populated <code>ProofCustomField</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofCustomField();

            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('proofPropertyId')) {
                obj['proofPropertyId'] = ApiClient.convertToType(data['proofPropertyId'], 'Number');
            }
            if (data.hasOwnProperty('proofPropertyValue')) {
                obj['proofPropertyValue'] = ApiClient.convertToType(data['proofPropertyValue'], ['String']);
            }
            if (data.hasOwnProperty('accountProofPropertyId')) {
                obj['accountProofPropertyId'] = ApiClient.convertToType(data['accountProofPropertyId'], 'Number');
            }
            if (data.hasOwnProperty('accountProofPropertyEnum')) {
                obj['accountProofPropertyEnum'] = ApiClient.convertToType(data['accountProofPropertyEnum'], ['String']);
            }
            if (data.hasOwnProperty('accountProofPropertyLabel')) {
                obj['accountProofPropertyLabel'] = ApiClient.convertToType(data['accountProofPropertyLabel'], 'String');
            }
            if (data.hasOwnProperty('accountProofPropertyDataType')) {
                obj['accountProofPropertyDataType'] = ApiClient.convertToType(data['accountProofPropertyDataType'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProofCustomField</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProofCustomField</code>.
     */
    static validateJSON(data) {
        // ensure the json data is an array
        if (!Array.isArray(data['proofPropertyValue'])) {
            throw new Error("Expected the field `proofPropertyValue` to be an array in the JSON data but got " + data['proofPropertyValue']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['accountProofPropertyEnum'])) {
            throw new Error("Expected the field `accountProofPropertyEnum` to be an array in the JSON data but got " + data['accountProofPropertyEnum']);
        }
        // ensure the json data is a string
        if (data['accountProofPropertyLabel'] && !(typeof data['accountProofPropertyLabel'] === 'string' || data['accountProofPropertyLabel'] instanceof String)) {
            throw new Error("Expected the field `accountProofPropertyLabel` to be a primitive type in the JSON string but got " + data['accountProofPropertyLabel']);
        }
        // ensure the json data is a string
        if (data['accountProofPropertyDataType'] && !(typeof data['accountProofPropertyDataType'] === 'string' || data['accountProofPropertyDataType'] instanceof String)) {
            throw new Error("Expected the field `accountProofPropertyDataType` to be a primitive type in the JSON string but got " + data['accountProofPropertyDataType']);
        }

        return true;
    }


}



/**
 * @member {Number} accountId
 */
ProofCustomField.prototype['accountId'] = undefined;

/**
 * @member {Number} proofPropertyId
 */
ProofCustomField.prototype['proofPropertyId'] = undefined;

/**
 * @member {Array.<String>} proofPropertyValue
 */
ProofCustomField.prototype['proofPropertyValue'] = undefined;

/**
 * @member {Number} accountProofPropertyId
 */
ProofCustomField.prototype['accountProofPropertyId'] = undefined;

/**
 * @member {Array.<String>} accountProofPropertyEnum
 */
ProofCustomField.prototype['accountProofPropertyEnum'] = undefined;

/**
 * @member {String} accountProofPropertyLabel
 */
ProofCustomField.prototype['accountProofPropertyLabel'] = undefined;

/**
 * @member {String} accountProofPropertyDataType
 */
ProofCustomField.prototype['accountProofPropertyDataType'] = undefined;

/**
 * @member {Date} createdAt
 */
ProofCustomField.prototype['createdAt'] = undefined;






export default ProofCustomField;

