/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import WaitHandle from './WaitHandle';

/**
 * The CancellationToken model module.
 * @module model/CancellationToken
 * @version V1
 */
class CancellationToken {
    /**
     * Constructs a new <code>CancellationToken</code>.
     * @alias module:model/CancellationToken
     */
    constructor() { 
        
        CancellationToken.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CancellationToken</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CancellationToken} obj Optional instance to populate.
     * @return {module:model/CancellationToken} The populated <code>CancellationToken</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CancellationToken();

            if (data.hasOwnProperty('isCancellationRequested')) {
                obj['isCancellationRequested'] = ApiClient.convertToType(data['isCancellationRequested'], 'Boolean');
            }
            if (data.hasOwnProperty('canBeCanceled')) {
                obj['canBeCanceled'] = ApiClient.convertToType(data['canBeCanceled'], 'Boolean');
            }
            if (data.hasOwnProperty('waitHandle')) {
                obj['waitHandle'] = WaitHandle.constructFromObject(data['waitHandle']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CancellationToken</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CancellationToken</code>.
     */
    static validateJSON(data) {
        // validate the optional field `waitHandle`
        if (data['waitHandle']) { // data not null
          WaitHandle.validateJSON(data['waitHandle']);
        }

        return true;
    }


}



/**
 * @member {Boolean} isCancellationRequested
 */
CancellationToken.prototype['isCancellationRequested'] = undefined;

/**
 * @member {Boolean} canBeCanceled
 */
CancellationToken.prototype['canBeCanceled'] = undefined;

/**
 * @member {module:model/WaitHandle} waitHandle
 */
CancellationToken.prototype['waitHandle'] = undefined;






export default CancellationToken;

